import React, { ReactElement, useMemo } from "react";

import { ResponsiveHeader } from "@buildresonance/resonance-lib-layout-components";
import { useTheme } from "@mui/material";

const DEMAND_LIGHT_APP_BAR_COLOR = "#6d4c41";
const DEMAND_DARK_APP_BAR_COLOR = "#5d4037";

const DemandResponsiveHeader = ({
    children,
}: React.PropsWithChildren): ReactElement | null => {
    const theme = useTheme();

    const appBarColor = useMemo(
        () =>
            theme.palette.mode === "dark"
                ? DEMAND_DARK_APP_BAR_COLOR
                : DEMAND_LIGHT_APP_BAR_COLOR,
        [theme.palette.mode],
    );

    return (
        <ResponsiveHeader
            supercedeResponsiveSideDrawer
            appBarProps={{
                sx: {
                    backgroundColor: appBarColor,
                    color: theme.palette.getContrastText(appBarColor),
                },
            }}
        >
            {children}
        </ResponsiveHeader>
    );
};

export { DemandResponsiveHeader };
