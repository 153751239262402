import { ResonanceDomain } from "../../domainNames";
import { ResonanceDates } from "../../global";
import { DemandHqId } from "../demand/DemandIds";
import { SupplierId } from "../supply/SupplyIds";

import { ContentItemId } from "./CdnIds";

/** Accepted "image" media file extensions */
export enum ContentItemImageExtension {
    GIF = "gif",
    JPEG = "jpeg",
    JPG = "jpg",
    PNG = "png",
    WEBP = "webp",
}

/** The file extension */
export type ContentItemExtension = ContentItemImageExtension;

/** Upload Status */
export enum ContentItemStatus {
    /** Signed URL created, but not used */
    AwaitingUpload = "AWAITINGUPLOAD",
    Ready = "READY",
}

/** Media type */
export enum ResonanceCdnContentType {
    Image = "IMAGE",
    Static = "STATIC",
    Video = "VIDEO",
}

/** Just a convenience, this gets referenced often as it is a Primary Key */
export type ContentItemDomainEntityId = DemandHqId | SupplierId;

/** How did we receive this content item? */
export enum ContentItemSourceType {
    /** AKA POST */
    UploadMultiPart = "UPLOADMULTIPART",
    /** AKA PUT */
    UploadSinglePart = "UPLOADSINGLEPART",
    Url = "URL",
}

/**
 * The actual prefix and filename in S3.
 * This may need to become more flexible with additional content types, and feel free to Union.
 */
export type ContentItemS3Key =
    | `${ContentItemId}/${Lowercase<ResonanceCdnContentType>}.${ContentItemExtension}`
    | `videos/${ContentItemId}/${Lowercase<ResonanceCdnContentType>}.${ContentItemExtension}`;

export interface ContentItemIdFields {
    /**
     * @see CdnEntityIdPrefix.ContentItem
     * KSUID
     */
    contentItemId: ContentItemId;
}

export interface ContentItemDataFields {
    /** The file extension */
    contentExtension: ContentItemExtension;
    /** Upload Status */
    contentStatus: ContentItemStatus;
    /** Media type */
    contentType: ResonanceCdnContentType;
    domain: ResonanceDomain.Demand | ResonanceDomain.Supply;
    domainEntityId: ContentItemDomainEntityId;
    /**
     * This is used in conjunction with ContentItemStatus.
     * When a signed URL is created, item status is Awaiting, and this gets set.
     * If the signed URL is used, this gets set back to undefined.
     * If the signed URL never gets used, the metadata gets deleted.
     */
    expiresAt?: number;
    /**
     * Generated md5 for this file, use to compare to determine update need or for duplicate content.
     * Will not be populated in some statuses.
     */
    md5?: string | null;
    /** Key including prefix for associated S3 object */
    s3Key: ContentItemS3Key;
    /** The original URL, or some kind of file pointer, based on sourceType */
    sourceDetail: string;
    /** How did we receive this content item? */
    sourceType: ContentItemSourceType;
}

/**
 * Metadata record to hold additional information for an S3 Object, outside of S3, for easy lookup.
 */
export interface ContentItem
    extends ContentItemIdFields,
        ResonanceDates,
        ContentItemDataFields {}

export type ContentItemModelCreateInput = Omit<ContentItemDataFields, "s3Key">;

export type ContentItemModelUpdateInput = ContentItemIdFields &
    Partial<ContentItemDataFields>;

export type ContentItemApiCreateSignedUrlInput = Omit<
    ContentItemModelCreateInput,
    "contentStatus" | "expiresAt"
>;

export type ContentItemApiCreateFromUrlInput = Omit<
    ContentItemApiCreateSignedUrlInput,
    "contentExtension"
>;

export interface ContentItemWithSignedS3Url {
    contentItem: ContentItem;
    url: string;
    fields: {
        [key: string]: string;
    };
}
