import {
    CheckoutOrderApiUpdateInput,
    OrderApiCreateInput,
    OrderApiUpdateInput,
    OrderError,
} from "@buildresonance/resonance-lib-entities";
import { array, mixed, object, ObjectSchema, string } from "yup";

import { tagArraySchema } from "../common/tags";
import { resonanceAddressSchema } from "../global";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";
import { PHONE_NUMBER_OR_EMPTY_REGEX } from "../stringHelpers";

import {
    customerIdSchema,
    demandHqIdSchema,
    orderIdSchema,
    storefrontIdSchema,
} from "./demandIds";
import { orderShippingChargeSchema } from "./orderItem";

export const ORDER_CUSTOMER_NOTE_MAX_LENGTH = 512;
export const ORDER_EMAIL_ADDRESS_MAX_LENGTH = 256;
export const ORDER_NOTE_MAX_LENGTH = 512;
export const ORDER_PAYMENT_ID_MAX_LENGTH = 128;
export const ORDER_REFERENCE_NUMBER_MAX_LENGTH = 32;

export const orderErrorSchema: ObjectSchema<OrderError> = object({
    code: string().required(),
    message: string().required(),
    details: object(),
    errorType: string()
        .oneOf(["orderError", "completeCheckoutError"])
        .required(),
    name: string().required(),
    stack: string(),
    cause: mixed(),
}).noUnknown();

export const orderApiCreateInputSchema: ObjectSchema<OrderApiCreateInput> =
    object({
        billingAddress: resonanceAddressSchema
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        customerId: customerIdSchema.nullable(),
        customerNote: string().nullable().max(ORDER_CUSTOMER_NOTE_MAX_LENGTH),
        demandHqId: demandHqIdSchema.required(),
        emailAddress: string()
            .email()
            .required()
            .max(ORDER_EMAIL_ADDRESS_MAX_LENGTH),
        note: string().nullable().max(ORDER_NOTE_MAX_LENGTH),
        paymentId: string().nullable().max(ORDER_PAYMENT_ID_MAX_LENGTH),
        phoneNumber: string().matches(PHONE_NUMBER_OR_EMPTY_REGEX).nullable(),
        referenceNumber: string()
            .max(ORDER_REFERENCE_NUMBER_MAX_LENGTH)
            .nullable(),
        shippingAddress: resonanceAddressSchema
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        shippingCharges: array()
            .of(orderShippingChargeSchema.required())
            .nullable(),
        storefrontId: storefrontIdSchema.nullable(),
        tags: tagArraySchema.nullable(),
        errors: array().of(orderErrorSchema.required()).nullable(),
    }).noUnknown();

export const orderUpdateInputCommonSchema: ObjectSchema<CheckoutOrderApiUpdateInput> =
    object({
        billingAddress: resonanceAddressSchema
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        customerNote: string().nullable().max(ORDER_CUSTOMER_NOTE_MAX_LENGTH),
        emailAddress: string().email().max(ORDER_EMAIL_ADDRESS_MAX_LENGTH),
        paymentId: string().nullable().max(ORDER_PAYMENT_ID_MAX_LENGTH),
        phoneNumber: string().matches(PHONE_NUMBER_OR_EMPTY_REGEX).nullable(),
        shippingAddress: resonanceAddressSchema
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        shippingCharges: array()
            .of(orderShippingChargeSchema.required())
            .nullable(),
        tags: tagArraySchema.nullable(),
    }).noUnknown();

export const orderApiUpdateInputSchema: ObjectSchema<OrderApiUpdateInput> =
    orderUpdateInputCommonSchema
        .concat(
            object({
                note: string().nullable().max(ORDER_NOTE_MAX_LENGTH),
                orderId: orderIdSchema.required(),
                errors: array().of(orderErrorSchema.required()).nullable(),
            }),
        )
        .noUnknown();
