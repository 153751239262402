import {
    CustomerAddressApiCreateInput,
    CustomerAddressApiUpdateInput,
    CustomerApiCreateInput,
    CustomerApiUpdateInput,
    CustomerMarketingConsent,
    CustomerMarketingConsentLevel,
    CustomerStatus,
    CustomerStorefrontApiCreateInput,
    CustomerStorefrontApiUpdateInput,
} from "@buildresonance/resonance-lib-entities";
import { boolean, object, ObjectSchema, string } from "yup";

import { tagArraySchema } from "../common/tags";
import { dateTimeStringSchema } from "../dateHelpers";
import {
    resonanceAddressAllFieldsOptionalSchema,
    resonanceAddressSchema,
} from "../global";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";
import {
    PHONE_NUMBER_OR_EMPTY_REGEX,
    transformBlankStringToNull,
} from "../stringHelpers";

import {
    customerAddressIdSchema,
    customerIdSchema,
    demandHqIdSchema,
    storefrontIdSchema,
} from "./demandIds";

export const CUSTOMER_MARKETING_CONSENT_SOURCE_MAX_LENGTH = 64;
export const CUSTOMER_EMAIL_ADDRESS_MAX_LENGTH = 256;
export const CUSTOMER_EXTERNAL_CUSTOMER_ID_MAX_LENGTH = 256;
export const CUSTOMER_FIRST_NAME_MAX_LENGTH = 256;
export const CUSTOMER_LAST_NAME_MAX_LENGTH = 256;

export const customerAddressApiCreateInputSchema: ObjectSchema<CustomerAddressApiCreateInput> =
    resonanceAddressSchema
        .concat(
            object({
                customerId: customerIdSchema.required(),
                demandHqId: demandHqIdSchema.required(),
                storefrontId: storefrontIdSchema.required(),
            }),
        )
        .noUnknown();

export const customerAddressApiUpdateInputSchema: ObjectSchema<CustomerAddressApiUpdateInput> =
    resonanceAddressAllFieldsOptionalSchema
        .concat(
            object({
                customerAddressId: customerAddressIdSchema.required(),
            }),
        )
        .noUnknown();

export const customerMarketingConsentSchema: ObjectSchema<CustomerMarketingConsent> =
    object({
        consentedAt: dateTimeStringSchema.required(),
        consentLevel: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(CustomerMarketingConsentLevel))
            .required(),
        hasConsented: boolean().required(),
        source: string()
            .max(CUSTOMER_MARKETING_CONSENT_SOURCE_MAX_LENGTH)
            .nullable(),
    }).noUnknown();

export const customerStorefrontApiCreateInputSchema: ObjectSchema<CustomerStorefrontApiCreateInput> =
    object({
        defaultBillingAddressId: customerAddressIdSchema,
        defaultShippingAddressId: customerAddressIdSchema,
        emailAddress: string()
            .max(CUSTOMER_EMAIL_ADDRESS_MAX_LENGTH)
            .required(),
        emailMarketingConsent: customerMarketingConsentSchema
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        externalCustomerId: string()
            .max(CUSTOMER_EXTERNAL_CUSTOMER_ID_MAX_LENGTH)
            .nullable(),
        firstName: string().max(CUSTOMER_FIRST_NAME_MAX_LENGTH).nullable(),
        lastName: string().max(CUSTOMER_LAST_NAME_MAX_LENGTH).nullable(),
        phoneNumber: string().nullable().matches(PHONE_NUMBER_OR_EMPTY_REGEX),
        smsMarketingConsent: customerMarketingConsentSchema
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        tags: tagArraySchema.nullable(),
    }).noUnknown();

export const customerApiCreateInputSchema: ObjectSchema<CustomerApiCreateInput> =
    customerStorefrontApiCreateInputSchema
        .concat(
            object({
                demandHqId: demandHqIdSchema.required(),
                status: string()
                    .transform(transformBlankStringToNull)
                    .oneOf(Object.values(CustomerStatus))
                    .required(),
                storefrontId: storefrontIdSchema.required(),
            }),
        )
        .noUnknown();

export const customerStorefrontApiUpdateInputSchema: ObjectSchema<CustomerStorefrontApiUpdateInput> =
    object({
        defaultBillingAddressId: customerAddressIdSchema,
        defaultShippingAddressId: customerAddressIdSchema,
        emailAddress: string().max(CUSTOMER_EMAIL_ADDRESS_MAX_LENGTH),
        emailMarketingConsent: customerMarketingConsentSchema
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        firstName: string().max(CUSTOMER_FIRST_NAME_MAX_LENGTH).nullable(),
        lastName: string().max(CUSTOMER_LAST_NAME_MAX_LENGTH).nullable(),
        phoneNumber: string().nullable().matches(PHONE_NUMBER_OR_EMPTY_REGEX),
        smsMarketingConsent: customerMarketingConsentSchema
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        tags: tagArraySchema.nullable(),
    }).noUnknown();

export const customerApiUpdateInputSchema: ObjectSchema<CustomerApiUpdateInput> =
    customerStorefrontApiUpdateInputSchema
        .concat(
            object({
                customerId: customerIdSchema.required(),
                externalCustomerId: string()
                    .max(CUSTOMER_EXTERNAL_CUSTOMER_ID_MAX_LENGTH)
                    .nullable(),
                status: string()
                    .transform(transformBlankStringToNull)
                    .oneOf(Object.values(CustomerStatus)),
            }),
        )
        .noUnknown();
