import {
    HandlingCostUnit,
    ResonanceShippingMethod,
    SupplierVariantSupplementApiCreateInput,
    SupplierVariantSupplementApiUpdateInput,
    SupplierVariantSupplementInCompositeApiCreateInput,
} from "@buildresonance/resonance-lib-entities";
import { array, number, object, ObjectSchema, string } from "yup";

import { variantCommonSchema } from "../common/variantCommon";
import { transformNaNToNull } from "../numberHelpers";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import {
    supplierIdSchema,
    supplierProductIdSchema,
    supplierVariantIdSchema,
} from "./supplyIds";

export const SUPPLIER_VARIANT_SUPPLEMENT_SKU_MAX_LENGTH = 64;

export const supplierVariantSupplementHandlingCostSchema = object({
    amount: number().required().positive().transform(transformNaNToNull),
    unit: string()
        .transform(transformBlankStringToNull)
        .required()
        .oneOf(Object.values(HandlingCostUnit)),
})
    .noUnknown()
    .transform(requiredOnlyWhenExistsObjectTransformer)
    .default(undefined);

export const supplierVariantSupplementCommonCreateInputSchema: ObjectSchema<
    Pick<
        SupplierVariantSupplementApiCreateInput,
        | "availableShippingMethods"
        | "averageShippingCost"
        | "cost"
        | "handlingCost"
        | "recommendedPrice"
    >
> = object({
    availableShippingMethods: array()
        .of(
            string()
                .transform(transformBlankStringToNull)
                .oneOf(Object.values(ResonanceShippingMethod))
                .required(),
        )
        .nullable(),
    averageShippingCost: number()
        .min(0)
        .nullable()
        .transform(transformNaNToNull),
    cost: number().min(0).nullable(),
    handlingCost: supplierVariantSupplementHandlingCostSchema.nullable(),
    recommendedPrice: number().min(0).nullable().transform(transformNaNToNull),
}).noUnknown();

export const supplierVariantSupplementInCompositeApiCreateInputSchema: ObjectSchema<SupplierVariantSupplementInCompositeApiCreateInput> =
    variantCommonSchema
        .concat(supplierVariantSupplementCommonCreateInputSchema)
        .noUnknown();

export const supplierVariantSupplementApiCreateInputSchema: ObjectSchema<SupplierVariantSupplementApiCreateInput> =
    supplierVariantSupplementInCompositeApiCreateInputSchema
        .concat(
            object({
                supplierId: supplierIdSchema.required(),
                supplierProductId: supplierProductIdSchema.required(),
                supplierVariantId: supplierVariantIdSchema.required(),
            }),
        )
        .noUnknown();

export const supplierVariantSupplementApiUpdateInputSchema: ObjectSchema<SupplierVariantSupplementApiUpdateInput> =
    supplierVariantSupplementCommonCreateInputSchema
        .concat(variantCommonSchema)
        .concat(
            object({
                supplierVariantId: supplierVariantIdSchema.required(),
            }),
        )
        .noUnknown();
