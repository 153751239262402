export enum ScopePermission {
    Read = "read",
    Write = "write",
}

export enum ResourceType {
    Api = "api",
    Lib = "lib",
    Infrastructure = "infrastructure",
    Jobs = "jobs",
    Static = "static",
    WebApp = "webapp",
}

/** Just a time-saving device, extend it when you are using dates */
export interface ResonanceDates {
    /** An ISO Date string representing when the entity was created */
    createdAt: string;
    /** An ISO Date string representing when the entity was last updated */
    updatedAt: string;
}

/** For entities that exist in more than one domain and are synced either one or two ways. */
export interface ResonanceSyncFields {
    /**
     * An ISO Date string representing when the entity was last synced from the opposite domain.
     * If undefined or null, this entity was last updated in this domain.
     */
    syncedAt?: string | null;
}

export enum ResonanceLocaleType {
    EnglishUS = "EN_US",
}

export enum ResonanceCurrency {
    USD = "USD",
}

export const PRODUCT_NO_OPTION_TEXT = "No Option";

/**
 * This should be all encompassing and be used in place of every address in Resonance.
 */
export interface ResonanceAddress {
    /** The name associated with the address, i.e. the Person in the household */
    name: string;
    /** The company for the address, optional */
    company?: string | null;
    /** The first line of the street address */
    streetAddress1: string;
    /** The second line of the street address */
    streetAddress2?: string | null;
    /** The city of the address */
    city: string;
    /** The state of the address. Considered "region", "stateOrProvince", or even "administrativeAreaLevel1"...this is just well known for what it is. */
    state: string;
    /** The zip or postal code of the address */
    postalCode: string;
    /** The country of the address */
    country: string;
    /** The phone number of the address. Format +11231231234 */
    phoneNumber?: string | null;
}

/**
 * This is the overlap between collections, pages, and products.
 */
export type CommonSortInputFields = "PUBLISHED_AT" | "RELEVANCE" | "TITLE";

export type SortInput<TField extends string = CommonSortInputFields> = {
    direction: "ASC" | "DESC";
    field: TField;
};

/** Special value for TermFilter values to signify an Exists query (there is any value, or no value for NOT) */
export const EXISTS_SPECIAL_FILTER_VALUE = "#EXISTS#";

export type FilterOperator = "AND" | "OR" | "NOT";

export type StringFilterInput = {
    operator?: FilterOperator;
    values: string[];
};
