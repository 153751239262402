import {
    ReviewResponseApiCreateInput,
    ReviewResponseApiUpdateInput,
} from "@buildresonance/resonance-lib-entities";
import { object, ObjectSchema, string } from "yup";

import { demandHqIdSchema, reviewIdSchema } from "./demandIds";

export const REVIEW_RESPONSE_MESSAGE_MAX_LENGTH = 1000;
export const REVIEW_RESPONSE_RESPONDER_COMPANY_NAME_MAX_LENGTH = 512;
export const REVIEW_RESPONSE_RESPONDER_NAME_MAX_LENGTH = 512;

export const reviewResponseApiCreateInputSchema: ObjectSchema<ReviewResponseApiCreateInput> =
    object({
        demandHqId: demandHqIdSchema.required(),
        message: string().max(REVIEW_RESPONSE_MESSAGE_MAX_LENGTH).required(),
        responderCompanyName: string()
            .max(REVIEW_RESPONSE_RESPONDER_COMPANY_NAME_MAX_LENGTH)
            .nullable(),
        responderName: string()
            .max(REVIEW_RESPONSE_RESPONDER_NAME_MAX_LENGTH)
            .required(),
        reviewId: reviewIdSchema.required(),
    }).noUnknown();

export const reviewResponseApiUpdateInputSchema: ObjectSchema<ReviewResponseApiUpdateInput> =
    object({
        message: string().max(REVIEW_RESPONSE_MESSAGE_MAX_LENGTH),
        responderCompanyName: string()
            .max(REVIEW_RESPONSE_RESPONDER_COMPANY_NAME_MAX_LENGTH)
            .nullable(),
        responderName: string().max(REVIEW_RESPONSE_RESPONDER_NAME_MAX_LENGTH),
        reviewId: reviewIdSchema.required(),
    }).noUnknown();
