import { SupplyEntityIdPrefix } from "./SupplyEntity";

/** Uniquely, the string portion is a tracking number, not a KSUID */
export type FulfillmentTrackingId =
    `${SupplyEntityIdPrefix.FulfillmentTracking}-${string}`;

export type PurchaseOrderItemId =
    `${SupplyEntityIdPrefix.PurchaseOrderItem}-${string}`;

export type SupplierId = `${SupplyEntityIdPrefix.Supplier}-${string}`;

export type SupplierProductId =
    `${SupplyEntityIdPrefix.SupplierProduct}-${string}`;

export type SupplierVariantId =
    `${SupplyEntityIdPrefix.SupplierVariant}-${string}`;

export type SupplierNotificationId =
    `${SupplyEntityIdPrefix.SupplierNotification}-${string}`;

export type ResonanceSupplyIds =
    | FulfillmentTrackingId
    | SupplierId
    | SupplierProductId
    | SupplierVariantId;
