/** These are used for functionality throughout the platform, and match up with Cognito Group names. */
export enum IdentityGroup {
    Apps = "Apps",
    BetaUsers = "BetaUsers",
    ResonanceDevelopers = "ResonanceDevelopers",
    Users = "Users",
}

export const IDENTITY_GROUP_DESCRIPTIONS = {
    [IdentityGroup.Apps]: "Non-Human users.",
    [IdentityGroup.BetaUsers]: "Users that qualify for the beta program.",
    [IdentityGroup.ResonanceDevelopers]:
        "Software Developers who work at Resonance. Special security privileges apply.",
    [IdentityGroup.Users]: "Actual human users (as opposed to apps).",
};
