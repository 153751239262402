import { ResonanceEntityIdPrefix, ResonanceId } from ".";

export const parseResonanceId = (
    id: ResonanceId,
): {
    idPrefix: ResonanceEntityIdPrefix;
    entityId: string;
} => {
    const [idPrefix, ...restOfId] = id.split("-");
    return {
        idPrefix: idPrefix as ResonanceEntityIdPrefix,
        entityId: restOfId.join("-"),
    };
};
