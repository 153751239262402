import { useMemo } from "react";

import { useRouter } from "next/router";

export const SELECTED_TAB_QUERY_PARAM = "selectedTab";

export const useSelectedTab = <TTabValue extends string>(
    defaultTab: TTabValue,
): TTabValue => {
    const router = useRouter();

    const selectedTabFromParam = useMemo(
        () => router.query?.[SELECTED_TAB_QUERY_PARAM],
        [router.query],
    );

    if (
        typeof selectedTabFromParam !== "string" ||
        selectedTabFromParam === ""
    ) {
        return defaultTab;
    }

    return selectedTabFromParam as TTabValue;
};
