import {
    ResonanceDomainEntityId,
    ResonanceIdentityId,
} from "../../../entityComposites";
import { DemandBulkActionExportType } from "../../demand/bulk/DemandBulkActionExport";
import { SupplyBulkActionExportType } from "../../supply/bulk/SupplyBulkActionExport";

import { S3FileExtension, BulkActionStatus } from "./common";

/**
 * This is the data extracted from the S3 filename.
 * Can be parsed out or built.
 */
export type BulkActionExportPrefixAndKeyData<
    TDomainEntityId extends ResonanceDomainEntityId,
    TBulkActionExportType extends
        | DemandBulkActionExportType
        | SupplyBulkActionExportType,
> = {
    domainEntityId: TDomainEntityId;
    fileExtension: S3FileExtension;
    /** The requesting identityId */
    identityId: ResonanceIdentityId;
    exportType: TBulkActionExportType;
    exportStatus: BulkActionStatus;
    /** Arbitrary short name given by the user */
    name: string;
    /** S3 can only sort one way, and this makes that way be createdAt desc. You calculate the largest date you can and subtract right now. */
    ticksTillTheEndOfTime: number;
};

// No Data type, its just a string since we link the file directly.

/**
 * While not stored anywhere in S3, these parameters are used to determine how to export the data.
 */
export type BulkActionExportRequestParameters = {
    /** If not populated, defaults to 1M, approximately what fits in MS Excel. */
    maximumNumberOfItems?: number | null;
};
