import { ResonanceDates } from "../../global";
import { DemandHqId, ProductId } from "../demand/DemandIds";

import { SupplierId, SupplierProductId } from "./SupplyIds";

export interface SupplierProductProductIdFields {
    productId: ProductId;
    supplierProductId: SupplierProductId;
}

export interface SupplierProductProductRelationshipFields {
    demandHqId: DemandHqId;
    supplierId: SupplierId;
}

export interface SupplierProductProduct
    extends SupplierProductProductIdFields,
        SupplierProductProductRelationshipFields,
        Pick<ResonanceDates, "createdAt"> {}

// No create, same fields as update.

export type SupplierProductProductModelUpdateInput = Omit<
    SupplierProductProduct,
    keyof ResonanceDates
>;
