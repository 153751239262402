import React, { ReactElement, useMemo } from "react";

import { EventCard } from "@buildresonance/resonance-lib-display-components";
import { ResonanceEvent } from "@buildresonance/resonance-lib-entities";
import { usePublicIdentity } from "@hooks/graphql/queries/usePublicIdentity";
import { Box, useTheme } from "@mui/material";

export interface EventCardParentProps {
    demandHqEvent: ResonanceEvent;
}

export const EventCardParent = ({
    demandHqEvent,
}: EventCardParentProps): ReactElement => {
    const theme = useTheme();

    const { data, isLoading } = usePublicIdentity({
        identityId: demandHqEvent?.message?.metadata?.updatedBy,
    });

    const identityDisplayName = useMemo(() => {
        if (isLoading) {
            return "Loading...";
        }

        return (
            data?.publicIdentity?.displayName ??
            demandHqEvent?.message?.metadata?.updatedBy ??
            undefined
        );
    }, [
        data?.publicIdentity?.displayName,
        demandHqEvent?.message?.metadata?.updatedBy,
        isLoading,
    ]);

    return (
        <Box margin={theme.spacing(1)}>
            <EventCard
                eventMessage={demandHqEvent?.message}
                id={demandHqEvent?.messageId}
                updatingIdentityDisplayName={identityDisplayName}
            />
        </Box>
    );
};
