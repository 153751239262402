import { SeoFields } from "./SeoFields";

/**
 * These fields are common to all TypeContent entities (CollectionTypeContent, PageTypeContent)
 */
export interface CommonTypeContent extends SeoFields {
    contentAsString?: string | null;
    /**
     * This field may appear very similar to content, and it is, but this can be used for human-readable description on pages that are not the actual typeContent page.
     * It may also be used as a fallback for seoDescription if it is not provided.
     * If it is going to be displayed on the actual typeContent page, use Content(s).
     */
    description?: string | null;
    secondaryContentAsString?: string | null;
    subtitle?: string | null;
    /**
     * These tags function in the opposite direction of other tags, allowing you to choose several to focus on while displaying the content.
     * Commonly, these are used to filter or categorize content by providing options that match tags on the content itself.
     *
     * i.e. a tag of "Category,Foods" might be used to filter pages that also have a tag of "Category,Foods", a la a Page about Steak.
     *
     * The limits imposed on this field are much smaller than on the content itself, a max count of 100 instead of 1000.
     */
    tags?: {
        raw?: string[] | null;
    } | null;
    title?: string | null;
}
