import { ServiceClientMethod } from "../../serviceClient/serviceClientGlobal";

export enum CdnServiceClientRoute {
    Image = "Image",
    Images = "Images",
    ImageUploadUrls = "ImageUploadUrls",
}

export const CDN_SERVICE_CLIENT_ROUTES_TO_METHOD_MAP: Record<
    CdnServiceClientRoute,
    ServiceClientMethod[]
> = {
    [CdnServiceClientRoute.Image]: ["GET"],
    [CdnServiceClientRoute.Images]: ["POST"],
    [CdnServiceClientRoute.ImageUploadUrls]: ["POST", "PUT"],
};
