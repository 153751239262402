import {
    OrderTransactionApiCreateInput,
    OrderTransactionCurrencyExchangeAdjustment,
    OrderTransactionDataFields,
    OrderTransactionGateway,
    OrderTransactionType,
    ResonanceCurrency,
} from "@buildresonance/resonance-lib-entities";
import { number, object, ObjectSchema, string } from "yup";

import { dateTimeStringSchema } from "../dateHelpers";
import { transformNaNToNull } from "../numberHelpers";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import { demandHqIdSchema, orderIdSchema } from "./demandIds";

export const orderTransactionCurrencyExchangeAdjustmentSchema: ObjectSchema<OrderTransactionCurrencyExchangeAdjustment> =
    object({
        targetAmount: number().required().transform(transformNaNToNull),
        targetCurrency: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(ResonanceCurrency))
            .required(),
    }).noUnknown();

export const orderTransactionDataFieldsSchema: ObjectSchema<OrderTransactionDataFields> =
    object({
        amount: number().required().transform(transformNaNToNull),
        authorizationCode: string().nullable(),
        authorizationExpiresAt: dateTimeStringSchema.nullable(),
        currency: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(ResonanceCurrency))
            .required(),
        currencyExchangeAdjustment:
            orderTransactionCurrencyExchangeAdjustmentSchema
                .transform(requiredOnlyWhenExistsObjectTransformer)
                .default(undefined)
                .nullable(),
        errorMessage: string().nullable(),
        gateway: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(OrderTransactionGateway))
            .required(),
        gatewayMessage: string().nullable(),
        gatewayTransactionId: string().nullable(),
        orderTransactionType: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(OrderTransactionType))
            .required(),
        processedAt: dateTimeStringSchema.nullable(),
    }).noUnknown();

export const orderTransactionApiCreateInputSchema: ObjectSchema<OrderTransactionApiCreateInput> =
    orderTransactionDataFieldsSchema
        .concat(
            object({
                demandHqId: demandHqIdSchema.required(),
                orderId: orderIdSchema.required(),
            }),
        )
        .noUnknown();
