import {
    DemandHqId,
    DemandHqNotification,
    DemandHqNotificationCategory,
    NotificationStatus,
    RelayPageInfo,
} from "@buildresonance/resonance-lib-entities";
import {
    DEMAND_HQ_NOTIFICATION_FRAGMENT,
    DemandHqNotificationFragment,
} from "@hooks/graphql/fragments/demandHqNotification";
import { GraphQLClient, gql } from "graphql-request";
import { QueryFunction } from "react-query";

const LIST_DEMAND_HQ_NOTIFICATIONS_QUERY = gql`
    ${DEMAND_HQ_NOTIFICATION_FRAGMENT}
    query listDemandHqNotifications(
        $last: Int!
        $before: String
        $demandHqId: ID!
        $status: NotificationStatus!
        $category: DemandHqNotificationCategory
    ) {
        demandHq(demandHqId: $demandHqId) {
            notifications(
                last: $last
                before: $before
                category: $category
                status: $status
            ) {
                nodes {
                    ...demandHqNotificationFragment
                }
                pageInfo {
                    hasPreviousPage
                    startCursor
                }
            }
        }
    }
`;

export type ListDemandHqNotificationsNode = DemandHqNotification;

export type ListDemandHqNotificationsResponse = {
    demandHq: {
        notifications: {
            nodes: DemandHqNotificationFragment[];
            pageInfo: Pick<RelayPageInfo, "hasPreviousPage" | "startCursor">;
        };
    };
};

export type ListDemandHqNotificationsVariables = {
    demandHqId: DemandHqId;
    last: number;
    before?: string | null;
    category?: DemandHqNotificationCategory | null;
    status: NotificationStatus;
};

export type ListDemandHqNotificationsInfiniteQueryKey = [
    "listDemandHqNotificationsInfinite",
    ListDemandHqNotificationsVariables,
];

export const buildListDemandHqNotificationsInfiniteQueryKey = (
    variables: ListDemandHqNotificationsVariables,
): ListDemandHqNotificationsInfiniteQueryKey => [
    "listDemandHqNotificationsInfinite",
    variables,
];

export const listDemandHqNotifications =
    (
        client: GraphQLClient,
    ): QueryFunction<
        ListDemandHqNotificationsResponse,
        ListDemandHqNotificationsInfiniteQueryKey
    > =>
    async ({ queryKey, pageParam }) => {
        return await client.request<
            ListDemandHqNotificationsResponse,
            ListDemandHqNotificationsVariables
        >(LIST_DEMAND_HQ_NOTIFICATIONS_QUERY, {
            before: pageParam,
            ...queryKey[1],
        });
    };
