import {
    NotificationCommonFields,
    NotificationIdentityFields,
    NotificationStatus,
    ResonanceNotification,
} from "../../";

import { SupplierId, SupplierNotificationId } from "./SupplyIds";

/**
 * This denotes what the notification pertains to
 */
export enum SupplierNotificationCategory {
    PurchaseOrder = "PURCHASE_ORDER",
    Integrity = "INTEGRITY",
    Other = "OTHER",
    Apps = "APPS",
}

export interface SupplierNotificationIdFields {
    supplierNotificationId: SupplierNotificationId;
}

export interface SupplierNotificationRelationshipFields {
    /** The Supplier that this notification is targeted at */
    supplierId: SupplierId;
}

/**
 * An actionable notification for a Supplier
 * A User can acknowledge, ignore, or resolve a notification
 * In some cases, a suggested action can be taken by the User to resolve the notification
 */
export interface SupplierNotification
    extends SupplierNotificationIdFields,
        SupplierNotificationRelationshipFields,
        ResonanceNotification<SupplierNotificationCategory> {}

export type SupplierNotificationModelCreateInput = Pick<
    SupplierNotification,
    | keyof SupplierNotificationRelationshipFields
    | keyof NotificationIdentityFields
    | keyof NotificationCommonFields<SupplierNotificationCategory>
>;

export type SupplierNotificationModelUpdateInput =
    SupplierNotificationIdFields &
        Partial<
            Pick<
                SupplierNotification,
                | keyof NotificationIdentityFields
                | keyof NotificationCommonFields<SupplierNotificationCategory>
            >
        >;

export type SupplierNotificationApiCreateInput = Omit<
    SupplierNotificationModelCreateInput,
    keyof NotificationIdentityFields | "expiresAt" | "status"
>;

export type SupplierNotificationApiStatusUpdateInput =
    SupplierNotificationIdFields & {
        status: NotificationStatus;
    };
