/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const transformNaNToNull = (currentValue: any): number | null =>
    currentValue === Number(currentValue) ? currentValue : null;
/**
 * This one is a rarer use case, usually for positive-only numbers, where a zero would cause more problems than using the default.
 * @param currentValue
 * @returns
 */
export const transformZeroToNull = (currentValue: any): number | null =>
    currentValue === 0 ? null : currentValue;
