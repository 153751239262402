import {
    FulfillmentTrackingId,
    PurchaseOrderItemId,
    SupplierId,
    SupplierNotificationId,
    SupplierProductId,
    SupplierVariantId,
    SupplyEntityIdPrefix,
} from "@buildresonance/resonance-lib-entities";
import { string } from "yup";

// This file exists to fight circular dependencies.
export const fulfillmentTrackingIdSchema =
    string<FulfillmentTrackingId>().matches(
        new RegExp(`^${SupplyEntityIdPrefix.FulfillmentTracking}.*$`),
    );
export const purchaseOrderItemIdSchema = string<PurchaseOrderItemId>().matches(
    new RegExp(`^${SupplyEntityIdPrefix.PurchaseOrderItem}.*$`),
);
export const supplierIdSchema = string<SupplierId>().matches(
    new RegExp(`^${SupplyEntityIdPrefix.Supplier}.*`),
);
export const supplierNotificationIdSchema =
    string<SupplierNotificationId>().matches(
        new RegExp(`^${SupplyEntityIdPrefix.SupplierNotification}.*`),
    );
export const supplierProductIdSchema = string<SupplierProductId>().matches(
    new RegExp(`^${SupplyEntityIdPrefix.SupplierProduct}.*`),
);
export const supplierVariantIdSchema = string<SupplierVariantId>().matches(
    new RegExp(`^${SupplyEntityIdPrefix.SupplierVariant}.*`),
);
