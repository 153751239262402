import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { ResonanceShippingMethod } from "../common";
import { VariantCommon } from "../common/VariantCommon";

import { SupplierVariantId, SupplierId, SupplierProductId } from "./SupplyIds";

/** IdFields are the ones that are required to find the correct entity. Must come in all update methods. */
export interface SupplierVariantSupplementIdFields {
    supplierVariantId: SupplierVariantId;
}

export interface SupplierVariantSupplementRelationshipFields {
    supplierId: SupplierId;
    supplierProductId: SupplierProductId;
}

/** The unit of measure for Handling Cost */
export enum HandlingCostUnit {
    /** Amount is in a currency, i.e. $ */
    Currency = "CURRENCY",
    /** Amount is represented as a % of cost */
    PercentageOfCost = "PERCENTAGE_OF_COST",
}

export interface HandlingCost {
    /** Must be a positive number. */
    amount: number;
    unit: HandlingCostUnit;
}

/**
 * This object exists to supplement SupplierVariant, and just contains extra data that may be useful for the Supplier,
 * but also some important required properties that do not frequently change.
 * SupplierVariant contains the very-high frequency updated items, this contains the medium to low frequency items.
 *
 * @see SupplierVariant for very high frequency updating.
 */
export interface SupplierVariantSupplement
    extends SupplierVariantSupplementIdFields,
        SupplierVariantSupplementRelationshipFields,
        VariantCommon,
        ResonanceDates,
        ResonanceUpdatedBy {
    availableShippingMethods?: ResonanceShippingMethod[] | null;
    /**
     * A calculated metric for some kind of average for a common region. Uncommon.
     *
     * Must be a positive number.
     */
    averageShippingCost?: number | null;
    /**
     * COGS, or Cost of Goods Sold for this variant
     *
     * Required to publish the associated Product. Must be a positive number.
     */
    cost?: number | null;
    /** Additional warehouse handling charge, typically for larger items. */
    handlingCost?: HandlingCost | null;
    /**
     * This field is what the Suppler is recommending the demandHQ should likely offer the product at initially, and it is presumably between MSRP and MAP (or cost).
     * For a retailer acting as a Supplier, this is likely the price they are offering it at in retail.
     *
     * Must be a positive number.
     * */
    recommendedPrice?: number | null;
}

export type SupplierVariantSupplierGql = SupplierVariantSupplement & {
    handlingCostAsCurrency?: number | null;
};

export type SupplierVariantSupplementModelUpdateInput =
    SupplierVariantSupplementIdFields &
        Partial<
            Omit<
                SupplierVariantSupplement,
                | keyof SupplierVariantSupplementIdFields
                | keyof SupplierVariantSupplementRelationshipFields
                | keyof ResonanceDates
                | keyof ResonanceUpdatedBy
            >
        >;

export type SupplierVariantSupplementModelCreateInput = Omit<
    SupplierVariantSupplement,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type SupplierVariantSupplementApiUpdateInput = Omit<
    SupplierVariantSupplementModelUpdateInput,
    keyof ResonanceDates
>;

export type SupplierVariantSupplementApiCreateInput =
    SupplierVariantSupplementModelCreateInput;

export type SupplierVariantSupplementInCompositeApiCreateInput = Omit<
    SupplierVariantSupplementApiCreateInput,
    | keyof SupplierVariantSupplementIdFields
    | keyof SupplierVariantSupplementRelationshipFields
>;
