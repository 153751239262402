import {
    AssociationApiUpsertInput,
    AssociationType,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, array, object, string } from "yup";

import { demandHqIdSchema } from "../demandIds";

export const DEMAND_ASSOCIATION_VALUE_MAX_LENGTH = 256;

export const associationApiUpsertInputSchema: ObjectSchema<AssociationApiUpsertInput> =
    object({
        associationType: string()
            .oneOf(Object.values(AssociationType))
            .required(),
        demandHqId: demandHqIdSchema.required(),
        sourceValue: string()
            .required()
            .max(DEMAND_ASSOCIATION_VALUE_MAX_LENGTH),
        targetValues: array()
            .of(string().required().max(DEMAND_ASSOCIATION_VALUE_MAX_LENGTH))
            .required()
            .min(1, "At least one target value is required."),
    }).noUnknown();
