import { ResonanceDomain } from "../../domainNames";
import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates, ResonanceSyncFields } from "../../global";
import { DemandHqId } from "../demand";

import { SupplierId } from "./SupplyIds";

/**
 * Indicates what status the current relationship between a Supplier and a DemandHQ is in.
 */
export enum SupplierDemandHqStatus {
    /** Indicates that a DemandHq has the right to sell items from this Supplier. */
    Approved = "APPROVED",
    /** Indicates that the Supplier has refused access to a DemandHq. */
    Denied = "DENIED",
    /** Indicates that a DemandHq has requested the right to sell items from this Supplier, and is waiting on Supplier response. */
    Pending = "PENDING",
}

/** This map determines which domain can set the status to which values. */
export const SUPPLIER_DEMAND_HQ_STATUS_TO_RESPONSIBLE_DOMAIN_MAP: Record<
    SupplierDemandHqStatus,
    ResonanceDomain
> = {
    [SupplierDemandHqStatus.Approved]: ResonanceDomain.Supply,
    [SupplierDemandHqStatus.Denied]: ResonanceDomain.Supply,
    [SupplierDemandHqStatus.Pending]: ResonanceDomain.Demand,
};

export interface SupplierDemandHqIdFields {
    demandHqId: DemandHqId;
    supplierId: SupplierId;
}

/**
 * This represents a relationship between a Supplier and a DemandHQ.
 * Provided an approved status, a DemandHQ can sell items from a Supplier and this entity keeps track of that.
 * When in doubt, the entity in the Supply domain should be regarded as the source of truth.
 *
 * Note that this entity is called
 * @see DemandHqSupplier
 * in the DemandHq domain.
 * Aside from indexing, they are identical, and sync back and forth.
 */
export interface SupplierDemandHq
    extends SupplierDemandHqIdFields,
        ResonanceDates,
        ResonanceSyncFields,
        ResonanceUpdatedBy {
    status: SupplierDemandHqStatus;
}

export type SupplierDemandHqModelCreateInput = Omit<
    SupplierDemandHq,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type SupplierDemandHqApiCreateInput = SupplierDemandHqModelCreateInput;

/** Basically just status is mutable */
export type SupplierDemandHqModelUpdateInput = SupplierDemandHqIdFields &
    Partial<
        Omit<
            SupplierDemandHq,
            | keyof SupplierDemandHqIdFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type SupplierDemandHqApiUpdateInput = SupplierDemandHqModelUpdateInput;
