import {
    NotificationCommonFields,
    NotificationSentiment,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, object, string } from "yup";

import { resonanceActionSchema } from "../common/action";
import { transformBlankStringToNull } from "../stringHelpers";

export const NOTIFICATION_ACTION_LABEL_MAX_LENGTH = 32;
export const NOTIFICATION_CONTENT_MAX_LENGTH = 256;
export const NOTIFICATION_SENDER_REFERENCE_KEY_MAX_LENGTH = 128;
export const NOTIFICATION_SUBJECT_MAX_LENGTH = 64;

export const notificationCommonApiCreateInputSchema: ObjectSchema<
    Omit<NotificationCommonFields<string>, "expiresAt" | "status" | "category">
> = object({
    action: resonanceActionSchema.nullable(),
    actionLabel: string().max(NOTIFICATION_ACTION_LABEL_MAX_LENGTH).nullable(),
    content: string().max(NOTIFICATION_CONTENT_MAX_LENGTH).required(),
    senderReferenceKey: string()
        .max(NOTIFICATION_SENDER_REFERENCE_KEY_MAX_LENGTH)
        .nullable(),
    sentiment: string()
        .transform(transformBlankStringToNull)
        .oneOf(Object.values(NotificationSentiment))
        .nullable(),
    subject: string().max(NOTIFICATION_SUBJECT_MAX_LENGTH).required(),
}).noUnknown();
