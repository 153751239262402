import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";

import {
    CustomerId,
    DemandHqId,
    ProductId,
    ReviewId,
    StorefrontId,
} from "./DemandIds";

export type ReviewRating = 1 | 2 | 3 | 4 | 5;

export enum ReviewStatus {
    Draft = "DRAFT",
    NeedsModeration = "NEEDS_MODERATION",
    Published = "PUBLISHED",
    SoftDeleted = "SOFT_DELETED",
}

export interface ReviewIdFields {
    reviewId: ReviewId;
}

export interface ReviewRelationshipFields {
    demandHqId: DemandHqId;
    productId: ProductId;
}

/**
 * Reviews are ratings of a Product, usually given by a shopper or customer.
 * This must contain a 1-5 rating, and can optionally contain textual details, like a title and description of the reasoning for the rating.
 */
export interface Review
    extends ReviewIdFields,
        ReviewRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /** Optional customerId if the reviewer is logged in while writing one. */
    customerId?: CustomerId | null;
    /**
     * Other customers can mark reviews as helpful, and this field contains who have done so. Must be logged in to mark helpful.
     */
    customerIdsWhoFoundHelpful?: CustomerId[] | null;
    /** The primary content of the review, describing reasons for the rating and possible other helpful information for other shoppers. */
    description?: string | null;
    fromStorefrontId?: StorefrontId | null;
    rating: ReviewRating;
    reviewerName: string;
    /** Differentiate reviews from manufacturer, customers, etc. */
    reviewType?: string | null;
    status: ReviewStatus;
    title?: string | null;
}

export type ReviewModelCreateInput = Omit<
    Review,
    keyof ReviewIdFields | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type ReviewModelUpdateInput = ReviewIdFields &
    Partial<
        Omit<
            Review,
            | keyof ReviewIdFields
            | keyof ReviewRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type ReviewApiCreateInput = ReviewModelCreateInput;

export type ReviewApiUpdateInput = ReviewModelUpdateInput;

/**
 * What can be exposed to the storefront API.
 */
export type PublicReview = Omit<
    Review,
    | "customerIdsWhoFoundHelpful"
    | "customerId"
    | "fromStorefrontId"
    | "status"
    // Dates are OK to expose
    | keyof ResonanceUpdatedBy
>;
