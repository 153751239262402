import {
    AdminIdentityEntity,
    ALL_SUPPLY_SCOPES,
    ResonanceIdentityType,
    SupplierIdentityApiCreateInput,
    SupplierIdentityApiUpdateInput,
    SupplyRole,
} from "@buildresonance/resonance-lib-entities";
import { string, object, ObjectSchema, array } from "yup";

import {
    appIdSchema,
    resonanceIdentityIdSchema,
} from "../adminIdentity/adminIdentityIds";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import { supplierIdSchema } from "./supplyIds";

export const supplierIdentityApiCreateInputSchema: ObjectSchema<SupplierIdentityApiCreateInput> =
    object({
        identityId: resonanceIdentityIdSchema.required(),
        identityType: string<ResonanceIdentityType>()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf([AdminIdentityEntity.AppClient, AdminIdentityEntity.User]),
        identityParentEntityId: appIdSchema.nullable(),
        permissions: object({
            roles: array()
                .of(
                    string()
                        .transform(transformBlankStringToNull)
                        .required()
                        .oneOf(Object.values(SupplyRole)),
                )
                .required(),
            scopes: array()
                .of(
                    string()
                        .transform(transformBlankStringToNull)
                        .required()
                        .oneOf(ALL_SUPPLY_SCOPES),
                )
                .required(),
        }).required(),
        supplierId: supplierIdSchema.required(),
    }).noUnknown();

export const supplierIdentityApiUpdateInputSchema: ObjectSchema<SupplierIdentityApiUpdateInput> =
    object({
        identityId: resonanceIdentityIdSchema.required(),
        identityParentEntityId: appIdSchema.nullable(),
        permissions: object({
            roles: array()
                .of(
                    string()
                        .transform(transformBlankStringToNull)
                        .required()
                        .oneOf(Object.values(SupplyRole)),
                )
                .required(),
            scopes: array()
                .of(
                    string()
                        .transform(transformBlankStringToNull)
                        .required()
                        .oneOf(ALL_SUPPLY_SCOPES),
                )
                .required(),
        })
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        supplierId: supplierIdSchema.required(),
    }).noUnknown();
