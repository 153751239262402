import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import { useResonanceApi } from "@buildresonance/resonance-lib-frontend-admin-auth-passwordless";
import { buildGqlEndpoint, GqlApi } from "@lib/common/domainNames";
import { ClientError } from "graphql-request";
import { useQuery, UseQueryResult } from "react-query";

import {
    getDemandHq,
    GetDemandHqResponse,
    GetDemandHqQueryKey,
    buildGetDemandHqQueryKey,
} from "./getDemandHq";

const useDemandHq = ({
    demandHqId,
}: {
    demandHqId?: DemandHqId;
}): UseQueryResult<GetDemandHqResponse, ClientError> => {
    const { gqlClient, withAuthQueryOptions } = useResonanceApi({
        endpoint: buildGqlEndpoint(GqlApi.Demand),
    });

    return useQuery<
        GetDemandHqResponse,
        ClientError,
        GetDemandHqResponse,
        GetDemandHqQueryKey
    >(
        buildGetDemandHqQueryKey(demandHqId as DemandHqId),
        getDemandHq(gqlClient),
        withAuthQueryOptions({
            enabled: !!demandHqId,
        }),
    );
};

export { useDemandHq };
