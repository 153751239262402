export enum CdnEntity {
    ContentItem = "CONTENTITEM",
}

export enum CdnEntityIdPrefix {
    ContentItem = "cntnt",
}

export const CDN_ENTITY_TO_ID_PREFIX_MAP: Record<CdnEntity, CdnEntityIdPrefix> =
    {
        [CdnEntity.ContentItem]: CdnEntityIdPrefix.ContentItem,
    };
