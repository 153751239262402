import React, { ReactElement, useEffect, useRef, useState } from "react";

import {
    DemandHqId,
    StorefrontId,
} from "@buildresonance/resonance-lib-entities";
import {
    ResonanceMainNavAccordion,
    ResonanceMainNavItem,
    ResonanceMainNavSecondaryAction,
} from "@buildresonance/resonance-lib-nav-components";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import AddIcon from "@mui/icons-material/AddCircle";
import { useTheme, Box } from "@mui/material";
import { useRouter } from "next/router";

const DemandHqNavManagementMenu = ({
    handleDrawerClose,
}: {
    handleDrawerClose: () => void;
}): ReactElement => {
    const theme = useTheme();
    const router = useRouter();
    const demandHqId = router?.query?.demandHqId as DemandHqId;
    const storefrontId = router?.query?.storefrontId as StorefrontId;

    const [isExpanded, setIsExpanded] = useState(!storefrontId);

    const prevStorefrontIdRef = useRef(storefrontId);
    useEffect(() => {
        // If we are not on a storefront page, then travel to one, collapse the menu
        if (!prevStorefrontIdRef.current && storefrontId && isExpanded) {
            setIsExpanded(false);
        }

        prevStorefrontIdRef.current = storefrontId;
    }, [storefrontId, isExpanded]);

    return (
        <Box marginTop={theme.spacing(1)}>
            <ResonanceMainNavAccordion
                title="Management"
                expanded={isExpanded}
                onChange={(_, newIsExpanded) => setIsExpanded(newIsExpanded)}
            >
                <ResonanceMainNavItem
                    primaryText="Collections"
                    secondaryText="Of Products"
                    href={DemandAdminRoute.Collections.build(demandHqId)}
                    isSelected={[
                        DemandAdminRoute.Collections.pathname,
                        DemandAdminRoute.CollectionEdit.pathname,
                        DemandAdminRoute.CollectionImagesAdd.pathname,
                    ].includes(router.pathname)}
                    onClick={() => handleDrawerClose()}
                    secondaryAction={
                        <ResonanceMainNavSecondaryAction
                            href={DemandAdminRoute.CollectionCreate.build(
                                demandHqId,
                            )}
                            title="Create Collection"
                            IconComponent={AddIcon}
                            onClick={() => handleDrawerClose()}
                        />
                    }
                />
                <ResonanceMainNavItem
                    primaryText="Pages"
                    secondaryText="Content"
                    href={DemandAdminRoute.Pages.build(demandHqId)}
                    isSelected={[
                        DemandAdminRoute.Pages.pathname,
                        DemandAdminRoute.PageEdit.pathname,
                        DemandAdminRoute.PageImagesAdd.pathname,
                    ].includes(router.pathname)}
                    onClick={() => handleDrawerClose()}
                    secondaryAction={
                        <ResonanceMainNavSecondaryAction
                            href={DemandAdminRoute.PageCreate.build(demandHqId)}
                            title="Create Page"
                            IconComponent={AddIcon}
                            onClick={() => handleDrawerClose()}
                        />
                    }
                />
                <ResonanceMainNavItem
                    primaryText="Products"
                    secondaryText="And Variants"
                    href={DemandAdminRoute.Products.build(demandHqId)}
                    isSelected={[
                        DemandAdminRoute.Products.pathname,
                        DemandAdminRoute.ProductEdit.pathname,
                        DemandAdminRoute.VariantCreate.pathname,
                        DemandAdminRoute.VariantEdit.pathname,
                    ].includes(router.pathname)}
                    onClick={() => handleDrawerClose()}
                    secondaryAction={
                        <ResonanceMainNavSecondaryAction
                            href={DemandAdminRoute.ProductCreate.build(
                                demandHqId,
                            )}
                            title="Create Product"
                            IconComponent={AddIcon}
                            onClick={() => handleDrawerClose()}
                        />
                    }
                />
                <ResonanceMainNavItem
                    primaryText="Orders"
                    secondaryText="And Fulfillment"
                    href={DemandAdminRoute.Orders.build(demandHqId)}
                    isSelected={[
                        DemandAdminRoute.Orders.pathname,
                        DemandAdminRoute.OrderEdit.pathname,
                    ].includes(router.pathname)}
                    onClick={() => handleDrawerClose()}
                    secondaryAction={
                        <ResonanceMainNavSecondaryAction
                            href={DemandAdminRoute.OrderCreate.build(
                                demandHqId,
                            )}
                            title="Create Order"
                            IconComponent={AddIcon}
                            onClick={() => handleDrawerClose()}
                        />
                    }
                />
                <ResonanceMainNavItem
                    primaryText="Discounts"
                    secondaryText="For Marketing"
                    href={DemandAdminRoute.Discounts.build(demandHqId)}
                    isSelected={[
                        DemandAdminRoute.Discounts.pathname,
                        DemandAdminRoute.DiscountEdit.pathname,
                        DemandAdminRoute.DiscountCreate.pathname,
                    ].includes(router.pathname)}
                    onClick={() => handleDrawerClose()}
                    secondaryAction={
                        <ResonanceMainNavSecondaryAction
                            href={DemandAdminRoute.DiscountCreate.build(
                                demandHqId,
                            )}
                            title="Create Discount"
                            IconComponent={AddIcon}
                            onClick={() => handleDrawerClose()}
                        />
                    }
                />
                <ResonanceMainNavItem
                    primaryText="Taxonomy"
                    secondaryText="For Products"
                    href={DemandAdminRoute.Taxonomy.build(demandHqId)}
                    isSelected={[
                        DemandAdminRoute.Taxonomy.pathname,
                        DemandAdminRoute.TaxonomyCreate.pathname,
                    ].includes(router.pathname)}
                    onClick={() => handleDrawerClose()}
                />
                <ResonanceMainNavItem
                    primaryText="Associations"
                    secondaryText="Between Groups"
                    href={DemandAdminRoute.Associations.build(demandHqId)}
                    isSelected={[
                        DemandAdminRoute.Associations.pathname,
                    ].includes(router.pathname)}
                    onClick={() => handleDrawerClose()}
                />
                <ResonanceMainNavItem
                    primaryText="My Settings"
                    secondaryText="For This DemandHq"
                    href={DemandAdminRoute.MyDemandHqSettings.build(demandHqId)}
                    isSelected={[
                        DemandAdminRoute.MyDemandHqSettings.pathname,
                    ].includes(router.pathname)}
                    onClick={() => handleDrawerClose()}
                />
            </ResonanceMainNavAccordion>
        </Box>
    );
};

export { DemandHqNavManagementMenu };
