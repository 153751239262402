import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";

import { DemandHqId, ReviewId } from "./DemandIds";

export interface ReviewResponseIdFields {
    /** 1:1 relationship with Review */
    reviewId: ReviewId;
}

export interface ReviewResponseRelationshipFields {
    demandHqId: DemandHqId;
}

/**
 * ReviewResponses are the responses to a Review.
 * These typically occur on negative reviews, allowing the Manufacturer, Retailer, or Staff to respond to the shopper in public fashion.
 */
export interface ReviewResponse
    extends ReviewResponseIdFields,
        ReviewResponseRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /** The name of the company responding to the review. */
    responderCompanyName?: string | null;
    /** A human being's name, who probably works for a company associated with the Product being reviewed. */
    responderName: string;
    /** The actual response message to a review. */
    message: string;
}

export type ReviewResponseModelCreateInput = Omit<
    ReviewResponse,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type ReviewResponseModelUpdateInput = ReviewResponseIdFields &
    Partial<
        Omit<
            ReviewResponse,
            | keyof ReviewResponseIdFields
            | keyof ReviewResponseRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type ReviewResponseApiCreateInput = ReviewResponseModelCreateInput;

export type ReviewResponseApiUpdateInput = ReviewResponseModelUpdateInput;
