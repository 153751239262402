import {
    GqlActionRequest,
    LinkAction,
    ResonanceAction,
    ResonanceActionType,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, object, string } from "yup";

import { transformBlankStringToNull } from "../stringHelpers";

export const resonanceGqlActionRequestSchema: ObjectSchema<
    GqlActionRequest["actionRequest"]
> = object({
    query: string().required(),
    variables: object().nullable(),
});

export const resonanceLinkActionRequestSchema: ObjectSchema<
    LinkAction["actionRequest"]
> = object({
    href: string().required(),
    target: string(),
    rel: string(),
});

export const resonanceActionSchema = object({
    actionType: string()
        .transform(transformBlankStringToNull)
        .oneOf(Object.values(ResonanceActionType))
        .required(),
    actionRequest: object()
        .when("actionType", ([actionType]) => {
            switch (actionType) {
                case ResonanceActionType.GQL:
                    return resonanceGqlActionRequestSchema.required();
                case ResonanceActionType.Link:
                    return resonanceLinkActionRequestSchema.required();
                default:
                    throw new Error(
                        `Unknown action type: ${actionType}. Please add a schema for this action type.`,
                    );
            }
        })
        .required(),
}).noUnknown() as ObjectSchema<ResonanceAction>;
