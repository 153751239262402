/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const LETTERS_AND_NUMBERS_ONLY_REGEX = /^[a-zA-Z0-9]+$/;
export const LETTERS_AND_NUMBERS_ONLY_VALIDATION_MESSAGE =
    "Must be CAPITAL and lowercase letters and numbers only, no spaces, dashes, or underscores";

export const LETTERS_NUMBERS_SPACES_DASHES_UNDERSCORES_COMMAS_AMPERSANDS_PARENTHESES_PERCENTAGE_REGEX =
    /^[a-zA-Z0-9 ,-_&'()%]+$/;
export const LETTERS_NUMBERS_SPACES_DASHES_UNDERSCORES_COMMAS_AMPERSANDS_PARENTHESES_PERCENTAGE_VALIDATION_MESSAGE =
    "Can only contain CAPITAL and lowercase letters, numbers, spaces, dashes, underscores, ampersands, apostrophes, commas, percent sings, and parentheses";

export const EXCLUDES_PIPE_REGEX = /^[^|]+$/;
export const EXCLUDES_PIPE_VALIDATION_MESSAGE =
    "Cannot contain a pipe (|) character as these are used for delimiting";

export const PHONE_NUMBER_REGEX = /^\+[0-9]{11,13}$/;
export const PHONE_NUMBER_OR_EMPTY_REGEX = /^(\+[0-9]{11,13})?$/;

/** Can also be used as "part of URL" */
export const SLUG_REGEX = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g;
export const SLUG_REGEX_VALIDATION_MESSAGE =
    "Must be a valid slug (lowercase with dashes, no spaces and no starting with a dash or having two consecutive dashes)";

export const transformBlankStringToNull = (val: any): any | null =>
    val !== "" ? val : null;
