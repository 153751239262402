import {
    SupplierProductContentItemApiUpdateInput,
    SupplierProductContentItemAssociationApiCreateInput,
    SupplierProductContentItemAssociationInCompositeApiCreateInput,
    SupplierProductContentItemCommonApiCreateInput,
    SupplierProductContentItemIdFields,
    SupplierProductContentItemRelationshipFields,
    SupplierProductContentItemStatus,
    SupplierProductImageFromFileApiInput,
    SupplierProductImageFromUrlApiInput,
    SupplierProductImageFromUrlInCompositeApiInput,
} from "@buildresonance/resonance-lib-entities";
import { number, object, ObjectSchema, string } from "yup";

import { contentItemIdSchema } from "../cdn/cdnIds";
import {
    CONTENT_ITEM_ALT_MAX_LENGTH,
    CONTENT_ITEM_CATEGORY_MAX_LENGTH,
    CONTENT_ITEM_FILENAME_MAX_LENGTH,
    CONTENT_ITEM_FILE_EXTENSION_MAX_LENGTH,
} from "../cdn/contentItem";
import { CONTENT_ITEM_INPUT_ID_MAX_LENGTH } from "../common/contentItem";
import { transformNaNToNull, transformZeroToNull } from "../numberHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import { supplierIdSchema, supplierProductIdSchema } from "./supplyIds";

export const SUPPLIER_PRODUCT_CONTENT_ITEM_POSITION_MAX_VALUE = 9999999999;

const supplierProductContentItemCommonApiInCompositeInputSchema: ObjectSchema<
    Omit<
        SupplierProductContentItemCommonApiCreateInput,
        | keyof SupplierProductContentItemIdFields
        | keyof SupplierProductContentItemRelationshipFields
    >
> = object({
    alt: string().max(CONTENT_ITEM_ALT_MAX_LENGTH).nullable(),
    category: string().nullable().max(CONTENT_ITEM_CATEGORY_MAX_LENGTH),
    position: number()
        .integer()
        .positive()
        .nullable()
        .max(SUPPLIER_PRODUCT_CONTENT_ITEM_POSITION_MAX_VALUE)
        .transform(transformNaNToNull)
        .transform(transformZeroToNull),
}).noUnknown();

const supplierProductContentItemCommonApiCreateInputSchema: ObjectSchema<SupplierProductContentItemCommonApiCreateInput> =
    supplierProductContentItemCommonApiInCompositeInputSchema
        .concat(
            object({
                supplierId: supplierIdSchema.required(),
                supplierProductId: supplierProductIdSchema.required(),
            }),
        )
        .noUnknown();

export const supplierProductContentItemAssociationInCompositeApiCreateInputSchema: ObjectSchema<SupplierProductContentItemAssociationInCompositeApiCreateInput> =
    supplierProductContentItemCommonApiInCompositeInputSchema
        .concat(
            object({
                contentItemId: contentItemIdSchema.required(),
            }),
        )
        .noUnknown();

export const supplierProductContentItemAssociationApiCreateInputSchema: ObjectSchema<SupplierProductContentItemAssociationApiCreateInput> =
    supplierProductContentItemCommonApiCreateInputSchema
        .concat(
            object({
                contentItemId: contentItemIdSchema.required(),
            }),
        )
        .noUnknown();

export const supplierProductImageFromUrlApiInputSchema: ObjectSchema<SupplierProductImageFromUrlApiInput> =
    supplierProductContentItemCommonApiCreateInputSchema
        .concat(
            object({
                url: string().required().url(),
            }),
        )
        .noUnknown();

export const supplierProductImageFromUrlInCompositeApiInputSchema: ObjectSchema<SupplierProductImageFromUrlInCompositeApiInput> =
    supplierProductContentItemCommonApiInCompositeInputSchema
        .concat(
            object({
                contentItemInputId: string()
                    .nullable()
                    .max(CONTENT_ITEM_INPUT_ID_MAX_LENGTH),
                url: string().required().url(),
            }),
        )
        .noUnknown();

export const supplierProductImageFromFileApiInputSchema: ObjectSchema<SupplierProductImageFromFileApiInput> =
    supplierProductContentItemCommonApiCreateInputSchema
        .concat(
            object({
                fileName: string()
                    .required()
                    .max(CONTENT_ITEM_FILENAME_MAX_LENGTH),
                fileExtension: string()
                    .required()
                    .max(CONTENT_ITEM_FILE_EXTENSION_MAX_LENGTH),
            }),
        )
        .noUnknown();

export const supplierProductContentItemApiUpdateInputSchema: ObjectSchema<SupplierProductContentItemApiUpdateInput> =
    object({
        alt: string().max(CONTENT_ITEM_ALT_MAX_LENGTH).nullable(),
        category: string().nullable().max(CONTENT_ITEM_CATEGORY_MAX_LENGTH),
        contentItemId: contentItemIdSchema.required(),
        position: number()
            .integer()
            .positive()
            .nullable()
            .max(SUPPLIER_PRODUCT_CONTENT_ITEM_POSITION_MAX_VALUE)
            .transform(transformNaNToNull)
            .transform(transformZeroToNull),
        status: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(SupplierProductContentItemStatus)),
        supplierProductId: supplierProductIdSchema.required(),
    }).noUnknown();
