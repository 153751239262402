import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { ComponentRepeater } from "@buildresonance/resonance-lib-display-components";
import { ListDemandHqEventsResponse } from "@hooks/graphql/queries/useListDemandHqEvents/listDemandHqEvents";
import ToTheBottomIcon from "@mui/icons-material/ArrowDownward";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Card,
    CardContent,
    IconButton,
    Skeleton,
    Toolbar,
    Tooltip,
    alpha,
    useTheme,
} from "@mui/material";
import { ClientError } from "graphql-request";
import { UseInfiniteQueryResult } from "react-query";

import { EventCardParent } from "./EventCardParent";

export interface EventsDrawerProps {
    demandHqEventsResult: UseInfiniteQueryResult<
        ListDemandHqEventsResponse,
        ClientError
    >;
}

export const EventsDrawer = ({
    demandHqEventsResult,
}: EventsDrawerProps): ReactElement => {
    const theme = useTheme();

    const [hasBeenScrolled, setHasBeenScrolled] = useState(false);

    const scrollBoxRef = useRef<HTMLDivElement>(null);

    const scrollToTheBottom = useCallback(() => {
        const timeout = setTimeout(() => {
            scrollBoxRef.current?.scrollTo({
                behavior: "smooth",
                top: scrollBoxRef.current.scrollHeight,
            });
        }, 100);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (
            demandHqEventsResult.status === "success" &&
            !hasBeenScrolled &&
            scrollBoxRef.current
        ) {
            setHasBeenScrolled(true);
            scrollToTheBottom();
        }
    }, [demandHqEventsResult.status, hasBeenScrolled, scrollToTheBottom]);

    const demandHqEvents = useMemo(
        () =>
            demandHqEventsResult?.data?.pages?.flatMap(
                (page) => page?.events?.nodes,
            ) ?? [],
        [demandHqEventsResult],
    );

    const renderLoadMoreButton = (): ReactElement | null => {
        if (
            demandHqEventsResult?.status === "success" &&
            demandHqEventsResult.hasNextPage
        ) {
            return (
                <LoadingButton
                    size="small"
                    variant="outlined"
                    loading={demandHqEventsResult.isFetching}
                    onClick={() => demandHqEventsResult.fetchNextPage()}
                >
                    Load more
                </LoadingButton>
            );
        }

        return null;
    };

    const renderEvents = (): ReactElement => {
        switch (demandHqEventsResult.status) {
            case "idle":
            case "loading":
                return (
                    <ComponentRepeater repeat={10}>
                        <Skeleton height="5rem" />
                    </ComponentRepeater>
                );
            case "error":
                return (
                    <Card>
                        <CardContent>
                            There was an error loading events
                        </CardContent>
                    </Card>
                );
            case "success":
                return renderEventsSuccess();
        }
    };

    const renderEventsSuccess = (): ReactElement => {
        if (demandHqEvents.length === 0) {
            return (
                <Card>
                    <CardContent>No events yet</CardContent>
                </Card>
            );
        }

        return (
            <Box display="flex" flexDirection="column-reverse">
                {demandHqEvents?.map((demandHqEvent) => (
                    <EventCardParent
                        key={demandHqEvent.messageId}
                        demandHqEvent={demandHqEvent}
                    />
                ))}
            </Box>
        );
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent={"flex-end"}
            height="100%"
            width={270}
            paddingBottom={theme.spacing(2)}
            bgcolor={alpha(theme.palette.secondary.light, 0.1)}
        >
            <Toolbar />
            <Box overflow="auto" ref={scrollBoxRef}>
                <Box display="flex" padding={theme.spacing(1)}>
                    <Box flex="1">{renderLoadMoreButton()}</Box>
                    <Box>
                        <Tooltip title="To the Bottom">
                            <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => scrollToTheBottom()}
                            >
                                <ToTheBottomIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Box>{renderEvents()}</Box>
            </Box>
        </Box>
    );
};
