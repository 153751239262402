import { StorefrontAnalyticsSettingsApiUpdateInput } from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, array, object, string } from "yup";

import { storefrontIdSchema } from "../demandIds";

export const STOREFRONT_ANALYTICS_SETTINGS_ALLOWED_ORIGINS_MAX_LENGTH = 253;

export const storefrontAnalyticsSettingsApiUpdateInputSchema: ObjectSchema<StorefrontAnalyticsSettingsApiUpdateInput> =
    object({
        allowedOrigins: array()
            .of(string().required())
            .nullable()
            .test(
                `total-max-length`,
                `Total Origin Length should be less than ${STOREFRONT_ANALYTICS_SETTINGS_ALLOWED_ORIGINS_MAX_LENGTH} characters`,
                (value) =>
                    !value ||
                    value?.reduce(
                        (totalLength, origin) => totalLength + origin.length,
                        0,
                    ) <=
                        STOREFRONT_ANALYTICS_SETTINGS_ALLOWED_ORIGINS_MAX_LENGTH,
            ),
        storefrontId: storefrontIdSchema.required(),
    }).noUnknown();
