import {
    ResonanceCurrency,
    ResonanceLocaleType,
    DemandHqApiCreateInput,
    DemandHqApiUpdateInput,
    DemandSubscriptionCode,
} from "@buildresonance/resonance-lib-entities";
import { array, object, ObjectSchema, string } from "yup";

import { userNameSchema } from "../adminIdentity/adminIdentityIds";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import { demandHqIdSchema } from "./demandIds";

export const DEMAND_HQ_DESCRIPTION_MAX_LENGTH = 8000;
export const DEMAND_HQ_NAME_MAX_LENGTH = 128;

export const demandHqApiCreateInputSchema: ObjectSchema<DemandHqApiCreateInput> =
    object({
        defaultCurrency: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(ResonanceCurrency)),
        defaultLocaleType: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(ResonanceLocaleType)),
        demandSubscriptionCodes: array()
            .nullable()
            .of(
                string()
                    .transform(transformBlankStringToNull)
                    .oneOf(Object.values(DemandSubscriptionCode))
                    .required(),
            ),
        description: string().nullable().max(DEMAND_HQ_DESCRIPTION_MAX_LENGTH),
        name: string().required().max(DEMAND_HQ_NAME_MAX_LENGTH),
        notificationUserNames: object({
            catalog: array().of(userNameSchema.required()),
            commerce: array().of(userNameSchema.required()),
            orders: array().of(userNameSchema.required()),
        })
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
    }).noUnknown();

export const demandHqApiUpdateInputSchema: ObjectSchema<DemandHqApiUpdateInput> =
    object({
        defaultCurrency: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(ResonanceCurrency)),
        defaultLocaleType: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(ResonanceLocaleType)),
        demandHqId: demandHqIdSchema.required(),
        demandSubscriptionCodes: array()
            .nullable()
            .of(
                string()
                    .transform(transformBlankStringToNull)
                    .oneOf(Object.values(DemandSubscriptionCode))
                    .required(),
            ),
        description: string().nullable().max(DEMAND_HQ_DESCRIPTION_MAX_LENGTH),
        name: string().max(DEMAND_HQ_NAME_MAX_LENGTH),
        notificationUserNames: object({
            catalog: array().of(userNameSchema.required()),
            commerce: array().of(userNameSchema.required()),
            orders: array().of(userNameSchema.required()),
        })
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        ownerUserName: userNameSchema,
    }).noUnknown();
