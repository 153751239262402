import {
    buildUserFacingErrorMessage,
    useErrorSnackbar,
} from "@buildresonance/resonance-lib-display-components";
import { useResonanceApi } from "@buildresonance/resonance-lib-frontend-admin-auth-passwordless";
import {
    ListDemandHqCommentsResponse,
    buildListDemandHqCommentsInfiniteQueryKey,
} from "@hooks/graphql/queries/useListDemandHqComments/listDemandHqComments";
import { GqlApi, buildGqlEndpoint } from "@lib/common/domainNames";
import { ClientError } from "graphql-request";
import cloneDeep from "lodash.clonedeep";
import {
    InfiniteData,
    UseMutationResult,
    useMutation,
    useQueryClient,
} from "react-query";

import {
    CreateDemandHqCommentResponse,
    CreateDemandHqCommentVariables,
    createDemandHqComment,
} from "./createDemandHqComment";

export const useCreateDemandHqComment = (): UseMutationResult<
    CreateDemandHqCommentResponse,
    ClientError,
    CreateDemandHqCommentVariables
> => {
    const queryClient = useQueryClient();
    const { enqueueErrorSnackbar } = useErrorSnackbar({
        logLabel: "useCreateDemandHqComment",
        snackbarMessage: buildUserFacingErrorMessage("creating", "comment"),
    });

    const { gqlClient, withAuthMutationOptions } = useResonanceApi({
        endpoint: buildGqlEndpoint(GqlApi.Demand),
    });

    return useMutation<
        CreateDemandHqCommentResponse,
        ClientError,
        CreateDemandHqCommentVariables
    >(
        createDemandHqComment(gqlClient),
        withAuthMutationOptions({
            onSuccess: (data) => {
                if (data.createDemandHqComment) {
                    const queryKey = buildListDemandHqCommentsInfiniteQueryKey({
                        demandHqId: data.createDemandHqComment.demandHqId,
                        entityId: data.createDemandHqComment.entityId,
                    });

                    const existingData =
                        queryClient.getQueryData<
                            InfiniteData<ListDemandHqCommentsResponse>
                        >(queryKey);

                    if (existingData) {
                        const newData = cloneDeep(existingData);
                        newData?.pages?.[0]?.demandHq?.comments?.nodes?.unshift(
                            data.createDemandHqComment,
                        );

                        queryClient.setQueryData(queryKey, newData);
                    }

                    return;
                }

                enqueueErrorSnackbar(data);
            },
            onError: (error) => {
                enqueueErrorSnackbar(error);
            },
        }),
    );
};
