import React, { ReactElement, useMemo } from "react";

import { stringToColor } from "@buildresonance/resonance-lib-display-components";
import {
    DemandHqComment,
    PublicIdentityGql,
} from "@buildresonance/resonance-lib-entities";
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { extractInitialsFromWords } from "@util/extractInitialsFromWords";
import { formatAsRelativeTime } from "@util/formatAsRelativeTime";
import ReactMarkdown from "react-markdown";

export interface CommentCardProps {
    demandHqComment: DemandHqComment;
    identity?: PublicIdentityGql;
}

export const CommentCard = ({
    demandHqComment,
    identity,
}: CommentCardProps): ReactElement => {
    const theme = useTheme();

    const initials = useMemo(
        () => extractInitialsFromWords(identity?.displayName ?? ""),
        [identity?.displayName],
    );

    return (
        <Card>
            <CardHeader
                avatar={
                    <Tooltip title={identity?.displayName}>
                        <Avatar
                            sx={{
                                backgroundColor: identity?.displayName
                                    ? stringToColor(identity?.displayName)
                                    : undefined,
                                fontSize: 16,
                                height: 24,
                                width: 24,
                            }}
                        >
                            {initials}
                        </Avatar>
                    </Tooltip>
                }
                subheader={
                    <Tooltip
                        title={new Date(
                            demandHqComment.createdAt,
                        ).toLocaleString()}
                    >
                        <Typography variant="body2" color="textSecondary">
                            {formatAsRelativeTime(
                                new Date(demandHqComment.createdAt),
                            )}
                        </Typography>
                    </Tooltip>
                }
                disableTypography
                sx={{
                    padding: theme.spacing(1),
                }}
            />

            <CardContent sx={{ fontSize: 14 }}>
                <ReactMarkdown
                    components={{
                        p: ({ children }) => (
                            <Typography variant="body2">{children}</Typography>
                        ),
                    }}
                >
                    {demandHqComment.contentAsString}
                </ReactMarkdown>
            </CardContent>
        </Card>
    );
};
