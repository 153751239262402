import { SeoFields } from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, object, string } from "yup";

import { SLUG_REGEX, SLUG_REGEX_VALIDATION_MESSAGE } from "../stringHelpers";

export const SEO_DESCRIPTION_MAX_LENGTH = 170;
export const SEO_TITLE_MAX_LENGTH = 256;
export const SLUG_MAX_LENGTH = 128;

export const slugSchema = string()
    .matches(SLUG_REGEX, SLUG_REGEX_VALIDATION_MESSAGE)
    .max(SLUG_MAX_LENGTH);

export const seoFieldsSchema: ObjectSchema<SeoFields> = object({
    seoDescription: string().max(SEO_DESCRIPTION_MAX_LENGTH).nullable(),
    seoTitle: string().max(SEO_TITLE_MAX_LENGTH).nullable(),
});
