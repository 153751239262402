import React, { ReactElement, useRef } from "react";

import { ThemeProviders } from "@buildresonance/resonance-lib-core-components";
import {
    configureAuth,
    ResonanceAuthenticator,
} from "@buildresonance/resonance-lib-frontend-admin-auth-passwordless";
import { EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { AdminLayout } from "@layout/AdminLayout";
import { createEmotionCache } from "@lib/common/createEmotionCache";
import CssBaseline from "@mui/material/CssBaseline";
import ms from "ms";
import { AppProps } from "next/app";
import Head from "next/head";
import { QueryClient, QueryClientProvider } from "react-query";

configureAuth();

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface AdminAppProps extends AppProps {
    emotionCache?: EmotionCache;
}

const MyApp = ({
    Component,
    pageProps,
    emotionCache = clientSideEmotionCache,
}: AdminAppProps): ReactElement => {
    const queryClient = useRef(
        new QueryClient({
            defaultOptions: { queries: { staleTime: ms("5min") } },
        }),
    );

    return (
        <CacheProvider value={emotionCache}>
            <Head>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
            </Head>
            <QueryClientProvider client={queryClient.current}>
                <ThemeProviders>
                    <CssBaseline />
                    <ResonanceAuthenticator>
                        <AdminLayout gutterBottom gutterTop>
                            <Component {...pageProps} />
                        </AdminLayout>
                    </ResonanceAuthenticator>
                </ThemeProviders>
            </QueryClientProvider>
        </CacheProvider>
    );
};

export default MyApp;
