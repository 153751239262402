import { ResonanceDomain } from "../domainNames";
import {
    ResonanceServiceType,
    ResonanceServiceId,
    RESONANCE_SERVICE_ID_PREFIX,
} from "../domains/adminidentity/AdminIdentityIds";

export const RESONANCE_GET_SERVICE_CLIENT_TOKEN_FUNCTION_NAME =
    "prod-AdminIdentity-GetServiceClientToken";

export type ServiceClientMethod = "GET" | "POST" | "PUT" | "DELETE";

/**
 * Use this in the sending and receiving service to identify the service with continuity.
 *
 * @param domain ResonanceDomain
 * @param serviceType ResonanceServiceType
 * @returns ResonanceServiceId
 */
export const buildResonanceServiceId = (
    domain: ResonanceDomain | "storefront",
    serviceType: ResonanceServiceType,
): ResonanceServiceId =>
    `${RESONANCE_SERVICE_ID_PREFIX}-${domain}-${serviceType}`;

/**
 * Reverse engineer a ResonanceServiceId to get the domain and serviceType.
 *
 * @param serviceId
 * @returns { domain: ResonanceDomain; serviceType: ResonanceServiceType }
 */
export const parseResonanceServiceId = (
    serviceId: ResonanceServiceId,
): { domain: ResonanceDomain; serviceType: ResonanceServiceType } => {
    const [prefix, domain, serviceType] = serviceId.split("-");
    if (prefix !== RESONANCE_SERVICE_ID_PREFIX) {
        throw new Error(
            `Invalid ResonanceServiceId. Expected prefix ${RESONANCE_SERVICE_ID_PREFIX} but got ${prefix}.`,
        );
    }
    return {
        domain: domain as ResonanceDomain,
        serviceType: serviceType as ResonanceServiceType,
    };
};
