import {
    IdentityParentEntityId,
    ResonanceIdentityId,
    ResonanceIdentityType,
    ResonanceUpdatedBy,
} from "../../entityComposites";
import { Permissions } from "../../util/permissions";

import { PartnerId } from "./AdminIdentityIds";
import { PartnerRole, PartnerScope } from "./Partner";
import { PublicIdentityGql } from "./PublicIdentity";

export type PartnerIdentityPermissions = Permissions<PartnerRole, PartnerScope>;

export interface PartnerIdentityIdFields {
    partnerId: PartnerId;
    identityId: ResonanceIdentityId;
}

/**
 * This gives permission to partner-related activities to a ResonanceIdentity.
 * @see Partner
 * @see ResonanceIdentity
 */
export interface PartnerIdentity
    extends PartnerIdentityIdFields,
        ResonanceUpdatedBy {
    /** For instances where knowing humans from machines is important */
    identityType: ResonanceIdentityType;
    /**
     * This is used to hold AppId to prevent needing to look it up. Using a general name to enable unknown future uses.
     * This is unlikely to be used for Partners, but it's here just in case.
     */
    identityParentEntityId?: IdentityParentEntityId | null;
    /**
     * Permissions are additive, roles + scopes.
     * Roles are dynamic and subject to change. When in doubt, use scopes.
     */
    permissions: PartnerIdentityPermissions;
}

export type PartnerIdentityModelCreateInput = Omit<
    PartnerIdentity,
    keyof ResonanceUpdatedBy
>;

export type PartnerIdentityModelUpdateInput = PartnerIdentityIdFields &
    Partial<
        Omit<
            PartnerIdentity,
            | "identityType"
            | "identityParentEntityId"
            | keyof PartnerIdentityIdFields
            | keyof ResonanceUpdatedBy
        >
    >;

export type PartnerIdentityApiCreateInput = PartnerIdentityModelCreateInput;

export type PartnerIdentityApiUpdateInput = PartnerIdentityModelUpdateInput;

export interface PartnerIdentityGql extends PartnerIdentity {
    publicIdentity: PublicIdentityGql;
}
