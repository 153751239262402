import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { BaseQuickLink } from "../adminidentity/common/BaseQuickLink";

import { SupplierId } from "./SupplyIds";

/** Indicates which page the SupplierQuickLink would live on */
export enum SupplierQuickLinkType {
    SupplierProduct = "SUPPLIERPRODUCT",
    SupplierVariant = "SUPPLIERVARIANT",
}

export type SupplierQuickLinkIdFields = Pick<BaseQuickLink, "appId"> & {
    /** Indicates which page the SupplierQuickLink would live on */
    quickLinkType: SupplierQuickLinkType;
    /** The Id of the Supplier this quicklink pertains to (the one that has installed your app). */
    supplierId: SupplierId;
};

/** Represents a link to your app from inside the Resonance Supply Portal */
export interface SupplierQuickLink
    extends BaseQuickLink,
        SupplierQuickLinkIdFields {}

export type SupplierQuickLinkModelCreateInput = Omit<
    SupplierQuickLink,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type SupplierQuickLinkModelUpdateInput = SupplierQuickLinkIdFields &
    Partial<
        Omit<SupplierQuickLinkModelCreateInput, keyof SupplierQuickLinkIdFields>
    >;

export type SupplierQuickLinkApiCreateInput = SupplierQuickLinkModelCreateInput;

export type SupplierQuickLinkApiUpdateInput = SupplierQuickLinkModelUpdateInput;
