import { DemandEntityIdPrefix } from "./DemandEntity";
import { GatewayCredentialsId } from "./secure";

export type CollectionId = `${DemandEntityIdPrefix.Collection}-${string}`;
export type CollectionTypeContentId =
    `${DemandEntityIdPrefix.CollectionTypeContent}-${string}`;

export type CustomerId = `${DemandEntityIdPrefix.Customer}-${string}`;
export type CustomerAddressId =
    `${DemandEntityIdPrefix.CustomerAddress}-${string}`;

export type DemandHqId = `${DemandEntityIdPrefix.DemandHq}-${string}`;

export type DiscountId = `${DemandEntityIdPrefix.Discount}-${string}`;

export type NavId = `${DemandEntityIdPrefix.Nav}-${string}`;

export type OrderId = `${DemandEntityIdPrefix.Order}-${string}`;
export type OrderItemId = `${DemandEntityIdPrefix.OrderItem}-${string}`;
export type OrderTransactionId =
    `${DemandEntityIdPrefix.OrderTransaction}-${string}`;

export type PageId = `${DemandEntityIdPrefix.Page}-${string}`;
export type PageTypeContentId =
    `${DemandEntityIdPrefix.PageTypeContent}-${string}`;

export type ProductId = `${DemandEntityIdPrefix.Product}-${string}`;
export type VariantId = `${DemandEntityIdPrefix.Variant}-${string}`;

export type ReviewId = `${DemandEntityIdPrefix.Review}-${string}`;

export type StorefrontId = `${DemandEntityIdPrefix.Storefront}-${string}`;

export type TaxonomyBranchId =
    `${DemandEntityIdPrefix.TaxonomyBranch}-${string}`;

// Support Types

export type DemandHqCommentId =
    `${DemandEntityIdPrefix.DemandHqComment}-${string}`;
export type DemandHqNotificationId =
    `${DemandEntityIdPrefix.DemandHqNotification}-${string}`;

export type ResonanceDemandIds =
    | CollectionId
    | CustomerId
    | DemandHqId
    | GatewayCredentialsId
    | OrderId
    | OrderItemId
    | OrderTransactionId
    | PageId
    | ProductId
    | ReviewId
    | StorefrontId
    | VariantId;
