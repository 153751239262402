export enum EventsEntity {
    ResonanceEvent = "RESONANCEEVENT",
    ResonanceEventSubscription = "RESONANCEEVENTSUBSCRIPTION",
    ResonanceEventSubscriptionFailure = "RESONANCEEVENTSUBSCRIPTIONFAILURE",
}

export enum EventsEntityIdPrefix {
    ResonanceEvent = "event",
    ResonanceEventSubscription = "evsub",
    ResonanceEventSubscriptionFailure = "esbfl",
}

export const EVENTS_ENTITY_TO_ID_PREFIX_MAP: Record<
    EventsEntity,
    EventsEntityIdPrefix
> = {
    [EventsEntity.ResonanceEvent]: EventsEntityIdPrefix.ResonanceEvent,
    [EventsEntity.ResonanceEventSubscription]:
        EventsEntityIdPrefix.ResonanceEventSubscription,
    [EventsEntity.ResonanceEventSubscriptionFailure]:
        EventsEntityIdPrefix.ResonanceEventSubscriptionFailure,
};
