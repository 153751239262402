import React, { ReactElement } from "react";

import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import {
    ResonanceMainNavAccordion,
    ResonanceMainNavItem,
} from "@buildresonance/resonance-lib-nav-components";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import { useRouter } from "next/router";

export const DemandHqNavBulkManagement = ({
    handleDrawerClose,
}: {
    handleDrawerClose: () => void;
}): ReactElement => {
    const router = useRouter();
    const demandHqId = router?.query?.demandHqId as DemandHqId;

    return (
        <ResonanceMainNavAccordion title="Automation / Bulk">
            <ResonanceMainNavItem
                href={DemandAdminRoute.DemandHqAutomationSettings.build(
                    demandHqId,
                )}
                isSelected={[
                    DemandAdminRoute.DemandHqAutomationSettings.pathname,
                ].includes(router.pathname)}
                primaryText="Automation Settings"
                secondaryText="Automate your DemandHq"
                onClick={() => handleDrawerClose()}
            />

            <ResonanceMainNavItem
                href={DemandAdminRoute.ProductCreateFromSupplierProductsBulk.build(
                    demandHqId,
                )}
                isSelected={[
                    DemandAdminRoute.ProductCreateFromSupplierProductsBulk
                        .pathname,
                ].includes(router.pathname)}
                primaryText="Bulk Create Products"
                secondaryText="From Supplier Products"
                onClick={() => handleDrawerClose()}
            />

            <ResonanceMainNavItem
                href={DemandAdminRoute.Exports.build(demandHqId)}
                isSelected={[DemandAdminRoute.Exports.pathname].includes(
                    router.pathname,
                )}
                primaryText="Exports"
                secondaryText="Bulk Export Jobs"
                onClick={() => handleDrawerClose()}
            />

            <ResonanceMainNavItem
                href={DemandAdminRoute.Imports.build(demandHqId)}
                isSelected={[DemandAdminRoute.Imports.pathname].includes(
                    router.pathname,
                )}
                primaryText="Imports"
                secondaryText="Bulk Import Jobs"
                onClick={() => handleDrawerClose()}
            />
        </ResonanceMainNavAccordion>
    );
};
