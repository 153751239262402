import React, { ReactElement } from "react";

import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import { ResonanceIcon } from "@buildresonance/resonance-lib-icons";
import { AppBarTitle } from "@buildresonance/resonance-lib-layout-components";
import { useDemandHq } from "@hooks/graphql/queries/useDemandHq";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { useRouter } from "next/router";

const DemandHeaderLeftParent = ({
    setMobileNavIsOpen,
}: {
    setMobileNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const router = useRouter();
    const demandHqId = router?.query?.demandHqId as DemandHqId | undefined;

    const { data } = useDemandHq({ demandHqId });

    const renderMenuButton = (): ReactElement | null => {
        if (isDesktop) {
            return null;
        }
        return (
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setMobileNavIsOpen((prev) => !prev)}
            >
                <MenuIcon />
            </IconButton>
        );
    };

    return (
        <Box display="flex" flex="1" alignItems="center">
            {renderMenuButton()}
            <AppBarTitle
                icon={<ResonanceIcon fontSize="inherit" />}
                iconHref={DemandAdminRoute.MyDemandHqs.build()}
                iconTooltipTitle="Resonance"
                title="Demand"
                titleHref={DemandAdminRoute.MyDemandHqs.build()}
                subheader={data?.demandHq?.name}
                subheaderHref={
                    demandHqId
                        ? DemandAdminRoute.Products.build(demandHqId)
                        : undefined
                }
            />
        </Box>
    );
};

export { DemandHeaderLeftParent };
