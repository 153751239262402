import { useMemo } from "react";

import { useRouter } from "next/router";

export const useCurrentPageParam = (pageParam: string = "page"): number => {
    const { query } = useRouter();

    const currentPageParam = query[pageParam];

    return useMemo<number>(() => {
        if (typeof currentPageParam === "string") {
            const intParsedString = parseInt(currentPageParam, 10);
            if (!Number.isNaN(intParsedString) && intParsedString > 0)
                return intParsedString;
        }
        return 1;
    }, [currentPageParam]);
};
