import {
    TaxonomyBranchApiCreateInput,
    TaxonomyBranchApiUpdateInput,
    TaxonomyLevel,
} from "@buildresonance/resonance-lib-entities";
import { array, object, ObjectSchema, string } from "yup";

import { tagSchema } from "../common/tags";
import {
    SLUG_REGEX,
    SLUG_REGEX_VALIDATION_MESSAGE,
    transformBlankStringToNull,
} from "../stringHelpers";

import {
    collectionIdSchema,
    demandHqIdSchema,
    storefrontIdSchema,
    taxonomyBranchIdSchema,
} from "./demandIds";

export const TAXONOMY_BRANCH_TITLE_MAX_LENGTH = 256;
export const TAXONOMY_TREE_MAX_LENGTH = 128;

export const taxonomyLevelSchema = string()
    .transform(transformBlankStringToNull)
    .oneOf(Object.values(TaxonomyLevel));
export const taxonomyTreeSchema = string()
    .matches(SLUG_REGEX, SLUG_REGEX_VALIDATION_MESSAGE)
    .max(TAXONOMY_TREE_MAX_LENGTH);

export const taxonomyBranchApiCreateInputSchemaCommon: ObjectSchema<
    Omit<
        TaxonomyBranchApiCreateInput,
        "parentTaxonomyBranchId" | "storefrontIds"
    >
> = object({
    collectionId: collectionIdSchema.nullable(),
    demandHqId: demandHqIdSchema.required(),
    productTag: tagSchema.required(),
    taxonomyLevel: taxonomyLevelSchema.required(),
    taxonomyTree: taxonomyTreeSchema.required(),
    title: string().required().max(TAXONOMY_BRANCH_TITLE_MAX_LENGTH),
});

export const rootTaxonomyBranchApiCreateInputSchema: ObjectSchema<
    Omit<TaxonomyBranchApiCreateInput, "parentTaxonomyBranchId">
> = taxonomyBranchApiCreateInputSchemaCommon
    .concat(
        object({
            storefrontIds: array().of(storefrontIdSchema.required()).nullable(),
        }),
    )
    .noUnknown();

export const taxonomyBranchApiCreateInputSchema: ObjectSchema<
    Omit<TaxonomyBranchApiCreateInput, "storefrontIds">
> = taxonomyBranchApiCreateInputSchemaCommon
    .concat(
        object({
            parentTaxonomyBranchId: taxonomyBranchIdSchema.required(),
        }),
    )
    .noUnknown();

export const taxonomyBranchApiUpdateInputSchema: ObjectSchema<
    Omit<TaxonomyBranchApiUpdateInput, "storefrontIds">
> = object({
    collectionId: collectionIdSchema.nullable(),
    productTag: tagSchema,
    taxonomyBranchId: taxonomyBranchIdSchema.required(),
    title: string().max(TAXONOMY_BRANCH_TITLE_MAX_LENGTH),
}).noUnknown();

export const rootTaxonomyBranchApiUpdateInputSchema: ObjectSchema<TaxonomyBranchApiUpdateInput> =
    taxonomyBranchApiUpdateInputSchema
        .concat(
            object({
                storefrontIds: array()
                    .of(storefrontIdSchema.required())
                    .nullable(),
            }),
        )
        .noUnknown();
