import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";

import { CommonTypeContent } from "./common/CommonTypeContent";
import { PageTypeContentId, DemandHqId, StorefrontId } from "./DemandIds";

export interface PageTypeContentIdFields {
    pageTypeContentId: PageTypeContentId;
}

export interface PageTypeContentRelationshipFields {
    demandHqId: DemandHqId;
    /**
     * Any string to declare a category of this page, can be used to filter pages, limit 128 characters.
     * This string must be in slug format (lowercase and dashes).
     */
    pageType: string;
    storefrontId: StorefrontId;
}

/** This entity holds content specific to a pageType. This is generally used on a page that lists all pages for a pageType. */
export interface PageTypeContent
    extends PageTypeContentIdFields,
        PageTypeContentRelationshipFields,
        CommonTypeContent,
        ResonanceDates,
        ResonanceUpdatedBy {}

export type PageTypeContentModelCreateInput = Omit<
    PageTypeContent,
    | keyof PageTypeContentIdFields
    | keyof ResonanceDates
    | keyof ResonanceUpdatedBy
>;

export type PageTypeContentApiCreateInput = PageTypeContentModelCreateInput;

export type PageTypeContentModelUpdateInput = PageTypeContentIdFields &
    Omit<
        PageTypeContent,
        | keyof PageTypeContentIdFields
        | keyof PageTypeContentRelationshipFields
        | keyof ResonanceDates
        | keyof ResonanceUpdatedBy
    >;

export type PageTypeContentApiUpdateInput = PageTypeContentModelUpdateInput;
