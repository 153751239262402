import {
    IdentityParentEntityId,
    ResonanceIdentityId,
    ResonanceIdentityType,
    ResonanceUpdatedBy,
} from "../../entityComposites";
import { Permissions } from "../../util/permissions";
import { PublicAppGql } from "../adminidentity/App";
import { PublicIdentityGql } from "../adminidentity/PublicIdentity";

import { SupplyRole, SupplyScope } from "./SupplyEntity";
import { SupplierId } from "./SupplyIds";

export type SupplierIdentityPermissions = Permissions<SupplyRole, SupplyScope>;

/** IdFields are the ones that are required to find the correct entity. Must come in all update methods. */
export interface SupplierIdentityIdFields {
    supplierId: SupplierId;
    /** Could be an app, and this would be clientId */
    identityId: ResonanceIdentityId;
}

/**
 * This is a crossover connection entity with the AdminIdentity domain.
 * It connects a user or app to a Supplier, and grants that identity certain scopes.
 * This will be heavily used as authorization in the API.
 */
export interface SupplierIdentity
    extends SupplierIdentityIdFields,
        ResonanceUpdatedBy {
    /** For instances where knowing humans from machines is important */
    identityType: ResonanceIdentityType;
    /**
     * This is used to hold AppId to prevent needing to look it up. Using a general name to enable unknown future uses.
     */
    identityParentEntityId?: IdentityParentEntityId | null;
    /**
     * Permissions are additive, roles + scopes.
     * Roles are dynamic and subject to change. When in doubt, use scopes.
     */
    permissions: SupplierIdentityPermissions;
}

export type SupplierIdentityModelUpdateInput = SupplierIdentityIdFields &
    Partial<
        Omit<
            SupplierIdentity,
            | "identityType"
            | "parentEntityId"
            | keyof SupplierIdentityIdFields
            | keyof ResonanceUpdatedBy
        >
    >;

export type SupplierIdentityModelCreateInput = Omit<
    SupplierIdentity,
    keyof ResonanceUpdatedBy
>;

export type SupplierIdentityApiUpdateInput = SupplierIdentityModelUpdateInput;

export type SupplierIdentityApiCreateInput = SupplierIdentityModelCreateInput;

export interface SupplierIdentityGql extends SupplierIdentity {
    publicIdentityParent: PublicAppGql;
    publicIdentity: PublicIdentityGql;
}
