import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceAddress, ResonanceDates } from "../../global";
import { ResonanceShippingMethod } from "../common";
import {
    DemandHqId,
    OrderId,
    OrderItemId,
    StorefrontId,
} from "../demand/DemandIds";

import {
    PurchaseOrderItemId,
    SupplierId,
    SupplierProductId,
    SupplierVariantId,
} from "./SupplyIds";

/**
 * Indicates what status a PurchaseOrderItem is in. Generally, this field is calculated based on other properties.
 */
export enum PurchaseOrderItemStatus {
    /** Indicates that the PurchaseOrderItem was placed, but is no longer needed to be fulfilled. */
    Cancelled = "CANCELLED",
    /** Indicates that the PurchaseOrderItem is currently in the process of being created, and data is incomplete. */
    Draft = "DRAFT",
    /** Indicates that the PurchaseOrderItem is fully fulfilled and is now complete. */
    Fulfilled = "FULFILLED",
    /** Indicates that the PurchaseOrderItem is ready for processing, and data is complete. */
    Initial = "INITIAL",
    /** Indicates that the PurchaseOrderItem is in the process of being fulfilled. */
    PartiallyFulfilled = "PARTIALLY_FULFILLED",
    /** Indicates that the PurchaseOrderItem now just beginning to be fulfilled. This also means that the item can no longer be cancelled. */
    Processing = "PROCESSING",
}

/**
 * This represents a package of items being shipped. May be part or all of a PurchaseOrderItem's quantity.
 */
export interface PurchaseOrderItemFulfillment {
    fulfilledAt: string;
    fulfilledQuantity: number;
    shippingCarrier: string;
    trackingNumber: string;
}

export interface PurchaseOrderItemIdFields {
    purchaseOrderItemId: PurchaseOrderItemId;
}

export interface PurchaseOrderItemRelationshipFields {
    // Demand
    demandHqId: DemandHqId;
    orderId: OrderId;
    referenceNumber: string;
    orderItemId: OrderItemId;
    storefrontId?: StorefrontId | null;
    // Supply
    supplierId: SupplierId;
    supplierProductId: SupplierProductId;
    supplierVariantId: SupplierVariantId;
}

/**
 * These fields can only be set on Creation.
 */
export interface PurchaseOrderItemImmutableFields {
    barcode?: string | null;
    /**
     * Anything can go into these fields, but the following limits apply:
     * - Each key is limited to 64 characters.
     * - Each value is limited to 512 characters.
     * - Maximum of 5 key/value pairs.
     */
    customProperties?: Record<string, string> | null;
    externalProductId: string;
    externalVariantId: string;
    orderTags?: string[] | null;
    productTitle: string;
    quantity: number;
    shippingAddress: ResonanceAddress;
    shippingMethod: ResonanceShippingMethod;
    sku?: string | null;
    unitCost: number;
    variantTitle: string;
    vendor: string;
}

/**
 * These fields can be changed at the model level, but cannot be changed at the API level.
 * This means that the API must calculate these values.
 */
export interface PurchaseOrderItemReadOnlyFields {
    status: PurchaseOrderItemStatus;
}

export interface PurchaseOrderItemError {
    acknowledged?: boolean | null;
    cause?: string | null;
    code: string;
    details?: Record<string, string | number | boolean | string[] | null>;
    message: string;
}

/**
 * Contains information about a single item to be shipped to an address.
 * Most of the fields can only be set when the item is created, and as the item moves through the process,
 * less and less properties can be changed as the item moves closer to fulfillment.
 * The status field is read-only, calculated, and tracks progress.
 */
export interface PurchaseOrderItem
    extends PurchaseOrderItemIdFields,
        PurchaseOrderItemRelationshipFields,
        PurchaseOrderItemImmutableFields,
        PurchaseOrderItemReadOnlyFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    errors?: PurchaseOrderItemError[] | null;
    /** Order or PurchaseOrder id/number for an external Supplier */
    externalPurchaseOrderId?: string | null;
    /** Order Item or Purchase Order Item id/number for an external Supplier (used for uniqueness) */
    externalPurchaseOrderItemId?: string | null;
    fulfillments?: PurchaseOrderItemFulfillment[] | null;
    /** When is cancelled, cannot be processed. */
    isCancelled?: boolean | null;
    /** When is a draft, cannot be processed. */
    isDraft?: boolean | null;
    /** Once in processing, can no longer be draft or cancelled. */
    isProcessing?: boolean | null;
}

export type PurchaseOrderItemModelCreateInput = Omit<
    PurchaseOrderItem,
    | keyof PurchaseOrderItemIdFields
    | keyof ResonanceDates
    | keyof ResonanceUpdatedBy
>;

export type PurchaseOrderItemApiCreateInput = Omit<
    PurchaseOrderItemModelCreateInput,
    keyof PurchaseOrderItemReadOnlyFields
>;

export type PurchaseOrderItemModelUpdateInput = PurchaseOrderItemIdFields &
    Partial<
        Omit<
            PurchaseOrderItem,
            | keyof PurchaseOrderItemIdFields
            | keyof PurchaseOrderItemRelationshipFields
            | keyof PurchaseOrderItemImmutableFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type PurchaseOrderItemApiUpdateInput = Omit<
    PurchaseOrderItemModelUpdateInput,
    keyof PurchaseOrderItemReadOnlyFields
>;
