// eslint-disable-next-line import/prefer-default-export
export const requiredOnlyWhenExistsObjectTransformer = <TObjectSchema>(
    value: TObjectSchema,
): TObjectSchema | undefined => {
    // If any child property has a value, skip the transform
    if (
        value &&
        Object.values(value).some(
            (v) =>
                !(v === null || v === undefined || v === "" || Number.isNaN(v)),
        )
    ) {
        return value;
    }

    // Transform the value to undefined
    return undefined;
};
