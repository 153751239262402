import React, { useEffect, useMemo, useRef, useState } from "react";

import { useRouter } from "next/router";

export const CLEAR_SEARCH_OPTION_TEXT = "Clear search query";

export const parseSearchAutoCompleteOptions = (
    currentQuery: string | undefined,
    searchValue: string,
    autoCompleteQueryResponse: string[] | undefined,
): string[] => {
    if (searchValue && searchValue.length > 0) {
        return [
            ...new Set(
                [searchValue, ...(autoCompleteQueryResponse ?? [])].filter(
                    Boolean,
                ),
            ),
        ];
    }

    if (
        currentQuery &&
        currentQuery.length > 0 &&
        (!searchValue || searchValue.length === 0)
    ) {
        return [CLEAR_SEARCH_OPTION_TEXT];
    }

    return [];
};

/**
 * Hook used to power a Search-related Autocomplete and handle the URL Query parameter.
 *
 * Note that the URL Query Param is named query, causing some odd naming in router code (query.query).
 *
 * @param options
 */
export const useSearchAutocompleteParams = ({
    autoCompletes,
}: {
    autoCompletes: string[] | undefined;
}): {
    autoCompleteOptions: string[];
    searchValue: string;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>;
} => {
    const router = useRouter();

    const [searchValue, setSearchValue] = useState<string>(
        (router.query.query as string) ?? "",
    );

    const prevRouterQueryQuery = useRef<string | undefined>(
        router.query.query as string,
    );
    useEffect(() => {
        if (prevRouterQueryQuery.current !== router.query.query) {
            setSearchValue((router.query.query as string | undefined) ?? "");
            prevRouterQueryQuery.current = router.query.query as string;
        }
    }, [router.query.query]);

    const autoCompleteOptions = useMemo(
        () =>
            parseSearchAutoCompleteOptions(
                router.query.query as string | undefined,
                searchValue,
                autoCompletes,
            ),
        [autoCompletes, router.query.query, searchValue],
    );

    return { autoCompleteOptions, searchValue, setSearchValue };
};
