import {
    DemandHqNotificationApiCreateInput,
    DemandHqNotificationCategory,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, object, string } from "yup";

import { notificationCommonApiCreateInputSchema } from "../../common/notificationSchema";
import { demandHqIdSchema } from "../demandIds";

/**
 * @deprecated use `NOTIFICATION_ACTION_LABEL_MAX_LENGTH` from `schema/common/notificationSchema` instead.
 */
export const DEMAND_HQ_NOTIFICATION_ACTION_LABEL_MAX_LENGTH = 32;
/**
 * @deprecated use `NOTIFICATION_CONTENT_MAX_LENGTH` from `schema/common/notificationSchema` instead.
 */
export const DEMAND_HQ_NOTIFICATION_CONTENT_MAX_LENGTH = 256;
/**
 * @deprecated use `NOTIFICATION_SENDER_REFERENCE_KEY_MAX_LENGTH` from `schema/common/notificationSchema` instead.
 */
export const DEMAND_HQ_NOTIFICATION_SENDER_REFERENCE_KEY_MAX_LENGTH = 128;
/**
 * @deprecated use `NOTIFICATION_SUBJECT_MAX_LENGTH` from `schema/common/notificationSchema` instead.
 */
export const DEMAND_HQ_NOTIFICATION_SUBJECT_MAX_LENGTH = 64;

export const demandHqNotificationApiCreateInputSchema: ObjectSchema<DemandHqNotificationApiCreateInput> =
    notificationCommonApiCreateInputSchema
        .concat(
            object({
                category: string()
                    .oneOf(Object.values(DemandHqNotificationCategory))
                    .required(),
                demandHqId: demandHqIdSchema.required(),
            }),
        )
        .noUnknown();
