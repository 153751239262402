import {
    ResonanceIdentityId,
    ResonanceUpdatedBy,
} from "../../entityComposites";

import { UserName } from "./AdminIdentityIds";

export interface UserSupplementIdFields {
    userName: UserName;
}

/**
 * This entity exists outside Cognito to supplement with additional information,
 * but should be viewed as an extension of User.
 */
export interface UserSupplement
    extends UserSupplementIdFields,
        ResonanceUpdatedBy {
    /** ISO Date */
    invitedAt?: string;
    /** User Id of the inviter */
    invitedBy?: ResonanceIdentityId;
    acceptsMarketing?: boolean;
}

export type UserSupplementModelCreateInput = Omit<
    UserSupplement,
    keyof ResonanceUpdatedBy
>;

export type UserSupplementModelUpdateInput = UserSupplementIdFields &
    Partial<
        Omit<
            UserSupplement,
            keyof UserSupplementIdFields | keyof ResonanceUpdatedBy
        >
    >;
