import {
    SupplierQuickLinkApiCreateInput,
    SupplierQuickLinkApiUpdateInput,
    SupplierQuickLinkType,
} from "@buildresonance/resonance-lib-entities";
import { object, ObjectSchema, string } from "yup";

import { appIdSchema } from "../adminIdentity/adminIdentityIds";
import { QUICK_LINK_LABEL_MAX_LENGTH } from "../common/quickLink";
import { transformBlankStringToNull } from "../stringHelpers";

import { supplierIdSchema } from "./supplyIds";

export const supplierQuickLinkApiCreateInputSchema: ObjectSchema<SupplierQuickLinkApiCreateInput> =
    object({
        appId: appIdSchema.required(),
        dynamicUrl: string().required().url(),
        label: string().required().max(QUICK_LINK_LABEL_MAX_LENGTH),
        quickLinkType: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(SupplierQuickLinkType)),
        supplierId: supplierIdSchema.required(),
    }).noUnknown();

export const supplierQuickLinkApiUpdateInputSchema: ObjectSchema<SupplierQuickLinkApiUpdateInput> =
    object({
        appId: appIdSchema.required(),
        dynamicUrl: string().url(),
        label: string().max(QUICK_LINK_LABEL_MAX_LENGTH),
        quickLinkType: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(SupplierQuickLinkType)),
        supplierId: supplierIdSchema.required(),
    }).noUnknown();
