import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceAddress, ResonanceDates } from "../../global";
import { NullableObject } from "../../utility";

import { CustomerId, DemandHqId, OrderId, StorefrontId } from "./DemandIds";
import { OrderItem, OrderShippingCharge } from "./OrderItem";
import { OrderTransaction } from "./OrderTransaction";
import { CompleteCheckoutErrorCode } from "./storefront";

/** The status of the order currently, this field is read-only and can change many times over the course of the order */
export enum OrderStatus {
    /** Initial state for an order, during which it can contain invalid or incomplete information */
    Draft = "DRAFT",
    /** Order has been placed, waiting on authorization */
    Pending = "PENDING",
    /** Order has been authorized, but no PurchaseOrderItems have been created */
    Authorized = "AUTHORIZED",
    /** Order has been authorized and PurchaseOrderItem(s) created, awaiting fulfillments */
    AwaitingFulfillment = "AWAITING_FULFILLMENT",
    /** Order has been partially fulfilled, waiting on other fulfillments and for the existing fulfillments payment */
    PartiallyFulfilledAwaitingPayment = "PARTIALLY_FULFILLED_AWAITING_PAYMENT",
    /** Order has been partially fulfilled, and paid up for the currently fulfilled items */
    PartiallyFulfilled = "PARTIALLY_FULFILLED",
    /** Order has been fulfilled, waiting on payment */
    Fulfilled = "FULFILLED",
    /** Order has been paid, and is now complete. This can also indicate that an order was partially or fully refunded, but that process is also complete. */
    Complete = "COMPLETE",
    /** All items have been adjusted off of the order, and it is now cancelled. */
    Cancelled = "CANCELLED",
}

export interface OrderIdFields {
    orderId: OrderId;
}

export interface OrderRelationshipFields {
    customerId?: CustomerId | null;
    demandHqId: DemandHqId;
    storefrontId?: StorefrontId | null;
}

/**
 * Can be set on create, but never updated.
 */
export interface OrderImmutableFields {
    /** Not 100% guaranteed unique, but good enough for humans to exchange.
     *
     * @default Autogenerated in format AAAA-123456 based on orderId (deterministic)
     */
    referenceNumber: string;
}

/**
 * These fields can be changed at the model level, but cannot be changed at the API level.
 * This means that the API must calculate these values.
 */
export interface OrderReadOnlyFields {
    /** ISO Date indicated when an order should be considered complete, closed for some changes, and started to process. */
    placedAt?: string | null;
    /** The status of the order currently, this field is read-only and can change many times over the course of the order */
    status: OrderStatus;
}

export type OrderErrorType = "orderError" | "completeCheckoutError";

/**
 * An error on an order
 * The `errorType` field can be used to narrow the type so more specific errors can be handled with type safety.
 */
export interface OrderError<
    TOrderErrorType extends OrderErrorType = OrderErrorType,
> {
    code: TOrderErrorType extends "completeCheckoutError"
        ? CompleteCheckoutErrorCode
        : string;
    details?: Record<
        string,
        string | number | boolean | string[] | null | undefined
    >;
    errorType: TOrderErrorType;
    cause?: string | null;
    message: string;
    name: string;
    stack?: string;
}

/**
 * An order is a collection of items that a customer has purchased.
 * As an order accrues transactions, it changes status until it is eventually complete.
 * Orders rely on Suppliers to fulfill the OrderItems by creating PurchaseOrderItems (which are then fulfilled).
 */
export interface Order
    extends OrderIdFields,
        OrderRelationshipFields,
        OrderImmutableFields,
        OrderReadOnlyFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    billingAddress?: ResonanceAddress | null;
    /** A note provided by the customer at time of order */
    customerNote?: string | null;
    emailAddress: string;
    note?: string | null;
    paymentId?: string | null;
    phoneNumber?: string | null;
    shippingAddress?: ResonanceAddress | null;
    /** Shipping charges can be on the item or on the order level */
    shippingCharges?: OrderShippingCharge[] | null;
    errors?: OrderError[] | null;
}

/**
 * The tags associated with this order, a child object of the Order with a 1-1 relationship.
 * This is separate due to possible size, at 1000 * 96 = 96000 bytes, way more than we want to store with many GSI indexes, but we still want Dynamo speed (and cost is comparable to S3 for these params).
 * This enables us not to pull them for list queries and such as well, this should be obscured from consumers by the API, but still obvious at the model level.
 */
export interface OrderTags
    extends OrderIdFields,
        Pick<OrderRelationshipFields, "demandHqId">,
        ResonanceUpdatedBy {
    /** Tags for this order, limit to 1000 and 96 characters each. */
    raw?: string[] | null;
    updatedAt: string;
}

export type OrderModelCreateInput = Partial<
    NullableObject<OrderImmutableFields>
> & // Will be automatically set if not provided
    Omit<
        Order,
        | keyof OrderIdFields
        | keyof OrderImmutableFields
        | keyof ResonanceDates
        | keyof ResonanceUpdatedBy
    >;

export type OrderApiCreateInput = Omit<
    OrderModelCreateInput,
    keyof OrderReadOnlyFields
> & {
    /** Tags for this order, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

export type OrderModelUpdateInput = OrderIdFields &
    Partial<
        Omit<
            Order,
            | keyof OrderIdFields
            | keyof OrderRelationshipFields
            | keyof OrderImmutableFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type OrderApiUpdateInput = Omit<
    OrderModelUpdateInput,
    keyof OrderReadOnlyFields
> & {
    /** Tags for this order, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

/** Process Order SQS message body */
export type ProcessOrderMessageBody = {
    type: "processOrder";
    order: Order;
    orderItems: OrderItem[];
};

export interface OrderComposite {
    order: Order;
    orderItems?: OrderItem[];
    orderTags?: OrderTags;
    orderTransactions?: OrderTransaction[];
}

export type OrderSearchComposite = OrderComposite;
