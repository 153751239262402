import { ResonanceIdentityId } from "@buildresonance/resonance-lib-entities";
import { useResonanceApi } from "@buildresonance/resonance-lib-frontend-admin-auth-passwordless";
import { buildGqlEndpoint, GqlApi } from "@lib/common/domainNames";
import { ClientError } from "graphql-request";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import {
    getPublicIdentity,
    GetPublicIdentityResponse,
    GetPublicIdentityQueryKey,
    buildGetPublicIdentityQueryKey,
} from "./getPublicIdentity";

const usePublicIdentity = (
    {
        identityId,
    }: {
        identityId?: ResonanceIdentityId | null;
    },
    options?: UseQueryOptions<
        GetPublicIdentityResponse,
        ClientError,
        GetPublicIdentityResponse,
        GetPublicIdentityQueryKey
    >,
): UseQueryResult<GetPublicIdentityResponse, ClientError> => {
    const { gqlClient, withAuthQueryOptions } = useResonanceApi({
        endpoint: buildGqlEndpoint(GqlApi.AdminIdentity),
    });

    return useQuery<
        GetPublicIdentityResponse,
        ClientError,
        GetPublicIdentityResponse,
        GetPublicIdentityQueryKey
    >(
        buildGetPublicIdentityQueryKey(identityId as ResonanceIdentityId),
        getPublicIdentity(gqlClient),
        withAuthQueryOptions({
            enabled: (options?.enabled ?? true) && !!identityId,
        }),
    );
};

export { usePublicIdentity };
