import {
    PartnerApiCreateInput,
    PartnerApiUpdateInput,
} from "@buildresonance/resonance-lib-entities";
import { array, object, ObjectSchema, string } from "yup";

import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";

import { partnerIdSchema, userNameSchema } from "./adminIdentityIds";

export const PARTNER_DESCRIPTION_MAX_LENGTH = 8000;
export const PARTNER_NAME_MAX_LENGTH = 128;

export const partnerApiCreateInputSchema: ObjectSchema<PartnerApiCreateInput> =
    object({
        description: string().nullable().max(PARTNER_DESCRIPTION_MAX_LENGTH),
        name: string().required().max(PARTNER_NAME_MAX_LENGTH),
        notificationUserNames: object({
            apps: array().of(userNameSchema.required()).nullable(),
        })
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
    }).noUnknown();

export const partnerApiUpdateInputSchema: ObjectSchema<PartnerApiUpdateInput> =
    object({
        description: string().nullable().max(PARTNER_DESCRIPTION_MAX_LENGTH),
        name: string().max(PARTNER_NAME_MAX_LENGTH),
        notificationUserNames: object({
            apps: array().of(userNameSchema.required()).nullable(),
        })
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        ownerUserName: userNameSchema,
        partnerId: partnerIdSchema.required(),
    }).noUnknown();
