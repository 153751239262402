import { CdnEntityIdPrefix } from "./CdnEntity";

/**
 * @see CdnEntityIdPrefix.ContentItem
 * KSUID
 */

export type ContentItemId = `${CdnEntityIdPrefix.ContentItem}-${string}`;

export type ResonanceCdnIds = ContentItemId;
