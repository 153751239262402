import { UrlObject } from "url";

import {
    CollectionId,
    ContentItemId,
    CustomerId,
    DemandHqId,
    OrderId,
    OrderItemId,
    PageId,
    ProductId,
    StorefrontId,
    UserName,
    VariantId,
} from "@buildresonance/resonance-lib-entities";

const APP_ID_PATHNAME = "[appId]";
const COLLECTION_ID_PATHNAME = "[collectionId]";
const CUSTOMER_ID_PATHNAME = "[customerId]";
const DEMAND_HQ_ID_PATHNAME = "[demandHqId]";
const FILENAME_PATHNAME = "[filename]";
const ORDER_ID_PATHNAME = "[orderId]";
const ORDER_ITEM_ID_PATHNAME = "[orderItemId]";
const PAGE_ID_PATHNAME = "[pageId]";
const PRODUCT_CONTENT_ITEM_ID_PATHNAME = "[contentItemId]";
const PRODUCT_ID_PATHNAME = "[productId]";
const STOREFRONT_ID_PATHNAME = "[storefrontId]";
const VARIANT_ID_PATHNAME = "[variantId]";
const USER_NAME_ROUTE_PLACEHOLDER = "[userName]";
const DISCOUNT_ID_PATHNAME = "[discountId]";

export class DemandAdminRoute {
    static Associations = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.Associations.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/associations`,
    };

    static CollectionCreate = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.CollectionCreate.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/collections/create`,
    };

    static CollectionEdit = {
        build: (demandHqId: DemandHqId, collectionId: CollectionId): string =>
            DemandAdminRoute.CollectionEdit.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(COLLECTION_ID_PATHNAME, collectionId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/collections/${COLLECTION_ID_PATHNAME}`,
    };

    static CollectionImagesAdd = {
        build: (demandHqId: DemandHqId, collectionId: CollectionId): string =>
            DemandAdminRoute.CollectionImagesAdd.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(COLLECTION_ID_PATHNAME, collectionId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/collections/${COLLECTION_ID_PATHNAME}/images/add`,
    };

    static Collections = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.Collections.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/collections`,
    };

    static CustomerCreate = {
        build: (demandHqId: DemandHqId, storefrontId: StorefrontId): string =>
            DemandAdminRoute.CustomerCreate.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(STOREFRONT_ID_PATHNAME, storefrontId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/storefronts/${STOREFRONT_ID_PATHNAME}/customers/create`,
    };

    static CustomerEdit = {
        build: (
            demandHqId: DemandHqId,
            storefrontId: StorefrontId,
            customerId: CustomerId,
        ): string =>
            DemandAdminRoute.CustomerEdit.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(STOREFRONT_ID_PATHNAME, storefrontId)
                .replace(CUSTOMER_ID_PATHNAME, customerId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/storefronts/${STOREFRONT_ID_PATHNAME}/customers/${CUSTOMER_ID_PATHNAME}`,
    };

    static Customers = {
        build: (demandHqId: DemandHqId, storefrontId: StorefrontId): string =>
            DemandAdminRoute.Customers.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(STOREFRONT_ID_PATHNAME, storefrontId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/storefronts/${STOREFRONT_ID_PATHNAME}/customers`,
    };

    static DemandHqApps = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.DemandHqApps.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/apps`,
    };

    static DemandHqCreate = {
        build: (): string => DemandAdminRoute.DemandHqCreate.pathname,
        pathname: "/demand-hqs/create",
    };

    static DemandHqDetail = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.DemandHqDetail.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}`,
    };

    static DemandHqEdit = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.DemandHqEdit.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/edit`,
    };

    static DemandHqAutomationSettings = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.DemandHqAutomationSettings.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/automation/settings`,
    };

    static DemandHqInstallApp = {
        build: (demandHqId: DemandHqId, appId: string): string =>
            DemandAdminRoute.DemandHqInstallApp.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(APP_ID_PATHNAME, appId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/apps/${APP_ID_PATHNAME}/install`,
    };

    static DemandHqNotifications = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.DemandHqNotifications.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/notifications`,
    };

    static DemandHqSubscriptions = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.DemandHqSubscriptions.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/subscriptions`,
    };

    static DemandHqUsers = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.DemandHqUsers.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/users`,
    };

    static DemandHqUsersAdd = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.DemandHqUsersAdd.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/users/add`,
    };

    static DemandHqUsersEdit = {
        build: (demandHqId: DemandHqId, userName: UserName): string =>
            DemandAdminRoute.DemandHqUsersEdit.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(USER_NAME_ROUTE_PLACEHOLDER, userName),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/users/${USER_NAME_ROUTE_PLACEHOLDER}`,
    };

    static DiscountCreate = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.DiscountCreate.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/discounts/create`,
    };

    static DiscountEdit = {
        build: (demandHqId: DemandHqId, discountId: string): string =>
            DemandAdminRoute.DiscountEdit.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(DISCOUNT_ID_PATHNAME, discountId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/discounts/${DISCOUNT_ID_PATHNAME}`,
    };

    static Discounts = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.Discounts.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/discounts`,
    };

    static MyDemandHqs = {
        build: (): string => DemandAdminRoute.MyDemandHqs.pathname,
        pathname: "/",
    };

    static MyDemandHqSettings = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.MyDemandHqSettings.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/my-settings`,
    };

    static NavCreate = {
        build: (demandHqId: DemandHqId, storefrontId: StorefrontId): string =>
            DemandAdminRoute.NavCreate.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(STOREFRONT_ID_PATHNAME, storefrontId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/storefronts/${STOREFRONT_ID_PATHNAME}/navs/create`,
    };

    static Navs = {
        build: (demandHqId: DemandHqId, storefrontId: StorefrontId): string =>
            DemandAdminRoute.Navs.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(STOREFRONT_ID_PATHNAME, storefrontId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/storefronts/${STOREFRONT_ID_PATHNAME}/navs`,
    };

    static OrderCreate = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.OrderCreate.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/orders/create`,
    };

    static OrderEdit = {
        build: (demandHqId: DemandHqId, orderId: OrderId): string =>
            DemandAdminRoute.OrderEdit.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(ORDER_ID_PATHNAME, orderId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/orders/${ORDER_ID_PATHNAME}`,
    };

    static OrderItemCreate = {
        build: (demandHqId: DemandHqId, orderId: OrderId): string =>
            DemandAdminRoute.OrderItemCreate.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(ORDER_ID_PATHNAME, orderId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/orders/${ORDER_ID_PATHNAME}/order-items/create`,
    };

    static OrderItemEdit = {
        build: (
            demandHqId: DemandHqId,
            orderId: OrderId,
            orderItemId: OrderItemId,
        ): string =>
            DemandAdminRoute.OrderItemEdit.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(ORDER_ID_PATHNAME, orderId)
                .replace(ORDER_ITEM_ID_PATHNAME, orderItemId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/orders/${ORDER_ID_PATHNAME}/order-items/${ORDER_ITEM_ID_PATHNAME}`,
    };

    static OrderTransactionCreate = {
        build: (demandHqId: DemandHqId, orderId: OrderId): string =>
            DemandAdminRoute.OrderTransactionCreate.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(ORDER_ID_PATHNAME, orderId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/orders/${ORDER_ID_PATHNAME}/order-transactions/create`,
    };

    static Orders = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.Orders.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/orders`,
    };

    static PageCreate = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.PageCreate.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/pages/create`,
    };

    static PageEdit = {
        build: (demandHqId: DemandHqId, pageId: PageId): string =>
            DemandAdminRoute.PageEdit.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(PAGE_ID_PATHNAME, pageId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/pages/${PAGE_ID_PATHNAME}`,
    };

    static PageImagesAdd = {
        build: (demandHqId: DemandHqId, pageId: PageId): string =>
            DemandAdminRoute.PageImagesAdd.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(PAGE_ID_PATHNAME, pageId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/pages/${PAGE_ID_PATHNAME}/images/add`,
    };

    static Pages = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.Pages.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/pages`,
    };

    static PaymentGateway = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.PaymentGateway.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/payment-gateway`,
    };

    static ProductCreate = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.ProductCreate.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/products/create`,
    };

    static ProductCreateFromSupplierProducts = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.ProductCreateFromSupplierProducts.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/products/create-from-supplier-products`,
    };

    static ProductCreateFromSupplierProductsBulk = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.ProductCreateFromSupplierProductsBulk.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/products/bulk/create-from-supplier-products`,
    };

    static ProductEdit = {
        build: (demandHqId: DemandHqId, productId: ProductId): string =>
            DemandAdminRoute.ProductEdit.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(PRODUCT_ID_PATHNAME, productId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/products/${PRODUCT_ID_PATHNAME}`,
    };

    static ProductImageAdd = {
        build: (demandHqId: DemandHqId, productId: ProductId): string =>
            DemandAdminRoute.ProductImageAdd.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(PRODUCT_ID_PATHNAME, productId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/products/${PRODUCT_ID_PATHNAME}/images/add`,
    };

    static ProductImageEdit = {
        build: (
            demandHqId: DemandHqId,
            productId: ProductId,
            contentItemId: ContentItemId,
        ): string => {
            return DemandAdminRoute.ProductImageEdit.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(PRODUCT_ID_PATHNAME, productId)
                .replace(PRODUCT_CONTENT_ITEM_ID_PATHNAME, contentItemId);
        },
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/products/${PRODUCT_ID_PATHNAME}/images/${PRODUCT_CONTENT_ITEM_ID_PATHNAME}`,
    };

    static Products = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.Products.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/products`,
    };

    static StorefrontCreate = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.StorefrontCreate.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/storefronts/create`,
    };

    static StorefrontEdit = {
        build: (demandHqId: DemandHqId, storefrontId: StorefrontId): string =>
            DemandAdminRoute.StorefrontEdit.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(STOREFRONT_ID_PATHNAME, storefrontId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/storefronts/${STOREFRONT_ID_PATHNAME}`,
    };

    static StorefrontSettings = {
        build: (demandHqId: DemandHqId, storefrontId: StorefrontId): string =>
            DemandAdminRoute.StorefrontSettings.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(STOREFRONT_ID_PATHNAME, storefrontId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/storefronts/${STOREFRONT_ID_PATHNAME}/settings`,
    };

    static Storefronts = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.Storefronts.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/storefronts`,
    };

    static SupplierRequest = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.SupplierRequest.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/suppliers/request`,
    };

    static Suppliers = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.Suppliers.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/suppliers`,
    };

    static Taxonomy = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.Taxonomy.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/taxonomy`,
    };

    static TaxonomyCreate = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.TaxonomyCreate.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/taxonomy/create`,
    };

    static TypeContents = {
        build: (demandHqId: DemandHqId, storefrontId: StorefrontId): string =>
            DemandAdminRoute.TypeContents.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(STOREFRONT_ID_PATHNAME, storefrontId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/storefronts/${STOREFRONT_ID_PATHNAME}/type-contents`,
    };

    static VariantCreate = {
        build: (demandHqId: DemandHqId, productId: ProductId): string =>
            DemandAdminRoute.VariantCreate.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(PRODUCT_ID_PATHNAME, productId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/products/${PRODUCT_ID_PATHNAME}/variants/create`,
    };

    static VariantCreateFromSupplierVariant = {
        build: (demandHqId: DemandHqId, productId: ProductId): string =>
            DemandAdminRoute.VariantCreateFromSupplierVariant.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(PRODUCT_ID_PATHNAME, productId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/products/${PRODUCT_ID_PATHNAME}/variants/create-from-supplier-variant`,
    };

    static VariantEdit = {
        build: (
            demandHqId: DemandHqId,
            productId: ProductId,
            variantId: VariantId,
        ): string =>
            DemandAdminRoute.VariantEdit.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(PRODUCT_ID_PATHNAME, productId)
                .replace(VARIANT_ID_PATHNAME, variantId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/products/${PRODUCT_ID_PATHNAME}/variants/${VARIANT_ID_PATHNAME}`,
    };

    static VariantSupplierVariantAdd = {
        build: (
            demandHqId: DemandHqId,
            productId: ProductId,
            variantId: VariantId,
        ): string =>
            DemandAdminRoute.VariantSupplierVariantAdd.pathname
                .replace(DEMAND_HQ_ID_PATHNAME, demandHqId)
                .replace(PRODUCT_ID_PATHNAME, productId)
                .replace(VARIANT_ID_PATHNAME, variantId),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/products/${PRODUCT_ID_PATHNAME}/variants/${VARIANT_ID_PATHNAME}/add`,
    };

    static Exports = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.Exports.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/exports`,
    };

    static Imports = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.Imports.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/imports`,
    };
    static ImportCreate = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.ImportCreate.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/imports/create`,
    };
    static ImportDetail = {
        build: (demandHqId: DemandHqId, filename: string): UrlObject => ({
            pathname: DemandAdminRoute.ImportDetail.pathname,
            query: {
                demandHqId,
                filename,
            },
        }),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/imports/${FILENAME_PATHNAME}`,
    };

    static ReportsDashboard = {
        build: (demandHqId: DemandHqId): string =>
            DemandAdminRoute.ReportsDashboard.pathname.replace(
                DEMAND_HQ_ID_PATHNAME,
                demandHqId,
            ),
        pathname: `/demand-hqs/${DEMAND_HQ_ID_PATHNAME}/reports`,
    };
}
