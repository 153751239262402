import { ScopePermission } from "../../global";
import { AdminIdentityIdPrefix } from "../adminidentity/AdminIdentityEntity";

/** What stage of creation is this Entity in? */
export enum SupplyEntityStatus {
    Draft = "DRAFT",
    NeedsReview = "NEEDSREVIEW",
    Published = "PUBLISHED",
    TemporaryHold = "TEMPORARYHOLD",
    Archived = "ARCHIVED",
}

export enum SupplyEntity {
    FulfillmentTracking = "FULFILLMENTTRACKING",
    PurchaseOrderItem = "PURCHASEORDERITEM",

    Supplier = "SUPPLIER",
    SupplierDemandHq = "SUPPLIERDEMANDHQ",
    SupplierIdentity = "SUPPLIERIDENTITY",

    SupplierProduct = "SUPPLIERPRODUCT",
    SupplierProductContentItem = "SUPPLIERPRODUCTCONTENTITEM",
    SupplierProductCustomData = "SUPPLIERPRODUCTCUSTOMDATA",
    SupplierProductLocale = "SUPPLIERPRODUCTLOCALE",
    SupplierProductTags = "SUPPLIERPRODUCTTAGS",
    SupplierVariant = "SUPPLIERVARIANT",
    SupplierVariantCustomData = "SUPPLIERVARIANTCUSTOMDATA",
    SupplierVariantSupplement = "SUPPLIERVARIANTSUPPLEMENT",

    SupplierNotification = "SUPPLIERNOTIFICATION",

    SupplierQuickLink = "SUPPLIERQUICKLINK",
    SupplierProductProduct = "SUPPLIERPRODUCTPRODUCT",
}

/** Contains all Writable and Readable Entities for the Supply Domain */
export type SupplyScope = `${Lowercase<
    Exclude<
        SupplyEntity,
        | SupplyEntity.FulfillmentTracking // POI handles this
        | SupplyEntity.SupplierProductTags // Product itself handles Tags
        | SupplyEntity.SupplierQuickLink
        | SupplyEntity.SupplierProductProduct // No plans to give access to this directly at the moment, just using for Search filtering.
    >
>}/${ScopePermission}`;

export const WRITABLE_SUPPLY_ENTITIES: SupplyEntity[] = [
    SupplyEntity.PurchaseOrderItem,
    SupplyEntity.Supplier,
    SupplyEntity.SupplierDemandHq,
    SupplyEntity.SupplierIdentity,
    SupplyEntity.SupplierNotification,
    SupplyEntity.SupplierProduct,
    SupplyEntity.SupplierProductContentItem,
    SupplyEntity.SupplierProductCustomData,
    SupplyEntity.SupplierProductLocale,
    SupplyEntity.SupplierVariant,
    SupplyEntity.SupplierVariantCustomData,
    SupplyEntity.SupplierVariantSupplement,
];

export const ALL_SUPPLY_SCOPES: SupplyScope[] = [
    ...(WRITABLE_SUPPLY_ENTITIES.flatMap((entity) => [
        `${entity.toLowerCase()}/${ScopePermission.Write}`,
        `${entity.toLowerCase()}/${ScopePermission.Read}`,
    ]) as SupplyScope[]),
];

export enum SupplyEntityIdPrefix {
    FulfillmentTracking = "track",
    PurchaseOrderItem = "poitm",
    /** Covers pretty much everything in the metadata domain group */
    Supplier = "spplr",
    /**
     * Covers all of the below, as they use the same ID (but separate SupplyEntity).
     * @see SupplierProduct
     * @see SupplierProductContentItem
     * @see SupplierProductLocale
     * @see SupplierProductTags
     */
    SupplierProduct = "spprd",
    /**
     * Covers all of the below, as they use the same ID (but separate SupplyEntity).
     * @see SupplierVariant
     * @see SupplierVariantCustomData
     * @see SupplierVariantSupplement
     */
    SupplierVariant = "spvar",
    /** This is used for the last part of the S3 Key */
    SupplierProductContentItem = "spcon",
    SupplierNotification = "spnot",
}

/**
 * This is used in event publishing to determine the main entityId.
 * Generally interfaces have more than one id in them, so this helps immensely.
 * MAKE SURE TO UPDATE THIS, OR EVENTS WILL HAVE NO ENTITYID. (TS will help you)
 */
export const SUPPLY_ENTITY_TO_ID_PREFIX_MAP: Record<
    SupplyEntity,
    SupplyEntityIdPrefix | AdminIdentityIdPrefix
> = {
    [SupplyEntity.FulfillmentTracking]:
        SupplyEntityIdPrefix.FulfillmentTracking,
    [SupplyEntity.PurchaseOrderItem]: SupplyEntityIdPrefix.PurchaseOrderItem,
    [SupplyEntity.Supplier]: SupplyEntityIdPrefix.Supplier,
    [SupplyEntity.SupplierDemandHq]: SupplyEntityIdPrefix.Supplier,
    [SupplyEntity.SupplierIdentity]: SupplyEntityIdPrefix.Supplier,

    [SupplyEntity.SupplierProduct]: SupplyEntityIdPrefix.SupplierProduct,
    [SupplyEntity.SupplierProductContentItem]:
        SupplyEntityIdPrefix.SupplierProduct,
    [SupplyEntity.SupplierProductCustomData]:
        SupplyEntityIdPrefix.SupplierProduct,
    [SupplyEntity.SupplierProductLocale]: SupplyEntityIdPrefix.SupplierProduct,
    [SupplyEntity.SupplierProductTags]: SupplyEntityIdPrefix.SupplierProduct,
    [SupplyEntity.SupplierVariant]: SupplyEntityIdPrefix.SupplierVariant,
    [SupplyEntity.SupplierVariantCustomData]:
        SupplyEntityIdPrefix.SupplierVariant,
    [SupplyEntity.SupplierVariantSupplement]:
        SupplyEntityIdPrefix.SupplierVariant,

    [SupplyEntity.SupplierNotification]:
        SupplyEntityIdPrefix.SupplierNotification,

    [SupplyEntity.SupplierQuickLink]: AdminIdentityIdPrefix.App,
    [SupplyEntity.SupplierProductProduct]: SupplyEntityIdPrefix.SupplierProduct,
};

export const ALL_SUPPLY_ENTITIES = [...WRITABLE_SUPPLY_ENTITIES];

export enum SupplyRole {
    Admin = "ADMIN",
}

export const SUPPLY_ENTITY_ROLES: Record<SupplyRole, SupplyScope[]> = {
    [SupplyRole.Admin]: ALL_SUPPLY_ENTITIES.map(
        (entity) =>
            `${entity.toLowerCase()}/${ScopePermission.Write}` as SupplyScope,
    ),
};

export const SUPPLY_SCOPE_DESCRIPTIONS: Record<SupplyScope, string> = {
    "purchaseorderitem/write": "Can create and update PurchaseOrderItems.",
    "purchaseorderitem/read": "Can read PurchaseOrderItems.",
    "supplier/write":
        "Can alter Supplier information, including ownership. This is not recommended to provide to apps.",
    "supplier/read": "Can read basic Supplier information",
    "supplierdemandhq/write": "Can approve or deny DemandHq requests.",
    "supplierdemandhq/read":
        "Can read DemandHq relationships and their status.",
    "supplieridentity/write":
        "Can invite, remove, and alter Supplier users and other apps permissions. This is not recommended to provide to apps.",
    "supplieridentity/read":
        "Can read Supplier users and other apps permissions. This is not recommended for apps, as an app can always read their own permissions.",
    "suppliernotification/read":
        "Can read Supplier Notifications. Permissions are still required for actions pertaining to other entities.",
    "suppliernotification/write":
        "Can create and update Supplier Notifications.",
    "supplierproduct/write":
        "Can create and updated Supplier Products. This does not include child entities.",
    "supplierproduct/read":
        "Can read data in Supplier Products. This does not include child entities.",
    "suppliervariant/write":
        "Can create and update Supplier Variants. This does not include entities such as Supplement or Locales.",
    "suppliervariant/read":
        "Can read data in Supplier Variants. This does not include entities such as Supplement or Locales.",
    "supplierproductcontentitem/write":
        "Can create, update, and remove Supplier Variant images, files, etc.",
    "supplierproductcontentitem/read":
        "Can read Supplier Variant images, files, etc.",
    "supplierproductcustomdata/write":
        "Can create and update Supplier Product Custom Data.",
    "supplierproductcustomdata/read": "Can read Supplier Product Custom Data.",
    "supplierproductlocale/write":
        "Can create, update, and remove Supplier Variant Locales.",
    "supplierproductlocale/read": "Can read Supplier Variant Locales.",
    "suppliervariantsupplement/write":
        "Can create and update Supplier Variant Supplements.",
    "suppliervariantsupplement/read": "Can read Supplier Variant Supplements.",
    "suppliervariantcustomdata/write":
        "Can create and update Supplier Variant Custom Data.",
    "suppliervariantcustomdata/read": "Can read Supplier Variant Custom Data.",
};
