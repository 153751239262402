import { ServiceClientMethod } from "../../serviceClient/serviceClientGlobal";

export enum EventsServiceClientRoute {
    Events = "Events",
    EventSubscription = "EventSubscription",
    EventSubscriptions = "EventsSubscriptions",
}

export const EVENTS_SERVICE_CLIENT_ROUTES_TO_METHOD_MAP: Record<
    EventsServiceClientRoute,
    ServiceClientMethod[]
> = {
    [EventsServiceClientRoute.Events]: ["GET"],
    [EventsServiceClientRoute.EventSubscription]: ["GET", "DELETE"],
    [EventsServiceClientRoute.EventSubscriptions]: ["GET", "POST"],
};
