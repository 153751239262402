import { DemandHqUserSettingsApiUpdateInput } from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, boolean, object } from "yup";

import { userNameSchema } from "../../adminIdentity/adminIdentityIds";
import { tagArraySchema } from "../../common/tags";
import { demandHqIdSchema } from "../demandIds";

export const createProductCompositeFromSupplierProductsAdditionsSchema = object(
    {
        tags: tagArraySchema.nullable(),
    },
)
    .nullable()
    .noUnknown();

export const demandHqUserSettingsApiUpdateInputSchema: ObjectSchema<DemandHqUserSettingsApiUpdateInput> =
    object({
        allowMultipleSupplierProductAssociations: boolean().nullable(),
        createProductCompositeFromSupplierProductsAdditions:
            createProductCompositeFromSupplierProductsAdditionsSchema.nullable(),
        demandHqId: demandHqIdSchema.required(),
        userName: userNameSchema.required(),
    }).noUnknown();
