import { ServiceClientMethod } from "../../serviceClient/serviceClientGlobal";

export enum AdminIdentityServiceClientRoute {
    GraphQL = "graphql",
}

export const ADMIN_IDENTITY_SERVICE_CLIENT_ROUTES_TO_METHOD_MAP: Record<
    AdminIdentityServiceClientRoute,
    ServiceClientMethod[]
> = {
    [AdminIdentityServiceClientRoute.GraphQL]: ["POST"],
};
