import { DemandHqGql, UserName } from "@buildresonance/resonance-lib-entities";
import { GraphQLClient, gql } from "graphql-request";
import { QueryFunction } from "react-query";

const LIST_DEMAND_HQS_FOR_ME_QUERY = gql`
    query listDemandHqsQuery($userName: ID!) {
        demandHqIdentities(first: 20, identityId: $userName) {
            nodes {
                demandHq {
                    createdAt
                    defaultCurrency
                    defaultLocaleType
                    demandHqId
                    demandSubscriptionCodes
                    description
                    name
                    ownerUserName
                    updatedAt
                }
            }
        }
    }
`;

export type ListDemandHqsForMeDemandHq = Pick<
    DemandHqGql,
    | "createdAt"
    | "demandHqId"
    | "defaultCurrency"
    | "defaultLocaleType"
    | "demandSubscriptionCodes"
    | "description"
    | "ownerUserName"
    | "name"
    | "updatedAt"
>;

export interface ListDemandHqsForMeResponse {
    demandHqIdentities: {
        nodes: { demandHq: ListDemandHqsForMeDemandHq }[];
    };
}

export type ListDemandHqsForMeQueryKey = ["listDemandHqsForMe", UserName];

export const buildListDemandHqsForMeQueryKey = (
    userName: UserName,
): ListDemandHqsForMeQueryKey => ["listDemandHqsForMe", userName];

const listDemandHqsForMe =
    (
        client: GraphQLClient | null,
    ): QueryFunction<ListDemandHqsForMeResponse, ListDemandHqsForMeQueryKey> =>
    async ({ queryKey }) => {
        if (client) {
            return await client.request(LIST_DEMAND_HQS_FOR_ME_QUERY, {
                userName: queryKey[1],
            });
        }
        throw new Error("Client not present");
    };

export { listDemandHqsForMe };
