import {
    DemandHqQuickLinkApiCreateInput,
    DemandHqQuickLinkApiUpdateInput,
    DemandHqQuickLinkType,
} from "@buildresonance/resonance-lib-entities";
import { object, ObjectSchema, string } from "yup";

import { appIdSchema } from "../adminIdentity/adminIdentityIds";
import { QUICK_LINK_LABEL_MAX_LENGTH } from "../common/quickLink";
import { transformBlankStringToNull } from "../stringHelpers";

import { demandHqIdSchema } from "./demandIds";

export const demandHqQuickLinkApiCreateInputSchema: ObjectSchema<DemandHqQuickLinkApiCreateInput> =
    object({
        appId: appIdSchema.required(),
        dynamicUrl: string().required().url(),
        label: string().required().max(QUICK_LINK_LABEL_MAX_LENGTH),
        quickLinkType: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(DemandHqQuickLinkType)),
        demandHqId: demandHqIdSchema.required(),
    }).noUnknown();

export const demandHqQuickLinkApiUpdateInputSchema: ObjectSchema<DemandHqQuickLinkApiUpdateInput> =
    object({
        appId: appIdSchema.required(),
        dynamicUrl: string().url(),
        label: string().max(QUICK_LINK_LABEL_MAX_LENGTH),
        quickLinkType: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(DemandHqQuickLinkType)),
        demandHqId: demandHqIdSchema.required(),
    }).noUnknown();
