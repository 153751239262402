import React, { ReactElement } from "react";

import {
    DemandHqId,
    DemandHqCommentEntityId,
} from "@buildresonance/resonance-lib-entities";
import { DEMAND_HQ_COMMENT_CONTENT_MAX_LENGTH } from "@buildresonance/resonance-lib-entity-schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateDemandHqComment } from "@hooks/graphql/mutations/useCreateDemandHqComment";
import SendIcon from "@mui/icons-material/Send";
import {
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
} from "@mui/material";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

import { useDemandHqCommentEntityId } from "../useDemandHqCommentEntityId";

export interface CommentTextFieldProps {
    scrollToTheBottom: () => void;
}

export interface CommentFormData {
    contentAsString: string;
}

export const CommentTextField = ({
    scrollToTheBottom,
}: CommentTextFieldProps): ReactElement => {
    const router = useRouter();
    const demandHqId = router.query.demandHqId as DemandHqId;
    const entityId = useDemandHqCommentEntityId() as DemandHqCommentEntityId;

    const { mutate: sendCreateDemandHqComment, isLoading: isCreating } =
        useCreateDemandHqComment();

    const { handleSubmit, register, formState, reset } =
        useForm<CommentFormData>({
            defaultValues: {
                contentAsString: "",
            },
            resolver: yupResolver(
                object({
                    contentAsString: string()
                        .required()
                        .max(DEMAND_HQ_COMMENT_CONTENT_MAX_LENGTH),
                }),
            ),
        });
    const { errors } = formState ?? {};

    const renderSubmitButton = (): ReactElement => {
        if (isCreating) {
            return <CircularProgress size={20} />;
        }

        return (
            <Tooltip title="Submit Comment">
                <IconButton size="small" type="submit">
                    <SendIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        );
    };

    return (
        <form
            onSubmit={handleSubmit((data) => {
                sendCreateDemandHqComment(
                    {
                        input: {
                            demandHqId,
                            entityId,
                            contentAsString: data.contentAsString,
                        },
                    },
                    {
                        onSuccess: () => {
                            reset({
                                contentAsString: "",
                            });
                            scrollToTheBottom();
                        },
                    },
                );
            })}
        >
            <TextField
                label="Comment"
                fullWidth
                size="small"
                hiddenLabel
                multiline
                minRows={3}
                placeholder="**Add Markdown Comment...**"
                error={!!errors?.contentAsString}
                helperText={errors?.contentAsString?.message}
                {...register("contentAsString")}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {renderSubmitButton()}
                        </InputAdornment>
                    ),
                }}
            />
        </form>
    );
};
