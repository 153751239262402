/* istanbul ignore file */

import { useRef, useEffect, useCallback } from "react";

import { useRouter } from "next/router";

export interface UseWarningOnExitOptions {
    message?: string;
}

const DEFAULT_MESSAGE = "Are you sure that you want to leave?";

/**
 * Throwing an actual error class trips the Next.JS 500 Page, this string literal does not.
 */
const throwFakeErrorToFoolNextRouter = (): never => {
    throw "Abort route change. Please ignore this error.";
};

/**
 * @see https://github.com/vercel/next.js/issues/2476#issuecomment-926127255
 * There is no great solution for protecting a page with dirty form values for Next.JS, so here is a hacky one.
 * As far as I (Derek) am concerned, there is no real way to test this thing.
 *
 * @experimental
 *
 * @param shouldWarn Indicates whether this page should currently be "protected"
 */
const useWarningOnExit = (
    shouldWarn: boolean,
    options?: UseWarningOnExitOptions,
): void => {
    const router = useRouter();

    const message = options?.message ?? DEFAULT_MESSAGE;

    const lastHistoryState = useRef<{ idx: number }>(global.history?.state);

    useEffect(() => {
        const storeLastHistoryState = (): void => {
            lastHistoryState.current = history.state;
        };
        router.events.on("routeChangeComplete", storeLastHistoryState);

        return () => {
            router.events.off("routeChangeComplete", storeLastHistoryState);
        };
    }, [router]);

    /**
     * @experimental HACK - idx is not documented
     * Determines which direction to travel in history.
     */
    const revertTheChangeRouterJustMade = useCallback(() => {
        const state = lastHistoryState.current;
        if (
            state !== null &&
            history.state !== null &&
            state.idx !== history.state.idx
        ) {
            history.go(
                lastHistoryState.current.idx < history.state.idx ? -1 : 1,
            );
        }
    }, []);

    const killRouterEvent = useCallback(() => {
        router.events.emit("routeChangeError");

        revertTheChangeRouterJustMade();

        throwFakeErrorToFoolNextRouter();
    }, [revertTheChangeRouterJustMade, router]);

    useEffect(() => {
        let isWarned = false;

        const routeChangeStart = (url: string): void => {
            const parsedUrl = new URL(
                url,
                url.includes("://") ? undefined : window.location.origin,
            );
            const parsedRouterPath = new URL(
                router.asPath,
                window.location.origin,
            );

            if (
                parsedRouterPath.pathname !== parsedUrl.pathname &&
                shouldWarn &&
                !isWarned
            ) {
                isWarned = true;

                if (window.confirm(message)) {
                    void router.push(url);
                    return;
                }

                isWarned = false;
                killRouterEvent();
            }
        };

        const beforeUnload = (e: BeforeUnloadEvent): string | null => {
            if (shouldWarn && !isWarned) {
                const event = e ?? window.event;
                event.returnValue = message;
                return message;
            }
            return null;
        };

        router.events.on("routeChangeStart", routeChangeStart);
        window.addEventListener("beforeunload", beforeUnload);

        return () => {
            router.events.off("routeChangeStart", routeChangeStart);
            window.removeEventListener("beforeunload", beforeUnload);
        };
    }, [message, shouldWarn, killRouterEvent, router]);
};

export default useWarningOnExit;
