import React, { ReactElement } from "react";

import { DOCS_DOMAIN_NAME } from "@lib/common/domainNames";
import { List, ListItemButton, ListItemText } from "@mui/material";

const ExternalNav = (): ReactElement => {
    return (
        <List component="nav" aria-label="External Nav">
            <ListItemButton dense href={`https://${DOCS_DOMAIN_NAME}`}>
                <ListItemText
                    primary="Documentation"
                    primaryTypographyProps={{ variant: "subtitle2" }}
                />
            </ListItemButton>
        </List>
    );
};

export { ExternalNav };
