import { ResonanceDates } from "../../global";
import { InventoryStatus, VariantStandardizedColor } from "../common";

import {
    ProductIdFields,
    ProductRelationshipFields,
} from "./common/ProductCommon";
import { VariantId } from "./DemandIds";

/**
 * These values records allows us to have atomic updates for each variant / variantMetrics.
 * In the event of concurrency issues (e.g. two competing updates), any
 * error will be self-corrected on the next update. These properties are not a source
 * of truth itself, just a state capture so we can easily aggregate.
 *
 * Values are handled separately because we cannot be assured of having all three properties
 * on any given update (or create) - in fact we are almost guaranteed _not_ to have all three.
 * Dynamo does not allow us to set nested properties, so we cannot set(`variants.${variantId}.msrp`, newMsrp)
 * if no key exists for ${variantId}.  Setting each property separately allows us to simply overwrite the primitive value for any update.
 *
 */
export type VariantMsrpRecord = Partial<Record<VariantId, number | null>>;
export type VariantPriceRecord = Partial<Record<VariantId, number | null>>;
export type VariantInventoryQuantityRecord = Partial<Record<VariantId, number>>;
export type VariantStandardizedColorRecord = Partial<
    Record<VariantId, VariantStandardizedColor | null>
>;

export interface ProductMetricsNonCalculatedFields {
    /** The percentile (0-100) popularity of this product. */
    popularity?: number | null;

    /** The number of ratings that have been published with a 5 rating. */
    reviewsCountOf5Ratings?: number | null;
    /** The number of ratings that have been published with a 4 rating. */
    reviewsCountOf4Ratings?: number | null;
    /** The number of ratings that have been published with a 3 rating. */
    reviewsCountOf3Ratings?: number | null;
    /** The number of ratings that have been published with a 2 rating. */
    reviewsCountOf2Ratings?: number | null;
    /** The number of ratings that have been published with a 1 rating. */
    reviewsCountOf1Ratings?: number | null;
}

/**
 * Used to calculate the number of variants and to allow for idempotency
 * @see {@link VariantsRecord}
 */
export interface ProductMetricsPrivateFields {
    variantsMsrp: VariantMsrpRecord;
    variantsPrice: VariantPriceRecord;
    variantsInventoryQuantity: VariantInventoryQuantityRecord;
    variantsStandardizedColor: VariantStandardizedColorRecord;
}

/**
 * These fields can be calculated at any time via the ProductMetrics model.
 * `msrp` is calculated to be the same as `price` if:
 * * the variant `msrp` is null
 * * the variant `msrp` is less than (or equal to) `price`
 * * This means that `msrp` is always greater than or equal to `price`
 */
export type ProductMetricsCalculatedFields = {
    /** This number is mostly useful in tallying other numbers, averages, etc. */
    countOfVariants: number;
    /** In Stock if any Variant inventoryStatus is In Stock */
    inventoryStatus: InventoryStatus;

    /** The maximum MSRP from all of the variants */
    msrpMaximum: number;
    /** The maximum MSRP from all in stock variants (null if no variants are in stock) */
    msrpMaximumInStock: number | null;
    /** The minimum MSRP from all of the variants */
    msrpMinimum: number;
    /** The minimum MSRP from all in stock variants (null if no variants are in stock) */
    msrpMinimumInStock: number | null;
    /** The maximum price from all of the variants */
    priceMaximum: number;
    /** The maximum price from all in stock variants (null if no variants are in stock) */
    priceMaximumInStock: number | null;
    /** The minimum price from all of the variants */
    priceMinimum: number;
    /** The minimum price from all in stock variants (null if no variants are in stock) */
    priceMinimumInStock: number | null;

    /** The maximum discount for all of the variants */
    discountMaximum: number;
    /** The maximum discount for all in stock variants (null if no variants are in stock) */
    discountMaximumInStock: number | null;
    /** The minimum discount for all of the variants */
    discountMinimum: number;
    /** The minimum discount for all in stock variants (null if no variants are in stock) */
    discountMinimumInStock: number | null;
    /** The maximum discount percentage for all of the variants */
    discountPercentageMaximum: number;
    /** The maximum discount percentage for all in stock variants (null if no variants are in stock) */
    discountPercentageMaximumInStock: number | null;
    /** The minimum discount percentage for all of the variants */
    discountPercentageMinimum: number;
    /** The minimum discount percentage for all in stock variants (null if no variants are in stock) */
    discountPercentageMinimumInStock: number | null;

    /** The aggregated list of standardizedColors for all variants of this Product */
    standardizedColors: VariantStandardizedColor[];
    /** The aggregated list of standardizedColors for all in stock variants of this Product */
    standardizedColorsInStock: VariantStandardizedColor[];

    /** The average rating for all ratings. Sum of all ratings / countOfPublishedReviews. */
    reviewsAverageRating?: number | null;
    /** The number of ratings that have been published. */
    reviewsCountOfPublishedReviews?: number | null;
};

/**
 * Public ProductMetrics fields
 */
export type PublicProductMetrics = ProductMetricsCalculatedFields &
    ProductMetricsNonCalculatedFields;

/**
 * The data stored in a Dynamo record
 */
export type ProductMetricsDynamoData = ProductIdFields &
    ProductRelationshipFields &
    ProductMetricsPrivateFields &
    ProductMetricsNonCalculatedFields &
    Pick<ResonanceDates, "updatedAt">;

/**
 * Full ProductMetrics entity
 */
export interface ProductMetrics
    extends ProductIdFields,
        ProductRelationshipFields,
        ProductMetricsCalculatedFields,
        ProductMetricsNonCalculatedFields,
        Pick<ResonanceDates, "updatedAt"> {}

export interface ProductMetricsBulkUpsertVariant {
    variantId: VariantId;
    msrp?: number | null;
    price?: number | null;
    inventoryQuantity: number;
    standardizedColor?: VariantStandardizedColor | null;
}

/**
 * _All_ relationship ids are required on every PartialUpsert request.
 * We will create the entity as a fallback to the update, which requires these fields.
 *
 * InventoryQuantity comes from `variantMetrics`, MSRP and Price come from `variant`.
 * Because of the two sources of data (and MSRP being optional in `variant`), no data fields are required.
 *
 */
export type ProductMetricsModelPartialUpsertInput = ProductIdFields &
    ProductRelationshipFields &
    Partial<ProductMetricsBulkUpsertVariant>;

/**
 * A bulkUpsert will overwrite _everything_ in the entity, including the private fields.
 * * This is a complete reset of the entity. *
 *
 * The `variants` property will require some minor data munging as the `inventoryQuantity` value comes from `variantMetrics`:
 * e.g.
 * query listVariantMetrics($productId: ID!) {
 *    product(productId: $productId) {
 *        demandHqId
 *        productId
 *        variants(first: 1000) {
 *            nodes {
 *                variantId
 *                msrp
 *                price
 *                variantMetrics {
 *                    inventoryQuantity
 *                }
 *            }
 *        }
 *    }
 * }
 */
export interface ProductMetricsModelBulkUpsertInput
    extends ProductIdFields,
        ProductRelationshipFields,
        ProductMetricsNonCalculatedFields {
    variants: ProductMetricsBulkUpsertVariant[];
}
