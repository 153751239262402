import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { ContentItem } from "../cdn";
import { ContentItemId } from "../cdn/CdnIds";

import {
    PublicSupplierProductContentItemGql,
    SupplierProductContentItem,
    SupplierProductContentItemAssociationInCompositeApiCreateInput,
    SupplierProductImageFromUrlInCompositeApiInput,
} from "./SupplierProductContentItem";
import {
    PublicSupplierProductLocale,
    SupplierProductLocale,
    SupplierProductLocaleInCompositeApiCreateInput,
} from "./SupplierProductLocale";
import { SupplierProductProduct } from "./SupplierProductProduct";
import {
    SupplierVariant,
    SupplierVariantComposite,
    SupplierVariantInCompositeApiCreateInput,
} from "./SupplierVariant";
import {
    SupplierVariantSupplement,
    SupplierVariantSupplementInCompositeApiCreateInput,
} from "./SupplierVariantSupplement";
import { SupplyEntityStatus } from "./SupplyEntity";
import { SupplierId, SupplierProductId } from "./SupplyIds";

export enum SortDirection {
    Asc = "ASC",
    Desc = "DESC",
}

export interface SupplierProductIdFields {
    supplierProductId: SupplierProductId;
}

export interface SupplierProductRelationshipFields {
    supplierId: SupplierId;
}

/**
 * This is the main entity of the Supplier domain, and is used to represent a product, which is a group of SKUs.
 * SKUs are represented by SupplierVariants, a child of this entity.
 * This entity is less frequently updated, it is more likely that SupplyVariant will be updated frequently.
 */
export interface SupplierProduct
    extends SupplierProductIdFields,
        SupplierProductRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /**
     * Passed along in PurchaseOrders so the Supplier can identify items in their warehouse.
     *
     * Limit to 128 characters.
     */
    externalProductId: string;
    /**
     * The labels for the possible option groups for this product, i.e. "Color", "Size", "Material", etc.
     * These will match with labelOptions in SupplierVariants.
     *
     * Set the first optionLabel to "No Option" if you don't want to use options for this product.
     * @see https://docs.buildresonance.com/concepts/products/#no-option-products for more info.
     *
     * Each is limited to 64 characters.
     */
    optionLabels: [
        string,
        (string | undefined | null)?,
        (string | undefined | null)?,
    ];
    /** What stage of creation is this SupplierProduct in? */
    status: SupplyEntityStatus;
    /**
     * Product Title is not localized, this is unusual to do unless your Product has a very general name.
     *
     * Limit to 256 characters.
     */
    title: string;
    /**
     * AKA Brand.
     *
     * Limit to 256 characters.
     */
    vendor: string;
}

/**
 * The tags associated with this page, a child object of the SupplierProduct with a 1-1 relationship.
 * This is separate due to possible size, at 1000 * 96 = 96000 bytes, way more than we want to store with many GSI indexes, but we still want Dynamo speed (and cost is comparable to S3 for these params).
 * This enables us not to pull them for list queries and such as well, this should be obscured from consumers by the API, but still obvious at the model level.
 */
export interface SupplierProductTags
    extends SupplierProductIdFields,
        SupplierProductRelationshipFields,
        ResonanceUpdatedBy {
    /** Tags for this page, limit to 1000 and 96 characters each. */
    raw?: string[] | null;
    updatedAt: string;
}

export type PublicSupplierProductTags = Pick<
    SupplierProductTags,
    "raw" | "supplierId" | "supplierProductId"
>;

export type SupplierProductModelCreateInput = Omit<
    SupplierProduct,
    | keyof SupplierProductIdFields
    | keyof ResonanceDates
    | keyof ResonanceUpdatedBy
>;

export type SupplierProductModelUpdateInput = SupplierProductIdFields &
    Partial<
        Omit<
            SupplierProductModelCreateInput,
            keyof SupplierProductRelationshipFields
        >
    >;

/**
 * status cant be set on create due to lack of possible variants that are required to publish.
 * If a one-shot SupplierProduct is needed, a mutation should be created to handle all of the SupplierProduct family created at once.
 */
export type SupplierProductApiCreateInput = Omit<
    SupplierProductModelCreateInput,
    "status"
> & {
    /** Tags for this page, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

/** You're a composite. */
export interface SupplierVariantCompositeInCompositeApiCreateInput {
    supplierVariantInput: Omit<
        SupplierVariantInCompositeApiCreateInput,
        "contentItemIds"
    > & {
        /**
         * For contentItems where you are associating an existing contentItem, input the actual contentItemId.
         * For other contentItem Inputs, match this to the contentItemInputId you have assigned.
         */
        contentItemInputIds?: (ContentItemId | string)[] | null;
    };
    supplierVariantSupplementInput?: SupplierVariantSupplementInCompositeApiCreateInput | null;
}

export interface SupplierProductCompositeApiCreateInput {
    supplierProductInput: SupplierProductApiCreateInput;
    supplierProductContentItemAssociationInputs?:
        | SupplierProductContentItemAssociationInCompositeApiCreateInput[]
        | null;
    supplierProductImageFromUrlInputs?:
        | SupplierProductImageFromUrlInCompositeApiInput[]
        | null;
    supplierProductLocaleInputs?:
        | SupplierProductLocaleInCompositeApiCreateInput[]
        | null;
    supplierVariantInputs?:
        | SupplierVariantCompositeInCompositeApiCreateInput[]
        | null;
}

export type SupplierProductApiUpdateInput = SupplierProductModelUpdateInput & {
    /** Tags for this page, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

export interface SupplierProductComposite {
    supplierProduct: SupplierProduct;
    supplierProductTags?: SupplierProductTags | null;
    supplierProductContentItems?: SupplierProductContentItem[] | null;
    supplierProductLocales?: SupplierProductLocale[] | null;
    supplierVariantComposites?: SupplierVariantComposite[] | null;
}

export interface SupplierProductGql extends SupplierProduct {
    /** Calculated field, the first (by position) contentItem of type IMAGE */
    featuredImage?: ContentItem | null;
    tags?: SupplierProductTags | null;
}

export interface PublicSupplierProductGql
    extends Pick<
        SupplierProductGql,
        | "externalProductId"
        | "optionLabels"
        | "supplierId"
        | "supplierProductId"
        | "title"
        | "vendor"
    > {
    featuredImage?: PublicSupplierProductContentItemGql | null;
    tags?: PublicSupplierProductTags | null;
}

export interface PublicSupplierProductDetailsGql
    extends Omit<PublicSupplierProductGql, "featuredImage"> {
    contentItems?: PublicSupplierProductContentItemGql[] | null;
    locale?: PublicSupplierProductLocale | null;
}

export enum SupplierProductSortField {
    Cost = "COST",
    CreatedAt = "CREATED_AT",
    InventoryQuantity = "INVENTORY_QUANTITY",
    Msrp = "MSRP",
    RecommendedPrice = "RECOMMENDED_PRICE",
    Relevance = "RELEVANCE",
    Title = "TITLE",
    UpdatedAt = "UPDATED_AT",
    Vendor = "VENDOR",
}

export interface SupplierProductSort {
    direction: SortDirection;
    field: SupplierProductSortField;
}

export interface SupplierProductSearchComposite
    extends Omit<SupplierProductComposite, "supplierVariantComposites"> {
    supplierVariants?: SupplierVariant[] | null;
    supplierVariantSupplements?: SupplierVariantSupplement[] | null;
    supplierProductProducts?: SupplierProductProduct[] | null;
}
