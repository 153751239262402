import {
    ResonanceLocaleType,
    SupplierProductLocaleApiCreateInput,
    SupplierProductLocaleApiUpdateInput,
    SupplierProductLocaleInCompositeApiCreateInput,
} from "@buildresonance/resonance-lib-entities";
import {
    AnyObject,
    array,
    object,
    ObjectSchema,
    string,
    TupleSchema,
} from "yup";

import { transformBlankStringToNull } from "../stringHelpers";

import { supplierIdSchema, supplierProductIdSchema } from "./supplyIds";

export const SUPPLIER_PRODUCT_LOCALE_DESCRIPTION_MAX_LENGTH = 10000;
export const SUPPLIER_PRODUCT_LOCALE_FEATURE_BULLET_MAX_LENGTH = 1000;
export const SUPPLIER_PRODUCT_LOCALE_MAX_NUMBER_OF_FEATURE_BULLETS = 5;

export const supplierProductLocaleInCompositeApiCreateInputSchema: ObjectSchema<SupplierProductLocaleInCompositeApiCreateInput> =
    object({
        description: string()
            .max(SUPPLIER_PRODUCT_LOCALE_DESCRIPTION_MAX_LENGTH)
            .nullable(),
        featureBullets: array()
            .of(string().max(SUPPLIER_PRODUCT_LOCALE_FEATURE_BULLET_MAX_LENGTH))
            .max(SUPPLIER_PRODUCT_LOCALE_MAX_NUMBER_OF_FEATURE_BULLETS)
            .nullable() as unknown as TupleSchema<
            [
                string | undefined | null,
                string | undefined | null,
                string | undefined | null,
                string | undefined | null,
                string | undefined | null,
            ],
            AnyObject,
            undefined
        >,
        localeType: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(ResonanceLocaleType)),
    }).noUnknown();

export const supplierProductLocaleApiCreateInputSchema: ObjectSchema<SupplierProductLocaleApiCreateInput> =
    supplierProductLocaleInCompositeApiCreateInputSchema
        .concat(
            object({
                supplierId: supplierIdSchema.required(),
                supplierProductId: supplierProductIdSchema.required(),
            }),
        )
        .noUnknown();

export const supplierProductLocaleApiUpdateInputSchema: ObjectSchema<SupplierProductLocaleApiUpdateInput> =
    supplierProductLocaleApiCreateInputSchema
        .clone()
        .omit(["supplierId"])
        .noUnknown();
