import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { ContentItemId, ContentItemS3Key, ContentItemSourceType } from "../cdn";

import { SupplierId, SupplierProductId } from "./SupplyIds";

export enum SupplierProductContentType {
    File = "FILE",
    Image = "IMAGE",
    Video = "VIDEO",
}

export enum SupplierProductContentItemStatus {
    Active = "ACTIVE",
    /** Archived should be regarded as deleted, but we need to keep track of archived items so they will not continually be re-created by integrations */
    Archived = "ARCHIVED",
}

/** IdFields are the ones that are required to find the correct entity. Must come in all update methods. */
export interface SupplierProductContentItemIdFields {
    contentItemId: ContentItemId;
    supplierProductId: SupplierProductId;
}

export interface SupplierProductContentItemRelationshipFields {
    /** The key to the file in the CDN (S3) */
    contentKey: ContentItemS3Key;
    contentType: SupplierProductContentType;
    /** The original URL, or some kind of file pointer, based on sourceType */
    sourceDetail: string;
    /** How did we receive this content item? */
    sourceType: ContentItemSourceType;
    supplierId: SupplierId;
}

/**
 * A relational lookup for content contained in the CDN.
 * Contains additional metadata specific to this SupplierProduct for the contentItem as well.
 */
export interface SupplierProductContentItem
    extends SupplierProductContentItemIdFields,
        SupplierProductContentItemRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /**
     * Limit to 1000 characters.
     */
    alt?: string | null;
    /**
     * Indicates some kind of grouping of the content, i.e. Back View. Up to consumer, can be any string.
     *
     * Limit to 256 characters.
     */
    category?: string | null;
    /**
     * Used to determine what order the content comes out in.
     * Positive Integers are allowed, up to 9,999,999,999, and it does not have to be unique or sequential.
     */
    position?: number | null;
    /** Indicates the current status of the SupplierProductContentItem */
    status: SupplierProductContentItemStatus;
}

export type SupplierProductContentItemGql = SupplierProductContentItem;

export type PublicSupplierProductContentItemGql = Pick<
    SupplierProductContentItem,
    | "alt"
    | "category"
    | "contentItemId"
    | "contentKey"
    | "contentType"
    | "position"
    | "supplierId"
    | "supplierProductId"
>;

export type SupplierProductContentItemModelUpdateInput =
    SupplierProductContentItemIdFields &
        Partial<
            Omit<
                SupplierProductContentItem,
                | keyof SupplierProductContentItemIdFields
                | keyof SupplierProductContentItemRelationshipFields
                | keyof ResonanceDates
                | keyof ResonanceUpdatedBy
            >
        >;

export type SupplierProductContentItemModelCreateInput = Omit<
    SupplierProductContentItem,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type SupplierProductContentItemCommonApiCreateInput = Pick<
    SupplierProductContentItem,
    "alt" | "category" | "position" | "supplierId" | "supplierProductId"
>;

export interface SupplierProductImageFromUrlApiInput
    extends SupplierProductContentItemCommonApiCreateInput {
    /**
     * The URL of the image to upload.
     *
     * Should match url structure, including https://, and should be a valid image.
     */
    url: string;
}

export type SupplierProductImageFromUrlInCompositeApiInput = Omit<
    SupplierProductImageFromUrlApiInput,
    "supplierProductId" | keyof SupplierProductContentItemRelationshipFields
> & {
    /** Temporary ID, can be any string, to associate Variants with this Image. On create, the real contentItemId will be substituted. */
    contentItemInputId?: string | null;
};

export interface SupplierProductImageFromFileApiInput
    extends SupplierProductContentItemCommonApiCreateInput {
    /**
     * Limit to 1000 characters.
     */
    fileName: string;
    /**
     * Limit to 100 characters (although, that would be a weird file extension).
     */
    fileExtension: string;
}

export type SupplierProductContentItemApiUpdateInput =
    SupplierProductContentItemModelUpdateInput;

/** For associating an existing content item with a new supplierProduct */
export type SupplierProductContentItemAssociationApiCreateInput =
    SupplierProductContentItemCommonApiCreateInput &
        Pick<SupplierProductContentItem, "contentItemId">;

export type SupplierProductContentItemAssociationInCompositeApiCreateInput =
    Omit<
        SupplierProductContentItemAssociationApiCreateInput,
        "supplierProductId" | keyof SupplierProductContentItemRelationshipFields
    >;
