import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates, ResonanceLocaleType } from "../../global";

import { SupplierId, SupplierProductId } from "./SupplyIds";

/** IdFields are the ones that are required to find the correct entity. Must come in all update methods. */
export interface SupplierProductLocaleIdFields {
    localeType: ResonanceLocaleType;
    supplierProductId: SupplierProductId;
}

export interface SupplierProductLocaleRelationshipFields {
    supplierId: SupplierId;
}

/**
 * This has a many to one relationship with SupplierProduct, and provides localized language data for the appropriate fields.
 * There will often only be one of these, and it should be the one set in defaultLocale for the supplier.
 */
export interface SupplierProductLocale
    extends SupplierProductLocaleIdFields,
        SupplierProductLocaleRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    description?: string | null;
    /** Limited to 5 */
    featureBullets?:
        | [
              (string | undefined | null)?,
              (string | undefined | null)?,
              (string | undefined | null)?,
              (string | undefined | null)?,
              (string | undefined | null)?,
          ]
        | null;
}

export type SupplierProductLocaleGql = SupplierProductLocale;

export type SupplierProductLocaleModelUpdateInput =
    SupplierProductLocaleIdFields &
        Partial<
            Omit<
                SupplierProductLocale,
                | keyof SupplierProductLocaleIdFields
                | keyof SupplierProductLocaleRelationshipFields
                | keyof ResonanceDates
                | keyof ResonanceUpdatedBy
            >
        >;

export type SupplierProductLocaleModelCreateInput = Omit<
    SupplierProductLocale,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type SupplierProductLocaleApiUpdateInput =
    SupplierProductLocaleModelUpdateInput;

export type SupplierProductLocaleApiCreateInput =
    SupplierProductLocaleModelCreateInput;

export type SupplierProductLocaleInCompositeApiCreateInput = Omit<
    SupplierProductLocaleApiCreateInput,
    "supplierProductId" | keyof SupplierProductLocaleRelationshipFields
>;

export type PublicSupplierProductLocale = Omit<
    SupplierProductLocale,
    keyof ResonanceUpdatedBy | keyof ResonanceDates
>;
