import { ResonanceUpdatedBy } from "../..";
import { ResonanceDates } from "../../global";

import { DemandHqId, DiscountId } from "./DemandIds";

export interface DiscountIdFields {
    discountId: DiscountId;
}

export interface DiscountRelationshipFields {
    demandHqId: DemandHqId;
}

export enum DiscountType {
    /** A percentage discount off applicable products. */
    Percentage = "PERCENTAGE",
    /** A fixed dollar amount discount off applicable products. */
    Price = "PRICE",
}

/** A Discount is a marketing tool created in Demand. A reduction from the usual cost of products, discounts are typically applied at customer checkout via a supplied valid code within a start and end date. */
export interface Discount
    extends DiscountIdFields,
        DiscountRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /** The code that the customer will use to apply the discount. */
    code: string;
    /** The amount to discount. Optional because the discountType may be FREE_PRODUCT or similar */
    discountAmount?: number | null;
    /** The type of discount to apply */
    discountType: DiscountType;
    /** A raw product tag, used to compare against and determine if the discount code is valid for products being purchased */
    productTag: string;
    /** The date the discount becomes active. */
    startAt: string;
    /** The date the discount becomes inactive. */
    endAt?: string | null;
    /** Optional string for describing the discount. */
    description?: string | null;
}

export type DiscountModelCreateInput = Omit<
    Discount,
    keyof DiscountIdFields | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type DiscountModelUpdateInput = DiscountIdFields &
    Partial<
        Omit<
            Discount,
            | "code"
            | keyof DiscountIdFields
            | keyof DiscountRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type DiscountApiCreateInput = DiscountModelCreateInput;
export type DiscountApiUpdateInput = DiscountModelUpdateInput;
