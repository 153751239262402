export enum AdminIdentityEntity {
    App = "APP",
    /** This is a Cognito entity */
    AppClient = "APPCLIENT",
    /**
     * Could be user or appClient, for occasions where you store both identically, like permissions for DemandHQs and Suppliers.
     * Plus, it's an awesome tongue twister. (AdminIdentityEntity.Identity)
     */
    Identity = "IDENTITY",
    Partner = "PARTNER",
    PartnerIdentity = "PARTNERIDENTITY",
    ResonanceService = "SERVICE",
    User = "USER",
    UserNotification = "USERNOTIFICATION",
    UserNotificationExternalDelivery = "USERNOTIFICATIONEXTERNALDELIVERY",
    UserSupplement = "USERSUPPLEMENT",
}

export enum AdminIdentityIdPrefix {
    App = "appid",
    AppClient = "appcl",
    UserNotification = "usrnt",
    UserNotificationExternalDelivery = "urntd",
    Partner = "prtnr",
    ResonanceService = "srvce",
    User = "usrid",
}

/**
 * This is used in event publishing to determine the main entityId.
 * Generally interfaces have more than one id in them, so this helps immensely.
 * MAKE SURE TO UPDATE THIS, OR EVENTS WILL HAVE NO ENTITYID. (TS will help you)
 */
export const ADMIN_IDENTITY_ENTITY_TO_ID_PREFIX_MAP: Record<
    AdminIdentityEntity,
    AdminIdentityIdPrefix
> = {
    [AdminIdentityEntity.App]: AdminIdentityIdPrefix.App,
    [AdminIdentityEntity.AppClient]: AdminIdentityIdPrefix.AppClient,
    // Below will never be used, added for completeness
    [AdminIdentityEntity.Identity]: AdminIdentityIdPrefix.User,
    [AdminIdentityEntity.Partner]: AdminIdentityIdPrefix.Partner,
    [AdminIdentityEntity.PartnerIdentity]: AdminIdentityIdPrefix.Partner,
    [AdminIdentityEntity.ResonanceService]:
        AdminIdentityIdPrefix.ResonanceService,
    [AdminIdentityEntity.User]: AdminIdentityIdPrefix.User,
    [AdminIdentityEntity.UserNotification]:
        AdminIdentityIdPrefix.UserNotification,
    [AdminIdentityEntity.UserNotificationExternalDelivery]:
        AdminIdentityIdPrefix.UserNotificationExternalDelivery,
    [AdminIdentityEntity.UserSupplement]: AdminIdentityIdPrefix.User,
};
