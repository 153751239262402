import { ResonanceUpdatedBy } from "../../../entityComposites";
import { ResonanceDates } from "../../../global";
import { DemandHqId, StorefrontId } from "../DemandIds";

export interface StorefrontAnalyticsSettingsIdFields {
    storefrontId: StorefrontId;
}

export interface StorefrontAnalyticsSettingsRelationshipFields {
    demandHqId: DemandHqId;
}

export interface StorefrontAnalyticsSettings
    extends StorefrontAnalyticsSettingsIdFields,
        StorefrontAnalyticsSettingsRelationshipFields,
        Pick<ResonanceDates, "updatedAt">,
        ResonanceUpdatedBy {
    /** Origins should be of format https://domain.com:port. Overall length of domains should not be above 253 characters. "*" is allowed. */
    allowedOrigins?: string[] | null;
}

// No create inputs for this entity needed, partial updating is possible due to 100% optional fields and a single index.

export type StorefrontAnalyticsSettingsModelUpdateInput =
    StorefrontAnalyticsSettingsIdFields &
        StorefrontAnalyticsSettingsRelationshipFields &
        Partial<
            Omit<
                StorefrontAnalyticsSettings,
                | keyof StorefrontAnalyticsSettingsIdFields
                | keyof StorefrontAnalyticsSettingsRelationshipFields
                | "updatedAt"
                | keyof ResonanceUpdatedBy
            >
        >;

export type StorefrontAnalyticsSettingsApiUpdateInput = Omit<
    StorefrontAnalyticsSettingsModelUpdateInput,
    keyof StorefrontAnalyticsSettingsRelationshipFields
>;
