/**
 * Demand Secure Entities are exactly like Demand Entities, except that they do not run through the events system
 * and do not have permission scopes associated with them since no one should have permission.
 *
 * They are typically held in a database that is not accessible through the API, also, typically alone in that database.
 */
export enum DemandSecureEntity {
    GatewayCredentials = "GATEWAY_CREDENTIALS",
    PaymentGateway = "PAYMENT_GATEWAY",
}

export enum DemandSecureEntityIdPrefix {
    GatewayCredentials = "gwcrd",
}
