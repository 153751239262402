import { ResonanceDates } from "../../global";
import { InventoryStatus } from "../common/VariantCommon";
import { SupplierVariantId } from "../supply";

import { VariantIdFields, VariantRelationshipFields } from "./Variant";

/**
 * A supplierVariantsRecord allows us to have atomic updates for each supplierVariant.
 * In the event of concurrency issues (e.g. two competing inventoryQuantity updates), any
 * error will be self-corrected on the next update. This property is not a source
 * of truth itself, just a state capture so we can easily aggregate.
 */
export type SupplierVariantsRecord = Record<
    SupplierVariantId,
    { inventoryQuantity: number }
>;

/**
 * Used to calculate other VariantMetrics fields and to allow for idempotency.
 * * key of 0 means the product is out of stock
 * * key of 10,000,000 means the product is bottomless
 * @see {@link SupplierVariantsRecord}
 */
export interface VariantMetricsPrivateFields {
    supplierVariants: SupplierVariantsRecord;
}

/**
 * These fields can be calculated at any time via the VariantMetrics model.
 */
export type VariantMetricsCalculatedFields = {
    /** Not sure where we would use this, but it seems good to know. */
    countOfInStockSupplierVariants: number;
    /** Not sure where we would use this, but it seems good to know. */
    countOfSupplierVariants: number;
    /** This is mostly useful for determining whether a certain quantity can be added to the cart. For BOTTOMLESS InventoryPolicy, a value of 10,000,000 is added here. */
    inventoryQuantity: number;
    /** If any SupplierVariant is in stock, this is in stock. */
    inventoryStatus: InventoryStatus;
};

/**
 * Public VariantMetrics fields
 */
export type PublicVariantMetrics = VariantMetricsCalculatedFields;

/**
 * The data stored in a Dynamo record
 */
export type VariantMetricsDynamoData = VariantIdFields &
    VariantRelationshipFields &
    VariantMetricsPrivateFields &
    Pick<ResonanceDates, "updatedAt">;

/**
 * Full VariantMetrics entity
 *
 * @see {@link VariantMetricsPrivateFields}
 */
export interface VariantMetrics
    extends VariantIdFields,
        VariantRelationshipFields,
        VariantMetricsCalculatedFields,
        Pick<ResonanceDates, "updatedAt"> {}

/**
 * _All_ relationship ids are required on every PartialUpsert request.
 * We will create the entity as a fallback to the update, which requires these fields.
 *
 * When a new Variant is created, there is no VariantSupplierVariant data, so we create a blank SupplierVariantsRecord
 */
export interface VariantMetricsModelPartialUpsertInput
    extends Omit<
        VariantMetricsDynamoData,
        keyof VariantMetricsPrivateFields | "updatedAt"
    > {
    supplierVariantId?: SupplierVariantId;
    inventoryQuantity?: VariantMetrics["inventoryQuantity"];
}

/**
 * A bulkUpsert will overwrite _everything_ in the entity, including the private fields.
 * * This is a complete reset of the entity. *
 *
 * The `supplierVariants` property is designed to take the `nodes` property of a `variantSupplierVariants` query for ease of creation.
 * If a variant does not have any supplierVariants, the SupplierVariantsRecord will be empty.
 *
 * e.g.
 * query listVariantSupplierVariants($variantId: ID!) {
 *   variant(variantId: $variantId) {
 *       demandHqId
 *       productId
 *       variantId
 *       variantSupplierVariants(first: 1000) {
 *           nodes {
 *               supplierVariantId
 *               inventoryQuantity
 *           }
 *       }
 *   }
 * }
 */
export interface VariantMetricsModelBulkUpsertInput
    extends VariantIdFields,
        VariantRelationshipFields {
    supplierVariants?: {
        supplierVariantId: SupplierVariantId;
        inventoryQuantity: number;
    }[];
}
