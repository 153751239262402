import { ResonanceUpdatedBy } from "../../../entityComposites";
import { ResonanceDates } from "../../../global";
import { UserName } from "../../adminidentity/AdminIdentityIds";
import { DemandHqId } from "../DemandIds";

/**
 * All changes are additive to the existing data.
 */
export type CreateProductCompositeFromSupplierProductsAdditions = {
    tags?: string[] | null;
};

export interface DemandHqUserSettingsIdFields {
    demandHqId: DemandHqId;
    userName: UserName;
}

/**
 * UserSettings are settings specific to a logged-in user to help them be more efficient with their workflow in Resonance Demand Portal.
 */
export interface DemandHqUserSettings
    extends DemandHqUserSettingsIdFields,
        Pick<ResonanceDates, "updatedAt"> {
    createProductCompositeFromSupplierProductsAdditions?: CreateProductCompositeFromSupplierProductsAdditions | null;
    /** Setting this to true allows for a SupplierProduct to be part of multiple Products. Defaults to false. */
    allowMultipleSupplierProductAssociations?: boolean | null;
}

// No create inputs for this entity needed, partial updating is possible due to 100% optional fields and a single index.

export type DemandHqUserSettingsModelUpdateInput =
    DemandHqUserSettingsIdFields &
        Omit<
            DemandHqUserSettings,
            | keyof DemandHqUserSettingsIdFields
            | "updatedAt"
            | keyof ResonanceUpdatedBy
        >;

export type DemandHqUserSettingsApiUpdateInput =
    DemandHqUserSettingsModelUpdateInput;
