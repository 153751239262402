import { ResonanceDates } from "../../global";

import { AppClientId, AppId, PartnerId } from "./AdminIdentityIds";
import { AppType } from "./App";

/**
 * This entity is stored in Cognito and is very, very similar to a User, but for machines.
 * The appClientId is similar to userName, and the appSecret is similar to password, and function identically to create tokens.
 */
export interface AppClient {
    /**
     * Username in Cognito. Used to identify the app nearly everywhere.
     * There will always be two per app, so make any permission style changes twice.
     */
    appClientId: AppClientId;
    /**
     * Password in Cognito.
     * This will only ever be shown once and can never be re-accessed (we do not want to store this).
     * The only path to recovery is a reset.
     */
    appSecret?: string;
}

/**
 * This entity should likely never be used, but it is provided here for reference reasons
 * and for the initial creation of the AppClient.
 * It is used temporarily to return the "publicUser" of an AppClient
 */
export interface FullAppClient extends AppClient, ResonanceDates {
    /** This is set to appId, just in case we need it someday to reference backwards to the app. */
    givenName: string;
    /** This is set to partnerId */
    familyName: string;
    /** This is set to appType */
    gender: string;
    /** This is set to "name" */
    nickname: string;
}

export interface AppClientModelCreateInput {
    appId: AppId;
    appType: AppType;
    partnerId: PartnerId;
    name: string;
}
