import { ResonanceDomain } from "../../domainNames";
import {
    ResonanceDomainEntityId,
    ResonanceEntity,
    ResonanceIdentityId,
    ResonanceUpdatedBy,
} from "../../entityComposites";
import { AnyObject } from "../../utility";
import { StorefrontEntity } from "../demand";

import { EventsEntityIdPrefix } from ".";

/**
 * You may think "this should be called ResonanceEventId".
 * The reason for "Message" is because eventId sounds like a category of events, not an individual.
 * The reason why ALL of the things in here don't contain message is because the names are already long enough, lol.
 */
export type ResonanceEventMessageId =
    `${EventsEntityIdPrefix.ResonanceEvent}-${string}`;

export interface ResonanceEventIdFields {
    messageId: ResonanceEventMessageId;
}

export type EventEntityType = Lowercase<ResonanceEntity | StorefrontEntity>;

export enum EventMutationType {
    Created = "created",
    Updated = "updated",
    Deleted = "deleted",
}

export type EventEntityAndMutationType =
    `events-${EventEntityType}-${EventMutationType}`;

export const buildEventEntityAndMutationType = (
    entityType: EventEntityType,
    mutationType: EventMutationType,
): EventEntityAndMutationType => `events-${entityType}-${mutationType}`;

/**
 * Items consistent to every event.
 * The INTERNAL SNS's are setup to take advantage of itemType and mutationType,
 * anything else will require a subscription filter.
 *
 * Note that our metadata inside the message will include nulls, and never be undefined, but due to SNS limitations, attributes set to null will be left out entirely.
 * This also enables use of the "exists" operator in the subscription filter.
 */
export interface ResonanceEventMetadata {
    domainEntityId: ResonanceDomainEntityId;
    domain: ResonanceDomain;
    entityId: string | null;
    itemType: EventEntityType;
    identityId: ResonanceIdentityId | null;
    mutationType: EventMutationType;
    /** Common field name, often used for indexing. */
    status: string | null;
    /** This can be used in SubscriptionFilter to break infinite sync loops. This is converted to Epoch in seconds to be able to use numeric comparators. */
    syncedAt: number | null;
    // Written this way so that the type will break if somehow the name changed.
    updatedBy: ResonanceUpdatedBy["updatedBy"] | null;
}

interface BaseResonanceDynamoEventData {
    /** Epoch in seconds */
    approximateCreationDateTime: number;
    sizeInBytes: number;
}

export interface ResonanceDynamoDeletedEventData<TObject>
    extends BaseResonanceDynamoEventData {
    oldItem: TObject;
}

export interface ResonanceDynamoCreatedEventData<TObject>
    extends BaseResonanceDynamoEventData {
    newItem: TObject;
}

export interface ResonanceDynamoUpdatedEventData<TObject>
    extends ResonanceDynamoDeletedEventData<TObject>,
        ResonanceDynamoCreatedEventData<TObject> {}

/** The most typical EventData */
export type ResonanceDynamoEventData<
    TObject,
    TMutationType extends EventMutationType,
> = TMutationType extends EventMutationType.Created
    ? ResonanceDynamoCreatedEventData<TObject>
    : TMutationType extends EventMutationType.Deleted
      ? ResonanceDynamoDeletedEventData<TObject>
      : TMutationType extends EventMutationType.Updated
        ? ResonanceDynamoUpdatedEventData<TObject>
        : unknown;

interface BaseResonanceEventMessage<TData> {
    /** These are also message attributes in SNS, and can be used for filtering */
    metadata: ResonanceEventMetadata;
    /**
     * The actual event, typically a DynamoStream event.
     * @see ResonanceDynamoEventData
     */
    data: TData;
}

/**
 * This data is the only data in INTERNAL event subscriptions, but it is by far the most important.
 */
export type ResonanceEventMessage<TData extends AnyObject = AnyObject> =
    BaseResonanceEventMessage<TData>;

/**
 * Base64-encoded string that has been JSON-stringified and GZipped.
 */
export type ResonanceEventMessageCompressed = BaseResonanceEventMessage<string>;

interface BaseResonanceEvent<TData> extends ResonanceEventIdFields {
    /**
     * This is the event data and metadata.
     * The data is unknown, but typically is a DynamoStream event.
     * @see ResonanceEventMetadata
     */
    message: BaseResonanceEventMessage<TData>;
    /** Message subject */
    subject: string;
    /** The timestamp for the event, ISO string */
    timestamp: string;
}

/**
 * The most basic entity in the Events domain, the event itself.
 * This has Resonance on the front to distinguish from other events in Browser/Node.
 */
export type ResonanceEvent<TData extends AnyObject = AnyObject> =
    BaseResonanceEvent<TData>;

/**
 * Base64-encoded string that has been JSON-stringified and GZipped.
 */
export type ResonanceEventCompressed = BaseResonanceEvent<string>;

/**
 * MessageId is only needed for external messages, so creating it in the model will work.
 */
export type ResonanceEventModelCreateInput<
    TData extends AnyObject = AnyObject,
> = Omit<ResonanceEvent<TData>, keyof ResonanceEventIdFields>;
