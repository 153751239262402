import {
    ReviewApiCreateInput,
    ReviewApiUpdateInput,
    ReviewRating,
    ReviewStatus,
} from "@buildresonance/resonance-lib-entities";
import { array, number, object, ObjectSchema, string } from "yup";

import {
    SLUG_REGEX,
    SLUG_REGEX_VALIDATION_MESSAGE,
    transformBlankStringToNull,
} from "../stringHelpers";

import {
    customerIdSchema,
    demandHqIdSchema,
    productIdSchema,
    reviewIdSchema,
    storefrontIdSchema,
} from "./demandIds";

export const REVIEW_DESCRIPTION_MAX_LENGTH = 10000;
export const REVIEW_REVIEWER_NAME_MAX_LENGTH = 512;
export const REVIEW_REVIEW_TYPE_MAX_LENGTH = 128;
export const REVIEW_TITLE_MAX_LENGTH = 512;

export const reviewApiCreateInputSchema: ObjectSchema<ReviewApiCreateInput> =
    object({
        customerId: customerIdSchema.nullable(),
        customerIdsWhoFoundHelpful: array()
            .of(customerIdSchema.required())
            .nullable(),
        demandHqId: demandHqIdSchema.required(),
        description: string().max(REVIEW_DESCRIPTION_MAX_LENGTH).nullable(),
        fromStorefrontId: storefrontIdSchema.nullable(),
        productId: productIdSchema.required(),
        rating: number<ReviewRating>().integer().min(1).max(5).required(),
        reviewerName: string().max(REVIEW_REVIEWER_NAME_MAX_LENGTH).required(),
        reviewType: string()
            .matches(SLUG_REGEX, SLUG_REGEX_VALIDATION_MESSAGE)
            .max(REVIEW_REVIEW_TYPE_MAX_LENGTH)
            .nullable(),
        status: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(ReviewStatus))
            .required(),
        title: string().max(REVIEW_TITLE_MAX_LENGTH).nullable(),
    }).noUnknown();

export const reviewApiUpdateInputSchema: ObjectSchema<ReviewApiUpdateInput> =
    object({
        customerId: customerIdSchema.nullable(),
        customerIdsWhoFoundHelpful: array()
            .of(customerIdSchema.required())
            .nullable(),
        description: string().max(REVIEW_DESCRIPTION_MAX_LENGTH).nullable(),
        fromStorefrontId: storefrontIdSchema.nullable(),
        rating: number<ReviewRating>().integer().min(1).max(5),
        reviewId: reviewIdSchema.required(),
        reviewerName: string().max(REVIEW_REVIEWER_NAME_MAX_LENGTH),
        reviewType: string()
            .matches(SLUG_REGEX, SLUG_REGEX_VALIDATION_MESSAGE)
            .max(REVIEW_REVIEW_TYPE_MAX_LENGTH)
            .nullable(),
        status: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(ReviewStatus)),
        title: string().max(REVIEW_TITLE_MAX_LENGTH).nullable(),
    }).noUnknown();
