import { DiscountResponse } from "../../..";
import { ResonanceDates } from "../../../global";
import { DemandHqId, StorefrontId, VariantId } from "../DemandIds";
import { OrderItemDiscount } from "../OrderItem";

export const SAVED_FOR_LATER_ITEM_POSITION_MAX_VALUE = 9999999999;

export enum CartType {
    Customer = "CUSTOMER",
    Device = "DEVICE",
}

export interface CartIdFields {
    /**
     * This is a unique identifier for a cart. It is optional, but can be used to have multiple carts for a given customer or device.
     */
    cartName?: string | null;
    /**
     * This field can be one of two strings, representing either:
     * - An externalCustomerId, which matches to a customer. This is a logged in cart.
     * - An deviceId, which can be any string that remains the same for a given device. This is an anonymous cart.
     */
    externalCustomerOrDeviceId: string;
}

export interface CartRelationshipFields {
    demandHqId: DemandHqId;
    storefrontId: StorefrontId;
}

export interface CartItemDiscount extends OrderItemDiscount {}

export interface CartItem {
    /**
     * Anything can go into these fields, but the following limits apply:
     * - Each key is limited to 64 characters.
     * - Each value is limited to 512 characters.
     * - Maximum of 5 key/value pairs.
     */
    customProperties?: Record<string, string> | null;
    quantity: number;
    variantId: VariantId;
    discounts?: CartItemDiscount[] | null;
}

export interface SavedForLaterItem {
    position?: number | null;
    variantId: VariantId;
}

export interface CartDynamoData
    extends CartIdFields,
        CartRelationshipFields,
        // We won't necessarily know if we are creating or not, so we'll just use the updatedAt field.
        Pick<ResonanceDates, "updatedAt"> {
    /** This only gets used on anonymous carts, and gets set to a distant time on every update. This is merely so we don't accrue carts forever. */
    expiresAt?: number | null;
    cartType: CartType;
    /** We store cartItems as a record rather than array so that we can update atomically */
    cartItems: Record<VariantId, CartItem>;
    /** We store savedForLaterItems as a record rather than array so that we can update atomically*/
    savedForLaterItems: Record<VariantId, SavedForLaterItem>;
    /** We store discountResponses as a record rather than array so that we can update atomically*/
    discountResponses?: Record<string, DiscountResponse> | null;
}

/**
 * A Cart exists to be a temporary holding place for a Customer to add Variants that he or she wish to eventually purchase.
 * Carts can be associated with an actual customer, or simply to a device anonymously for a shopper who is not logged in.
 */
export interface Cart
    extends Omit<
        CartDynamoData,
        "cartItems" | "savedForLaterItems" | "discountResponses"
    > {
    cartItems: CartItem[];
    savedForLaterItems: SavedForLaterItem[];
    discountResponses?: DiscountResponse[] | null;
}
