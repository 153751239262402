import React, { ReactElement } from "react";

import {
    DemandHqId,
    NotificationStatus,
} from "@buildresonance/resonance-lib-entities";
import {
    NotificationsIconButton,
    ResonanceMainNavItem,
} from "@buildresonance/resonance-lib-nav-components";
import { useListDemandHqsForMe } from "@hooks/graphql/queries/useListDemandHqsForMe";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import { List } from "@mui/material";
import { useDemandHqNotifications } from "@views/demandHqs/notifications/DemandHqNotificationsParent/useDemandHqNotifications";
import ms from "ms";
import { useRouter } from "next/router";

import { DemandHqNavAdminMenu } from "./DemandHqNavAdminMenu";
import { DemandHqNavBulkManagement } from "./DemandHqNavBulkManagement";
import { DemandHqNavIntegrationsMenu } from "./DemandHqNavIntegrationsMenu";
import { DemandHqNavManagementMenu } from "./DemandHqNavManagementMenu";
import { DemandHqNavReportingMenu } from "./DemandHqNavReportingMenu";

const DemandHqNav = ({
    handleDrawerClose,
}: {
    handleDrawerClose: () => void;
}): ReactElement => {
    const router = useRouter();
    const demandHqId = router?.query?.demandHqId as DemandHqId | undefined;

    const { demandHq } = useListDemandHqsForMe({
        demandHqId,
    });

    const { data: demandHqNotificationsData } = useDemandHqNotifications(
        1,
        {
            demandHqId,
            status: NotificationStatus.Fresh,
            last: 10,
        },
        {
            staleTime: ms("15 min"),
        },
    );

    const renderDemandHqMenu = (): ReactElement | null => {
        if (demandHqId) {
            return (
                <>
                    <DemandHqNavManagementMenu
                        handleDrawerClose={handleDrawerClose}
                    />
                    <DemandHqNavBulkManagement
                        handleDrawerClose={handleDrawerClose}
                    />
                    <DemandHqNavReportingMenu
                        handleDrawerClose={handleDrawerClose}
                    />
                    <DemandHqNavIntegrationsMenu
                        handleDrawerClose={handleDrawerClose}
                    />
                    <DemandHqNavAdminMenu
                        handleDrawerClose={handleDrawerClose}
                    />
                </>
            );
        }

        return null;
    };

    const renderNotificationsIconButton = (): ReactElement | null => {
        if (!demandHqId) {
            return null;
        }
        return (
            <NotificationsIconButton
                href={DemandAdminRoute.DemandHqNotifications.build(demandHqId)}
                notificationCount={
                    demandHqNotificationsData?.pages?.reduce(
                        (sum, page) =>
                            sum + page?.demandHq?.notifications?.nodes?.length,
                        0,
                    ) ?? 0
                }
                resonanceMainNavSecondaryActionProps={{
                    isSelected:
                        router.pathname ===
                        DemandAdminRoute.DemandHqNotifications.pathname,
                    title: "Demand Notifications",
                }}
            />
        );
    };

    return (
        <div role="navigation">
            <List aria-label="Demand Portal Nav">
                <ResonanceMainNavItem
                    variant="mainHeading"
                    href={
                        demandHqId
                            ? DemandAdminRoute.DemandHqDetail.build(demandHqId)
                            : DemandAdminRoute.MyDemandHqs.build()
                    }
                    onClick={() => handleDrawerClose()}
                    primaryText="DemandHq"
                    secondaryText={demandHq?.name ?? "Select a DemandHq"}
                    secondaryAction={renderNotificationsIconButton()}
                />
                {renderDemandHqMenu()}
            </List>
        </div>
    );
};

export { DemandHqNav };
