import { useMemo } from "react";

import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import { DEMAND_API_DOMAIN_NAME } from "@lib/common/domainNames";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useDebouncedCallback } from "use-debounce";

const COLLECTION_AUTOCOMPLETE_DEBOUNCE_DELAY_IN_MS = 150;

export interface UseCollectionAutoCompleteReturn {
    /** Hit this as the user types, will automatically debounce */
    debouncedQuery: (queryParam: string) => void;
    collectionAutoCompletes: string[] | undefined;
}

const useCollectionAutoComplete = (
    demandHqId: DemandHqId | undefined,
): UseCollectionAutoCompleteReturn => {
    const { mutate, data } = useMutation<
        AxiosResponse<{ collectionAutoCompletes: string[] }>,
        AxiosError,
        { query: string }
    >(({ query }) =>
        axios.get(`/demand-hqs/${demandHqId}/collection-auto-completes`, {
            baseURL: `https://${DEMAND_API_DOMAIN_NAME}`,
            params: { query, first: 10 },
        }),
    );

    const debouncedQuery = useDebouncedCallback(
        (queryParam) => mutate({ query: queryParam }),
        COLLECTION_AUTOCOMPLETE_DEBOUNCE_DELAY_IN_MS,
    );

    const collectionAutoCompletes = useMemo(
        () => data?.data?.collectionAutoCompletes,
        [data],
    );

    return { debouncedQuery, collectionAutoCompletes };
};

export { useCollectionAutoComplete };
