import { confirmExistence } from "@util/confirmExists";

export const INTERNAL_DOMAIN_NAME = confirmExistence(
    process.env.NEXT_PUBLIC_INTERNAL_DOMAIN_NAME,
    "NEXT_PUBLIC_INTERNAL_DOMAIN_NAME",
);

export const MARKETING_DOMAIN_NAME = confirmExistence(
    process.env.NEXT_PUBLIC_MARKETING_DOMAIN_NAME,
    "NEXT_PUBLIC_MARKETING_DOMAIN_NAME",
);

export const CDN_DOMAIN_NAME = confirmExistence(
    process.env.NEXT_PUBLIC_CDN_DOMAIN_NAME,
    "NEXT_PUBLIC_CDN_DOMAIN_NAME",
);

export const DEMAND_ADMIN_DOMAIN_NAME = confirmExistence(
    process.env.NEXT_PUBLIC_DEMAND_ADMIN_DOMAIN_NAME,
    "NEXT_PUBLIC_DEMAND_ADMIN_DOMAIN_NAME",
);
export const DOCS_DOMAIN_NAME = confirmExistence(
    process.env.NEXT_PUBLIC_DOCS_DOMAIN_NAME,
    "NEXT_PUBLIC_DOCS_DOMAIN_NAME",
);
export const IDENTITY_ADMIN_DOMAIN_NAME = confirmExistence(
    process.env.NEXT_PUBLIC_IDENTITY_ADMIN_DOMAIN_NAME,
    "NEXT_PUBLIC_IDENTITY_ADMIN_DOMAIN_NAME",
);
export const SUPPLY_ADMIN_DOMAIN_NAME = confirmExistence(
    process.env.NEXT_PUBLIC_SUPPLY_ADMIN_DOMAIN_NAME,
    "NEXT_PUBLIC_SUPPLY_ADMIN_DOMAIN_NAME",
);

export const IDENTITY_API_DOMAIN_NAME = confirmExistence(
    process.env.NEXT_PUBLIC_IDENTITY_API_DOMAIN_NAME,
    "NEXT_PUBLIC_IDENTITY_API_DOMAIN_NAME",
);
export const DEMAND_API_DOMAIN_NAME = confirmExistence(
    process.env.NEXT_PUBLIC_DEMAND_API_DOMAIN_NAME,
    "NEXT_PUBLIC_DEMAND_API_DOMAIN_NAME",
);
export const SUPPLY_API_DOMAIN_NAME = confirmExistence(
    process.env.NEXT_PUBLIC_SUPPLY_API_DOMAIN_NAME,
    "NEXT_PUBLIC_SUPPLY_API_DOMAIN_NAME",
);

export enum GqlApi {
    AdminIdentity = "AdminIdentity",
    Demand = "Demand",
    Supply = "Supply",
}

const findApiDomainName = (api: GqlApi | RestApi): string | undefined => {
    switch (api) {
        case GqlApi.AdminIdentity:
            return IDENTITY_API_DOMAIN_NAME;
        case GqlApi.Demand:
            return DEMAND_API_DOMAIN_NAME;
        case GqlApi.Supply:
            return SUPPLY_API_DOMAIN_NAME;
        default:
            throw new Error("Invalid API");
    }
};

export const buildGqlEndpoint = (api: GqlApi): string => {
    const domainName = findApiDomainName(api);

    if (!domainName) {
        throw new Error("API Domain name not found in env variables!");
    }

    return `https://${domainName}/graphql`;
};

/**
 * Duplicate of GqlApi - would copy directly but TS doesn't support this
 */
export enum RestApi {
    AdminIdentity = "AdminIdentity",
    Demand = "Demand",
    Supply = "Supply",
}

export enum DemandEndpoints {
    PostOAuthAuthorizeNet = "/oauth/authorizenet",
}

/**
 * Creates a REST endpoint for the provided domain Api and endpoint
 */
export const buildRestEndpoint = (
    api: RestApi,
    endpoint: DemandEndpoints,
): string => {
    const domainName = findApiDomainName(api);

    if (!domainName) {
        throw new Error("API Domain name not found in env variables!");
    }

    return `https://${domainName}/${endpoint}`;
};
