import {
    VariantSupplierVariantApiCreateInput,
    VariantSupplierVariantApiUpdateInput,
} from "@buildresonance/resonance-lib-entities";
import { object, ObjectSchema } from "yup";

import {
    supplierVariantApiCreateInputSchema,
    supplierVariantApiUpdateInputSchema,
} from "../supply/supplierVariant";
import {
    supplierVariantSupplementApiUpdateInputSchema,
    supplierVariantSupplementCommonCreateInputSchema,
} from "../supply/supplierVariantSupplement";
import {
    supplierIdSchema,
    supplierProductIdSchema,
    supplierVariantIdSchema,
} from "../supply/supplyIds";

import {
    demandHqIdSchema,
    productIdSchema,
    variantIdSchema,
} from "./demandIds";

export const variantSupplierVariantApiCreateInputSchema: ObjectSchema<VariantSupplierVariantApiCreateInput> =
    object({
        variantId: variantIdSchema.required(),
        supplierVariantId: supplierVariantIdSchema.required(),
        demandHqId: demandHqIdSchema.required(),
        productId: productIdSchema.required(),
        supplierId: supplierIdSchema.required(),
        supplierProductId: supplierProductIdSchema.required(),
    })
        .concat(
            supplierVariantApiCreateInputSchema.pick([
                "inventoryQuantity",
                "inventoryPolicy",
            ]),
        )
        .concat(
            supplierVariantSupplementCommonCreateInputSchema.pick([
                "averageShippingCost",
                "cost",
                "handlingCost",
            ]),
        )
        .noUnknown();

export const variantSupplierVariantApiUpdateSchema: ObjectSchema<VariantSupplierVariantApiUpdateInput> =
    object({
        supplierVariantId: supplierVariantIdSchema.required(),
        variantId: variantIdSchema.required(),
    })
        .concat(
            supplierVariantApiUpdateInputSchema.pick([
                "inventoryQuantity",
                "inventoryPolicy",
            ]),
        )
        .concat(
            supplierVariantSupplementApiUpdateInputSchema.pick([
                "averageShippingCost",
                "cost",
                "handlingCost",
            ]),
        )
        .noUnknown();
