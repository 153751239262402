import ms from "ms";

const units = {
    year: ms("1 year"),
    month: ms("1 month"),
    day: ms("1 day"),
    hour: ms("1 hour"),
    minute: ms("1 minute"),
    second: ms("1 second"),
};

const rtfShort = new Intl.RelativeTimeFormat("en", { style: "short" });

export const formatAsRelativeTime = (d1: Date, d2 = new Date()): string => {
    const elapsed = Number(d1) - Number(d2);

    // "Math.abs" accounts for both "past" & "future" scenarios
    for (const [unitName, unitValue] of Object.entries(units)) {
        if (Math.abs(elapsed) > unitValue || unitName == "second") {
            return rtfShort.format(
                Math.round(elapsed / unitValue),
                unitName as Intl.RelativeTimeFormatUnit,
            );
        }
    }

    return "Something went terribly wrong.";
};
