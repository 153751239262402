import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { BaseQuickLink } from "../adminidentity/common/BaseQuickLink";

import { DemandHqId } from "./DemandIds";

/** Indicates which page the DemandHqQuickLink would live on */
export enum DemandHqQuickLinkType {
    Product = "PRODUCT",
    Variant = "VARIANT",
}

export type DemandHqQuickLinkIdFields = Pick<BaseQuickLink, "appId"> & {
    /** Indicates which page the DemandHqQuickLink would live on */
    quickLinkType: DemandHqQuickLinkType;
    /** The Id of the DemandHq this quicklink pertains to (the one that has installed your app). */
    demandHqId: DemandHqId;
};

/** Represents a link to your app from inside the Resonance Supply Portal */
export interface DemandHqQuickLink
    extends BaseQuickLink,
        DemandHqQuickLinkIdFields {}

export type DemandHqQuickLinkModelCreateInput = Omit<
    DemandHqQuickLink,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type DemandHqQuickLinkModelUpdateInput = DemandHqQuickLinkIdFields &
    Partial<
        Omit<DemandHqQuickLinkModelCreateInput, keyof DemandHqQuickLinkIdFields>
    >;

export type DemandHqQuickLinkApiCreateInput = DemandHqQuickLinkModelCreateInput;

export type DemandHqQuickLinkApiUpdateInput = DemandHqQuickLinkModelUpdateInput;
