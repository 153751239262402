import {
    CheckoutOrderItemApiUpdateInput,
    CheckoutOrderItemRequiredFields,
    CheckoutOrderTransactionApiCreateInput,
    CheckoutOrderItemCommonCreateFields,
    CheckoutOrderApiUpdateInput,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, array, string } from "yup";

import { DISCOUNT_CODE_MAX_LENGTH } from "../discount";
import { orderUpdateInputCommonSchema } from "../order";
import {
    orderItemCreateInputCommonSchema,
    orderItemUpdateInputCommonSchema,
} from "../orderItem";
import { orderTransactionApiCreateInputSchema } from "../orderTransaction";

export const CHECKOUT_NAME_MAX_LENGTH = 64;

export const checkoutNameSchema = string()
    .max(CHECKOUT_NAME_MAX_LENGTH)
    .nullable();

export const discountCodeSchema = string()
    .required()
    .max(DISCOUNT_CODE_MAX_LENGTH);

export const discountCodeArraySchema = array().of(discountCodeSchema);

export const checkoutOrderApiUpdateInputSchema: ObjectSchema<CheckoutOrderApiUpdateInput> =
    orderUpdateInputCommonSchema;

const checkoutOrderItemCreateOptionalFields: readonly Exclude<
    CheckoutOrderItemCommonCreateFields,
    CheckoutOrderItemRequiredFields
>[] = ["quantity", "unitPrice"];

const checkoutOrderItemCreateRequiredFields: readonly CheckoutOrderItemRequiredFields[] =
    ["variantId"];

export const checkoutOrderItemApiCreateOrUpdateInputSchema: ObjectSchema<CheckoutOrderItemApiUpdateInput> =
    orderItemCreateInputCommonSchema
        .pick(checkoutOrderItemCreateOptionalFields)
        .partial()
        .concat(
            orderItemCreateInputCommonSchema.pick(
                checkoutOrderItemCreateRequiredFields,
            ),
        )
        .concat(orderItemUpdateInputCommonSchema)
        .noUnknown();

export const checkoutOrderTransactionApiCreateInputSchema: ObjectSchema<CheckoutOrderTransactionApiCreateInput> =
    orderTransactionApiCreateInputSchema;
