import {
    SupplierVariantApiCreateInput,
    SupplierVariantApiUpdateInput,
    SupplierVariantInCompositeApiCreateInput,
    SupplierVariantInventoryPolicy,
    SupplierVariantCompositeApiCreateInput,
    SupplierVariantExternalApiUpdateInput,
} from "@buildresonance/resonance-lib-entities";
import {
    AnyObject,
    array,
    number,
    object,
    ObjectSchema,
    string,
    TupleSchema,
} from "yup";

import { contentItemIdSchema } from "../cdn/cdnIds";
import { transformNaNToNull } from "../numberHelpers";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import { supplierVariantSupplementInCompositeApiCreateInputSchema } from "./supplierVariantSupplement";
import {
    supplierIdSchema,
    supplierProductIdSchema,
    supplierVariantIdSchema,
} from "./supplyIds";

export const SUPPLIER_VARIANT_EXTERNAL_VARIANT_ID_MAX_LENGTH = 128;
export const SUPPLIER_VARIANT_OPTION_VALUE_MAX_LENGTH = 128;
export const SUPPLIER_VARIANT_MAX_NUMBER_OF_CONTENT_ITEM_IDS = 25;
export const SUPPLIER_VARIANT_MAX_NUMBER_OF_OPTION_VALUES = 3;

export const supplierVariantInCompositeApiCreateInputSchema: ObjectSchema<SupplierVariantInCompositeApiCreateInput> =
    object({
        contentItemIds: array()
            .of(contentItemIdSchema.required())
            .max(SUPPLIER_VARIANT_MAX_NUMBER_OF_CONTENT_ITEM_IDS)
            .nullable(),
        externalVariantId: string()
            .required()
            .max(SUPPLIER_VARIANT_EXTERNAL_VARIANT_ID_MAX_LENGTH),
        inventoryPolicy: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(SupplierVariantInventoryPolicy)),
        inventoryQuantity: number()
            .integer()
            .required()
            .transform(transformNaNToNull),
        optionValues: array()
            .of(string().max(SUPPLIER_VARIANT_OPTION_VALUE_MAX_LENGTH))
            .required()
            .max(SUPPLIER_VARIANT_MAX_NUMBER_OF_OPTION_VALUES)
            .test({
                name: "firstRequired",
                message: "At least one option value is required",
                test: (values: (string | undefined)[] | undefined) =>
                    !values || (values.length > 0 && !!values[0]),
            }) as unknown as TupleSchema<
            [string, string?, string?],
            AnyObject,
            undefined
        >,
    }).noUnknown();

export const supplierVariantApiCreateInputSchema: ObjectSchema<SupplierVariantApiCreateInput> =
    supplierVariantInCompositeApiCreateInputSchema
        .concat(
            object({
                supplierId: supplierIdSchema.required(),
                supplierProductId: supplierProductIdSchema.required(),
            }),
        )
        .noUnknown();

export const supplierVariantCompositeApiCreateInputSchema: ObjectSchema<SupplierVariantCompositeApiCreateInput> =
    object({
        supplierVariantInput: supplierVariantApiCreateInputSchema,
        supplierVariantSupplementInput:
            supplierVariantSupplementInCompositeApiCreateInputSchema
                .nullable()
                .transform(requiredOnlyWhenExistsObjectTransformer)
                .default(undefined),
    }).noUnknown();

const supplierVariantApiUpdateCommon = object({
    contentItemIds: array()
        .of(contentItemIdSchema.required())
        .max(SUPPLIER_VARIANT_MAX_NUMBER_OF_CONTENT_ITEM_IDS)
        .nullable(),
    inventoryPolicy: string()
        .transform(transformBlankStringToNull)
        .oneOf([...Object.values(SupplierVariantInventoryPolicy), undefined])
        .transform(transformBlankStringToNull),
    inventoryQuantity: number().integer().transform(transformNaNToNull),
    optionValues: array()
        .of(string().max(SUPPLIER_VARIANT_OPTION_VALUE_MAX_LENGTH))
        .max(SUPPLIER_VARIANT_MAX_NUMBER_OF_OPTION_VALUES)
        .test({
            name: "firstRequired",
            message: "At least one option value is required",
            test: (values: (string | undefined)[] | undefined) =>
                !values || (values.length > 0 && !!values[0]),
        }) as unknown as TupleSchema<
        [string, string?, string?],
        AnyObject,
        undefined
    >,
});

export const supplierVariantApiUpdateInputSchema: ObjectSchema<SupplierVariantApiUpdateInput> =
    supplierVariantApiUpdateCommon
        .concat(
            object({
                externalVariantId: string().max(
                    SUPPLIER_VARIANT_EXTERNAL_VARIANT_ID_MAX_LENGTH,
                ),
                supplierVariantId: supplierVariantIdSchema.required(),
            }),
        )
        .noUnknown();

export const supplierVariantExternalApiUpdateInputSchema: ObjectSchema<SupplierVariantExternalApiUpdateInput> =
    supplierVariantApiUpdateCommon
        .concat(
            object({
                externalVariantId: string()
                    .required()
                    .max(SUPPLIER_VARIANT_EXTERNAL_VARIANT_ID_MAX_LENGTH),
                supplierId: supplierIdSchema.required(),
            }),
        )
        .noUnknown();
