import {
    AppApiCreateInput,
    AppApiUpdateInput,
    AppStatus,
    AppType,
} from "@buildresonance/resonance-lib-entities";
import { array, object, ObjectSchema, string } from "yup";

import { appIdSchema } from "..";
import { transformBlankStringToNull } from "../stringHelpers";

import { partnerIdSchema } from "./adminIdentityIds";

export const APP_DESCRIPTION_MAX_LENGTH = 8000;
export const APP_NAME_MAX_LENGTH = 128;

export const appApiCreateInputSchema: ObjectSchema<AppApiCreateInput> = object({
    appType: string<AppType>()
        .transform(transformBlankStringToNull)
        .required()
        .oneOf(Object.values(AppType)),
    description: string().nullable().max(APP_DESCRIPTION_MAX_LENGTH),
    name: string().required().max(APP_NAME_MAX_LENGTH),
    status: string<AppStatus>().required().oneOf(Object.values(AppStatus)),
    partnerId: partnerIdSchema.required(),
    requestedScopes: array().of(string().required()).nullable(),
}).noUnknown();

export const appApiUpdateInputSchema: ObjectSchema<AppApiUpdateInput> = object({
    appId: appIdSchema.required(),
    description: string().nullable().max(APP_DESCRIPTION_MAX_LENGTH),
    name: string().max(APP_NAME_MAX_LENGTH),
    status: string<AppStatus>()
        .transform(transformBlankStringToNull)
        .oneOf(Object.values(AppStatus)),
    requestedScopes: array().of(string().required()).nullable(),
}).noUnknown();
