import {
    RelayArgs,
    ResonanceAddress,
} from "@buildresonance/resonance-lib-entities";
import { number, object, ObjectSchema, string } from "yup";

import { transformNaNToNull } from "./numberHelpers";
import { PHONE_NUMBER_OR_EMPTY_REGEX } from "./stringHelpers";

export const relayArgsSchema: ObjectSchema<RelayArgs> = object({
    first: number().nullable().transform(transformNaNToNull),
    last: number().nullable().transform(transformNaNToNull),
    before: string().nullable(),
    after: string().nullable(),
}).noUnknown();

export const NAME_MAX_LENGTH = 256;
export const COMPANY_MAX_LENGTH = 256;
export const STREET_ADDRESS_MAX_LENGTH = 256;
export const CITY_MAX_LENGTH = 128;
export const STATE_MAX_LENGTH = 64;
export const POSTAL_CODE_MAX_LENGTH = 32;
export const COUNTRY_MAX_LENGTH = 128;

export const resonanceAddressSchema: ObjectSchema<ResonanceAddress> = object({
    name: string().max(NAME_MAX_LENGTH).required(),
    company: string().max(COMPANY_MAX_LENGTH).nullable(),
    streetAddress1: string().required().max(STREET_ADDRESS_MAX_LENGTH),
    streetAddress2: string().max(STREET_ADDRESS_MAX_LENGTH).nullable(),
    city: string().required().max(CITY_MAX_LENGTH),
    state: string().required().max(STATE_MAX_LENGTH),
    postalCode: string().required().max(POSTAL_CODE_MAX_LENGTH),
    country: string().required().max(COUNTRY_MAX_LENGTH),
    phoneNumber: string().nullable().matches(PHONE_NUMBER_OR_EMPTY_REGEX),
}).noUnknown();

export const resonanceAddressAllFieldsOptionalSchema: ObjectSchema<
    Partial<ResonanceAddress>
> = object({
    name: string().max(NAME_MAX_LENGTH),
    company: string().max(COMPANY_MAX_LENGTH).nullable(),
    streetAddress1: string().max(STREET_ADDRESS_MAX_LENGTH),
    streetAddress2: string().max(STREET_ADDRESS_MAX_LENGTH).nullable(),
    city: string().max(CITY_MAX_LENGTH),
    state: string().max(STATE_MAX_LENGTH),
    postalCode: string().max(POSTAL_CODE_MAX_LENGTH),
    country: string().max(COUNTRY_MAX_LENGTH),
    phoneNumber: string().nullable().matches(PHONE_NUMBER_OR_EMPTY_REGEX),
}).noUnknown();

export const CUSTOM_PROPERTIES_KEY_MAX_LENGTH = 64;
export const CUSTOM_PROPERTIES_VALUE_MAX_LENGTH = 512;
export const CUSTOM_PROPERTIES_MAX_NUMBER_OF_KEY_VALUE_PAIRS = 5;

export const customPropertiesSchema = object<Record<string, string>>()
    .test(
        "allKeysMeetLengthRequirements",
        ({ value }) =>
            `Keys ${Object.keys(value)
                .filter((key) => key.length > CUSTOM_PROPERTIES_KEY_MAX_LENGTH)
                .join(
                    ", ",
                )} exceed the maximum length of ${CUSTOM_PROPERTIES_KEY_MAX_LENGTH}`,
        (value) =>
            !value ||
            Object.keys(value).every(
                (key) => key.length <= CUSTOM_PROPERTIES_KEY_MAX_LENGTH,
            ),
    )
    .test(
        "allValuesAreStrings",
        ({ value }) =>
            `Values ${Object.values(value)
                .filter((innerValue) => typeof innerValue !== "string")
                .map((innerValue) => JSON.stringify(innerValue))
                .join(", ")} are not strings`,
        (value) =>
            !value ||
            Object.values(value).every(
                (innerValue) => typeof innerValue === "string",
            ),
    )
    .test(
        "allValuesMeetLengthRequirements",
        ({ value }) =>
            `Values ${Object.values(value)
                .filter(
                    (innerValue) =>
                        (innerValue as string)?.length >
                        CUSTOM_PROPERTIES_VALUE_MAX_LENGTH,
                )
                .join(
                    ", ",
                )} exceed the maximum length of ${CUSTOM_PROPERTIES_VALUE_MAX_LENGTH}`,
        (value) =>
            !value ||
            Object.values(value).every(
                (innerValue) =>
                    (innerValue as string)?.length <=
                    CUSTOM_PROPERTIES_VALUE_MAX_LENGTH,
            ),
    )
    .test(
        "numberOfKeyValuePairsDoesNotExceedMax",
        (value) =>
            `Number of key value pairs ${
                Object.keys(value).length
            } exceeds the maximum of ${CUSTOM_PROPERTIES_MAX_NUMBER_OF_KEY_VALUE_PAIRS}`,
        (value) =>
            !value ||
            Object.keys(value).length <=
                CUSTOM_PROPERTIES_MAX_NUMBER_OF_KEY_VALUE_PAIRS,
    );
