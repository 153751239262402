import {
    ALL_RESONANCE_ENTITIES,
    EventMutationType,
    ResonanceEventSubscriptionApiCreateInput,
    ResonanceEventSubscriptionEntityId,
    ResonanceEventSubscriptionEntityType,
    ResonanceEventSubscriptionProtocol,
    SupplyEntity,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, object, string } from "yup";

import { appIdOrUserNameSchema } from "../adminIdentity/adminIdentityIds";
import { transformBlankStringToNull } from "../stringHelpers";

// eslint-disable-next-line import/prefer-default-export
export const resonanceEventSubscriptionApiCreateInputSchema: ObjectSchema<ResonanceEventSubscriptionApiCreateInput> =
    object({
        endpoint: string().required(),
        itemType: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(
                ALL_RESONANCE_ENTITIES.map((entity) =>
                    entity.toLowerCase(),
                ) as Lowercase<SupplyEntity>[],
            ),
        mutationType: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(EventMutationType)),
        protocol: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(ResonanceEventSubscriptionProtocol)),
        subscriptionEntityId:
            string<ResonanceEventSubscriptionEntityId>().required(), // May want to slim this down, but I left it open from fear of being annoying to update, I could be wrong
        subscriptionEntityType: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(ResonanceEventSubscriptionEntityType)),
        subscriptionOwnerId: appIdOrUserNameSchema.required(),
    }).noUnknown();
