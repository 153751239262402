import {
    ContentItemApiCreateFromUrlInput,
    ContentItemApiCreateSignedUrlInput,
    ContentItemExtension,
    ContentItemSourceType,
    ResonanceCdnContentType,
    ResonanceDomain,
} from "@buildresonance/resonance-lib-entities";
import { object, ObjectSchema, string } from "yup";

import { transformBlankStringToNull } from "../stringHelpers";

import { domainEntityIdSchema } from "./cdnIds";

export const CONTENT_ITEM_ALT_MAX_LENGTH = 1000;
export const CONTENT_ITEM_CATEGORY_MAX_LENGTH = 256;
export const CONTENT_ITEM_FILENAME_MAX_LENGTH = 1000;
export const CONTENT_ITEM_FILE_EXTENSION_MAX_LENGTH = 100;

export const contentItemApiCreateFromUrlInputSchema: ObjectSchema<ContentItemApiCreateFromUrlInput> =
    object({
        contentType: string()
            .transform(transformBlankStringToNull)
            .oneOf(
                [ResonanceCdnContentType.Image],
                "Only image ContentType can be created on this route",
            )
            .required(),
        domain: string()
            .transform(transformBlankStringToNull)
            .oneOf([ResonanceDomain.Demand, ResonanceDomain.Supply])
            .required(),
        domainEntityId: domainEntityIdSchema.required(),
        md5: string().nullable(),
        sourceDetail: string().required(),
        sourceType: string()
            .transform(transformBlankStringToNull)
            .oneOf(
                [ContentItemSourceType.Url],
                "Only URL SourceType allowed on this route",
            )
            .required(),
    }).noUnknown();

export const contentItemApiCreateSignedUrlInputSchema: ObjectSchema<ContentItemApiCreateSignedUrlInput> =
    object({
        contentExtension: string<ContentItemExtension>().required(),
        contentType: string()
            .transform(transformBlankStringToNull)
            .oneOf(
                [ResonanceCdnContentType.Image],
                "Only image ContentType can be created on this route",
            )
            .required(),
        domain: string()
            .transform(transformBlankStringToNull)
            .oneOf([ResonanceDomain.Demand, ResonanceDomain.Supply])
            .required(),
        domainEntityId: domainEntityIdSchema.required(),
        md5: string().nullable(),
        sourceDetail: string().required(),
        sourceType: string()
            .transform(transformBlankStringToNull)
            .oneOf(
                [ContentItemSourceType.UploadMultiPart],
                "Only UploadMultiPart SourceType allowed on this route",
            )
            .required(),
    }).noUnknown();
