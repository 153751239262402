import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { ContentItemId } from "../cdn";
import { VariantCommon, VariantOptionValues } from "../common/VariantCommon";

import { DemandHqId, ProductId, VariantId } from "./DemandIds";

/** IdFields are the ones that are required to find the correct entity. Must come in all update methods. */
export interface VariantIdFields {
    variantId: VariantId;
}

export interface VariantRelationshipFields {
    demandHqId: DemandHqId;
    productId: ProductId;
}

/**
 * This is the child entity to a Product, and is a variation, such as a color, size, etc.
 *
 * This entity differs significantly from a SupplierVariant, and encompasses both a SupplierVariant and a VariantSupplement.
 * The reason for this is that without inventory concerns, the number of updates to a Variant is low across the board.
 * Inventory is housed in a separate entity: @see VariantSupplierVariant
 */
export interface Variant
    extends VariantIdFields,
        VariantRelationshipFields,
        VariantCommon,
        ResonanceDates,
        ResonanceUpdatedBy {
    /** ContentItemIds associated with this variant, use the first image as the variant image */
    contentItemIds?: ContentItemId[] | null;
    /**
     * Values for the optionLabels in the parent Product.
     * For label "Size", this might be "Small", "Medium", "Large", etc.
     *
     * Set the first optionValue to "No Option" if you don't want to use options for this product and this is the only variant.
     * @see https://docs.buildresonance.com/concepts/products/#no-option-products for more info.
     *
     * Each optionValue has a 128 character limit.
     */
    optionValues: VariantOptionValues;
    /**
     * Used to determine what order the content comes out in.
     * Positive Integers are allowed, up to 9,999,999,999, and it does not have to be unique or sequential.
     */
    position?: number | null;
    /**
     * Price for the defaultCurrency, can be any positive float number value.
     */
    price?: number | null;
}

export interface VariantGqlFields {
    /**
     * Calculated, combo of options
     * @readonly
     */
    title: string;
}

/** This is the response you would receive on the frontend (if you added every field, of course) */
export type VariantGql = Variant & VariantGqlFields;

export type VariantModelUpdateInput = VariantIdFields &
    Partial<
        Omit<
            Variant,
            | keyof VariantIdFields
            | keyof VariantRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type VariantModelCreateInput = Omit<
    Variant,
    keyof VariantIdFields | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type VariantInCompositeApiCreateInput = Omit<
    VariantApiCreateInput,
    keyof VariantIdFields | keyof VariantRelationshipFields
>;

export type VariantApiUpdateInput = VariantModelUpdateInput;

export type VariantApiCreateInput = VariantModelCreateInput;
