import React, { ReactElement } from "react";

import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import { useRouter } from "next/router";

import { AppBarSearchAutocomplete } from "../common/AppBarSearchAutocomplete";
import { DemandEntityRecentSearchesLocalStorageKey } from "../common/constants";

import { usePageAutoComplete } from "./usePageAutoComplete";

const PageSearchInputParent = ({
    setSearchInputIsFocused,
}: {
    setSearchInputIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement | null => {
    const router = useRouter();

    const demandHqId = router.query.demandHqId as DemandHqId | undefined;

    const { debouncedQuery, pageAutoCompletes } =
        usePageAutoComplete(demandHqId);

    if (
        !demandHqId ||
        ![
            DemandAdminRoute.PageCreate.pathname,
            DemandAdminRoute.PageEdit.pathname,
            DemandAdminRoute.PageImagesAdd.pathname,
            DemandAdminRoute.Pages.pathname,
        ].includes(router.pathname)
    ) {
        return null;
    }

    return (
        <AppBarSearchAutocomplete
            autoCompleteQueryResponse={pageAutoCompletes}
            id="page-search-input"
            label="Page Search"
            placeholder="Search Pages..."
            searchPathName={DemandAdminRoute.Pages.pathname}
            sendDebouncedAutoCompleteQuery={debouncedQuery}
            setSearchInputIsFocused={setSearchInputIsFocused}
            localStorageKey={DemandEntityRecentSearchesLocalStorageKey.Page}
        />
    );
};

export { PageSearchInputParent };
