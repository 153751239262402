import {
    PageTypeContentApiCreateInput,
    PageTypeContentApiUpdateInput,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, object, string } from "yup";

import { seoFieldsSchema } from "../common/seo";
import { tagSmallArraySchema } from "../common/tags";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";

import {
    pageTypeContentIdSchema,
    demandHqIdSchema,
    storefrontIdSchema,
} from "./demandIds";
import {
    pageContentAsStringSchema,
    PAGE_TITLE_MAX_LENGTH,
    pageTypeSchema,
    PAGE_DESCRIPTION_MAX_LENGTH,
} from "./page";

export const PAGE_TYPE_CONTENT_MAX_LENGTH = 10000;

export const pageTypeContentAsStringSchema = string().max(
    PAGE_TYPE_CONTENT_MAX_LENGTH,
);

export const pageTypeContentApiCreateInputSchema: ObjectSchema<PageTypeContentApiCreateInput> =
    object({
        pageType: pageTypeSchema.required(),
        demandHqId: demandHqIdSchema.required(),
        contentAsString: pageContentAsStringSchema.nullable(),
        description: string().max(PAGE_DESCRIPTION_MAX_LENGTH).nullable(),
        secondaryContentAsString: pageContentAsStringSchema.nullable(),
        storefrontId: storefrontIdSchema.required(),
        subtitle: string().max(PAGE_TITLE_MAX_LENGTH).nullable(),
        tags: object({
            raw: tagSmallArraySchema.nullable(),
        })
            .nullable()
            .noUnknown()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        title: string().max(PAGE_TITLE_MAX_LENGTH).nullable(),
    })
        .concat(seoFieldsSchema)
        .noUnknown();

export const pageTypeContentApiUpdateInputSchema: ObjectSchema<PageTypeContentApiUpdateInput> =
    object({
        pageTypeContentId: pageTypeContentIdSchema.required(),
        contentAsString: pageContentAsStringSchema.nullable(),
        description: string().max(PAGE_DESCRIPTION_MAX_LENGTH).nullable(),
        secondaryContentAsString: pageContentAsStringSchema.nullable(),
        subtitle: string().max(PAGE_TITLE_MAX_LENGTH).nullable(),
        tags: object({
            raw: tagSmallArraySchema.nullable(),
        })
            .nullable()
            .noUnknown()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        title: string().max(PAGE_TITLE_MAX_LENGTH).nullable(),
    })
        .concat(seoFieldsSchema)
        .noUnknown();
