import {
    DemandHqCommentApiCreateInput,
    DemandHqCommentEntityId,
    DEMAND_HQ_COMMENT_VALID_ID_PREFIXES,
    DEMAND_HQ_COMMENT_VALID_ENTITIES,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, object, string } from "yup";

import { demandHqIdSchema } from "../demandIds";

export const DEMAND_HQ_COMMENT_CONTENT_MAX_LENGTH = 10000;

export const demandHqCommentEntityIdSchema =
    string<DemandHqCommentEntityId>().matches(
        new RegExp(`^${DEMAND_HQ_COMMENT_VALID_ID_PREFIXES.join("|")}.*`),
        `Must be one of: ${DEMAND_HQ_COMMENT_VALID_ENTITIES.join(", ")}`,
    );

export const demandHqCommentApiCreateInputSchema: ObjectSchema<DemandHqCommentApiCreateInput> =
    object({
        contentAsString: string()
            .required()
            .max(DEMAND_HQ_COMMENT_CONTENT_MAX_LENGTH),
        demandHqId: demandHqIdSchema.required(),
        entityId: demandHqCommentEntityIdSchema.required(),
    }).noUnknown();
