import { useMemo } from "react";

import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import {
    useAuthUser,
    useResonanceApi,
} from "@buildresonance/resonance-lib-frontend-admin-auth-passwordless";
import { buildGqlEndpoint, GqlApi } from "@lib/common/domainNames";
import { ClientError } from "graphql-request";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import {
    listDemandHqsForMe,
    ListDemandHqsForMeResponse,
    buildListDemandHqsForMeQueryKey,
    ListDemandHqsForMeQueryKey,
    ListDemandHqsForMeDemandHq,
} from "./listDemandHqsForMe";

export interface UseDemandHqsForMeOptions
    extends UseQueryOptions<
        ListDemandHqsForMeResponse,
        ClientError,
        ListDemandHqsForMeResponse,
        ListDemandHqsForMeQueryKey
    > {
    /** If you input this, a field named "demandHq" will be output with the demandHq you specify. */
    demandHqId?: DemandHqId;
}

export type UseDemandHqsForMeResult = UseQueryResult<
    ListDemandHqsForMeResponse,
    ClientError
> & {
    demandHq: ListDemandHqsForMeDemandHq | undefined;
};

const useListDemandHqsForMe = (
    options?: UseDemandHqsForMeOptions,
): UseDemandHqsForMeResult => {
    const { demandHqId, enabled, ...restOfOptions } = options ?? {};

    const { userName } = useAuthUser();

    const { gqlClient: client, withAuthQueryOptions } = useResonanceApi({
        endpoint: buildGqlEndpoint(GqlApi.Demand),
    });

    const queryResponse = useQuery<
        ListDemandHqsForMeResponse,
        ClientError,
        ListDemandHqsForMeResponse,
        ListDemandHqsForMeQueryKey
    >(
        buildListDemandHqsForMeQueryKey(userName),
        listDemandHqsForMe(client),
        withAuthQueryOptions({
            ...restOfOptions,
            enabled: !!userName && (enabled ?? true),
        }),
    );

    const demandHq = useMemo(() => {
        if (demandHqId) {
            return queryResponse?.data?.demandHqIdentities?.nodes?.find(
                (demandHqIdentity) =>
                    demandHqIdentity.demandHq.demandHqId === demandHqId,
            )?.demandHq;
        }
        return;
    }, [queryResponse.data, demandHqId]);

    return { ...queryResponse, demandHq };
};

export { useListDemandHqsForMe };
