import { ResonanceUpdatedBy } from "../../entityComposites";
import {
    ResonanceAddress,
    ResonanceCurrency,
    ResonanceDates,
    ResonanceLocaleType,
} from "../../global";
import { UserName } from "../adminidentity/AdminIdentityIds";

import { SupplierId } from "./SupplyIds";

/** Indicates the vertical the majority of a Supplier's goods are in. */
export enum SupplierCategory {
    SportingGoods = "SPORTING_GOODS",
}

/** Indicates what stage a Supplier is in */
export enum SupplierStatus {
    /** Indicates a Supplier's goods are no longer available for retailing. */
    Archived = "ARCHIVED",
    /** Indicates that a Supplier's goods are not yet available for retailing. Functionally identical to Archived. */
    Draft = "DRAFT",
    /** Indicates that a Supplier is activated and available, but not in the public Supplier Marketplace. */
    Private = "PRIVATE",
    /** Indicates that a Supplier is not only activated, but available in the public Supplier Marketplace. */
    Public = "PUBLIC",
}

/** IdFields are the ones that are required to find the correct entity. Must come in all update methods. */
export interface SupplierIdFields {
    supplierId: SupplierId;
}

/**
 * The most basic item in the Supplier domain.
 * The primary purpose of a Supplier is to create SupplierProducts and SupplierVariants, and then give access to DemandHqs to sell them.
 * When sold, using PurchaseOrderItems, Suppliers fulfill those items and then bill the DemandHq for the merchandise.
 * This item should never be deleted once it exists, archive instead.
 */
export interface Supplier
    extends SupplierIdFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /** Indicates the vertical the majority of a Supplier's goods are in. */
    category?: SupplierCategory | null;
    defaultLocaleType: ResonanceLocaleType;
    defaultCurrency: ResonanceCurrency;
    /** 8000 character limit */
    description?: string | null;
    /** The approximate number of in stock products this Supplier has on a typical basis. */
    inStockProducts?: number | null;
    /** 128 character limit */
    name: string;
    /**
     * These users will get notified if something requires attention for this Supplier.
     * If none are provided, ownerUserName will be notified.
     */
    notificationUserNames?: {
        management?: UserName[] | null;
        distribution?: UserName[] | null;
        /** This is part of distribution, but has specific enough notification requirements to be split out */
        purchaseOrders?: UserName[] | null;
    } | null;
    ownerUserName: UserName;
    shipFromAddress?: ResonanceAddress | null;
    status: SupplierStatus;
    /** The top Brands or Vendors this Supplier carries, limit 5 and 256 characters each. */
    topVendors?: string[] | null;
}

export type SupplierGql = Supplier;

export type SupplierModelUpdateInput = SupplierIdFields &
    Partial<
        Omit<
            Supplier,
            | keyof SupplierIdFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type SupplierModelCreateInput = Omit<
    Supplier,
    "supplierId" | "status" | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type SupplierApiUpdateInput = Omit<
    SupplierModelUpdateInput,
    keyof ResonanceDates
>;

/**
 * Just ownerUserName missing.
 */
export type SupplierApiCreateInput = Omit<
    SupplierModelCreateInput,
    "ownerUserName"
>;

export type PublicSupplierGql = Pick<
    SupplierGql,
    | "category"
    | "description"
    | "inStockProducts"
    | "name"
    | "shipFromAddress"
    | "status"
    | "supplierId"
    | "topVendors"
>;

export const PUBLIC_SUPPLIER_FIELDS: (keyof PublicSupplierGql)[] = [
    "category",
    "description",
    "inStockProducts",
    "name",
    "shipFromAddress",
    "status",
    "supplierId",
    "topVendors",
];
