import { DiscountResponse } from "../../..";
import { ResonanceCurrency, ResonanceDates } from "../../../global";
import { DemandHqId, OrderItemId, StorefrontId, VariantId } from "../DemandIds";
import { OrderApiCreateInput, OrderModelCreateInput } from "../Order";
import {
    OrderItemModelCreateInput,
    OrderItemModelUpdateInput,
} from "../OrderItem";
import {
    OrderTransactionApiCreateInput,
    OrderTransactionDataFields,
} from "../OrderTransaction";

import { CompleteCheckoutError } from "./CompleteCheckoutError";
import { CompleteCheckoutErrorCode } from "./CompleteCheckoutErrorCode";

export enum CheckoutType {
    Customer = "CUSTOMER",
    Device = "DEVICE",
}

export interface CheckoutIdFields {
    /**
     * This is a unique identifier for a checkout. It is optional, but can be used to have multiple checkouts for a given customer or device.
     */
    checkoutName?: string | null;
    /**
     * This field can be one of two strings, representing either:
     * - An externalCustomerId, which matches to a customer. This is a logged in checkout.
     * - An deviceId, which can be any string that remains the same for a given device. This is an anonymous checkout.
     */
    externalCustomerOrDeviceId: string;
}

export interface CheckoutRelationshipFields {
    demandHqId: DemandHqId;
    storefrontId: StorefrontId;
}

/**
 * The minimum required fields to create an Order.
 * The "Optional" fields are still eventually required when they are required on the Order entities,
 * but you can build in whatever order you like, and they will be checked at order validation or placement time.
 */
export type CheckoutOrder = Partial<
    Pick<
        OrderApiCreateInput,
        | "billingAddress"
        | "customerNote"
        | "emailAddress"
        | "paymentId"
        | "phoneNumber"
        | "shippingAddress"
        | "shippingCharges"
        | "tags"
    >
>;

export type CheckoutOrderItemRequiredFields = "variantId";

export type CheckoutOrderItemCommonCreateFields =
    | "quantity"
    | "unitPrice"
    | CheckoutOrderItemRequiredFields;

export type CheckoutOrderItemApiCreateFields =
    | CheckoutOrderItemCommonCreateFields
    | "discount";

export type CheckoutOrderItemModelCreateFields =
    | CheckoutOrderItemCommonCreateFields
    | "discounts";

export type CheckoutOrderItemCommonUpdateFields =
    | "customProperties"
    | "shippingCharges"
    | "shippingMethod"
    | "taxes";

export type CheckoutOrderItemApiUpdateFields =
    | CheckoutOrderItemCommonUpdateFields
    | "discount";

export type CheckoutOrderItemModelUpdateFields =
    | CheckoutOrderItemCommonUpdateFields
    | "discounts";

/** Required fields are still eventually required, but you can build in whatever order you like. */
export type CheckoutOrderItemModelOptionalFields = Pick<
    OrderItemModelCreateInput,
    Exclude<CheckoutOrderItemModelCreateFields, CheckoutOrderItemRequiredFields>
> &
    Pick<OrderItemModelUpdateInput, CheckoutOrderItemModelUpdateFields>;

export type CheckoutOrderItemModelRequiredFields = Pick<
    OrderItemModelCreateInput,
    CheckoutOrderItemRequiredFields
>;

/**
 * The minimum required fields to create an OrderItem.
 * The "Optional" fields are still eventually required, but you can build in whatever order you like, and they will be checked at order placement time.
 */
export type CheckoutOrderItem = Partial<CheckoutOrderItemModelOptionalFields> &
    CheckoutOrderItemModelRequiredFields;

export interface CheckoutDynamoData
    extends CheckoutIdFields,
        CheckoutRelationshipFields,
        ResonanceDates {
    checkoutType: CheckoutType;
    currency: ResonanceCurrency;
    /**
     * This gets set to expire checkouts so that we don't accrue a bunch of them, and so we can accurately characterize them as "abandoned"
     * by listening to the Delete event.
     */
    expiresAt?: number | null;
    order?: CheckoutOrder | null;
    orderItems?: Record<VariantId, CheckoutOrderItem> | null;
    discountResponses?: Record<string, DiscountResponse> | null;
    // orderTransactions do not need to be stored on this entity, as they are only used during order placement.
}

/**
 * Checkout is a temporary storage device to handle the process of creating an Order on a Storefront.
 *
 * Generally the steps to Checkout are as follows:
 * 1. Create a Checkout for a given customer or device. This may involve logging the customer in.
 * 2. Add cart items and/or discount codes to the checkout.
 * 3. Add a shipping address to the checkout. It is generally possible to add tax information at this time as well.
 * 4. Add shipping method and charges to the checkout.
 * 5. Validate the checkout. This ensures that everything order and orderItem related is in a valid state to place the order.
 * 6. Gather payment information and billing address and hand off to the payment gateway for Credit Card authorization.
 * 7. Send the checkout to the Storefront API "placeOrders" mutation with the information returned from the payment gateway.
 * 8. Resonance will use its OAuth integration with the payment gateway to confirm that the authorization is valid. This prevents man in the middle attacks, even with valid customer credentials.
 * 9. Given a valid CC authorization, Resonance will create the Order.
 * 10. The storefront can now clear the cart for the given device or customer and display a confirmation page.
 *
 * Flexibility on this entity is paramount so that the process can be handled in any variety of ways, and validation should only be imposed on command.
 * It, however, MUST be imposed before accepting a Checkout as a valid Order.
 *
 * "Abandoned" checkouts should be calculated whenever:
 * - The checkout is deleted automatically by DynamoDB TTL
 */
export interface Checkout
    extends Omit<CheckoutDynamoData, "orderItems" | "discountResponses"> {
    orderItems?: CheckoutOrderItem[] | null;
    discountResponses?: DiscountResponse[] | null;
}

export type CheckoutModelCreateInput = Omit<Checkout, keyof ResonanceDates>;

export type CheckoutModelUpdateInput = Omit<
    CheckoutModelCreateInput,
    "checkoutType" | "currency" | keyof CheckoutRelationshipFields
>;

/** Allows for create and/or update with partial updating */
export type CheckoutOrderApiUpdateInput = Omit<
    CheckoutOrder,
    "discountResponses"
>;

/** Allows for create and/or update, but update must be full, and contain all orderItems */
export type CheckoutOrderItemApiUpdateInput = Omit<
    CheckoutOrderItem,
    "discounts"
>;

/** Only allows for creating, as Transactions are immutable and all information should be available at once. */
export type CheckoutOrderTransactionApiCreateInput =
    OrderTransactionApiCreateInput;

/** Shape of a CompleteCheckout SQS message body */
export type CompleteCheckoutMessage = {
    type: "completeCheckout";
    demandHqId: DemandHqId;
    order: Omit<OrderModelCreateInput, "paymentId">;
    orderItems: Omit<OrderItemModelCreateInput, "orderId">[];
    orderTags: string[] | null | undefined;
    orderTransaction: OrderTransactionDataFields | null;
    errors: CompleteCheckoutError[];
};

export type ValidateCheckoutResponse = {
    checkout: Checkout;
    errors?: CompleteCheckoutError[];
};

export interface InsufficientQuantityCompleteCheckoutError
    extends CompleteCheckoutError {
    code: CompleteCheckoutErrorCode.InsufficientQuantity;
    details: {
        variantId: VariantId;
        requestedQuantity: number;
        availableQuantity: number;
    };
}

export const isInsufficientQuantityError = (
    error: CompleteCheckoutError | InsufficientQuantityCompleteCheckoutError,
): error is InsufficientQuantityCompleteCheckoutError => {
    return error.code === CompleteCheckoutErrorCode.InsufficientQuantity;
};

export interface CheckoutNotFoundError extends CompleteCheckoutError {
    code: CompleteCheckoutErrorCode.CheckoutNotFound;
    details: {
        externalCustomerOrDeviceId: CheckoutIdFields["externalCustomerOrDeviceId"];
        demandHqId: DemandHqId;
        storefrontId: StorefrontId;
        checkoutName: string | null | undefined;
        checkoutType: CheckoutType;
    };
}

export const isCheckoutNotFoundError = (
    error: CompleteCheckoutError | CheckoutNotFoundError,
): error is CompleteCheckoutError => {
    return error.code === CompleteCheckoutErrorCode.CheckoutNotFound;
};

export interface CheckoutOrderNotFoundError
    extends Omit<CheckoutNotFoundError, "code"> {
    code: CompleteCheckoutErrorCode.CheckoutOrderNotFound;
}

export const isCheckoutOrderNotFoundError = (
    error: CompleteCheckoutError | CheckoutOrderNotFoundError,
): error is CheckoutOrderNotFoundError => {
    return error.code === CompleteCheckoutErrorCode.CheckoutOrderNotFound;
};

export interface VariantCompleteCheckoutError extends CompleteCheckoutError {
    code:
        | CompleteCheckoutErrorCode.VariantNotFound
        | CompleteCheckoutErrorCode.VariantMetricsNotFound
        | CompleteCheckoutErrorCode.CheckoutOrderItemQuantityNotFound;
    details: {
        variantId: VariantId;
    };
}

export const isVariantCompleteCheckoutError = (
    error: CompleteCheckoutError | VariantCompleteCheckoutError,
): error is VariantCompleteCheckoutError => {
    return (
        error.code === CompleteCheckoutErrorCode.VariantNotFound ||
        error.code === CompleteCheckoutErrorCode.VariantMetricsNotFound ||
        error.code ===
            CompleteCheckoutErrorCode.CheckoutOrderItemQuantityNotFound
    );
};

export interface CheckoutOrderItemsUnprocessed extends CompleteCheckoutError {
    code: CompleteCheckoutErrorCode.UnprocessedOrderItems;
    details: {
        orderItems: OrderItemId[];
    };
}

export const isCheckoutOrderItemsUnprocessed = (
    error: CompleteCheckoutError | CheckoutOrderItemsUnprocessed,
): error is CheckoutOrderItemsUnprocessed => {
    return error.code === CompleteCheckoutErrorCode.UnprocessedOrderItems;
};
