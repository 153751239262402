import {
    NotificationCommonFields,
    NotificationIdentityFields,
    NotificationStatus,
    ResonanceNotification,
} from "../../common/Notification";
import { DemandHqId, DemandHqNotificationId } from "../DemandIds";

/**
 * This denotes what the notification pertains to.
 */
export enum DemandHqNotificationCategory {
    Additions = "ADDITIONS",
    Integrity = "INTEGRITY",
    Other = "OTHER",
    Pricing = "PRICING",
    Supply = "SUPPLY",
    Apps = "APPS",
}

export interface DemandHqNotificationIdFields {
    demandHqNotificationId: DemandHqNotificationId;
}

export interface DemandHqNotificationRelationshipFields {
    /** The DemandHq that this notification is targeted at. */
    demandHqId: DemandHqId;
}

/**
 * An actionable notification for a DemandHq.
 * A User can acknowledge, ignore, or resolve a notification.
 * In some cases, a suggested action can be taken by the User to resolve the notification.
 */
export interface DemandHqNotification
    extends DemandHqNotificationIdFields,
        DemandHqNotificationRelationshipFields,
        ResonanceNotification<DemandHqNotificationCategory> {}

export type DemandHqNotificationModelCreateInput = Pick<
    DemandHqNotification,
    | keyof DemandHqNotificationRelationshipFields
    | keyof NotificationIdentityFields
    | keyof NotificationCommonFields<DemandHqNotificationCategory>
>;

export type DemandHqNotificationModelUpdateInput =
    DemandHqNotificationIdFields &
        Partial<
            Pick<
                DemandHqNotification,
                | keyof NotificationIdentityFields
                | keyof NotificationCommonFields<DemandHqNotificationCategory>
            >
        >;

export type DemandHqNotificationApiCreateInput = Omit<
    DemandHqNotificationModelCreateInput,
    keyof NotificationIdentityFields | "expiresAt" | "status"
>;

export type DemandHqNotificationUpdateStatusInput =
    DemandHqNotificationIdFields & {
        status: NotificationStatus;
    };

// There is no generic API Update, only other mutations
