import { ResonanceCreatedBy, ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { NotificationSentiment } from "../common/Notification";

import { UserName, UserNotificationId } from "./AdminIdentityIds";
import { NotificationExternalDeliveryType } from "./UserNotificationExternalDelivery";

/**
 * Denotes urgency as well as user having archived it.
 * A "restore" will not restore the urgency, all go to standard.
 */
export enum UserNotificationStatus {
    Urgent = "URGENT",
    Standard = "STANDARD",
    Archived = "ARCHIVED",
}

/**
 * These denote where the message is from and what it pertains to.
 * Add these as needed, but we shouldn't have more than fit in a Select.
 */
export enum UserNotificationCategory {
    App = "APP",
    Partner = "PARTNER",
}

export interface UserNotificationIdFields {
    userNotificationId: UserNotificationId;
}

/**
 * This entity exists to provide capabilities to notify users of events that concern them.
 * User notifications are distinct enough from App notifications that a separate entity was deemed appropriate,
 * (for starters, userName and AppId are different levels of id) but the fields may overlap quite a bit.
 */
export interface UserNotification
    extends UserNotificationIdFields,
        ResonanceDates,
        ResonanceCreatedBy,
        ResonanceUpdatedBy {
    expiresAt?: number | null;
    category: UserNotificationCategory;
    /** The actual message. It's optional, and potentially only the subject is needed. Should be short. */
    content?: string | null;
    externalDeliveries?: NotificationExternalDeliveryType[] | null;
    /** URL to link to in the notification */
    link?: string | null;
    sentiment?: NotificationSentiment | null;
    /**
     * The sender of the notification can use this to reference it later, think of this more like a partition key than ID.
     * For instance, if you are sending a notification that a user has been added to a Partner, you may place "PARTNER#ADDUSER#${userName}"
     * With the above, you could find the message(s), even if the user has been removed and re-added a few times.
     */
    senderReferenceKey?: string | null;
    status: UserNotificationStatus;
    subject: string;
    userName: UserName;
}

export type UserNotificationModelCreateInput = Omit<
    UserNotification,
    | "userNotificationId"
    | keyof ResonanceDates
    | keyof ResonanceCreatedBy
    | keyof ResonanceUpdatedBy
>;

export type UserNotificationModelUpdateStatusInput =
    UserNotificationIdFields & { status: UserNotificationStatus };

export type UserNotificationApiUpdateStatusInput =
    UserNotificationModelUpdateStatusInput;
