import { array, string } from "yup";

import {
    EXCLUDES_PIPE_REGEX,
    EXCLUDES_PIPE_VALIDATION_MESSAGE,
} from "../stringHelpers";

export const TAGS_MAX_LENGTH = 128;
export const STANDARD_MAX_NUMBER_OF_TAGS = 1000;
export const SMALL_MAX_NUMBER_OF_TAGS = 100;

export const tagSchema = string()
    .max(TAGS_MAX_LENGTH)
    .matches(EXCLUDES_PIPE_REGEX, EXCLUDES_PIPE_VALIDATION_MESSAGE);

export const tagArraySchema = array()
    .of(tagSchema.required())
    .max(STANDARD_MAX_NUMBER_OF_TAGS);

export const tagSmallArraySchema = array()
    .of(tagSchema.required())
    .max(SMALL_MAX_NUMBER_OF_TAGS);
