import React, { ReactElement } from "react";

import {
    DemandHqId,
    StorefrontId,
} from "@buildresonance/resonance-lib-entities";
import { StorefrontIcon } from "@buildresonance/resonance-lib-icons";
import {
    ResonanceMainNavAccordion,
    ResonanceMainNavItem,
    ResonanceMainNavSecondaryAction,
} from "@buildresonance/resonance-lib-nav-components";
import { useStorefront } from "@hooks/graphql/queries/useStorefront";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import AddIcon from "@mui/icons-material/AddCircle";
import { List } from "@mui/material";
import { useRouter } from "next/router";

const StorefrontNav = ({
    handleDrawerClose,
}: {
    handleDrawerClose: () => void;
}): ReactElement | null => {
    const router = useRouter();
    const demandHqId = router?.query?.demandHqId as DemandHqId | undefined;
    const storefrontId = router?.query?.storefrontId as
        | StorefrontId
        | undefined;

    const { data } = useStorefront({
        storefrontId,
    });

    if (!demandHqId) {
        return null;
    }

    const renderEvergreenListItems = (): ReactElement => {
        return (
            <ResonanceMainNavItem
                variant="mainHeading"
                href={DemandAdminRoute.Storefronts.build(demandHqId)}
                isSelected={
                    router.pathname === DemandAdminRoute.Storefronts.pathname
                }
                primaryText="Storefront"
                secondaryText={data?.storefront?.name ?? "Select a Storefront"}
                onClick={() => handleDrawerClose()}
                secondaryAction={
                    storefrontId ? (
                        <ResonanceMainNavSecondaryAction
                            href={DemandAdminRoute.StorefrontEdit.build(
                                demandHqId,
                                storefrontId,
                            )}
                            isSelected={
                                router.pathname ===
                                DemandAdminRoute.StorefrontEdit.pathname
                            }
                            IconComponent={StorefrontIcon}
                            title="Edit Storefront"
                            onClick={() => handleDrawerClose()}
                        />
                    ) : undefined
                }
            />
        );
    };

    const renderStorefrontDependentListItems = (): ReactElement | null => {
        if (storefrontId) {
            return (
                <>
                    <ResonanceMainNavAccordion
                        title="Management"
                        defaultExpanded
                    >
                        <ResonanceMainNavItem
                            primaryText="Customers"
                            href={DemandAdminRoute.Customers.build(
                                demandHqId,
                                storefrontId,
                            )}
                            isSelected={[
                                DemandAdminRoute.Customers.pathname,
                                DemandAdminRoute.CustomerEdit.pathname,
                            ].includes(router.pathname)}
                            onClick={() => handleDrawerClose()}
                            secondaryAction={
                                <ResonanceMainNavSecondaryAction
                                    title="Create Customer"
                                    href={DemandAdminRoute.CustomerCreate.build(
                                        demandHqId,
                                        storefrontId,
                                    )}
                                    isSelected={
                                        router.pathname ===
                                        DemandAdminRoute.CustomerCreate.pathname
                                    }
                                    IconComponent={AddIcon}
                                    onClick={() => handleDrawerClose()}
                                />
                            }
                        />

                        <ResonanceMainNavItem
                            primaryText="Menus"
                            secondaryText="Navs"
                            href={DemandAdminRoute.Navs.build(
                                demandHqId,
                                storefrontId,
                            )}
                            isSelected={[
                                DemandAdminRoute.Navs.pathname,
                                DemandAdminRoute.NavCreate.pathname,
                            ].includes(router.pathname)}
                            onClick={() => handleDrawerClose()}
                        />

                        <ResonanceMainNavItem
                            primaryText="Settings"
                            secondaryText="Emails, etc."
                            href={DemandAdminRoute.StorefrontSettings.build(
                                demandHqId,
                                storefrontId,
                            )}
                            isSelected={[
                                DemandAdminRoute.StorefrontSettings.pathname,
                            ].includes(router.pathname)}
                            onClick={() => handleDrawerClose()}
                        />

                        <ResonanceMainNavItem
                            primaryText="Type Content"
                            secondaryText="High-Level Content"
                            href={DemandAdminRoute.TypeContents.build(
                                demandHqId,
                                storefrontId,
                            )}
                            isSelected={[
                                DemandAdminRoute.TypeContents.pathname,
                            ].includes(router.pathname)}
                            onClick={() => handleDrawerClose()}
                        />
                    </ResonanceMainNavAccordion>
                </>
            );
        }

        return null;
    };

    return (
        <div role="navigation">
            <List aria-label="Storefront Nav">
                {renderEvergreenListItems()}
                {renderStorefrontDependentListItems()}
            </List>
        </div>
    );
};

export { StorefrontNav };
