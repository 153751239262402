import { StorefrontId } from "@buildresonance/resonance-lib-entities";
import { useResonanceApi } from "@buildresonance/resonance-lib-frontend-admin-auth-passwordless";
import { buildGqlEndpoint, GqlApi } from "@lib/common/domainNames";
import { ClientError } from "graphql-request";
import { useQuery, UseQueryResult } from "react-query";

import {
    getStorefront,
    GetStorefrontResponse,
    GetStorefrontQueryKey,
    buildGetStorefrontQueryKey,
} from "./getStorefront";

const useStorefront = ({
    storefrontId,
}: {
    storefrontId?: StorefrontId;
}): UseQueryResult<GetStorefrontResponse, ClientError> => {
    const { gqlClient, withAuthQueryOptions } = useResonanceApi({
        endpoint: buildGqlEndpoint(GqlApi.Demand),
    });

    return useQuery<
        GetStorefrontResponse,
        ClientError,
        GetStorefrontResponse,
        GetStorefrontQueryKey
    >(
        buildGetStorefrontQueryKey(storefrontId as StorefrontId),
        getStorefront(gqlClient),
        withAuthQueryOptions({
            enabled: !!storefrontId,
        }),
    );
};

export { useStorefront };
