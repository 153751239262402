import { useEffect } from "react";

import type { ClientError } from "graphql-request";
import { useRouter } from "next/router";
import type {
    RefetchOptions,
    RefetchQueryFilters,
    QueryObserverResult,
    InfiniteData,
} from "react-query";

/**
 * This hook will listen for a "refresh" query param and refetch the query, using the provided refetch function, when set to "true" or "1".
 * If undefined is provided for the refetch function, the hook will do nothing, but still remove the "refresh" query param.
 *
 * The "refresh" query param will be removed after the refetch is complete.
 *
 * @param refetch Function to call for refetching the query.
 */
const useRefreshQueryParam = <TQueryResult>(
    refetch:
        | (<TPageData>(
              options?:
                  | (RefetchOptions & RefetchQueryFilters<TPageData>)
                  | undefined,
          ) => Promise<
              QueryObserverResult<InfiniteData<TQueryResult>, ClientError>
          >)
        | undefined,
): void => {
    const router = useRouter();

    const refresh = router.query.refresh;

    useEffect(() => {
        const asyncWrapper = async (): Promise<void> => {
            await refetch?.();
            await router.replace(
                {
                    query: {
                        ...router.query,
                        refresh: undefined,
                    },
                },
                undefined,
                { scroll: false, shallow: true },
            );
        };

        if (refresh === "true" || refresh === "1") {
            void asyncWrapper();
        }
    }, [refetch, refresh, router]);
};

export default useRefreshQueryParam;
