import {
    StorefrontEmailSettingsApiUpdateInput,
    StorefrontEmailSettingsSmtpCredentials,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, boolean, number, object, string } from "yup";

import { transformNaNToNull } from "../../numberHelpers";
import { requiredOnlyWhenExistsObjectTransformer } from "../../objectHelpers";
import { transformBlankStringToNull } from "../../stringHelpers";
import { storefrontIdSchema } from "../demandIds";

export const STOREFRONT_EMAIL_SETTINGS_SMTP_HOST_MAX_LENGTH = 2048;
export const STOREFRONT_EMAIL_SETTINGS_SMTP_VALID_PORTS = [25, 465, 587];
export const STOREFRONT_EMAIL_SETTINGS_FROM_NAME_MAX_LENGTH = 256;
export const STOREFRONT_EMAIL_SETTINGS_URL_MAX_LENGTH = 2048;

export const storefrontEmailSettingsSmtpCredentialsSchema: ObjectSchema<StorefrontEmailSettingsSmtpCredentials> =
    object({
        host: string()
            .required()
            .max(STOREFRONT_EMAIL_SETTINGS_SMTP_HOST_MAX_LENGTH),
        port: number()
            .oneOf(STOREFRONT_EMAIL_SETTINGS_SMTP_VALID_PORTS)
            .required()
            .transform(transformNaNToNull)
            .transform(transformBlankStringToNull),
        username: string().required(),
        password: string().required(),
    }).noUnknown();

export const storefrontEmailSettingsApiUpdateInputSchema: ObjectSchema<StorefrontEmailSettingsApiUpdateInput> =
    object({
        fromEmailAddress: string().nullable().email(),
        fromName: string()
            .nullable()
            .max(STOREFRONT_EMAIL_SETTINGS_FROM_NAME_MAX_LENGTH),
        logoUrl: string()
            .nullable()
            .url()
            .max(STOREFRONT_EMAIL_SETTINGS_URL_MAX_LENGTH),
        orderUrl: string()
            .nullable()
            .max(STOREFRONT_EMAIL_SETTINGS_URL_MAX_LENGTH),
        sendDeliveryConfirmations: boolean().nullable(),
        sendOrderConfirmations: boolean().nullable(),
        sendShipConfirmations: boolean().nullable(),
        smtpCredentials: storefrontEmailSettingsSmtpCredentialsSchema
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        storefrontId: storefrontIdSchema.required(),
    }).noUnknown();
