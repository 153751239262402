import {
    PurchaseOrderItemApiCreateInput,
    PurchaseOrderItemApiUpdateInput,
    PurchaseOrderItemError,
    PurchaseOrderItemFulfillment,
    ResonanceShippingMethod,
} from "@buildresonance/resonance-lib-entities";
import { array, boolean, number, object, ObjectSchema, string } from "yup";

import { tagArraySchema } from "../common/tags";
import {
    VARIANT_BARCODE_MAX_LENGTH,
    VARIANT_SKU_MAX_LENGTH,
} from "../common/variantCommon";
import { dateTimeStringSchema } from "../dateHelpers";
import {
    demandHqIdSchema,
    orderIdSchema,
    orderItemIdSchema,
    storefrontIdSchema,
} from "../demand/demandIds";
import { ORDER_REFERENCE_NUMBER_MAX_LENGTH } from "../demand/order";
import { ORDER_ITEM_VARIANT_TITLE_MAX_LENGTH } from "../demand/orderItem";
import {
    PRODUCT_TITLE_MAX_LENGTH,
    PRODUCT_VENDOR_MAX_LENGTH,
} from "../demand/product";
import { customPropertiesSchema, resonanceAddressSchema } from "../global";
import { transformNaNToNull } from "../numberHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import { SUPPLIER_PRODUCT_EXTERNAL_PRODUCT_ID_MAX_LENGTH } from "./supplierProduct";
import {
    purchaseOrderItemIdSchema,
    supplierIdSchema,
    supplierProductIdSchema,
    supplierVariantIdSchema,
} from "./supplyIds";

export const PURCHASE_ORDER_ITEM_FULFILLMENT_SHIPPING_CARRIER_MAX_LENGTH = 64;
export const PURCHASE_ORDER_ITEM_FULFILLMENT_TRACKING_NUMBER_MAX_LENGTH = 256;

export const purchaseOrderItemErrorSchema: ObjectSchema<PurchaseOrderItemError> =
    object({
        code: string().required(),
        message: string().required(),
        acknowledged: boolean().nullable(),
        cause: string().nullable(),
        details: object(),
    }).noUnknown();

export const purchaseOrderItemFulfillmentSchema: ObjectSchema<PurchaseOrderItemFulfillment> =
    object({
        fulfilledAt: dateTimeStringSchema.required(),
        fulfilledQuantity: number()
            .integer()
            .positive()
            .required()
            .transform(transformNaNToNull),
        shippingCarrier: string().required(),
        trackingNumber: string().required(),
    }).noUnknown();

export const purchaseOrderItemApiCreateInputSchema: ObjectSchema<PurchaseOrderItemApiCreateInput> =
    object({
        barcode: string().max(VARIANT_BARCODE_MAX_LENGTH).nullable(),
        customProperties: customPropertiesSchema.nullable(),
        demandHqId: demandHqIdSchema.required(),
        errors: array().of(purchaseOrderItemErrorSchema.required()).nullable(),
        externalPurchaseOrderId: string().nullable(),
        externalPurchaseOrderItemId: string().nullable(),
        externalProductId: string()
            .required()
            .max(SUPPLIER_PRODUCT_EXTERNAL_PRODUCT_ID_MAX_LENGTH),
        externalVariantId: string()
            .required()
            .max(SUPPLIER_PRODUCT_EXTERNAL_PRODUCT_ID_MAX_LENGTH),
        fulfillments: array().of(purchaseOrderItemFulfillmentSchema).nullable(),
        isCancelled: boolean().nullable(),
        isDraft: boolean().nullable(),
        isProcessing: boolean().nullable(),
        orderId: orderIdSchema.required(),
        referenceNumber: string()
            .max(ORDER_REFERENCE_NUMBER_MAX_LENGTH)
            .required(),
        orderItemId: orderItemIdSchema.required(),
        orderTags: tagArraySchema.nullable(),
        productTitle: string().required().max(PRODUCT_TITLE_MAX_LENGTH),
        quantity: number()
            .integer()
            .positive()
            .required()
            .transform(transformNaNToNull),
        shippingAddress: resonanceAddressSchema.required(),
        shippingMethod: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(ResonanceShippingMethod))
            .required(),
        sku: string().nullable().max(VARIANT_SKU_MAX_LENGTH),
        storefrontId: storefrontIdSchema.nullable(),
        supplierId: supplierIdSchema.required(),
        supplierProductId: supplierProductIdSchema.required(),
        supplierVariantId: supplierVariantIdSchema.required(),
        unitCost: number().min(0).required().transform(transformNaNToNull),
        variantTitle: string()
            .required()
            .max(ORDER_ITEM_VARIANT_TITLE_MAX_LENGTH),
        vendor: string().required().max(PRODUCT_VENDOR_MAX_LENGTH),
    }).noUnknown();

export const purchaseOrderItemApiUpdateInputSchema: ObjectSchema<PurchaseOrderItemApiUpdateInput> =
    object({
        customProperties: customPropertiesSchema.nullable(),
        errors: array().of(purchaseOrderItemErrorSchema.required()).nullable(),
        externalPurchaseOrderId: string().nullable(),
        externalPurchaseOrderItemId: string().nullable(),
        fulfillments: array().of(purchaseOrderItemFulfillmentSchema).nullable(),
        isCancelled: boolean().nullable(),
        isDraft: boolean().nullable(),
        isProcessing: boolean().nullable(),
        purchaseOrderItemId: purchaseOrderItemIdSchema.required(),
        quantity: number().integer().positive().transform(transformNaNToNull),
        unitCost: number().min(0).transform(transformNaNToNull),
    }).noUnknown();
