import { BulkActionExportRequestParameters } from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, number, object, string } from "yup";

import {
    LETTERS_AND_NUMBERS_ONLY_REGEX,
    LETTERS_AND_NUMBERS_ONLY_VALIDATION_MESSAGE,
} from "../stringHelpers";

export const BULK_ACTION_NAME_MAX_LENGTH = 36;
export const BULK_ACTION_MAX_MAXIMUM_NUMBER_OF_ITEMS = 1000000;

export const bulkActionNameSchema = string()
    .matches(
        LETTERS_AND_NUMBERS_ONLY_REGEX,
        LETTERS_AND_NUMBERS_ONLY_VALIDATION_MESSAGE,
    )
    .max(BULK_ACTION_NAME_MAX_LENGTH);

export const bulkActionExportRequestParametersSchema: ObjectSchema<BulkActionExportRequestParameters> =
    object({
        maximumNumberOfItems: number()
            .integer()
            .nullable()
            .min(1)
            .max(BULK_ACTION_MAX_MAXIMUM_NUMBER_OF_ITEMS),
    });
