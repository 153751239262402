import {
    AppId,
    AdminIdentityIdPrefix,
    AppClientId,
    ResonanceIdentityId,
    RESONANCE_SERVICE_ID_PREFIX,
    UserName,
    AppIdOrUserName,
    UserNotificationId,
    PartnerId,
    IdentityParentEntityId,
} from "@buildresonance/resonance-lib-entities";
import { string } from "yup";

export const appIdSchema = string<AppId>().matches(
    new RegExp(`^${AdminIdentityIdPrefix.App}.*`),
);

export const appClientIdSchema = string<AppClientId>().matches(
    new RegExp(`^${AdminIdentityIdPrefix.AppClient}.*`),
);

export const partnerIdSchema = string<PartnerId>().matches(
    new RegExp(`^${AdminIdentityIdPrefix.Partner}.*`),
);

export const resonanceIdentityIdSchema = string<ResonanceIdentityId>().matches(
    new RegExp(
        `^${AdminIdentityIdPrefix.AppClient}|${AdminIdentityIdPrefix.User}|${RESONANCE_SERVICE_ID_PREFIX}.*`,
    ),
);

export const identityParentEntityIdSchema =
    string<IdentityParentEntityId>().matches(
        new RegExp(`^${AdminIdentityIdPrefix.App}.*`),
    );

export const userNameSchema = string<UserName>().matches(
    new RegExp(`^${AdminIdentityIdPrefix.User}.*`),
);

export const appIdOrUserNameSchema = string<AppIdOrUserName>().matches(
    new RegExp(`^${AdminIdentityIdPrefix.App}|${AdminIdentityIdPrefix.User}.*`),
);

export const userNotificationIdSchema = string<UserNotificationId>().matches(
    new RegExp(`^${AdminIdentityIdPrefix.UserNotification}.*`),
);
