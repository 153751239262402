import {
    Storefront,
    StorefrontId,
} from "@buildresonance/resonance-lib-entities";
import { gql, GraphQLClient } from "graphql-request";
import { QueryFunction } from "react-query";

const GET_STOREFRONT_QUERY = gql`
    query getStorefront($storefrontId: ID!) {
        storefront(storefrontId: $storefrontId) {
            apiKeys {
                key
                slug
            }
            authenticationSettings {
                externalCustomerIdFieldName
                issuer
                jwksUrl
            }
            authenticationType
            demandHqId
            description
            name
            storefrontId
            storefrontIdentities {
                slug
                permissions {
                    roles
                    scopes
                }
            }
            urlDomain
        }
    }
`;

export type GetStorefrontQueryKey = [`getStorefront`, StorefrontId];

export const buildGetStorefrontQueryKey = (
    storefrontId: StorefrontId,
): GetStorefrontQueryKey => [`getStorefront`, storefrontId];

export interface GetStorefrontResponse {
    storefront: Pick<
        Storefront,
        | "authenticationSettings"
        | "authenticationType"
        | "demandHqId"
        | "description"
        | "name"
        | "storefrontId"
        | "storefrontIdentities"
        | "urlDomain"
    > & {
        apiKeys?:
            | {
                  key: string;
                  slug: string;
              }[]
            | null;
    };
}

const getStorefront =
    (
        client: GraphQLClient,
    ): QueryFunction<GetStorefrontResponse, GetStorefrontQueryKey> =>
    async ({ queryKey }) => {
        return await client.request<
            GetStorefrontResponse,
            {
                storefrontId: StorefrontId;
            }
        >(GET_STOREFRONT_QUERY, { storefrontId: queryKey?.[1] });
    };

export { getStorefront };
