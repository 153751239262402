import React, { ReactElement, useState } from "react";

import { RESONANCE_INTERNAL_USERS_GROUP } from "@buildresonance/resonance-lib-entities";
import { useAuthUser } from "@buildresonance/resonance-lib-frontend-admin-auth-passwordless";
import {
    UserButton,
    DrawerHeader,
    UserActions,
    UISettings,
} from "@buildresonance/resonance-lib-layout-components";
import {
    IDENTITY_ADMIN_DOMAIN_NAME,
    DEMAND_ADMIN_DOMAIN_NAME,
    SUPPLY_ADMIN_DOMAIN_NAME,
    INTERNAL_DOMAIN_NAME,
} from "@lib/common/domainNames";
import { Drawer, Divider, useTheme, Toolbar, Box } from "@mui/material";
import { extractInitialsFromWords } from "@util/extractInitialsFromWords";
import { useSignOut } from "amplify-authenticator-react-custom";

const UserButtonParent = (): ReactElement => {
    const theme = useTheme();

    const { attributes, userGroups } = useAuthUser();
    const signOut = useSignOut();

    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const container =
        typeof window !== "undefined" ? () => window.document.body : undefined;

    const handleDrawerClose = (): void => {
        setDrawerIsOpen(false);
    };

    const determineLettersToDisplay = (): string => {
        if (attributes?.nickname && attributes?.nickname.length > 0) {
            return extractInitialsFromWords(attributes?.nickname);
        }

        return attributes?.email?.toUpperCase()?.charAt(0);
    };

    return (
        <>
            <UserButton
                lettersToDisplay={determineLettersToDisplay()}
                onClick={() => setDrawerIsOpen((prev) => !prev)}
                aria-controls="user-drawer"
            />
            <Drawer
                id="user-drawer"
                container={container}
                open={drawerIsOpen}
                onClose={handleDrawerClose}
                anchor={theme.direction === "rtl" ? "left" : "right"}
                variant="temporary"
            >
                <Toolbar />
                <Box flex="1">
                    <DrawerHeader
                        closeLabel="Close User Settings Drawer"
                        title={`Demand Portal`}
                        onClose={handleDrawerClose}
                    />
                    <Divider />
                    <UserActions
                        currentPortal="demand"
                        adminIdentityAdminDomainName={
                            IDENTITY_ADMIN_DOMAIN_NAME
                        }
                        demandAdminDomainName={DEMAND_ADMIN_DOMAIN_NAME}
                        internalDomainName={INTERNAL_DOMAIN_NAME}
                        isInternalUser={userGroups?.includes(
                            RESONANCE_INTERNAL_USERS_GROUP,
                        )}
                        supplyAdminDomainName={SUPPLY_ADMIN_DOMAIN_NAME}
                        onSignOut={() => signOut()}
                    />
                    <Divider />
                </Box>
                <Box>
                    <UISettings />
                </Box>
            </Drawer>
        </>
    );
};

export { UserButtonParent };
