import { DemandHqComment } from "@buildresonance/resonance-lib-entities";
import { gql } from "graphql-request";

export const DEMAND_HQ_COMMENT_FRAGMENT = gql`
    fragment demandHqCommentFragment on DemandHqComment {
        createdAt
        createdBy
        contentAsString
        demandHqId
        demandHqCommentId
        entityId
        updatedAt
    }
`;

export type DemandHqCommentGqlFragment = DemandHqComment;
