import React, { ReactElement } from "react";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
    Box,
    Divider,
    Toolbar,
    IconButton,
    useTheme,
    useMediaQuery,
} from "@mui/material";

import { DemandHqNav } from "./DemandHqNav";
import { ExternalNav } from "./ExternalNav";
import { StorefrontNav } from "./StorefrontNav";

const NavDrawer = ({
    handleDrawerClose,
}: {
    handleDrawerClose: () => void;
}): ReactElement => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const renderCloseIcon = (): ReactElement | null => {
        if (isDesktop) {
            return null;
        }
        return (
            <IconButton
                onClick={handleDrawerClose}
                aria-label="Close Nav Drawer"
            >
                {theme.direction === "ltr" ? (
                    <ChevronLeftIcon />
                ) : (
                    <ChevronRightIcon />
                )}
            </IconButton>
        );
    };

    return (
        <Box sx={{ overflow: "auto" }}>
            <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
                {renderCloseIcon()}
            </Toolbar>
            <Divider />
            <DemandHqNav handleDrawerClose={handleDrawerClose} />
            <Divider />
            <StorefrontNav handleDrawerClose={handleDrawerClose} />
            <Divider />
            <ExternalNav />
        </Box>
    );
};

export { NavDrawer };
