import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";

import { CommonTypeContent } from "./common/CommonTypeContent";
import { CollectionTypeContentId, DemandHqId, StorefrontId } from "./DemandIds";

export interface CollectionTypeContentIdFields {
    collectionTypeContentId: CollectionTypeContentId;
}

export interface CollectionTypeContentRelationshipFields {
    /**
     * Any string to declare a category of this collection, can be used to filter collections, limit 128 characters.
     * This string must be in slug format (lowercase and dashes).
     */
    collectionType: string;
    demandHqId: DemandHqId;
    storefrontId: StorefrontId;
}

/** This entity holds content specific to a collectionType. This is generally used on a page that lists all collections for a collectionType. */
export interface CollectionTypeContent
    extends CollectionTypeContentIdFields,
        CollectionTypeContentRelationshipFields,
        CommonTypeContent,
        ResonanceDates,
        ResonanceUpdatedBy {}

export type CollectionTypeContentModelCreateInput = Omit<
    CollectionTypeContent,
    | keyof CollectionTypeContentIdFields
    | keyof ResonanceDates
    | keyof ResonanceUpdatedBy
>;

export type CollectionTypeContentApiCreateInput =
    CollectionTypeContentModelCreateInput;

export type CollectionTypeContentModelUpdateInput =
    CollectionTypeContentIdFields &
        Omit<
            CollectionTypeContent,
            | keyof CollectionTypeContentIdFields
            | keyof CollectionTypeContentRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >;

export type CollectionTypeContentApiUpdateInput =
    CollectionTypeContentModelUpdateInput;
