import { UserApiUpdateInput } from "@buildresonance/resonance-lib-entities";
import { object, ObjectSchema, string } from "yup";

import { userNameSchema } from "..";

export const COGNITO_USER_ATTRIBUTE_MAX_LENGTH = 2048;

export const userApiUpdateInputSchema: ObjectSchema<UserApiUpdateInput> =
    object({
        address: string().nullable().max(COGNITO_USER_ATTRIBUTE_MAX_LENGTH),
        birthdate: string()
            .nullable()
            .matches(
                /^[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/,
                "birthdate must be in format YYYY-MM-DD",
            ),
        email: string().email().max(COGNITO_USER_ATTRIBUTE_MAX_LENGTH),
        familyName: string().nullable().max(COGNITO_USER_ATTRIBUTE_MAX_LENGTH),
        gender: string().nullable().max(COGNITO_USER_ATTRIBUTE_MAX_LENGTH),
        givenName: string().nullable().max(COGNITO_USER_ATTRIBUTE_MAX_LENGTH),
        locale: string().nullable().max(COGNITO_USER_ATTRIBUTE_MAX_LENGTH),
        nickname: string().nullable().max(COGNITO_USER_ATTRIBUTE_MAX_LENGTH),
        phoneNumber: string()
            .nullable()
            .matches(/^\+[0-9]+/, "phoneNumber must be in format +14325551212"),
        picture: string().nullable().max(COGNITO_USER_ATTRIBUTE_MAX_LENGTH),
        profile: string().nullable().max(COGNITO_USER_ATTRIBUTE_MAX_LENGTH),
        userName: userNameSchema.required(),
        website: string().nullable().max(COGNITO_USER_ATTRIBUTE_MAX_LENGTH),
        zoneinfo: string().nullable().max(COGNITO_USER_ATTRIBUTE_MAX_LENGTH),
    }).noUnknown();
