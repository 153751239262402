import { DiscountId } from "../DemandIds";

export enum DiscountResponseCode {
    EXPIRED = "EXPIRED",
    INVALID = "INVALID",
    REMOVED = "REMOVED",
    VALID = "VALID",
}

export enum DiscountResponseMessage {
    EXPIRED = "Code has expired.",
    INVALID = "Code is invalid.",
    REMOVED = "Code has been removed.",
    VALID = "Code is valid.",
}

export interface DiscountResponse {
    code: string;
    /** This field is nullable because a discountResponse can be for both valid and non-valid (discount not found) codes. */
    discountId?: DiscountId | null;
    discountResponseMessage: DiscountResponseMessage;
    discountResponseCode: DiscountResponseCode;
    updatedAt: string;
}
