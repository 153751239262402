import {
    ProductDataRuleActionType,
    ProductDataRuleApiCreateInput,
    ProductDataRuleApiUpdateInput,
    ProductDataRuleConditionType,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, array, object, string } from "yup";

import { demandHqIdSchema } from "../demandIds";

export const PRODUCT_DATA_RULE_MAX_ACTIONS = 25;
export const PRODUCT_DATA_RULE_VALUE_MAX_LENGTH = 256;

export const productDataRuleActionSchema = object({
    actionType: string()
        .oneOf(Object.values(ProductDataRuleActionType))
        .required(),
    actionValue: string().required().max(PRODUCT_DATA_RULE_VALUE_MAX_LENGTH),
}).noUnknown();

export const productDataRuleApiCreateInputSchema: ObjectSchema<ProductDataRuleApiCreateInput> =
    object({
        actions: array()
            .of(productDataRuleActionSchema.required())
            .required()
            .max(PRODUCT_DATA_RULE_MAX_ACTIONS),
        conditionType: string()
            .oneOf(Object.values(ProductDataRuleConditionType))
            .required(),
        conditionValue: string()
            .required()
            .max(PRODUCT_DATA_RULE_VALUE_MAX_LENGTH),
        demandHqId: demandHqIdSchema.required(),
    }).noUnknown();

export const productDataRuleApiUpdateInputSchema: ObjectSchema<ProductDataRuleApiUpdateInput> =
    productDataRuleApiCreateInputSchema;
