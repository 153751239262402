import {
    DemandHqCommentEntityId,
    DemandHqId,
    RelayPageInfo,
} from "@buildresonance/resonance-lib-entities";
import {
    DEMAND_HQ_COMMENT_FRAGMENT,
    DemandHqCommentGqlFragment,
} from "@hooks/graphql/fragments/demandHqComment";
import { GraphQLClient, gql } from "graphql-request";
import { QueryFunction } from "react-query";

const DEMAND_HQ_COMMENTS_QUERY = gql`
    ${DEMAND_HQ_COMMENT_FRAGMENT}
    query listDemandHqComments(
        $demandHqId: ID!
        $entityId: ID!
        $before: String
    ) {
        demandHq(demandHqId: $demandHqId) {
            comments(entityId: $entityId, last: 10, before: $before) {
                nodes {
                    ...demandHqCommentFragment
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                }
            }
        }
    }
`;

export type ListDemandHqCommentsResponse = {
    demandHq: {
        comments: {
            nodes: DemandHqCommentGqlFragment[];
            pageInfo: Pick<RelayPageInfo, "startCursor" | "hasPreviousPage">;
        };
    };
};

export type ListDemandHqCommentsVariables = {
    demandHqId: DemandHqId;
    entityId: DemandHqCommentEntityId;
    before?: string | null;
};

export type ListDemandHqCommentsInfiniteQueryKey = [
    "listDemandHqCommentsInfinite",
    Omit<ListDemandHqCommentsVariables, "before">,
];

export const buildListDemandHqCommentsInfiniteQueryKey = (
    variables: Omit<ListDemandHqCommentsVariables, "before">,
): ListDemandHqCommentsInfiniteQueryKey => [
    "listDemandHqCommentsInfinite",
    variables,
];

export const listDemandHqComments =
    (
        client: GraphQLClient,
    ): QueryFunction<
        ListDemandHqCommentsResponse,
        ListDemandHqCommentsInfiniteQueryKey
    > =>
    async ({ queryKey, pageParam }) => {
        const variables = queryKey[1];

        return await client.request<
            ListDemandHqCommentsResponse,
            ListDemandHqCommentsVariables
        >(DEMAND_HQ_COMMENTS_QUERY, {
            ...variables,
            before: pageParam,
        });
    };
