import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";

import { AppClientId, AppId, PartnerId } from "./AdminIdentityIds";

export const APP_COGNITO_GROUP_NAME = "Apps";

/**
 * This is the primary determination of which permission scopes can be requested.
 * An app cannot be more than one of these.
 * If something cross domain needs to exist, it should be two apps and they can communicate.
 */
export enum AppType {
    /** An app dealing primarily with the Demand Admin API */
    DemandHQ = "DEMANDHQAPP",
    /** An app dealing primarily with the Supply Admin API */
    Supplier = "SUPPLIERAPP",
}

/** What stage and availability the app is in */
export enum AppStatus {
    /** The app is in the process of being created */
    Draft = "DRAFT",
    /** The app is available to install, but only through shared links */
    Private = "PRIVATE",
    /** The app is available to install, and is searchable/discoverable */
    Public = "PUBLIC",
}

/** Id Fields are the ones required to identify this entity, cannot be updated */
export interface AppIdFields {
    appId: AppId;
}

export interface AppRelationshipFields {
    /**
     * Used to reference the Cognito Clients
     * There are always exactly two, so that one can be used if the other needs resetting.
     */
    appClientIds: [AppClientId, AppClientId];
    partnerId: PartnerId;
}

/**
 * An app is the primary entity that is built in the AdminIdentity domain, and is the primary purpose of a Partner (to create them).
 * Apps are somewhat like a user, in that they ask for certain permissions and do certain tasks with those permissions.
 * However, Apps contain two app clients, which are the entities that are very, very similar to a User. Two are provided for possible credential rotation.
 */
export interface App
    extends AppIdFields,
        AppRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /**
     * This is the primary determination of which permission scopes can be requested.
     * An app cannot be more than one of these.
     * If something cross domain needs to exist, it should be two apps and they can communicate.
     */
    appType: AppType;
    /** Short name for the app, limit 128 characters */
    name: string;
    /** Short description for the app, limit 8000 characters */
    description?: string | null;
    /** What stage and availability the app is in */
    status: AppStatus;
    /**
     * These are either SupplyScope[] or DemandScope[] depending on the type of the app.
     * Apps cannot have roles.
     * Required to Publish.
     */
    requestedScopes?: string[] | null;
}

export type AppGql = App;

/**
 * AppId is generated outside the model using a static method, in order to create appClients using it first,
 * then handed into the model with the input.
 */
export type AppModelCreateInput = Omit<
    App,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type AppApiCreateInput = Omit<
    AppModelCreateInput,
    "appId" | "appClientIds"
>;

export type AppModelUpdateInput = AppIdFields &
    Partial<
        Omit<
            App,
            | keyof AppIdFields
            | keyof AppRelationshipFields
            | "appType"
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type AppApiUpdateInput = AppModelUpdateInput;

/** These fields can be retrieved by anyone, so long as the AppStatus is one of the Published statuses */
export type PublicAppGql = Pick<
    AppGql,
    | "appClientIds"
    | "appId"
    | "appType"
    | "description"
    | "name"
    | "partnerId"
    | "requestedScopes"
    | "status"
>;

export const PUBLIC_APP_FIELDS: (keyof PublicAppGql)[] = [
    "appClientIds",
    "appId",
    "appType",
    "description",
    "name",
    "partnerId",
    "requestedScopes",
    "status",
];
