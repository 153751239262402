import { ResonanceUpdatedBy } from "../../../entityComposites";
import { ResonanceDates } from "../../../global";
import { DemandHqId, NavId, StorefrontId } from "../DemandIds";

export const NAV_POSITION_MAX_VALUE = 9999999999;

export enum NavLevel {
    Root = 0,
    One = 1,
    Two = 2,
    Three = 3,
    Four = 4,
    Five = 5,
    Six = 6,
}

export type ChildNavLevel =
    | NavLevel.One
    | NavLevel.Two
    | NavLevel.Three
    | NavLevel.Four
    | NavLevel.Five
    | NavLevel.Six;

export interface NavIdFields {
    navId: NavId;
}

export interface NavRelationshipFields {
    demandHqId: DemandHqId;
    storefrontId: StorefrontId;
}

/**
 * Nav is an item within a navigational hierarchy of some kind, most often viewed as a link inside a menu.
 * This gives possible structure and parent/child relationships to things like collections, or just random links.
 */
export interface Nav
    extends NavIdFields,
        NavRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /** Where this Nav link should lead to. Can be an absolute or relative url. 768 character limit. */
    href: string;
    /** The label for this Nav, limit 64 characters. */
    label: string;
    /** A number, representing where this Nav resides in the hierarchy. Immutable, and limited to 0 through 6. 0 is the top level, 1 is the second level, etc. */
    navLevel: NavLevel;
    /** A string indicating the purpose of this Nav hierarchy, must match parentNav if applicable.  Limit 128 characters and must be in slug format (lowercase and dashes). */
    navType: string;
    /** All Navs besides the root (Level 0) have a parentNavId. Immutable. */
    parentNavId?: NavId | null;
    /** Where this Nav should be located with respect to other Navs. Need not be sequential or unique. */
    position?: number | null;
}

export type NavModelCreateInput = Omit<
    Nav,
    keyof NavIdFields | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type NavModelUpdateInput = NavIdFields &
    Partial<
        Omit<
            Nav,
            | "navLevel"
            | "navType"
            | "parentNavId"
            | keyof NavIdFields
            | keyof NavRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type NavApiCreateInput = NavModelCreateInput;

export type NavApiUpdateInput = NavModelUpdateInput;
