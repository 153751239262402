import {
    SupplierDemandHq,
    SupplierDemandHqIdFields,
    SupplierDemandHqModelCreateInput,
    SupplierDemandHqModelUpdateInput,
} from "../supply/SupplierDemandHq";

/** Same shape, just renaming for consistency */
export type DemandHqSupplierIdFields = SupplierDemandHqIdFields;

/** Same shape, just renaming for consistency */
export type DemandHqSupplier = SupplierDemandHq;

/** Same shape, just renaming for consistency */
export type DemandHqSupplierModelCreateInput = SupplierDemandHqModelCreateInput;

/** Same shape, just renaming for consistency */
export type DemandHqSupplierModelUpdateInput = SupplierDemandHqModelUpdateInput;
