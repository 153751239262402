import {
    ResonanceCurrency,
    ResonanceLocaleType,
    SupplierApiCreateInput,
    SupplierApiUpdateInput,
    SupplierCategory,
    SupplierStatus,
} from "@buildresonance/resonance-lib-entities";
import { array, number, object, ObjectSchema, string } from "yup";

import { userNameSchema } from "../adminIdentity/adminIdentityIds";
import { resonanceAddressSchema } from "../global";
import { transformNaNToNull } from "../numberHelpers";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import { SUPPLIER_PRODUCT_VENDOR_MAX_LENGTH } from "./supplierProduct"; // Careful for circular dependency
import { supplierIdSchema } from "./supplyIds";

export const SUPPLIER_DESCRIPTION_MAX_LENGTH = 8000;
export const SUPPLIER_NAME_MAX_LENGTH = 128;
export const SUPPLIER_MAX_NUMBER_OF_TOP_VENDORS = 5;

export const supplierApiCreateInputSchema: ObjectSchema<SupplierApiCreateInput> =
    object({
        category: string()
            .transform(transformBlankStringToNull)
            .nullable()
            .oneOf(Object.values(SupplierCategory))
            .transform(transformBlankStringToNull),
        defaultCurrency: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(ResonanceCurrency)),
        defaultLocaleType: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(ResonanceLocaleType)),
        description: string().nullable().max(SUPPLIER_DESCRIPTION_MAX_LENGTH),
        inStockProducts: number()
            .nullable()
            .integer()
            .min(0)
            .transform(transformNaNToNull),
        name: string().required().max(SUPPLIER_NAME_MAX_LENGTH),
        notificationUserNames: object({
            management: array().of(userNameSchema.required()),
            distribution: array().of(userNameSchema.required()),
            purchaseOrders: array().of(userNameSchema.required()),
        })
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        shipFromAddress: resonanceAddressSchema
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        topVendors: array()
            .of(string().required().max(SUPPLIER_PRODUCT_VENDOR_MAX_LENGTH))
            .max(SUPPLIER_MAX_NUMBER_OF_TOP_VENDORS),
    }).noUnknown();

export const supplierApiUpdateInputSchema: ObjectSchema<SupplierApiUpdateInput> =
    object({
        category: string()
            .transform(transformBlankStringToNull)
            .nullable()
            .oneOf([...Object.values(SupplierCategory), null, undefined]),
        defaultCurrency: string().oneOf(Object.values(ResonanceCurrency)),
        defaultLocaleType: string().oneOf(Object.values(ResonanceLocaleType)),
        description: string().max(SUPPLIER_DESCRIPTION_MAX_LENGTH),
        inStockProducts: number()
            .nullable()
            .integer()
            .min(0)
            .transform(transformNaNToNull),
        name: string().max(SUPPLIER_NAME_MAX_LENGTH),
        notificationUserNames: object({
            management: array().of(userNameSchema.required()).nullable(),
            distribution: array().of(userNameSchema.required()).nullable(),
            purchaseOrders: array().of(userNameSchema.required()),
        }).nullable(),
        ownerUserName: userNameSchema,
        shipFromAddress: resonanceAddressSchema
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        status: string()
            .transform(transformBlankStringToNull)
            .oneOf([...Object.values(SupplierStatus), undefined]),
        supplierId: supplierIdSchema.required(),
        topVendors: array()
            .of(string().required().max(SUPPLIER_PRODUCT_VENDOR_MAX_LENGTH))
            .max(SUPPLIER_MAX_NUMBER_OF_TOP_VENDORS),
    }).noUnknown();
