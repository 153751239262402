import React, { ReactElement } from "react";

import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import {
    ResonanceMainNavAccordion,
    ResonanceMainNavItem,
    ResonanceMainNavSecondaryAction,
} from "@buildresonance/resonance-lib-nav-components";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import AddIcon from "@mui/icons-material/AddCircle";
import { useTheme, Box } from "@mui/material";
import { useRouter } from "next/router";

const DemandHqNavAdminMenu = ({
    handleDrawerClose,
}: {
    handleDrawerClose: () => void;
}): ReactElement => {
    const theme = useTheme();
    const router = useRouter();
    const demandHqId = router?.query?.demandHqId as DemandHqId;

    return (
        <Box marginTop={theme.spacing(1)}>
            <ResonanceMainNavAccordion title="Admin">
                <ResonanceMainNavItem
                    primaryText="Apps"
                    secondaryText="Installed"
                    href={DemandAdminRoute.DemandHqApps.build(demandHqId)}
                    isSelected={
                        router.pathname ===
                            DemandAdminRoute.DemandHqApps.pathname &&
                        !(router.query.installAppDialog === "true")
                    }
                    onClick={() => handleDrawerClose()}
                    secondaryAction={
                        <ResonanceMainNavSecondaryAction
                            title="Add App"
                            href={{
                                pathname:
                                    DemandAdminRoute.DemandHqApps.build(
                                        demandHqId,
                                    ),
                                query: { installAppDialog: true },
                            }}
                            isSelected={!!router.query.installAppDialog}
                            IconComponent={AddIcon}
                            onClick={() => handleDrawerClose()}
                        />
                    }
                />
                <ResonanceMainNavItem
                    primaryText="Users"
                    secondaryText="Access Control"
                    href={DemandAdminRoute.DemandHqUsers.build(demandHqId)}
                    isSelected={[
                        DemandAdminRoute.DemandHqUsers.pathname,
                    ].includes(router.pathname)}
                    onClick={() => handleDrawerClose()}
                    secondaryAction={
                        <ResonanceMainNavSecondaryAction
                            href={DemandAdminRoute.DemandHqUsersAdd.build(
                                demandHqId,
                            )}
                            title="Add User"
                            isSelected={
                                router.pathname ===
                                DemandAdminRoute.DemandHqUsersAdd.pathname
                            }
                            IconComponent={AddIcon}
                            onClick={() => handleDrawerClose()}
                        />
                    }
                />
            </ResonanceMainNavAccordion>
        </Box>
    );
};

export { DemandHqNavAdminMenu };
