import {
    ResonanceLocaleType,
    ProductLocaleApiCreateInput,
    ProductLocaleApiUpdateInput,
    ProductLocaleInCompositeApiCreateInput,
} from "@buildresonance/resonance-lib-entities";
import {
    AnyObject,
    array,
    object,
    ObjectSchema,
    string,
    TupleSchema,
} from "yup";

import { transformBlankStringToNull } from "../stringHelpers";

import { demandHqIdSchema, productIdSchema } from "./demandIds";

export const PRODUCT_LOCALE_DESCRIPTION_MAX_LENGTH = 10000;
export const PRODUCT_LOCALE_FEATURE_BULLET_MAX_LENGTH = 1000;
export const PRODUCT_LOCALE_MAX_NUMBER_OF_FEATURE_BULLETS = 5;

export const productLocaleInCompositeApiCreateInputSchema: ObjectSchema<ProductLocaleInCompositeApiCreateInput> =
    object({
        description: string()
            .max(PRODUCT_LOCALE_DESCRIPTION_MAX_LENGTH)
            .nullable(),
        featureBullets: array()
            .of(string().max(PRODUCT_LOCALE_FEATURE_BULLET_MAX_LENGTH))
            .max(PRODUCT_LOCALE_MAX_NUMBER_OF_FEATURE_BULLETS)
            .nullable() as unknown as TupleSchema<
            [
                string | undefined | null,
                string | undefined | null,
                string | undefined | null,
                string | undefined | null,
                string | undefined | null,
            ],
            AnyObject,
            undefined
        >,
        localeType: string()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(ResonanceLocaleType)),
    }).noUnknown();

export const productLocaleApiCreateInputSchema: ObjectSchema<ProductLocaleApiCreateInput> =
    productLocaleInCompositeApiCreateInputSchema
        .concat(
            object({
                demandHqId: demandHqIdSchema.required(),
                productId: productIdSchema.required(),
            }),
        )
        .noUnknown();

export const productLocaleApiUpdateInputSchema: ObjectSchema<ProductLocaleApiUpdateInput> =
    productLocaleApiCreateInputSchema.clone().omit(["demandHqId"]).noUnknown();
