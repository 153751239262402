import { ResonanceUpdatedBy } from "../../../entityComposites";
import { ResonanceDates } from "../../../global";
import { ContentItemId, ContentItemS3Key } from "../../cdn";
import { DemandHqId } from "../DemandIds";

export enum CommonContentType {
    File = "FILE",
    Image = "IMAGE",
    Video = "VIDEO",
}

/** IdFields are the ones that are required to find the correct entity. Must come in all update methods. */
export interface CommonContentItemIdFields {
    contentItemId: ContentItemId;
    /**
     * This may appear to be dangerous, but this string needs to be slug-formatted, but also allows and must begin with a forward slash.
     * This will allow the feigning of a file system, and allow prefix searching.
     * This must be unique to the DemandHq (Dynamo will handle on its own).
     * Limit of 512 characters, must begin with a forward slash, and must be lowercase letters, numbers, and dashes.
     */
    name: string;
}

export interface CommonContentItemRelationshipFields {
    /** The key to the file in the CDN (S3) */
    contentKey: ContentItemS3Key;
    contentType: CommonContentType;
    demandHqId: DemandHqId;
}

/**
 * A relational lookup for content contained in the CDN.
 * This applies to no entity in particular other than the DemandHq itself.
 * This is primarily for storage of very general items, such as store logos.
 */
export interface CommonContentItem
    extends CommonContentItemIdFields,
        CommonContentItemRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {}

export type CommonContentItemGql = CommonContentItem;

export type CommonContentItemModelUpdateInput = CommonContentItemIdFields &
    Partial<
        Omit<
            CommonContentItem,
            | keyof CommonContentItemIdFields
            | keyof CommonContentItemRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type CommonContentItemModelCreateInput = Omit<
    CommonContentItem,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export interface CommonImageFromUrlApiInput {
    /**
     * The URL of the image to upload.
     *
     * Should match url structure, including https://, and should be a valid image.
     */
    url: string;
}

export interface CommonImageFromFileApiInput {
    /**
     * Limit to 1000 characters.
     */
    fileName: string;
    /**
     * Limit to 100 characters (although, that would be a weird file extension).
     */
    fileExtension: string;
}

export type CommonContentItemApiUpdateInput = CommonContentItemModelUpdateInput;
