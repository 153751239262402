import React, { ReactElement } from "react";

import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import {
    ResonanceMainNavAccordion,
    ResonanceMainNavItem,
} from "@buildresonance/resonance-lib-nav-components";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import { useTheme, Box } from "@mui/material";
import { useRouter } from "next/router";

const DemandHqNavReportingMenu = ({
    handleDrawerClose,
}: {
    handleDrawerClose: () => void;
}): ReactElement => {
    const theme = useTheme();
    const router = useRouter();
    const demandHqId = router?.query?.demandHqId as DemandHqId;

    return (
        <Box marginTop={theme.spacing(1)}>
            <ResonanceMainNavAccordion title="Reporting">
                <ResonanceMainNavItem
                    primaryText="Dashboard"
                    href={DemandAdminRoute.ReportsDashboard.build(demandHqId)}
                    isSelected={[
                        DemandAdminRoute.ReportsDashboard.pathname,
                    ].includes(router.pathname)}
                    onClick={() => handleDrawerClose()}
                />
            </ResonanceMainNavAccordion>
        </Box>
    );
};

export { DemandHqNavReportingMenu };
