import {
    ProductContentItemApiUpdateInput,
    ProductContentItemAssociationApiCreateInput,
    ProductContentItemAssociationInCompositeApiCreateInput,
    ProductContentItemCommonApiCreateInput,
    ProductContentItemIdFields,
    ProductContentItemRelationshipFields,
    ProductImageFromFileApiInput,
    ProductImageFromUrlApiInput,
    ProductImageFromUrlInCompositeApiInput,
} from "@buildresonance/resonance-lib-entities";
import { number, object, ObjectSchema, string } from "yup";

import { contentItemIdSchema } from "../cdn/cdnIds";
import {
    CONTENT_ITEM_ALT_MAX_LENGTH,
    CONTENT_ITEM_CATEGORY_MAX_LENGTH,
    CONTENT_ITEM_FILENAME_MAX_LENGTH,
    CONTENT_ITEM_FILE_EXTENSION_MAX_LENGTH,
} from "../cdn/contentItem";
import { CONTENT_ITEM_INPUT_ID_MAX_LENGTH } from "../common/contentItem";
import { transformNaNToNull, transformZeroToNull } from "../numberHelpers";

import { demandHqIdSchema, productIdSchema } from "./demandIds";

export const PRODUCT_CONTENT_ITEM_POSITION_MAX_VALUE = 9999999999;
export const PRODUCT_CONTENT_ITEM_TITLE_MAX_LENGTH = 256;

const productContentItemCommonApiInCompositeInputSchema: ObjectSchema<
    Omit<
        ProductContentItemCommonApiCreateInput,
        | keyof ProductContentItemIdFields
        | keyof ProductContentItemRelationshipFields
    >
> = object({
    alt: string().nullable().max(CONTENT_ITEM_ALT_MAX_LENGTH),
    category: string().nullable().max(CONTENT_ITEM_CATEGORY_MAX_LENGTH),
    position: number()
        .integer()
        .positive()
        .nullable()
        .max(PRODUCT_CONTENT_ITEM_POSITION_MAX_VALUE)
        .transform(transformNaNToNull)
        .transform(transformZeroToNull),
    title: string().nullable().max(PRODUCT_CONTENT_ITEM_TITLE_MAX_LENGTH),
}).noUnknown();

export const productContentItemAssociationInCompositeApiCreateInputSchema: ObjectSchema<ProductContentItemAssociationInCompositeApiCreateInput> =
    productContentItemCommonApiInCompositeInputSchema
        .concat(
            object({
                contentItemId: contentItemIdSchema.required(),
            }),
        )
        .noUnknown();

const productContentItemCommonApiCreateInputSchema: ObjectSchema<ProductContentItemCommonApiCreateInput> =
    object({
        alt: string().nullable().max(CONTENT_ITEM_ALT_MAX_LENGTH),
        category: string().nullable().max(CONTENT_ITEM_CATEGORY_MAX_LENGTH),
        position: number()
            .integer()
            .positive()
            .nullable()
            .max(PRODUCT_CONTENT_ITEM_POSITION_MAX_VALUE)
            .transform(transformNaNToNull)
            .transform(transformZeroToNull),
        title: string().nullable().max(PRODUCT_CONTENT_ITEM_TITLE_MAX_LENGTH),
        demandHqId: demandHqIdSchema.required(),
        productId: productIdSchema.required(),
    }).noUnknown();

export const productContentItemAssociationApiCreateInputSchema: ObjectSchema<ProductContentItemAssociationApiCreateInput> =
    productContentItemCommonApiCreateInputSchema
        .concat(
            object({
                contentItemId: contentItemIdSchema.required(),
            }),
        )
        .noUnknown();

export const productImageFromUrlApiInputSchema: ObjectSchema<ProductImageFromUrlApiInput> =
    productContentItemCommonApiCreateInputSchema
        .concat(
            object({
                url: string().required().url(),
            }),
        )
        .noUnknown();

export const productImageFromFileApiInputSchema: ObjectSchema<ProductImageFromFileApiInput> =
    productContentItemCommonApiCreateInputSchema
        .concat(
            object({
                fileName: string()
                    .required()
                    .max(CONTENT_ITEM_FILENAME_MAX_LENGTH),
                fileExtension: string()
                    .required()
                    .max(CONTENT_ITEM_FILE_EXTENSION_MAX_LENGTH),
            }),
        )
        .noUnknown();

export const productContentItemApiUpdateInputSchema: ObjectSchema<ProductContentItemApiUpdateInput> =
    object({
        alt: string().nullable().max(CONTENT_ITEM_ALT_MAX_LENGTH),
        category: string().nullable().max(CONTENT_ITEM_CATEGORY_MAX_LENGTH),
        contentItemId: contentItemIdSchema.required(),
        position: number()
            .integer()
            .positive()
            .nullable()
            .max(PRODUCT_CONTENT_ITEM_POSITION_MAX_VALUE)
            .transform(transformNaNToNull)
            .transform(transformZeroToNull),
        title: string().nullable().max(PRODUCT_CONTENT_ITEM_TITLE_MAX_LENGTH),
        productId: productIdSchema.required(),
    }).noUnknown();

export const productImageFromUrlInCompositeApiInputSchema: ObjectSchema<ProductImageFromUrlInCompositeApiInput> =
    productContentItemCommonApiInCompositeInputSchema
        .concat(
            object({
                contentItemInputId: string()
                    .nullable()
                    .max(CONTENT_ITEM_INPUT_ID_MAX_LENGTH),
                url: string().required().url(),
            }),
        )
        .noUnknown();
