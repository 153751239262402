import {
    ProductApiCreateInput,
    ProductApiUpdateInput,
    DemandEntityStatus,
    ProductCompositeApiCreateInput,
    VariantInProductCompositeApiCreateInput,
} from "@buildresonance/resonance-lib-entities";
import {
    AnyObject,
    array,
    number,
    object,
    ObjectSchema,
    string,
    TupleSchema,
} from "yup";

import { CONTENT_ITEM_INPUT_ID_MAX_LENGTH } from "../common/contentItem";
import { seoFieldsSchema, slugSchema } from "../common/seo";
import { tagArraySchema } from "../common/tags";
import { dateTimeStringSchema } from "../dateHelpers";
import { transformNaNToNull } from "../numberHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import {
    demandHqIdSchema,
    productIdSchema,
    storefrontIdSchema,
} from "./demandIds";
import {
    productContentItemAssociationInCompositeApiCreateInputSchema,
    productImageFromUrlInCompositeApiInputSchema,
} from "./productContentItem";
import { productLocaleInCompositeApiCreateInputSchema } from "./productLocale";
import { variantInCompositeApiCreateInputSchema } from "./variant";

export const PRODUCT_OPTION_LABEL_MAX_LENGTH = 64;
export const PRODUCT_MAX_NUMBER_OF_OPTION_LABELS = 3;
export const PRODUCT_TITLE_MAX_LENGTH = 256;
export const PRODUCT_VENDOR_MAX_LENGTH = 256;

export const PRODUCT_MAX_NUMBER_OF_VARIANTS = 1000;

export const productApiCreateInputSchema: ObjectSchema<ProductApiCreateInput> =
    object({
        demandHqId: demandHqIdSchema.required(),
        optionLabels: array()
            .of(string().max(PRODUCT_OPTION_LABEL_MAX_LENGTH))
            .required()
            .max(PRODUCT_MAX_NUMBER_OF_OPTION_LABELS)
            .test({
                name: "firstRequired",
                message: "At least one option label is required",
                test: (values: (string | undefined)[] | undefined) =>
                    !values || (values.length > 0 && !!values[0]),
            }) as unknown as TupleSchema<
            [string, string?, string?],
            AnyObject,
            undefined
        >,
        seedPopularity: number()
            .integer()
            .min(0)
            .max(100)
            .nullable()
            .transform(transformNaNToNull),
        slug: slugSchema.required(),
        storefrontIds: array().of(storefrontIdSchema.required()).nullable(),
        tags: tagArraySchema.nullable(),
        title: string().max(PRODUCT_TITLE_MAX_LENGTH).required(),
        vendor: string().max(PRODUCT_VENDOR_MAX_LENGTH).required(),
    })
        .concat(seoFieldsSchema)
        .noUnknown();

export const productApiUpdateInputSchema: ObjectSchema<ProductApiUpdateInput> =
    object({
        optionLabels: array()
            .of(string().max(PRODUCT_OPTION_LABEL_MAX_LENGTH))
            .max(PRODUCT_MAX_NUMBER_OF_OPTION_LABELS)
            .test({
                name: "firstRequired",
                message: "At least one option label is required",
                test: (values: (string | undefined)[] | undefined) =>
                    !values || (values.length > 0 && !!values[0]),
            }) as unknown as TupleSchema<
            [string, string?, string?],
            AnyObject,
            undefined
        >,
        productId: productIdSchema.required(),
        publishedAt: dateTimeStringSchema.nullable(),
        seedPopularity: number()
            .integer()
            .min(0)
            .max(100)
            .nullable()
            .transform(transformNaNToNull),
        slug: slugSchema,
        status: string()
            .transform(transformBlankStringToNull)
            .oneOf([...Object.values(DemandEntityStatus), undefined])
            .transform(transformBlankStringToNull),

        storefrontIds: array().of(storefrontIdSchema.required()).nullable(),
        tags: tagArraySchema.nullable(),
        title: string().max(PRODUCT_TITLE_MAX_LENGTH),
        vendor: string().max(PRODUCT_VENDOR_MAX_LENGTH),
    })
        .concat(seoFieldsSchema)
        .noUnknown();

const variantInProductCompositeApiCreateInputSchema: ObjectSchema<VariantInProductCompositeApiCreateInput> =
    variantInCompositeApiCreateInputSchema.omit(["contentItemIds"]).concat(
        object({
            contentItemInputIds: array()
                .of(string().required().max(CONTENT_ITEM_INPUT_ID_MAX_LENGTH))
                .nullable(),
        }),
    );

export const productCompositeApiCreateInputSchema: ObjectSchema<ProductCompositeApiCreateInput> =
    object({
        productInput: productApiCreateInputSchema.required(),
        productContentItemAssociationInputs: array()
            .of(productContentItemAssociationInCompositeApiCreateInputSchema)
            .nullable(),
        productImageFromUrlInputs: array()
            .of(productImageFromUrlInCompositeApiInputSchema)
            .nullable(),
        productLocaleInputs: array()
            .of(productLocaleInCompositeApiCreateInputSchema)
            .nullable(),
        variantInputs: array()
            .of(variantInProductCompositeApiCreateInputSchema)
            .max(PRODUCT_MAX_NUMBER_OF_VARIANTS)
            .nullable(),
    }).noUnknown();
