import { ResonanceUpdatedBy } from "../../../entityComposites";
import { ResonanceDates } from "../../../global";
import { DemandEntityStatus } from "../DemandEntity";
import { DemandHqId, StorefrontId } from "../DemandIds";

export interface DemandHqAutomationSettingsIdFields {
    demandHqId: DemandHqId;
}

/**
 * The settings for the automation of a DemandHq
 */
export interface DemandHqAutomationSettings
    extends DemandHqAutomationSettingsIdFields,
        Pick<ResonanceDates, "updatedAt">,
        ResonanceUpdatedBy {
    notifications?: DemandHqAutomationNotificationSettings | null;

    /** The default shipping cost for this Demand HQ in the default currency amount. i.e. $8.95. */
    defaultAverageShippingCost?: number | null;
    /** An absolute price floor in default currency amount. i.e. 10 would mean the price can never drop below 10. */
    priceFloorAbsolute?: number | null;
    /** A price floor margin in percent. i.e. 10 would mean the price can never drop below 10% above the current Cost of Good Sold + Handling Cost + Average Shipping Cost. */
    priceFloorMarginPercent?: number | null;

    /** Created Vendor Collections will be created with this status. Defaults to DRAFT. */
    vendorCollectionStatus?: DemandEntityStatus | null;
    /** Created Vendor Collections will be created published to these storefrontIds. Defaults to None. */
    vendorCollectionStorefrontIds?: StorefrontId[] | null;
    /** Automatically create Vendor collections using this collectionType. Enables functionality when non-null. */
    vendorCollectionType?: string | null;
}

/**
 * Notification settings for a DemandHq
 */
export interface DemandHqAutomationNotificationSettings {
    /** The notification settings for when an order is created. */
    orders?: DemandHqAutomationNotification[] | null;
}

/**
 * An individual to be notified when an event occurs.
 */
export interface DemandHqAutomationNotification {
    /**
     * The target of the notification.
     * i.e. an email address or phone number.
     */
    target: string;
    /**
     * The type of target the notification is to be sent to.
     */
    type: DemandHqAutomationNotificationType;
}

/**
 * The type of target the notification is to be sent to.
 */
export enum DemandHqAutomationNotificationType {
    EMAIL = "EMAIL",
}

// No create inputs for this entity needed, partial updating is possible due to 100% optional fields and a single index.

export type DemandHqAutomationSettingsModelUpdateInput =
    DemandHqAutomationSettingsIdFields &
        Omit<
            DemandHqAutomationSettings,
            | keyof DemandHqAutomationSettingsIdFields
            | "updatedAt"
            | keyof ResonanceUpdatedBy
        >;

export type DemandHqAutomationSettingsApiUpdateInput =
    DemandHqAutomationSettingsModelUpdateInput;
