import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceAddress, ResonanceDates } from "../../global";

import {
    CustomerAddressId,
    CustomerId,
    DemandHqId,
    StorefrontId,
} from "./DemandIds";

export enum CustomerStatus {
    Active = "ACTIVE",
    Disabled = "DISABLED",
    Guest = "GUEST",
}

export enum CustomerMarketingConsentLevel {
    /** Level 1 */
    SingleOptIn = "SINGLE_OPT_IN",
    /** Level 2 */
    SingleOptInWithNotification = "SINGLE_OPT_IN_WITH_NOTIFICATION",
    /** Level 3 */
    ConfirmedOptIn = "CONFIRMED_OPT_IN",
    Unknown = "UNKNOWN",
}

export interface CustomerIdFields {
    customerId: CustomerId;
}

export interface CustomerRelationshipFields {
    demandHqId: DemandHqId;
    storefrontId: StorefrontId;
}

export interface CustomerMarketingConsent {
    consentedAt: string;
    /** https://www.m3aawg.org/sites/default/files/document/M3AAWG_Senders_BCP_Ver3-2015-02.pdf */
    consentLevel: CustomerMarketingConsentLevel;
    hasConsented: boolean;
    /** Optional field to store where consent was sourced from */
    source?: string | null;
}

/**
 * A Customer is a shopper on a Storefront, who's primary purpose is to create Orders.
 */
export interface Customer
    extends CustomerIdFields,
        CustomerRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    defaultBillingAddressId?: CustomerAddressId | null;
    defaultShippingAddressId?: CustomerAddressId | null;
    /** Required, must be unique to the Storefront, limit 256 characters. */
    emailAddress: string;
    emailMarketingConsent?: CustomerMarketingConsent | null;
    /**
     * Reference to the storefront's customer authentication system. Limit 256 characters.
     * This field is required if this customer is going to access any authenticated data (like their orders).
     */
    externalCustomerId?: string | null;
    /** Limit 256 characters. */
    firstName?: string | null;
    /** Limit 256 characters. */
    lastName?: string | null;
    /** Must be unique to the Storefront. Limit 64 characters. Format +11231231234 */
    phoneNumber?: string | null;
    smsMarketingConsent?: CustomerMarketingConsent | null;
    status: CustomerStatus;
}

export interface CustomerAddressIdFields {
    customerAddressId: CustomerAddressId;
}

export interface CustomerAddressRelationshipFields
    extends CustomerRelationshipFields {
    customerId: CustomerId;
}

export interface CustomerAddress
    extends ResonanceAddress,
        CustomerAddressIdFields,
        CustomerAddressRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {}

/**
 * The tags associated with this customer, a child object of the Customer with a 1-1 relationship.
 * This is separate due to possible size, at 1000 * 96 = 96000 bytes, way more than we want to store with many GSI indexes, but we still want Dynamo speed (and cost is comparable to S3 for these params).
 * This enables us not to pull them for list queries and such as well, this should be obscured from consumers by the API, but still obvious at the model level.
 */
export interface CustomerTags
    extends CustomerIdFields,
        Pick<CustomerRelationshipFields, "demandHqId">,
        ResonanceUpdatedBy {
    /** Tags for this customer, limit to 1000 and 96 characters each. */
    raw?: string[] | null;
    updatedAt: string;
}

export type CustomerModelCreateInput = Omit<
    Customer,
    keyof CustomerIdFields | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type CustomerApiCreateInput = CustomerModelCreateInput & {
    /** Tags for this customer, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

export type CustomerModelUpdateInput = CustomerIdFields &
    Partial<
        Omit<
            Customer,
            | keyof CustomerIdFields
            | keyof CustomerRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type CustomerStorefrontApiCreateInput = Omit<
    CustomerApiCreateInput,
    | keyof CustomerRelationshipFields // Comes in the Storefront token
    | "status"
>;

export type CustomerApiUpdateInput = CustomerModelUpdateInput & {
    /** Tags for this collection, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

export type CustomerStorefrontApiUpdateInput = Omit<
    CustomerModelUpdateInput,
    | keyof CustomerIdFields // Uses externalCustomerId instead
    | "externalCustomerId" // Comes from customerToken
    | "status"
> & {
    /** Tags for this collection, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

export type CustomerAddressModelCreateInput = Omit<
    CustomerAddress,
    | keyof CustomerAddressIdFields
    | keyof ResonanceDates
    | keyof ResonanceUpdatedBy
>;

export type CustomerAddressApiCreateInput = CustomerAddressModelCreateInput;

export type CustomerAddressModelUpdateInput = CustomerAddressIdFields &
    Partial<
        Omit<
            CustomerAddress,
            | keyof CustomerAddressIdFields
            | keyof CustomerAddressRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type CustomerAddressApiUpdateInput = CustomerAddressModelUpdateInput;

/**
 * The Search shape for Customers
 */
export interface CustomerComposite {
    customer: Customer;
    customerAddresses?: CustomerAddress[] | null;
    customerTags?: CustomerTags | null;
}

export type CustomerSearchComposite = CustomerComposite;
