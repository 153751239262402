import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { ComponentRepeater } from "@buildresonance/resonance-lib-display-components";
import { ListDemandHqCommentsResponse } from "@hooks/graphql/queries/useListDemandHqComments/listDemandHqComments";
import { CommentTextField } from "@layout/AdminLayout/DemandHeader/entityButtons/CommentsButtonParent/CommentsDrawer/CommentTextField";
import ToTheBottomIcon from "@mui/icons-material/ArrowDownward";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Card,
    CardContent,
    IconButton,
    Skeleton,
    Toolbar,
    Tooltip,
    alpha,
    useTheme,
} from "@mui/material";
import { ClientError } from "graphql-request";
import { UseInfiniteQueryResult } from "react-query";

import { CommentCardParent } from "./CommentCardParent";

export interface CommentsDrawerProps {
    demandHqCommentsResult: UseInfiniteQueryResult<
        ListDemandHqCommentsResponse,
        ClientError
    >;
}

export const CommentsDrawer = ({
    demandHqCommentsResult,
}: CommentsDrawerProps): ReactElement => {
    const theme = useTheme();

    const [hasBeenScrolled, setHasBeenScrolled] = useState(false);

    const scrollBoxRef = useRef<HTMLDivElement>(null);

    const scrollToTheBottom = useCallback(() => {
        const timeout = setTimeout(() => {
            scrollBoxRef.current?.scrollTo({
                behavior: "smooth",
                top: scrollBoxRef.current.scrollHeight,
            });
        }, 100);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (
            demandHqCommentsResult.status === "success" &&
            !hasBeenScrolled &&
            scrollBoxRef.current
        ) {
            setHasBeenScrolled(true);
            scrollToTheBottom();
        }
    }, [demandHqCommentsResult.status, hasBeenScrolled, scrollToTheBottom]);

    const demandHqComments = useMemo(
        () =>
            demandHqCommentsResult?.data?.pages?.flatMap(
                (page) => page?.demandHq?.comments?.nodes,
            ) ?? [],
        [demandHqCommentsResult],
    );

    const renderLoadMoreButton = (): ReactElement | null => {
        if (
            demandHqCommentsResult?.status === "success" &&
            demandHqCommentsResult.hasNextPage
        ) {
            return (
                <LoadingButton
                    size="small"
                    variant="outlined"
                    loading={demandHqCommentsResult.isFetching}
                    onClick={() => demandHqCommentsResult.fetchNextPage()}
                >
                    Load more
                </LoadingButton>
            );
        }

        return null;
    };

    const renderComments = (): ReactElement => {
        switch (demandHqCommentsResult.status) {
            case "idle":
            case "loading":
                return (
                    <ComponentRepeater repeat={10}>
                        <Skeleton height="10rem" />
                    </ComponentRepeater>
                );
            case "error":
                return (
                    <Card>
                        <CardContent>
                            There was an error loading comments
                        </CardContent>
                    </Card>
                );
            case "success":
                return renderCommentsSuccess();
        }
    };

    const renderCommentsSuccess = (): ReactElement => {
        if (demandHqComments.length === 0) {
            return (
                <Card>
                    <CardContent>No comments yet</CardContent>
                </Card>
            );
        }

        return (
            <Box display="flex" flexDirection="column-reverse">
                {demandHqComments?.map((demandHqComment) => (
                    <CommentCardParent
                        key={demandHqComment.demandHqCommentId}
                        demandHqComment={demandHqComment}
                    />
                ))}
            </Box>
        );
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent={"flex-end"}
            height="100%"
            width={270}
            paddingBottom={theme.spacing(2)}
            bgcolor={alpha(theme.palette.secondary.light, 0.1)}
        >
            <Toolbar />
            <Box overflow="auto" ref={scrollBoxRef}>
                <Box display="flex" padding={theme.spacing(1)}>
                    <Box flex="1">{renderLoadMoreButton()}</Box>
                    <Box>
                        <Tooltip title="To the Bottom">
                            <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => scrollToTheBottom()}
                            >
                                <ToTheBottomIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Box>{renderComments()}</Box>
                <Box padding={theme.spacing(1)}>
                    <Card sx={{ padding: theme.spacing(1) }}>
                        <CommentTextField
                            scrollToTheBottom={scrollToTheBottom}
                        />
                    </Card>
                </Box>
            </Box>
        </Box>
    );
};
