import React, { ReactElement } from "react";

import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import { useRouter } from "next/router";

import { AppBarSearchAutocomplete } from "../common/AppBarSearchAutocomplete";
import { DemandEntityRecentSearchesLocalStorageKey } from "../common/constants";

import { useCollectionAutoComplete } from "./useCollectionAutoComplete";

const CollectionSearchInputParent = ({
    setSearchInputIsFocused,
}: {
    setSearchInputIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement | null => {
    const router = useRouter();

    const demandHqId = router.query.demandHqId as DemandHqId | undefined;

    const { debouncedQuery, collectionAutoCompletes } =
        useCollectionAutoComplete(demandHqId);

    if (
        !demandHqId ||
        ![
            DemandAdminRoute.CollectionCreate.pathname,
            DemandAdminRoute.CollectionEdit.pathname,
            DemandAdminRoute.CollectionImagesAdd.pathname,
            DemandAdminRoute.Collections.pathname,
        ].includes(router.pathname)
    ) {
        return null;
    }

    return (
        <AppBarSearchAutocomplete
            autoCompleteQueryResponse={collectionAutoCompletes}
            id="collection-search-input"
            label="Collection Search"
            placeholder="Search Collections..."
            searchPathName={DemandAdminRoute.Collections.pathname}
            sendDebouncedAutoCompleteQuery={debouncedQuery}
            setSearchInputIsFocused={setSearchInputIsFocused}
            localStorageKey={
                DemandEntityRecentSearchesLocalStorageKey.Collection
            }
        />
    );
};

export { CollectionSearchInputParent };
