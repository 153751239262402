export interface SearchMetricsInput {
    /**
     * The maximum for which the count for the total number of results should be capped at.
     * As in, if the total number of results is 1000, but this is set to 10, then the total count will be 10.
     * 0 indicates "do not provide total result count" and is the most performant (lower numbers are more performant than higher).
     *
     * @default 0
     * @minimum 0
     * @maximum 10000
     */
    totalCountAccuracy?: number | null;
}

export interface SearchMetricsOutput {
    /**
     * The total number of results that are available, even outside the current result set.
     * This is capped by the totalCountAccuracy input, at which point it becomes "at least this many".
     * This number is not guaranteed to be provided even if totalCountAccuracy is set.
     */
    totalCount?: number | null;
}
