import {
    DiscountApiCreateInput,
    DiscountApiUpdateInput,
    DiscountType,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, number, object, string } from "yup";

import { tagSchema } from "../common/tags";
import { dateTimeStringSchema } from "../dateHelpers";
import { transformNaNToNull } from "../numberHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import { demandHqIdSchema, discountIdSchema } from "./demandIds";

export const DISCOUNT_CODE_MAX_LENGTH = 64;
export const DISCOUNT_DESCRIPTION_MAX_LENGTH = 256;

export const discountApiCreateInputSchema: ObjectSchema<DiscountApiCreateInput> =
    object({
        code: string().required().max(DISCOUNT_CODE_MAX_LENGTH),
        demandHqId: demandHqIdSchema.required(),
        discountAmount: number().transform(transformNaNToNull).nullable(),
        discountType: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(DiscountType))
            .required(),
        productTag: tagSchema.required(),
        startAt: dateTimeStringSchema.required(),
        endAt: dateTimeStringSchema
            .transform(transformBlankStringToNull)
            .nullable(),
        description: string()
            .max(DISCOUNT_DESCRIPTION_MAX_LENGTH)
            .transform(transformBlankStringToNull)
            .nullable(),
    }).noUnknown();

export const discountApiUpdateInputSchema: ObjectSchema<DiscountApiUpdateInput> =
    object({
        discountId: discountIdSchema.required(),
        discountAmount: number().transform(transformNaNToNull).nullable(),
        discountType: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(DiscountType)),
        productTag: tagSchema,
        startAt: dateTimeStringSchema,
        endAt: dateTimeStringSchema
            .transform(transformBlankStringToNull)
            .nullable(),
        description: string()
            .max(DISCOUNT_DESCRIPTION_MAX_LENGTH)
            .transform(transformBlankStringToNull)
            .nullable(),
    }).noUnknown();
