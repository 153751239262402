import React, { ReactElement } from "react";

import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import {
    ResonanceMainNavAccordion,
    ResonanceMainNavItem,
    ResonanceMainNavSecondaryAction,
} from "@buildresonance/resonance-lib-nav-components";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import AddIcon from "@mui/icons-material/AddCircle";
import { useTheme, Box } from "@mui/material";
import { useRouter } from "next/router";

const DemandHqNavIntegrationsMenu = ({
    handleDrawerClose,
}: {
    handleDrawerClose: () => void;
}): ReactElement => {
    const theme = useTheme();
    const router = useRouter();
    const demandHqId = router?.query?.demandHqId as DemandHqId;

    return (
        <Box marginTop={theme.spacing(1)}>
            <ResonanceMainNavAccordion title="Integrations">
                <ResonanceMainNavItem
                    primaryText="Suppliers"
                    secondaryText="Permission to Sell"
                    href={DemandAdminRoute.Suppliers.build(demandHqId)}
                    isSelected={
                        router.pathname === DemandAdminRoute.Suppliers.pathname
                    }
                    onClick={() => handleDrawerClose()}
                    secondaryAction={
                        <ResonanceMainNavSecondaryAction
                            title="Request Supplier"
                            href={DemandAdminRoute.SupplierRequest.build(
                                demandHqId,
                            )}
                            isSelected={
                                router.pathname ===
                                DemandAdminRoute.SupplierRequest.pathname
                            }
                            IconComponent={AddIcon}
                            onClick={() => handleDrawerClose()}
                        />
                    }
                />

                <ResonanceMainNavItem
                    primaryText="Payment Gateways"
                    href={DemandAdminRoute.PaymentGateway.build(demandHqId)}
                    isSelected={
                        router.pathname ===
                        DemandAdminRoute.PaymentGateway.pathname
                    }
                    onClick={() => handleDrawerClose()}
                />
            </ResonanceMainNavAccordion>
        </Box>
    );
};

export { DemandHqNavIntegrationsMenu };
