/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Will accept any object, but not primitives or arrays.
 */
export type AnyObject = { [key: string]: any };

/**
 * Makes all properties of an object nullable, one level deep (only the outer most properties). Combine with Partial for undefined.
 */
export type NullableObject<T> = { [K in keyof T]: T[K] | null };

/**
 * Works exactly like Nullable, but works only if T can be undefined.
 */
export type NullableOnlyUndefined<T> = T extends undefined ? T | null : T;

/**
 * Makes all properties of a property that can be undefined, also able to be null. Does not affect required properties.
 */
export type NullableObjectOnlyUndefined<T> = {
    [K in keyof T]: NullableOnlyUndefined<T>;
};

/**
 * Makes all properties of an object nullable, including nested properties. Combine with Partial for undefined.
 */
export type DeepNullableObject<T> = {
    [K in keyof T]: DeepNullableObject<T[K]> | null;
};

/**
 * Works exactly like NonNullable, but works only on undefined, rather than both null and undefined.
 */
export type NonNullableOnlyUndefined<T> = T extends undefined ? never : T;

/**
 * Works exactly like NonNullable, but works only on null, rather than both null and undefined.
 */
export type NonNullableOnlyNull<T> = T extends null ? never : T;

/**
 * Makes all properties of an object non-nullable (but not non-undefinable), one level deep (only the outer most properties).
 * Combine with Required for undefined.
 */
export type NonNullableObject<T> = {
    [K in keyof T]: NonNullableOnlyNull<T[K]>;
};

/**
 * Makes all properties of an object non-nullable (but not non-undefinable), including nested properties.
 * Combine with Required for undefined.
 */
export type DeepNonNullableObject<T> = {
    [K in keyof T]: DeepNonNullableObject<NonNullableOnlyNull<T[K]>>;
};
