import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";

import {
    CollectionId,
    DemandHqId,
    StorefrontId,
    TaxonomyBranchId,
} from "./DemandIds";

export enum TaxonomyLevel {
    /** Only a single TaxonomyBranch can live at this level */
    Root = "ROOT",
    /**
     * The outermost categorization for a Product, generally describing a very broad grouping.
     *
     * @example "Apparel", "Electronics", "Home & Garden"
     */
    Department = "DEPARTMENT",
    /**
     * The second most outer categorization for a Product, generally describing a broad, but slightly refined grouping of products.
     *
     * @example "Clothing", "Shoes", "Computers", "TVs"
     */
    Category = "CATEGORY",
    /**
     * The third most outer categorization for a Product, generally describing a specific grouping of products.
     *
     * @example "T-Shirts", "Jeans", "Sneakers", "Laptops", "Smartphones"
     */
    Subcategory1 = "SUBCATEGORY1",
    /**
     * An additional Subcategory level for a Product, generally describing a very specific grouping of products.
     */
    Subcategory2 = "SUBCATEGORY2",
    /**
     * An additional Subcategory level for a Product, generally describing a very, very specific grouping of products.
     */
    Subcategory3 = "SUBCATEGORY3",
}

export type TaxonomyBranchIdFields = {
    taxonomyBranchId: TaxonomyBranchId;
};

export type TaxonomyBranchRelationshipFields = {
    demandHqId: DemandHqId;
};

export type TaxonomyBranchImmutableFields = {
    /**
     * The Parent of this TaxonomyBranch. Populated on all besides the Root TaxonomyBranch. Immutable.
     */
    parentTaxonomyBranchId?: TaxonomyBranchId | null;
    /** The level of the TaxonomyBranch in the hierarchy. Immutable. */
    taxonomyLevel: TaxonomyLevel;
    /** The tree this taxonomyBranch lies within. This has slug formatting (Lowercase, no spaces). Immutable. */
    taxonomyTree: string;
};

export type TaxonomyBranch = TaxonomyBranchIdFields &
    TaxonomyBranchRelationshipFields &
    TaxonomyBranchImmutableFields &
    ResonanceDates &
    ResonanceUpdatedBy & {
        /**
         * The collectionId associated with the TaxonomyBranch.
         * Not required as a field, but is strongly suggested to be populated, and one is generally created with a TaxonomyBranch.
         */
        collectionId?: CollectionId | null;
        /**
         * The product tag associated with this TaxonomyBranch, used for filtering or display matching.
         *
         * On a Root TaxonomyBranch, this is generally a taxPrefix that is used in all child TaxonomyBranches,
         * i.e. "Taxonomy" for child branch productTags like "Taxonomy,Category,T-Shirts"
         */
        productTag: string;
        /**
         * The storefronts this taxonomyTree is published to.
         * This is only present on the Root TaxonomyBranch of a taxonomyTree and applies to all child taxonomyBranches.
         */
        storefrontIds?: StorefrontId[] | null;

        /** The Name of the taxonomyBranch. */
        title: string;
    };

export type TaxonomyBranchModelCreateInput = Omit<
    TaxonomyBranch,
    | keyof TaxonomyBranchIdFields
    | keyof ResonanceDates
    | keyof ResonanceUpdatedBy
>;

export type TaxonomyBranchModelUpdateInput = TaxonomyBranchIdFields &
    Partial<
        Omit<
            TaxonomyBranch,
            | keyof TaxonomyBranchImmutableFields
            | keyof TaxonomyBranchIdFields
            | keyof TaxonomyBranchRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type TaxonomyBranchApiCreateInput = TaxonomyBranchModelCreateInput;

export type TaxonomyBranchApiUpdateInput = TaxonomyBranchModelUpdateInput;
