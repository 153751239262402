import { ResonanceDates } from "../../../global";
import { DemandHqId } from "../DemandIds";
import { OrderTransactionGateway } from "../OrderTransaction";

import { GatewayCredentialsId } from "./DemandSecureIds";

export interface GatewayCredentialsIdFields {
    gatewayCredentialsId: GatewayCredentialsId;
}

export interface GatewayCredentialsRelationshipFields {
    demandHqId: DemandHqId;
    gateway: OrderTransactionGateway;
}

/**
 * Authorize.net Oauth credentials for a merchant
 * Replaces "name" and "transactionKey" request fields with "access_token" field
 */
export interface AuthorizeNetOauthCredentials {
    accessToken: `${string}.${string}.${string}`;
    refreshToken: `${string}.${string}.${string}`;
    tokenType?: "bearer" | "Bearer";
    scope?: string;
}

/**
 * These are the App credentials for Authorize.net
 * Required to refresh an access token
 * @see AuthorizeNetOauthCredentials
 */
export interface AuthorizeNetAppCredentials {
    clientId: string;
    clientSecret: string;
}

/**
 * The actual id and secret for an Authorize.net merchant
 * This is not the preferred pattern (accessToken preferred)
 *
 * @property name - alternatively called "merchant id"
 * @property transactionKey - alternatively called "merchant secret"
 */
export interface AuthorizeNetMerchantCredentials {
    name: string;
    transactionKey: string;
}

export type GatewayCredentialsCredentials =
    | AuthorizeNetOauthCredentials
    | AuthorizeNetAppCredentials
    | AuthorizeNetMerchantCredentials;

export interface GatewayCredentials
    extends GatewayCredentialsIdFields,
        GatewayCredentialsRelationshipFields,
        ResonanceDates {
    credentials: GatewayCredentialsCredentials;
}

export type GatewayCredentialsModelCreateInput = Omit<
    GatewayCredentials,
    keyof GatewayCredentialsIdFields | keyof ResonanceDates
>;

export type GatewayCredentialsModelUpdateInput = Omit<
    GatewayCredentials,
    keyof GatewayCredentialsRelationshipFields | keyof ResonanceDates
>;
