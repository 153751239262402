import {
    IdentityParentEntityId,
    ResonanceIdentityId,
    ResonanceIdentityType,
    ResonanceUpdatedBy,
} from "../../entityComposites";
import { Permissions } from "../../util/permissions";
import { PublicAppGql } from "../adminidentity/App";
import { PublicIdentityGql } from "../adminidentity/PublicIdentity";

import { DemandRole, DemandScope } from "./DemandEntity";
import { DemandHqId } from "./DemandIds";

export type DemandHqIdentityPermissions = Permissions<DemandRole, DemandScope>;

/** IdFields are the ones that are required to find the correct entity. Must come in all update methods. */
export interface DemandHqIdentityIdFields {
    demandHqId: DemandHqId;
    /** Could be an app, and this would be clientId */
    identityId: ResonanceIdentityId;
}

/**
 * This is a crossover connection entity with the AdminIdentity domain.
 * It connects a user or app to a DemandHq, and grants that identity certain scopes.
 * This will be heavily used as authorization in the API.
 */
export interface DemandHqIdentity
    extends DemandHqIdentityIdFields,
        ResonanceUpdatedBy {
    /** For instances where knowing humans from machines is important */
    identityType: ResonanceIdentityType;
    /**
     * This is used to hold AppId to prevent needing to look it up. Using a general name to enable unknown future uses.
     */
    identityParentEntityId?: IdentityParentEntityId | null;
    /**
     * Permissions are additive, roles + scopes.
     * Roles are dynamic and subject to change. When in doubt, use scopes.
     */
    permissions: DemandHqIdentityPermissions;
}

export type DemandHqIdentityModelUpdateInput = DemandHqIdentityIdFields &
    Partial<
        Omit<
            DemandHqIdentity,
            | "identityType"
            | "parentEntityId"
            | keyof DemandHqIdentityIdFields
            | keyof ResonanceUpdatedBy
        >
    >;

export type DemandHqIdentityModelCreateInput = Omit<
    DemandHqIdentity,
    keyof ResonanceUpdatedBy
>;

export type DemandHqIdentityApiUpdateInput = DemandHqIdentityModelUpdateInput;

export type DemandHqIdentityApiCreateInput = DemandHqIdentityModelCreateInput;

export interface DemandHqIdentityGql extends DemandHqIdentity {
    publicIdentityParent: PublicAppGql;
    publicIdentity: PublicIdentityGql;
}
