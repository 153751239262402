import {
    DemandEntityStatus,
    DemandHqAutomationNotificationType,
    DemandHqAutomationSettingsApiUpdateInput,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, array, number, object, string } from "yup";

import { transformEmptyArrayToUndefined } from "../../arrayHelpers";
import { transformNaNToNull } from "../../numberHelpers";
import { requiredOnlyWhenExistsObjectTransformer } from "../../objectHelpers";
import { transformBlankStringToNull } from "../../stringHelpers";
import { collectionTypeSchema } from "../collection";
import { demandHqIdSchema, storefrontIdSchema } from "../demandIds";

export const demandHqAutomationNotificationSchema = object({
    target: string()
        .required()
        .when("type", ([type]) => {
            if (type === DemandHqAutomationNotificationType.EMAIL) {
                return string().email().required();
            }
            return string().required();
        })
        .transform(transformBlankStringToNull),
    type: string()
        .oneOf(Object.values(DemandHqAutomationNotificationType))
        .required()
        .transform(transformBlankStringToNull),
})
    .noUnknown()
    .transform(requiredOnlyWhenExistsObjectTransformer)
    .default(undefined);

export const demandHqAutomationSettingsApiUpdateInputSchema: ObjectSchema<DemandHqAutomationSettingsApiUpdateInput> =
    object({
        notifications: object({
            orders: array()
                .of(demandHqAutomationNotificationSchema)
                .nullable()
                .transform(transformEmptyArrayToUndefined),
        })
            .noUnknown()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        defaultAverageShippingCost: number()
            .min(0)
            .nullable()
            .transform(transformNaNToNull),
        demandHqId: demandHqIdSchema.required(),
        priceFloorAbsolute: number()
            .min(0)
            .nullable()
            .transform(transformNaNToNull),
        priceFloorMarginPercent: number()
            .min(0)
            .nullable()
            .transform(transformNaNToNull),
        vendorCollectionStatus: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(DemandEntityStatus))
            .nullable(),
        vendorCollectionStorefrontIds: array()
            .of(storefrontIdSchema.required())
            .nullable(),
        vendorCollectionType: collectionTypeSchema
            .nullable()
            .transform(transformBlankStringToNull),
    }).noUnknown();
