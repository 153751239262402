import {
    AdminIdentityEntity,
    ALL_PARTNER_SCOPES,
    PartnerIdentityApiCreateInput,
    PartnerIdentityApiUpdateInput,
    PartnerRole,
    ResonanceIdentityType,
} from "@buildresonance/resonance-lib-entities";
import { array, object, ObjectSchema, string } from "yup";

import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import {
    appIdSchema,
    partnerIdSchema,
    resonanceIdentityIdSchema,
} from "./adminIdentityIds";

export const partnerIdentityApiCreateInputSchema: ObjectSchema<PartnerIdentityApiCreateInput> =
    object({
        identityId: resonanceIdentityIdSchema.required(),
        identityType: string<ResonanceIdentityType>()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf([AdminIdentityEntity.AppClient, AdminIdentityEntity.User]),
        identityParentEntityId: appIdSchema.nullable(),
        partnerId: partnerIdSchema.required(),
        permissions: object({
            roles: array()
                .of(
                    string<PartnerRole>()
                        .required()
                        .oneOf(Object.values(PartnerRole)),
                )
                .required(),
            scopes: array()
                .of(string().required().oneOf(ALL_PARTNER_SCOPES))
                .required(),
        }).required(),
    }).noUnknown();

export const partnerIdentityApiUpdateInputSchema: ObjectSchema<PartnerIdentityApiUpdateInput> =
    object({
        identityId: resonanceIdentityIdSchema.required(),
        partnerId: partnerIdSchema.required(),
        permissions: object({
            roles: array()
                .of(
                    string<PartnerRole>()
                        .transform(transformBlankStringToNull)
                        .required()
                        .oneOf(Object.values(PartnerRole)),
                )
                .required(),
            scopes: array()
                .of(string().required().oneOf(ALL_PARTNER_SCOPES))
                .required(),
        })
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
    }).noUnknown();
