import React, { ReactElement } from "react";

import {
    SELECTED_TAB_QUERY_PARAM,
    useSelectedTab,
} from "@hooks/custom/useSelectedTab";
import { Tab, Tabs } from "@mui/material";
import { useRouter } from "next/router";

export enum EditProductTab {
    Basics = "basics",
    Description = "description",
    Images = "images",
    Variants = "variants",
    Reviews = "reviews",
}

const EditProductTabs = (): ReactElement => {
    const router = useRouter();
    const selectedTab = useSelectedTab<EditProductTab>(EditProductTab.Basics);

    return (
        <Tabs
            value={selectedTab}
            onChange={(_, value) =>
                router.push({
                    pathname: router.pathname,
                    query: {
                        ...router.query,
                        [SELECTED_TAB_QUERY_PARAM]: value,
                    },
                })
            }
            aria-label="Edit Page Tabs"
        >
            <Tab
                label="Basics"
                value={EditProductTab.Basics}
                id="basics-tab"
                aria-controls="basics-tabpanel"
            />
            <Tab
                label="Description"
                value={EditProductTab.Description}
                id="description-tab"
                aria-controls="description-tabpanel"
            />
            <Tab
                label="Images"
                value={EditProductTab.Images}
                id="images-tab"
                aria-controls="images-tabpanel"
            />
            <Tab
                label="Variants"
                value={EditProductTab.Variants}
                id="variants-tab"
                aria-controls="variants-tabpanel"
            />
            <Tab
                label="Reviews"
                value={EditProductTab.Reviews}
                id="reviews-tab"
                aria-controls="reviews-tabpanel"
            />
        </Tabs>
    );
};

export { EditProductTabs };
