import {
    PageImageFromFileApiCreateInput,
    PageImageFromUrlApiCreateInput,
    PageApiCreateInput,
    PageApiUpdateInput,
    PageContentApiUpsertInput,
    DemandEntityStatus,
} from "@buildresonance/resonance-lib-entities";
import { array, object, ObjectSchema, string } from "yup";

import { contentItemIdSchema } from "../cdn/cdnIds";
import { seoFieldsSchema, slugSchema } from "../common/seo";
import { tagArraySchema } from "../common/tags";
import { dateTimeStringSchema } from "../dateHelpers";
import {
    SLUG_REGEX,
    SLUG_REGEX_VALIDATION_MESSAGE,
    transformBlankStringToNull,
} from "../stringHelpers";

import {
    demandHqIdSchema,
    pageIdSchema,
    storefrontIdSchema,
} from "./demandIds";

export const PAGE_AUTHOR_MAX_LENGTH = 256;
export const PAGE_MAX_NUMBER_OF_CONTENT_ITEMS = 100;
export const PAGE_DESCRIPTION_MAX_LENGTH = 1000;
export const PAGE_TYPE_MAX_LENGTH = 128;
export const PAGE_IMAGE_URL_MAX_LENGTH = 1028;
export const PAGE_TITLE_MAX_LENGTH = 256;

export const PAGE_CONTENT_MAX_LENGTH = 98000;

export const pageTypeSchema = string()
    .matches(SLUG_REGEX, SLUG_REGEX_VALIDATION_MESSAGE)
    .max(PAGE_TYPE_MAX_LENGTH);

export const pageContentAsStringSchema = string().max(PAGE_CONTENT_MAX_LENGTH);
export const pageContentApiUpsertInputSchema: ObjectSchema<PageContentApiUpsertInput> =
    object({
        contentAsString: pageContentAsStringSchema.required(),
        pageId: pageIdSchema.required(),
    });

export const pageApiCreateInputSchema: ObjectSchema<PageApiCreateInput> =
    object({
        author: string().nullable().max(PAGE_AUTHOR_MAX_LENGTH),
        contentItemIds: array()
            .of(contentItemIdSchema.required())
            .max(PAGE_MAX_NUMBER_OF_CONTENT_ITEMS)
            .nullable(),
        demandHqId: demandHqIdSchema.required(),
        description: string().nullable().max(PAGE_DESCRIPTION_MAX_LENGTH),
        pageType: pageTypeSchema.required(),
        primaryImageUrl: string().nullable().max(PAGE_IMAGE_URL_MAX_LENGTH),
        secondaryImageUrl: string().nullable().max(PAGE_IMAGE_URL_MAX_LENGTH),
        slug: slugSchema.required(),
        storefrontIds: array().of(storefrontIdSchema.required()).nullable(),
        subtitle: string().nullable().max(PAGE_TITLE_MAX_LENGTH),
        tags: tagArraySchema.nullable(),
        tertiaryImageUrl: string().nullable().max(PAGE_IMAGE_URL_MAX_LENGTH),
        title: string().required().max(PAGE_TITLE_MAX_LENGTH),
    })
        .concat(seoFieldsSchema)
        .noUnknown();

export const pageApiUpdateInputSchema: ObjectSchema<PageApiUpdateInput> =
    object({
        author: string().nullable().max(PAGE_AUTHOR_MAX_LENGTH),
        contentItemIds: array()
            .of(contentItemIdSchema.required())
            .max(PAGE_MAX_NUMBER_OF_CONTENT_ITEMS)
            .nullable(),
        description: string().nullable().max(PAGE_DESCRIPTION_MAX_LENGTH),
        pageId: pageIdSchema.required(),
        pageType: pageTypeSchema,
        primaryImageUrl: string().nullable().max(PAGE_IMAGE_URL_MAX_LENGTH),
        publishedAt: dateTimeStringSchema.nullable(),
        secondaryImageUrl: string().nullable().max(PAGE_IMAGE_URL_MAX_LENGTH),
        slug: slugSchema,
        status: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(DemandEntityStatus)),
        storefrontIds: array().of(storefrontIdSchema.required()).nullable(),
        subtitle: string().nullable().max(PAGE_TITLE_MAX_LENGTH),
        tags: tagArraySchema.nullable(),
        tertiaryImageUrl: string().nullable().max(PAGE_IMAGE_URL_MAX_LENGTH),
        title: string().max(PAGE_TITLE_MAX_LENGTH),
    })
        .concat(seoFieldsSchema)
        .noUnknown();

export const PAGE_IMAGE_FILENAME_MAX_LENGTH = 1000;
export const PAGE_IMAGE_FILE_EXTENSION_MAX_LENGTH = 100;

export const pageImageFromFileApiCreateInputSchema: ObjectSchema<PageImageFromFileApiCreateInput> =
    object({
        demandHqId: demandHqIdSchema.required(),
        fileName: string().max(PAGE_IMAGE_FILENAME_MAX_LENGTH).required(),
        fileExtension: string()
            .max(PAGE_IMAGE_FILE_EXTENSION_MAX_LENGTH)
            .required(),
        pageId: pageIdSchema.required(),
    }).noUnknown();

export const pageImageFromUrlApiCreateInputSchema: ObjectSchema<PageImageFromUrlApiCreateInput> =
    object({
        demandHqId: demandHqIdSchema.required(),
        pageId: pageIdSchema.required(),
        url: string().url().required(),
    }).noUnknown();
