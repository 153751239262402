import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates, ResonanceLocaleType } from "../../global";

import { DemandHqId, ProductId } from "./DemandIds";

/** IdFields are the ones that are required to find the correct entity. Must come in all update methods. */
export interface ProductLocaleIdFields {
    localeType: ResonanceLocaleType;
    productId: ProductId;
}

export interface ProductLocaleRelationshipFields {
    demandHqId: DemandHqId;
}

/**
 * This has a many to one relationship with Product, and provides localized language data for the appropriate fields.
 * There will often only be one of these, and it should be the one set in defaultLocale for the demandHq.
 */
export interface ProductLocale
    extends ProductLocaleIdFields,
        ProductLocaleRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /** Supports markdown */
    description?: string | null;
    /** Limited to 5 */
    featureBullets?:
        | [
              (string | undefined | null)?,
              (string | undefined | null)?,
              (string | undefined | null)?,
              (string | undefined | null)?,
              (string | undefined | null)?,
          ]
        | null;
}

export type ProductLocaleGql = ProductLocale;

export type ProductLocaleModelUpdateInput = ProductLocaleIdFields &
    Partial<
        Omit<
            ProductLocale,
            | keyof ProductLocaleIdFields
            | keyof ProductLocaleRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type ProductLocaleModelCreateInput = Omit<
    ProductLocale,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type ProductLocaleApiUpdateInput = ProductLocaleModelUpdateInput;

export type ProductLocaleApiCreateInput = ProductLocaleModelCreateInput;

export type ProductLocaleInCompositeApiCreateInput = Omit<
    ProductLocaleApiCreateInput,
    "productId" | keyof ProductLocaleRelationshipFields
>;
