import { ResonanceUpdatedBy } from "../../../entityComposites";
import { ResonanceDates } from "../../../global";
import { DemandHqId } from "../DemandIds";

export const DEMAND_ASSOCIATION_SPECIAL_FALLBACK_VALUE = "#FALLBACK#";

export enum AssociationType {
    ProductTagComplements = "PRODUCT_TAG_COMPLEMENTS",
}

/**
 * Association Id are a composite of three fields for guaranteeing uniqueness.
 * These fields are immutable.
 */
export interface AssociationIdFields {
    /** Dictates what kind of association this is. */
    associationType: AssociationType;
    demandHqId: DemandHqId;
    /**
     * The source value is the value that is being associated with the targets.
     * i.e. For PRODUCT_TAG_COMPLEMENT, it might make sense to associate source "shoes" with "socks" and "shoelaces"
     *
     * Special Values:
     * - #FALLBACK# - This is a special value that is used to indicate that the association is a fallback association (given no other matches, do this).
     */
    sourceValue: string;
}

export interface Association
    extends AssociationIdFields,
        Pick<ResonanceDates, "updatedAt">,
        ResonanceUpdatedBy {
    /**
     * The target values are the values that are being associated with the source.
     *
     * This could be read as "Given a scenario of [associationType], [sourceValue] should associate with [targetValues]".
     */
    targetValues: string[];
}

export type AssociationModelUpsertInput = Omit<
    Association,
    keyof Pick<ResonanceDates, "updatedAt"> | keyof ResonanceUpdatedBy
>;

export type AssociationApiUpsertInput = AssociationModelUpsertInput;
