import { useResonanceApi } from "@buildresonance/resonance-lib-frontend-admin-auth-passwordless";
import { GqlApi, buildGqlEndpoint } from "@lib/common/domainNames";
import { ClientError } from "graphql-request";
import { UseInfiniteQueryResult, useInfiniteQuery } from "react-query";

import {
    ListDemandHqCommentsInfiniteQueryKey,
    ListDemandHqCommentsResponse,
    ListDemandHqCommentsVariables,
    buildListDemandHqCommentsInfiniteQueryKey,
    listDemandHqComments,
} from "./listDemandHqComments";

export const useListDemandHqComments = (
    variables: Partial<Omit<ListDemandHqCommentsVariables, "before">>,
): UseInfiniteQueryResult<ListDemandHqCommentsResponse, ClientError> => {
    const { gqlClient, withAuthInfiniteQueryOptions } = useResonanceApi({
        endpoint: buildGqlEndpoint(GqlApi.Demand),
    });

    return useInfiniteQuery<
        ListDemandHqCommentsResponse,
        ClientError,
        ListDemandHqCommentsResponse,
        ListDemandHqCommentsInfiniteQueryKey
    >(
        buildListDemandHqCommentsInfiniteQueryKey(
            variables as Omit<ListDemandHqCommentsVariables, "before">,
        ),
        listDemandHqComments(gqlClient),
        withAuthInfiniteQueryOptions({
            enabled: !!variables.demandHqId && !!variables.entityId,
            getNextPageParam: (lastPage) =>
                lastPage?.demandHq?.comments?.pageInfo?.hasPreviousPage
                    ? lastPage?.demandHq?.comments?.pageInfo?.startCursor
                    : undefined,
        }),
    );
};
