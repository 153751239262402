import {
    DemandHqId,
    EventEntityType,
    RelayPageInfo,
    ResonanceEvent,
} from "@buildresonance/resonance-lib-entities";
import { GraphQLClient, gql } from "graphql-request";
import { QueryFunction } from "react-query";

const DEMAND_HQ_EVENTS_QUERY = gql`
    query listDemandHqEvents(
        $demandHqId: ID!
        $itemType: String
        $entityId: ID!
        $before: String
    ) {
        events(
            demandHqId: $demandHqId
            itemType: $itemType
            entityId: $entityId
            last: 10
            before: $before
        ) {
            nodes {
                message {
                    metadata {
                        domain
                        domainEntityId
                        entityId
                        itemType
                        mutationType
                        identityId
                        status
                        syncedAt
                        updatedBy
                    }
                    data
                }
                messageId
                subject
                timestamp
            }
            pageInfo {
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export type ListDemandHqEventsResponse = {
    events: {
        nodes: ResonanceEvent[];
        pageInfo: Pick<RelayPageInfo, "startCursor" | "hasPreviousPage">;
    };
};

export type ListDemandHqEventsVariables = {
    demandHqId: DemandHqId;
    itemType?: EventEntityType | null;
    entityId: string;
    before?: string | null;
};

export type ListDemandHqEventsInfiniteQueryKey = [
    "listDemandHqEventsInfinite",
    Omit<ListDemandHqEventsVariables, "before">,
];

export const buildListDemandHqEventsInfiniteQueryKey = (
    variables: Omit<ListDemandHqEventsVariables, "before">,
): ListDemandHqEventsInfiniteQueryKey => [
    "listDemandHqEventsInfinite",
    variables,
];

export const listDemandHqEvents =
    (
        client: GraphQLClient,
    ): QueryFunction<
        ListDemandHqEventsResponse,
        ListDemandHqEventsInfiniteQueryKey
    > =>
    async ({ queryKey, pageParam }) => {
        const variables = queryKey[1];

        return await client.request<
            ListDemandHqEventsResponse,
            ListDemandHqEventsVariables
        >(DEMAND_HQ_EVENTS_QUERY, {
            ...variables,
            before: pageParam,
        });
    };
