import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { ContentItemId, ContentItemS3Key, ContentItemSourceType } from "../cdn";

import { DemandHqId, ProductId } from "./DemandIds";

export enum ProductContentType {
    File = "FILE",
    Image = "IMAGE",
    Video = "VIDEO",
}

/** IdFields are the ones that are required to find the correct entity. Must come in all update methods. */
export interface ProductContentItemIdFields {
    contentItemId: ContentItemId;
    productId: ProductId;
}

export interface ProductContentItemRelationshipFields {
    /** The key to the file in the CDN (S3) */
    contentKey: ContentItemS3Key;
    contentType: ProductContentType;
    demandHqId: DemandHqId;
    /** The original URL, or some kind of file pointer, based on sourceType */
    sourceDetail: string;
    /** How did we receive this content item? */
    sourceType: ContentItemSourceType;
}

/**
 * A relational lookup for content contained in the CDN.
 * Contains additional metadata specific to this product for the contentItem as well.
 */
export interface ProductContentItem
    extends ProductContentItemIdFields,
        ProductContentItemRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /**
     * Limit to 1000 characters.
     */
    alt?: string | null;
    /**
     * Indicates some kind of grouping of the content, i.e. Back View. Up to consumer, can be any string.
     *
     * Limit to 256 characters.
     */
    category?: string | null;
    /**
     * Used to determine what order the content comes out in.
     * Positive Integers are allowed, up to 9,999,999,999, and it does not have to be unique or sequential.
     */
    position?: number | null;
    /**
     * This is another field in frontends, similar to alt, but for the title.
     *
     * Limit to 256 characters.
     */
    title?: string | null;
}

export type ProductContentItemGql = ProductContentItem;

export type ProductContentItemModelUpdateInput = ProductContentItemIdFields &
    Partial<
        Omit<
            ProductContentItem,
            | keyof ProductContentItemIdFields
            | keyof ProductContentItemRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type ProductContentItemModelCreateInput = Omit<
    ProductContentItem,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type ProductContentItemCommonApiCreateInput = Pick<
    ProductContentItem,
    "alt" | "category" | "position" | "demandHqId" | "productId"
>;

export interface ProductImageFromUrlApiInput
    extends ProductContentItemCommonApiCreateInput {
    /**
     * The URL of the image to upload.
     *
     * Should match url structure, including https://, and should be a valid image.
     */
    url: string;
}

export interface ProductImageFromFileApiInput
    extends ProductContentItemCommonApiCreateInput {
    /**
     * Limit to 1000 characters.
     */
    fileName: string;
    /**
     * Limit to 100 characters (although, that would be a weird file extension).
     */
    fileExtension: string;
}

export type ProductContentItemApiUpdateInput =
    ProductContentItemModelUpdateInput;

/** For associating an existing content item with a new product */
export type ProductContentItemAssociationApiCreateInput =
    ProductContentItemCommonApiCreateInput &
        Pick<ProductContentItem, "contentItemId">;

export type ProductContentItemAssociationInCompositeApiCreateInput = Omit<
    ProductContentItemAssociationApiCreateInput,
    "productId" | keyof ProductContentItemRelationshipFields
>;

export type ProductImageFromUrlInCompositeApiInput = Omit<
    ProductImageFromUrlApiInput,
    "productId" | keyof ProductContentItemRelationshipFields
> & {
    /** Temporary ID, can be any string, to associate Variants with this Image. On create, the real contentItemId will be substituted. */
    contentItemInputId?: string | null;
};
