import {
    ALL_STOREFRONT_SCOPES,
    StorefrontApiCreateInput,
    StorefrontApiUpdateInput,
    StorefrontAuthenticationType,
    StorefrontCognitoAuthenticationSettings,
    StorefrontIdentity,
    StorefrontJwksAuthenticationSettings,
    StorefrontPermissions,
    StorefrontRole,
} from "@buildresonance/resonance-lib-entities";
import { array, object, ObjectSchema, string } from "yup";

import { requiredOnlyWhenExistsObjectTransformer } from "../../objectHelpers";
import {
    SLUG_REGEX,
    SLUG_REGEX_VALIDATION_MESSAGE,
    transformBlankStringToNull,
} from "../../stringHelpers";
import { demandHqIdSchema, storefrontIdSchema } from "../demandIds";

export const STOREFRONT_ISSUER_MAX_LENGTH = 1024;
export const STOREFRONT_URL_MAX_LENGTH = 1024;

export const storefrontCognitoAuthenticationSettingsSchema: ObjectSchema<StorefrontCognitoAuthenticationSettings> =
    object({
        issuer: string().required().max(STOREFRONT_ISSUER_MAX_LENGTH),
    }).noUnknown();

export const storefrontJwksAuthenticationSettingsSchema: ObjectSchema<StorefrontJwksAuthenticationSettings> =
    object({
        externalCustomerIdFieldName: string().required(),
        issuer: string().required().max(STOREFRONT_ISSUER_MAX_LENGTH),
        jwksUrl: string().url().max(STOREFRONT_URL_MAX_LENGTH).required(),
    });

export const storefrontAuthenticationSettingsSchema =
    storefrontCognitoAuthenticationSettingsSchema.when(
        "authenticationType",
        ([authenticationType], schema) => {
            if (authenticationType === StorefrontAuthenticationType.Cognito) {
                return schema.required();
            }
            if (authenticationType === StorefrontAuthenticationType.JWKS) {
                return storefrontJwksAuthenticationSettingsSchema.required();
            }
            return schema.nullable();
        },
    );

export const STOREFRONT_DESCRIPTION_MAX_LENGTH = 8000;
export const STOREFRONT_NAME_MAX_LENGTH = 256;
export const STOREFRONT_IDENTITY_SLUG_MAX_LENGTH = 32;
export const STOREFRONT_MAX_NUMBER_OF_IDENTITIES = 6;

export const storefrontPermissionsSchema: ObjectSchema<StorefrontPermissions> =
    object({
        roles: array()
            .of(
                string()
                    .transform(transformBlankStringToNull)
                    .oneOf(Object.values(StorefrontRole))
                    .required(),
            )
            .required(),
        scopes: array()
            .of(
                string()
                    .transform(transformBlankStringToNull)
                    .oneOf(ALL_STOREFRONT_SCOPES)
                    .required(),
            )
            .required(),
    }).noUnknown();

export const storefrontIdentitySchema: ObjectSchema<StorefrontIdentity> =
    object({
        slug: string()
            .required()
            .matches(SLUG_REGEX, SLUG_REGEX_VALIDATION_MESSAGE)
            .max(STOREFRONT_IDENTITY_SLUG_MAX_LENGTH),
        permissions: storefrontPermissionsSchema.required(),
    }).noUnknown();

export const storefrontApiCreateInputSchema: ObjectSchema<StorefrontApiCreateInput> =
    object({
        authenticationSettings: storefrontAuthenticationSettingsSchema
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        authenticationType: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(StorefrontAuthenticationType))
            .nullable(),
        demandHqId: demandHqIdSchema.required(),
        description: string().nullable().max(STOREFRONT_DESCRIPTION_MAX_LENGTH),
        name: string().required().max(STOREFRONT_NAME_MAX_LENGTH),
        storefrontIdentities: array()
            .of(storefrontIdentitySchema)
            .max(STOREFRONT_MAX_NUMBER_OF_IDENTITIES)
            .nullable(),
        urlDomain: string().lowercase().required(),
    }).noUnknown();

export const storefrontApiUpdateInputSchema: ObjectSchema<StorefrontApiUpdateInput> =
    object({
        authenticationSettings: storefrontAuthenticationSettingsSchema
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        authenticationType: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(StorefrontAuthenticationType))
            .nullable(),
        description: string().nullable().max(STOREFRONT_DESCRIPTION_MAX_LENGTH),
        name: string().max(STOREFRONT_NAME_MAX_LENGTH),
        storefrontId: storefrontIdSchema.required(),
        storefrontIdentities: array()
            .of(storefrontIdentitySchema)
            .max(STOREFRONT_MAX_NUMBER_OF_IDENTITIES)
            .nullable(),
        urlDomain: string().lowercase(),
    }).noUnknown();
