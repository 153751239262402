import {
    CdnEntityIdPrefix,
    ContentItemDomainEntityId,
    ContentItemId,
    DemandEntityIdPrefix,
    SupplyEntityIdPrefix,
} from "@buildresonance/resonance-lib-entities";
import { string } from "yup";

export const contentItemIdSchema = string<ContentItemId>().matches(
    new RegExp(`^${CdnEntityIdPrefix.ContentItem}.*`),
);

export const domainEntityIdSchema = string<ContentItemDomainEntityId>()
    .matches(
        new RegExp(
            `^(${DemandEntityIdPrefix.DemandHq}|${SupplyEntityIdPrefix.Supplier}).*`,
        ),
    )
    .required();
