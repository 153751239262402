import {
    OrderItemAdjustment,
    OrderItemAdjustmentReason,
    OrderItemAdjustmentType,
    OrderItemApiCreateInput,
    OrderItemApiUpdateInput,
    OrderItemDiscount,
    OrderShippingCharge,
    OrderItemTax,
    ResonanceCurrency,
    ResonanceShippingMethod,
    VariantDeliveryMethod,
    DiscountType,
    CheckoutOrderItemCommonCreateFields,
    CheckoutOrderItemCommonUpdateFields,
} from "@buildresonance/resonance-lib-entities";
import { array, number, object, ObjectSchema, string } from "yup";

import {
    VARIANT_BARCODE_MAX_LENGTH,
    VARIANT_SKU_MAX_LENGTH,
} from "../common/variantCommon";
import { dateTimeStringSchema } from "../dateHelpers";
import { customPropertiesSchema } from "../global";
import { transformNaNToNull } from "../numberHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import {
    demandHqIdSchema,
    orderIdSchema,
    orderItemIdSchema,
    productIdSchema,
    variantIdSchema,
} from "./demandIds";
import { DISCOUNT_CODE_MAX_LENGTH } from "./discount";
import { PRODUCT_TITLE_MAX_LENGTH, PRODUCT_VENDOR_MAX_LENGTH } from "./product";

export const ORDER_ITEM_MAX_NUMBER_OF_ADJUSTMENTS = 15;
export const ORDER_ITEM_MAX_NUMBER_OF_DISCOUNTS = 15;
export const ORDER_ITEM_NOTE_MAX_LENGTH = 512;
export const ORDER_SHIPPING_CHARGE_TITLE_MAX_LENGTH = 64;
export const ORDER_ITEM_TAX_TITLE_MAX_LENGTH = 64;
export const ORDER_ITEM_VARIANT_TITLE_MAX_LENGTH = 256;

export const orderItemAdjustmentSchema: ObjectSchema<OrderItemAdjustment> =
    object({
        adjustedAt: dateTimeStringSchema.required(),
        adjustmentAmount: number().required().transform(transformNaNToNull),
        adjustmentReason: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(OrderItemAdjustmentReason))
            .required(),
        adjustmentType: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(OrderItemAdjustmentType))
            .required(),
    }).noUnknown();

export const orderItemDiscountSchema: ObjectSchema<OrderItemDiscount> = object({
    code: string().required().max(DISCOUNT_CODE_MAX_LENGTH),
    discountAmount: number().required().transform(transformNaNToNull),
    discountedAt: dateTimeStringSchema.required(),
    discountType: string()
        .transform(transformBlankStringToNull)
        .oneOf(Object.values(DiscountType))
        .required(),
}).noUnknown();

export const orderShippingChargeSchema: ObjectSchema<OrderShippingCharge> =
    object({
        amount: number().min(0).required().transform(transformNaNToNull),
        title: string().nullable().max(ORDER_SHIPPING_CHARGE_TITLE_MAX_LENGTH),
    }).noUnknown();

export const orderItemTaxSchema: ObjectSchema<OrderItemTax> = object({
    amount: number().min(0).required().transform(transformNaNToNull),
    rate: number().min(0).required().transform(transformNaNToNull),
    title: string().required().max(ORDER_ITEM_TAX_TITLE_MAX_LENGTH),
}).noUnknown();

/** common schema/fields for both orderItem (demand) and checkoutOrderItem (storefront only) */
export const orderItemCreateInputCommonSchema: ObjectSchema<
    Pick<OrderItemApiCreateInput, CheckoutOrderItemCommonCreateFields>
> = object({
    currency: string()
        .transform(transformBlankStringToNull)
        .oneOf(Object.values(ResonanceCurrency))
        .required(),
    quantity: number().positive().required().transform(transformNaNToNull),
    unitPrice: number().positive().required().transform(transformNaNToNull),
    variantId: variantIdSchema.required(),
}).noUnknown();

export const orderItemApiCreateInputSchema: ObjectSchema<OrderItemApiCreateInput> =
    orderItemCreateInputCommonSchema
        .concat(
            object({
                adjustments: array()
                    .of(orderItemAdjustmentSchema)
                    .nullable()
                    .max(ORDER_ITEM_MAX_NUMBER_OF_ADJUSTMENTS),
                barcode: string().max(VARIANT_BARCODE_MAX_LENGTH).nullable(),
                currency: string()
                    .transform(transformBlankStringToNull)
                    .oneOf(Object.values(ResonanceCurrency))
                    .required(),
                customProperties: customPropertiesSchema.nullable(),
                deliveryMethod: string()
                    .transform(transformBlankStringToNull)
                    .oneOf(Object.values(VariantDeliveryMethod))
                    .required(),
                demandHqId: demandHqIdSchema.required(),
                discounts: array()
                    .of(orderItemDiscountSchema)
                    .nullable()
                    .max(ORDER_ITEM_MAX_NUMBER_OF_DISCOUNTS),
                note: string().nullable().max(ORDER_ITEM_NOTE_MAX_LENGTH),
                orderId: orderIdSchema.required(),
                productId: productIdSchema.required(),
                productTitle: string().required().max(PRODUCT_TITLE_MAX_LENGTH),
                shippingCharges: array()
                    .of(orderShippingChargeSchema.required())
                    .nullable(),
                shippingMethod: string()
                    .transform(transformBlankStringToNull)
                    .oneOf(Object.values(ResonanceShippingMethod))
                    .nullable(),
                sku: string().nullable().max(VARIANT_SKU_MAX_LENGTH),
                taxes: array().of(orderItemTaxSchema).nullable(),
                variantTitle: string()
                    .required()
                    .max(ORDER_ITEM_VARIANT_TITLE_MAX_LENGTH),
                vendor: string().required().max(PRODUCT_VENDOR_MAX_LENGTH),
            }),
        )
        .noUnknown();

/** common schema/fields for both orderItem (demand) and checkoutOrderItem (storefront only) */
export const orderItemUpdateInputCommonSchema: ObjectSchema<
    Pick<OrderItemApiUpdateInput, CheckoutOrderItemCommonUpdateFields>
> = object({
    customProperties: customPropertiesSchema.nullable(),
    shippingCharges: array().of(orderShippingChargeSchema).nullable(),
    shippingMethod: string()
        .transform(transformBlankStringToNull)
        .oneOf(Object.values(ResonanceShippingMethod))
        .nullable(),
    taxes: array().of(orderItemTaxSchema).nullable(),
}).noUnknown();

export const orderItemApiUpdateInputSchema: ObjectSchema<OrderItemApiUpdateInput> =
    orderItemUpdateInputCommonSchema
        .concat(
            object({
                adjustments: array()
                    .of(orderItemAdjustmentSchema)
                    .nullable()
                    .max(ORDER_ITEM_MAX_NUMBER_OF_ADJUSTMENTS),
                discounts: array()
                    .of(orderItemDiscountSchema)
                    .nullable()
                    .max(ORDER_ITEM_MAX_NUMBER_OF_DISCOUNTS),
                note: string().nullable().max(ORDER_ITEM_NOTE_MAX_LENGTH),
                orderItemId: orderItemIdSchema.required(),
            }),
        )
        .noUnknown();
