import { useEffect } from "react";

import type { UseInfiniteQueryResult } from "react-query";

const useFetchNextPage = <TQueryResult, TError>(
    page: number,
    queryResponse: Pick<
        UseInfiniteQueryResult<TQueryResult, TError>,
        "data" | "fetchNextPage" | "hasNextPage" | "isFetching" | "status"
    >,
): void => {
    useEffect(() => {
        if (
            queryResponse.status === "success" &&
            !queryResponse.isFetching &&
            queryResponse.hasNextPage &&
            queryResponse?.data?.pages &&
            queryResponse?.data?.pages.length < page
        ) {
            void queryResponse.fetchNextPage();
        }
    }, [page, queryResponse]);
};

export default useFetchNextPage;
