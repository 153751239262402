import { ResonanceUpdatedBy } from "../../../entityComposites";
import { ResonanceDates } from "../../../global";
import { DemandHqId } from "../DemandIds";

export enum ProductDataRuleConditionType {
    TitleContains = "TITLE_CONTAINS",
    VendorEquals = "VENDOR_EQUALS",
}

export enum ProductDataRuleActionType {
    AddTag = "ADD_TAG",
    SetVendor = "SET_VENDOR",
}

export interface ProductDataRuleIdFields {
    demandHqId: DemandHqId;
    conditionType: ProductDataRuleConditionType;
    conditionValue: string;
}

export interface ProductDataRuleAction {
    actionType: ProductDataRuleActionType;
    actionValue: string;
}

export interface ProductDataRule
    extends ProductDataRuleIdFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    actions: ProductDataRuleAction[];
}

export type ProductDataRuleModelCreateInput = Omit<
    ProductDataRule,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type ProductDataRuleModelUpdateInput = ProductDataRuleModelCreateInput;

export type ProductDataRuleApiCreateInput = ProductDataRuleModelCreateInput;

export type ProductDataRuleApiUpdateInput = ProductDataRuleModelUpdateInput;
