import { DemandHqCommentApiCreateInput } from "@buildresonance/resonance-lib-entities";
import {
    DEMAND_HQ_COMMENT_FRAGMENT,
    DemandHqCommentGqlFragment,
} from "@hooks/graphql/fragments/demandHqComment";
import { GraphQLClient, gql } from "graphql-request";

const CREATE_DEMAND_HQ_COMMENT_MUTATION = gql`
    ${DEMAND_HQ_COMMENT_FRAGMENT}
    mutation createDemandHqComment($input: DemandHqCommentCreateInput!) {
        createDemandHqComment(input: $input) {
            ...demandHqCommentFragment
        }
    }
`;

export type CreateDemandHqCommentResponse = {
    createDemandHqComment: DemandHqCommentGqlFragment;
};

export type CreateDemandHqCommentVariables = {
    input: DemandHqCommentApiCreateInput;
};

export const createDemandHqComment =
    (client: GraphQLClient) =>
    async (variables: CreateDemandHqCommentVariables) => {
        return await client.request<
            CreateDemandHqCommentResponse,
            CreateDemandHqCommentVariables
        >(CREATE_DEMAND_HQ_COMMENT_MUTATION, variables);
    };
