import {
    SupplierNotificationApiCreateInput,
    SupplierNotificationCategory,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, object, string } from "yup";

import { notificationCommonApiCreateInputSchema } from "../common/notificationSchema";

import { supplierIdSchema } from "./supplyIds";

export const supplierNotificationApiCreateInputSchema: ObjectSchema<SupplierNotificationApiCreateInput> =
    notificationCommonApiCreateInputSchema
        .concat(
            object({
                category: string()
                    .oneOf(Object.values(SupplierNotificationCategory))
                    .required(),
                supplierId: supplierIdSchema.required(),
            }),
        )
        .noUnknown();
