import {
    VariantApiCreateInput,
    VariantApiUpdateInput,
    VariantInCompositeApiCreateInput,
} from "@buildresonance/resonance-lib-entities";
import {
    AnyObject,
    array,
    number,
    object,
    ObjectSchema,
    string,
    TupleSchema,
} from "yup";

import { contentItemIdSchema } from "../cdn/cdnIds";
import { variantCommonSchema } from "../common/variantCommon";
import { transformNaNToNull, transformZeroToNull } from "../numberHelpers";

import {
    demandHqIdSchema,
    productIdSchema,
    variantIdSchema,
} from "./demandIds";

export const VARIANT_OPTION_VALUE_MAX_LENGTH = 128;
export const VARIANT_MAX_NUMBER_OF_OPTION_VALUES = 3;
export const VARIANT_POSITION_MAX_VALUE = 9999999999;

export const variantInCompositeApiCreateInputSchema: ObjectSchema<VariantInCompositeApiCreateInput> =
    variantCommonSchema
        .concat(
            object({
                contentItemIds: array()
                    .of(contentItemIdSchema.required())
                    .nullable(),
                optionValues: array()
                    .of(string().max(VARIANT_OPTION_VALUE_MAX_LENGTH))
                    .required()
                    .max(VARIANT_MAX_NUMBER_OF_OPTION_VALUES)
                    .test({
                        name: "firstRequired",
                        message: "At least one option value is required",
                        test: (values: (string | undefined)[] | undefined) =>
                            !values || (values.length > 0 && !!values[0]),
                    }) as unknown as TupleSchema<
                    [string, string?, string?],
                    AnyObject,
                    undefined
                >,
                position: number()
                    .integer()
                    .positive()
                    .nullable()
                    .max(VARIANT_POSITION_MAX_VALUE)
                    .transform(transformNaNToNull)
                    .transform(transformZeroToNull),
                price: number().nullable().positive(),
            }),
        )
        .noUnknown();

export const variantApiCreateInputSchema: ObjectSchema<VariantApiCreateInput> =
    variantInCompositeApiCreateInputSchema
        .concat(
            object({
                demandHqId: demandHqIdSchema.required(),
                productId: productIdSchema.required(),
            }),
        )
        .noUnknown();

export const variantApiUpdateInputSchema: ObjectSchema<VariantApiUpdateInput> =
    variantCommonSchema
        .concat(
            object({
                contentItemIds: array()
                    .of(contentItemIdSchema.required())
                    .nullable(),
                optionValues: array()
                    .of(string().max(VARIANT_OPTION_VALUE_MAX_LENGTH))
                    .nullable()
                    .max(VARIANT_MAX_NUMBER_OF_OPTION_VALUES)
                    .test({
                        name: "firstRequired",
                        message: "At least one option value is required",
                        test: (
                            values: (string | undefined)[] | null | undefined,
                        ) => !values || (values.length > 0 && !!values[0]),
                    }) as unknown as TupleSchema<
                    [string, string?, string?],
                    AnyObject,
                    undefined
                >,
                position: number()
                    .integer()
                    .positive()
                    .nullable()
                    .max(VARIANT_POSITION_MAX_VALUE)
                    .transform(transformNaNToNull)
                    .transform(transformZeroToNull),
                price: number()
                    .positive()
                    .nullable()
                    .transform(transformNaNToNull),
                variantId: variantIdSchema.required(),
            }),
        )
        .noUnknown();
