import { useFetchNextPage } from "@buildresonance/global-lib-frontend-custom-hooks";
import { useResonanceApi } from "@buildresonance/resonance-lib-frontend-admin-auth-passwordless";
import { GqlApi, buildGqlEndpoint } from "@lib/common/domainNames";
import { ClientError } from "graphql-request";
import ms from "ms";
import {
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useInfiniteQuery,
} from "react-query";

import {
    ListDemandHqNotificationsInfiniteQueryKey,
    ListDemandHqNotificationsResponse,
    ListDemandHqNotificationsVariables,
    buildListDemandHqNotificationsInfiniteQueryKey,
    listDemandHqNotifications,
} from "./listDemandHqNotifications";

export const useDemandHqNotifications = (
    page: number,
    variables: Partial<ListDemandHqNotificationsVariables>,
    options?: UseInfiniteQueryOptions<
        ListDemandHqNotificationsResponse,
        ClientError,
        ListDemandHqNotificationsResponse,
        ListDemandHqNotificationsResponse,
        ListDemandHqNotificationsInfiniteQueryKey
    >,
): UseInfiniteQueryResult<ListDemandHqNotificationsResponse, ClientError> => {
    const { gqlClient, withAuthInfiniteQueryOptions } = useResonanceApi({
        endpoint: buildGqlEndpoint(GqlApi.Demand),
    });

    const result = useInfiniteQuery<
        ListDemandHqNotificationsResponse,
        ClientError,
        ListDemandHqNotificationsResponse,
        ListDemandHqNotificationsInfiniteQueryKey
    >(
        buildListDemandHqNotificationsInfiniteQueryKey(
            variables as ListDemandHqNotificationsVariables,
        ),
        listDemandHqNotifications(gqlClient),
        withAuthInfiniteQueryOptions({
            ...options,
            enabled:
                (options?.enabled ?? true) &&
                !!variables &&
                !!variables.demandHqId &&
                !!variables.status &&
                !!variables.last,
            getNextPageParam: (data) =>
                data?.demandHq?.notifications?.pageInfo?.hasPreviousPage
                    ? data?.demandHq?.notifications?.pageInfo?.startCursor
                    : undefined,
            staleTime: options?.staleTime ?? ms("15 sec"),
        }),
    );

    useFetchNextPage(page, result);

    return result;
};
