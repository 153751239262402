import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceCurrency, ResonanceDates } from "../../global";

import { DemandHqId, OrderId, OrderTransactionId } from "./DemandIds";

export interface OrderTransactionCurrencyExchangeAdjustment {
    targetAmount: number;
    targetCurrency: ResonanceCurrency;
}

/** The payment gateway associated with the given OrderTransaction */
export enum OrderTransactionGateway {
    AuthorizeNet = "AUTHORIZE_NET",
    Manual = "MANUAL",
    Test = "TEST",
}

/** The purpose of a given OrderTransaction */
export enum OrderTransactionType {
    /**
     * Money that the customer has agreed to pay.
     * The authorization period can be between 7 and 30 days (depending on your payment service) while a store waits for a payment to be captured.
     */
    Authorization = "AUTHORIZATION",
    /** A transfer of money that was reserved during the authorization of a shop. */
    Capture = "CAPTURE",
    /** The partial or full return of captured money to the customer. */
    Refund = "REFUND",
    /** The authorization and capture of a payment performed in one single step. */
    Sale = "SALE",
    /** The cancellation of a pending authorization or capture. */
    Void = "VOID",
}

export interface OrderTransactionIdFields {
    orderTransactionId: OrderTransactionId;
}

export interface OrderTransactionRelationshipFields {
    demandHqId: DemandHqId;
    orderId: OrderId;
}

export interface OrderTransactionDataFields {
    amount: number;
    authorizationCode?: string | null;
    authorizationExpiresAt?: string | null;
    currency: ResonanceCurrency;
    currencyExchangeAdjustment?: OrderTransactionCurrencyExchangeAdjustment | null;
    errorMessage?: string | null;
    gateway: OrderTransactionGateway;
    gatewayMessage?: string | null;
    gatewayTransactionId?: string | null;
    orderTransactionType: OrderTransactionType;
    processedAt?: string | null;
}

/**
 * An OrderTransaction is a financial interaction for an Order. All fields are immutable.
 * Most commonly the flow is Authorization (on creation of order) -> Capture (on shipment of order).
 * In refund scenarios, the flow may also include Refund or Void.
 */
export interface OrderTransaction
    extends OrderTransactionDataFields,
        OrderTransactionIdFields,
        OrderTransactionRelationshipFields,
        Pick<ResonanceDates, "createdAt">,
        ResonanceUpdatedBy {}

export type OrderTransactionModelCreateInput = Omit<
    OrderTransaction,
    | keyof OrderTransactionIdFields
    | keyof Pick<ResonanceDates, "createdAt">
    | keyof ResonanceUpdatedBy
>;

export type OrderTransactionApiCreateInput = OrderTransactionModelCreateInput;

// Immutable (No updates or deletes)
