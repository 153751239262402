import {
    SupplierProductApiCreateInput,
    SupplierProductApiUpdateInput,
    SupplyEntityStatus,
    SupplierProductCompositeApiCreateInput,
    SupplierVariantCompositeInCompositeApiCreateInput,
} from "@buildresonance/resonance-lib-entities";
import {
    AnyObject,
    array,
    object,
    ObjectSchema,
    string,
    TupleSchema,
} from "yup";

import { CONTENT_ITEM_INPUT_ID_MAX_LENGTH } from "../common/contentItem";
import { tagArraySchema } from "../common/tags";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

import {
    supplierProductContentItemAssociationInCompositeApiCreateInputSchema,
    supplierProductImageFromUrlInCompositeApiInputSchema,
} from "./supplierProductContentItem";
import { supplierProductLocaleInCompositeApiCreateInputSchema } from "./supplierProductLocale";
import { supplierVariantInCompositeApiCreateInputSchema } from "./supplierVariant";
import { supplierVariantSupplementInCompositeApiCreateInputSchema } from "./supplierVariantSupplement";
import { supplierIdSchema, supplierProductIdSchema } from "./supplyIds";

export const SUPPLIER_PRODUCT_EXTERNAL_PRODUCT_ID_MAX_LENGTH = 128;
export const SUPPLIER_PRODUCT_OPTION_LABEL_MAX_LENGTH = 64;
export const SUPPLIER_PRODUCT_MAX_NUMBER_OF_OPTION_LABELS = 3;
export const SUPPLIER_PRODUCT_TITLE_MAX_LENGTH = 256;
export const SUPPLIER_PRODUCT_VENDOR_MAX_LENGTH = 256;

export const SUPPLIER_PRODUCT_MAX_NUMBER_OF_VARIANTS = 1000;

export const supplierProductApiCreateInputSchema: ObjectSchema<SupplierProductApiCreateInput> =
    object({
        externalProductId: string()
            .required()
            .max(SUPPLIER_PRODUCT_EXTERNAL_PRODUCT_ID_MAX_LENGTH),
        optionLabels: array()
            .of(string().max(SUPPLIER_PRODUCT_OPTION_LABEL_MAX_LENGTH))
            .required()
            .max(SUPPLIER_PRODUCT_MAX_NUMBER_OF_OPTION_LABELS)
            .test({
                name: "firstRequired",
                message: "At least one option label is required",
                test: (values: (string | undefined)[] | undefined) =>
                    !values || (values.length > 0 && !!values[0]),
            }) as unknown as TupleSchema<
            [string, string?, string?],
            AnyObject,
            undefined
        >,
        supplierId: supplierIdSchema.required(),
        tags: tagArraySchema.nullable(),
        title: string().max(SUPPLIER_PRODUCT_TITLE_MAX_LENGTH).required(),
        vendor: string().max(SUPPLIER_PRODUCT_VENDOR_MAX_LENGTH).required(),
    }).noUnknown();

export const supplierVariantCompositeInCompositeApiCreateInputSchema: ObjectSchema<SupplierVariantCompositeInCompositeApiCreateInput> =
    object({
        supplierVariantInput: supplierVariantInCompositeApiCreateInputSchema
            .omit(["contentItemIds"])
            .concat(
                object({
                    contentItemInputIds: array()
                        .of(
                            string()
                                .required()
                                .max(CONTENT_ITEM_INPUT_ID_MAX_LENGTH),
                        )
                        .nullable(),
                }),
            )
            .required()
            .noUnknown(),
        supplierVariantSupplementInput:
            supplierVariantSupplementInCompositeApiCreateInputSchema
                .nullable()
                .transform(requiredOnlyWhenExistsObjectTransformer)
                .default(undefined),
    }).noUnknown();

export const supplierProductCompositeApiCreateInputSchema: ObjectSchema<SupplierProductCompositeApiCreateInput> =
    object({
        supplierProductInput: supplierProductApiCreateInputSchema.required(),
        supplierProductContentItemAssociationInputs: array()
            .of(
                supplierProductContentItemAssociationInCompositeApiCreateInputSchema,
            )
            .nullable(),
        supplierProductImageFromUrlInputs: array()
            .of(supplierProductImageFromUrlInCompositeApiInputSchema)
            .nullable(),
        supplierProductLocaleInputs: array()
            .of(supplierProductLocaleInCompositeApiCreateInputSchema)
            .nullable(),
        supplierVariantInputs: array()
            .of(supplierVariantCompositeInCompositeApiCreateInputSchema)
            .max(SUPPLIER_PRODUCT_MAX_NUMBER_OF_VARIANTS)
            .nullable(),
    }).noUnknown();

export const supplierProductApiUpdateInputSchema: ObjectSchema<SupplierProductApiUpdateInput> =
    object({
        externalProductId: string().max(
            SUPPLIER_PRODUCT_EXTERNAL_PRODUCT_ID_MAX_LENGTH,
        ),
        optionLabels: array()
            .of(string().max(SUPPLIER_PRODUCT_OPTION_LABEL_MAX_LENGTH))
            .max(SUPPLIER_PRODUCT_MAX_NUMBER_OF_OPTION_LABELS)
            .test({
                name: "firstRequired",
                message: "At least one option label is required",
                test: (values: (string | undefined)[] | undefined) =>
                    !values || (values.length > 0 && !!values[0]),
            }) as unknown as TupleSchema<
            [string, string?, string?],
            AnyObject,
            undefined
        >,
        status: string()
            .transform(transformBlankStringToNull)
            .oneOf([...Object.values(SupplyEntityStatus), undefined]),
        supplierProductId: supplierProductIdSchema.required(),
        tags: tagArraySchema.nullable(),
        title: string().max(SUPPLIER_PRODUCT_TITLE_MAX_LENGTH),
        vendor: string().max(SUPPLIER_PRODUCT_VENDOR_MAX_LENGTH),
    }).noUnknown();
