export enum DemandSubscriptionCode {
    BETAMAX = "BETAMAX",
}

export interface DemandSubscription {
    code: DemandSubscriptionCode;
    cost: number;
    description: string;
}

export const BETAMAX_SUBSCRIPTION: DemandSubscription = {
    code: DemandSubscriptionCode.BETAMAX,
    cost: 0,
    description:
        "This is a beta subscription, available by invite only, and provides maximum access to features.",
};

export const ALL_DEMAND_SUBSCRIPTIONS: DemandSubscription[] = [
    BETAMAX_SUBSCRIPTION,
];
