import {
    CollectionImageFromFileApiCreateInput,
    CollectionImageFromUrlApiCreateInput,
    CollectionApiCreateInput,
    CollectionApiUpdateInput,
    CollectionContentApiUpsertInput,
    DemandEntityStatus,
    CollectionRuleField,
    CollectionRuleOperator,
    COLLECTION_POSITION_MAX_VALUE,
} from "@buildresonance/resonance-lib-entities";
import { array, number, object, ObjectSchema, string } from "yup";

import { contentItemIdSchema } from "../cdn/cdnIds";
import { seoFieldsSchema, slugSchema } from "../common/seo";
import { TAGS_MAX_LENGTH, tagArraySchema } from "../common/tags";
import { dateTimeStringSchema } from "../dateHelpers";
import { transformNaNToNull, transformZeroToNull } from "../numberHelpers";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";
import {
    SLUG_REGEX,
    SLUG_REGEX_VALIDATION_MESSAGE,
    transformBlankStringToNull,
} from "../stringHelpers";

import {
    demandHqIdSchema,
    collectionIdSchema,
    storefrontIdSchema,
    productIdSchema,
} from "./demandIds";

export const COLLECTION_AUTHOR_MAX_LENGTH = 256;
export const COLLECTION_MAX_NUMBER_OF_CONTENT_ITEMS = 100;
export const COLLECTION_DESCRIPTION_MAX_LENGTH = 1000;
export const COLLECTION_MAX_NUMBER_OF_FEATURED_PRODUCT_IDS = 20;
export const COLLECTION_MAX_NUMBER_OF_PRODUCT_TAG_PREFIXES = 10;
export const COLLECTION_TYPE_MAX_LENGTH = 128;
export const COLLECTION_IMAGE_URL_MAX_LENGTH = 1028;
export const COLLECTION_MAX_NUMBER_OF_RULES_PER_RULE_TYPE = 10;
export const COLLECTION_RULE_VALUE_MAX_LENGTH = 256;
export const COLLECTION_TITLE_MAX_LENGTH = 256;

export const COLLECTION_CONTENT_MAX_LENGTH = 10000;

export const collectionContentAsStringSchema = string().max(
    COLLECTION_CONTENT_MAX_LENGTH,
);
export const collectionTypeSchema = string()
    .matches(SLUG_REGEX, SLUG_REGEX_VALIDATION_MESSAGE)
    .max(COLLECTION_TYPE_MAX_LENGTH);

export const collectionContentApiUpsertInputSchema: ObjectSchema<CollectionContentApiUpsertInput> =
    object({
        contentAsString: collectionContentAsStringSchema.required(),
        secondaryContentAsString: collectionContentAsStringSchema.nullable(),
        collectionId: collectionIdSchema.required(),
    }).noUnknown();

export const collectionProductTagPrefixSchema = object({
    category: string().nullable().max(TAGS_MAX_LENGTH),
    key: string().required().max(TAGS_MAX_LENGTH),
    position: number()
        .integer()
        .positive()
        .nullable()
        .max(COLLECTION_POSITION_MAX_VALUE)
        .transform(transformNaNToNull)
        .transform(transformZeroToNull),
});

export const collectionRuleSchema = object({
    field: string()
        .transform(transformBlankStringToNull)
        .oneOf(Object.values(CollectionRuleField))
        .required(),
    operator: string()
        .transform(transformBlankStringToNull)
        .oneOf(Object.values(CollectionRuleOperator))
        .required(),
    value: string()
        .transform(transformBlankStringToNull)
        .required()
        .max(COLLECTION_RULE_VALUE_MAX_LENGTH),
})
    .noUnknown()
    .transform(requiredOnlyWhenExistsObjectTransformer)
    .default(undefined);

export const collectionApiCreateInputSchema: ObjectSchema<CollectionApiCreateInput> =
    object({
        collectionType: collectionTypeSchema.required(),
        contentItemIds: array()
            .of(contentItemIdSchema.required())
            .max(COLLECTION_MAX_NUMBER_OF_CONTENT_ITEMS)
            .nullable(),
        demandHqId: demandHqIdSchema.required(),
        description: string().nullable().max(COLLECTION_DESCRIPTION_MAX_LENGTH),
        featuredProductIds: array()
            .of(productIdSchema.required())
            .max(COLLECTION_MAX_NUMBER_OF_FEATURED_PRODUCT_IDS)
            .nullable(),
        productTagPrefixes: array()
            .of(collectionProductTagPrefixSchema)
            .nullable(),
        primaryImageUrl: string()
            .nullable()
            .max(COLLECTION_IMAGE_URL_MAX_LENGTH),
        rules: object({
            and: array()
                .of(collectionRuleSchema)
                .max(COLLECTION_MAX_NUMBER_OF_RULES_PER_RULE_TYPE)
                .nullable(),
            any: array()
                .of(collectionRuleSchema)
                .max(COLLECTION_MAX_NUMBER_OF_RULES_PER_RULE_TYPE)
                .nullable(),
            not: array()
                .of(collectionRuleSchema)
                .max(COLLECTION_MAX_NUMBER_OF_RULES_PER_RULE_TYPE)
                .nullable(),
        })
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        secondaryImageUrl: string()
            .nullable()
            .max(COLLECTION_IMAGE_URL_MAX_LENGTH),
        slug: slugSchema.required(),
        storefrontIds: array().of(storefrontIdSchema.required()).nullable(),
        subtitle: string().nullable().max(COLLECTION_TITLE_MAX_LENGTH),
        tags: tagArraySchema.nullable(),
        tertiaryImageUrl: string()
            .nullable()
            .max(COLLECTION_IMAGE_URL_MAX_LENGTH),
        title: string().required().max(COLLECTION_TITLE_MAX_LENGTH),
    })
        .concat(seoFieldsSchema)
        .noUnknown();

export const collectionApiUpdateInputSchema: ObjectSchema<CollectionApiUpdateInput> =
    object({
        collectionId: collectionIdSchema.required(),
        collectionType: collectionTypeSchema,
        contentItemIds: array()
            .of(contentItemIdSchema.required())
            .max(COLLECTION_MAX_NUMBER_OF_CONTENT_ITEMS)
            .nullable(),
        description: string().nullable().max(COLLECTION_DESCRIPTION_MAX_LENGTH),
        featuredProductIds: array()
            .of(productIdSchema.required())
            .max(COLLECTION_MAX_NUMBER_OF_FEATURED_PRODUCT_IDS)
            .nullable(),
        productTagPrefixes: array()
            .of(collectionProductTagPrefixSchema)
            .nullable(),
        primaryImageUrl: string()
            .nullable()
            .max(COLLECTION_IMAGE_URL_MAX_LENGTH),
        publishedAt: dateTimeStringSchema.nullable(),
        rules: object({
            and: array()
                .of(collectionRuleSchema)
                .max(COLLECTION_MAX_NUMBER_OF_RULES_PER_RULE_TYPE)
                .nullable(),
            any: array()
                .of(collectionRuleSchema)
                .max(COLLECTION_MAX_NUMBER_OF_RULES_PER_RULE_TYPE)
                .nullable(),
            not: array()
                .of(collectionRuleSchema)
                .max(COLLECTION_MAX_NUMBER_OF_RULES_PER_RULE_TYPE)
                .nullable(),
        })
            .nullable()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        secondaryImageUrl: string()
            .nullable()
            .max(COLLECTION_IMAGE_URL_MAX_LENGTH),
        slug: slugSchema,
        status: string()
            .transform(transformBlankStringToNull)
            .oneOf(Object.values(DemandEntityStatus)),
        storefrontIds: array().of(storefrontIdSchema.required()).nullable(),
        subtitle: string().nullable().max(COLLECTION_TITLE_MAX_LENGTH),
        tags: tagArraySchema.nullable(),
        tertiaryImageUrl: string()
            .nullable()
            .max(COLLECTION_IMAGE_URL_MAX_LENGTH),
        title: string().max(COLLECTION_TITLE_MAX_LENGTH),
    })
        .concat(seoFieldsSchema)
        .noUnknown();

export const COLLECTION_IMAGE_FILENAME_MAX_LENGTH = 1000;
export const COLLECTION_IMAGE_FILE_EXTENSION_MAX_LENGTH = 100;

export const collectionImageFromFileApiCreateInputSchema: ObjectSchema<CollectionImageFromFileApiCreateInput> =
    object({
        demandHqId: demandHqIdSchema.required(),
        fileName: string().max(COLLECTION_IMAGE_FILENAME_MAX_LENGTH).required(),
        fileExtension: string()
            .max(COLLECTION_IMAGE_FILE_EXTENSION_MAX_LENGTH)
            .required(),
        collectionId: collectionIdSchema.required(),
    }).noUnknown();

export const collectionImageFromUrlApiCreateInputSchema: ObjectSchema<CollectionImageFromUrlApiCreateInput> =
    object({
        demandHqId: demandHqIdSchema.required(),
        collectionId: collectionIdSchema.required(),
        url: string().url().required(),
    }).noUnknown();
