import { ResonanceDates } from "../../global";

import { UserName, UserNotificationId } from "./AdminIdentityIds";

import { AdminIdentityIdPrefix } from ".";

export enum NotificationExternalDeliveryType {
    Email = "EMAIL",
    SMS = "SMS",
}

export type UserNotificationExternalDeliveryId =
    `${AdminIdentityIdPrefix.UserNotificationExternalDelivery}-${string}`;

export interface UserNotificationExternalDeliveryIdFields {
    userNotificationExternalDeliveryId: UserNotificationExternalDeliveryId;
}

/**
 * This should be an immutable entity, only being written once, and used rarely.
 * Mostly a log of sorts for what went out externally, and will expire in time.
 *
 * There is no updatedBy here because it will always be a job lambda and this will not be exposed via API.
 * We will likely still throw events surrounding these though.
 */
export interface UserNotificationExternalDelivery
    extends UserNotificationExternalDeliveryIdFields,
        ResonanceDates {
    expiresAt?: number;
    externalDeliveryType: NotificationExternalDeliveryType;
    /** This is the specific place a delivery went, like say a phone number or email address */
    target: string;
    userName: UserName;
    userNotificationId: UserNotificationId;
}

export type UserNotificationExternalDeliveryModelCreateInput = Omit<
    UserNotificationExternalDelivery,
    keyof UserNotificationExternalDeliveryIdFields | keyof ResonanceDates
>;
