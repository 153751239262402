import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { ContentItem, ContentItemId } from "../cdn";
import { InventoryStatus, VariantOptionValues } from "../common/VariantCommon";

import { PublicSupplierProductContentItemGql } from "./SupplierProductContentItem";
import {
    SupplierVariantSupplement,
    SupplierVariantSupplementInCompositeApiCreateInput,
} from "./SupplierVariantSupplement";
import { SupplierId, SupplierProductId, SupplierVariantId } from "./SupplyIds";

export enum SupplierVariantInventoryPolicy {
    /** If quantity > 0, instock, else outofstock */
    Default = "DEFAULT",
    /** instock no matter what */
    Bottomless = "BOTTOMLESS",
    /** outofstock no matter what */
    Hold = "HOLD",
}

/** IdFields are the ones that are required to find the correct entity. Must come in all update methods. */
export interface SupplierVariantIdFields {
    supplierVariantId: SupplierVariantId;
}

export interface SupplierVariantRelationshipFields {
    supplierId: SupplierId;
    supplierProductId: SupplierProductId;
}

/**
 * This is the child entity to a SupplierProduct, and is a variation, such as a color, size, etc.
 *
 * This item contains only the highest frequency updated and most indexable properties.
 * @see SupplierVariantSupplement for medium to low frequency updates.
 */
export interface SupplierVariant
    extends SupplierVariantIdFields,
        SupplierVariantRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /** ContentItemIds associated with this variant, use the first image as the variant image */
    contentItemIds?: ContentItemId[] | null;
    /**
     * Passed along in PurchaseOrders so the Supplier can identify items in their warehouse.
     */
    externalVariantId: string;
    /**
     * Must be an integer, though can be positive or negative.
     */
    inventoryQuantity: number;
    inventoryPolicy: SupplierVariantInventoryPolicy;
    /**
     * Values for the optionLabels in the parent SupplierProduct.
     * For label "Size", this might be "Small", "Medium", "Large", etc.
     *
     * Set the first optionValue to "No Option" if you don't want to use options for this product and this is the only variant.
     * @see https://docs.buildresonance.com/concepts/products/#no-option-products for more info.
     *
     * Each optionValue has a 128 character limit.
     */
    optionValues: VariantOptionValues;
}

export type SupplierVariantExternalIdFields = Pick<
    SupplierVariant,
    "externalVariantId" | "supplierId"
>;

export interface SupplierVariantGqlFields {
    /**
     * Calculated, combo of inventoryQuantity and inventoryPolicy
     * @readonly
     */
    inventoryStatus: InventoryStatus;
    /**
     * Calculated, combo of options
     * @readonly
     */
    title: string;
    /**
     * Calculated, the first content item in [contentItemIds] with IMAGE contentType
     * @readonly
     */
    featuredImage: ContentItem | null;
}

/** This is the response you would receive on the frontend (if you added every field, of course) */
export type SupplierVariantGql = SupplierVariant & SupplierVariantGqlFields;

export type SupplierVariantModelUpdateInput = SupplierVariantIdFields &
    Partial<
        Omit<
            SupplierVariant,
            | keyof SupplierVariantIdFields
            | keyof SupplierVariantRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type SupplierVariantModelCreateInput = Omit<
    SupplierVariant,
    | keyof SupplierVariantIdFields
    | keyof ResonanceDates
    | keyof ResonanceUpdatedBy
>;

export type SupplierVariantApiUpdateInput = Omit<
    SupplierVariantModelUpdateInput,
    keyof ResonanceDates
>;

export type SupplierVariantExternalApiUpdateInput = Required<
    Pick<SupplierVariant, keyof SupplierVariantExternalIdFields>
> &
    Partial<
        Omit<
            SupplierVariant,
            | keyof SupplierVariantExternalIdFields
            | keyof SupplierVariantIdFields
            | keyof SupplierVariantRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type SupplierVariantApiCreateInput = SupplierVariantModelCreateInput;

export type SupplierVariantInCompositeApiCreateInput = Omit<
    SupplierVariantApiCreateInput,
    keyof SupplierVariantIdFields | keyof SupplierVariantRelationshipFields
>;

export interface SupplierVariantCompositeApiCreateInput {
    supplierVariantInput: SupplierVariantApiCreateInput;
    supplierVariantSupplementInput?: SupplierVariantSupplementInCompositeApiCreateInput | null;
}

export interface SupplierVariantComposite {
    supplierVariant: SupplierVariant;
    supplierVariantSupplement?: SupplierVariantSupplement | null;
}

export type PublicSupplierVariant = Omit<
    SupplierVariant,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
> &
    Pick<
        SupplierVariantSupplement,
        | "averageShippingCost"
        | "barcode"
        | "cost"
        | "handlingCost"
        | "manufacturer"
        | "mpn"
        | "sku"
        | "recommendedPrice"
    > &
    PublicSupplierVariantGqlFields;

export interface PublicSupplierVariantGqlFields
    extends Pick<SupplierVariantGqlFields, "inventoryStatus" | "title"> {
    featuredImage: PublicSupplierProductContentItemGql | null;
}

export type PublicSupplierVariantGql = PublicSupplierVariant;
