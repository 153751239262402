/**
 * Note that doing something like process.env[envVariableName] doesn't work client-side due to webpack dynamically replacing process.env variables.
 *
 * @param envVariable The envVariable
 * @param envVariableName
 * @returns envVariable
 */
const confirmExistence = (
    envVariable?: string,
    envVariableName?: string,
): string => {
    if (!envVariable) {
        throw new Error(
            `${envVariableName ?? "Unknown"} env variable not populated`,
        );
    }
    return envVariable;
};

export { confirmExistence };
