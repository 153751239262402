import {
    ResonanceDomain,
    SupplierDemandHqApiCreateInput,
    SupplierDemandHqApiUpdateInput,
    SupplierDemandHqStatus,
    SUPPLIER_DEMAND_HQ_STATUS_TO_RESPONSIBLE_DOMAIN_MAP,
} from "@buildresonance/resonance-lib-entities";
import { object, ObjectSchema, string } from "yup";

import { dateTimeStringSchema } from "../dateHelpers";
import { demandHqIdSchema } from "../demand/demandIds";
import { transformBlankStringToNull } from "../stringHelpers";

import { supplierIdSchema } from "./supplyIds";

export const SUPPLIER_DEMAND_HQ_ALLOWED_STATUSES: SupplierDemandHqStatus[] =
    Object.entries(SUPPLIER_DEMAND_HQ_STATUS_TO_RESPONSIBLE_DOMAIN_MAP).reduce(
        (result, [status, domain]) =>
            domain === ResonanceDomain.Supply
                ? [...result, status as SupplierDemandHqStatus]
                : result,
        new Array<SupplierDemandHqStatus>(),
    );

export const supplierDemandHqApiCreateInputSchema: ObjectSchema<SupplierDemandHqApiCreateInput> =
    object({
        demandHqId: demandHqIdSchema.required(),
        status: string<SupplierDemandHqStatus>()
            .transform(transformBlankStringToNull)
            .oneOf(SUPPLIER_DEMAND_HQ_ALLOWED_STATUSES)
            .required(),
        supplierId: supplierIdSchema.required(),
        syncedAt: dateTimeStringSchema.nullable(),
    });

export const supplierDemandHqApiUpdateInputSchema: ObjectSchema<SupplierDemandHqApiUpdateInput> =
    object({
        demandHqId: demandHqIdSchema.required(),
        status: string<SupplierDemandHqStatus>()
            .transform(transformBlankStringToNull)
            .oneOf(SUPPLIER_DEMAND_HQ_ALLOWED_STATUSES)
            .required(),
        supplierId: supplierIdSchema.required(),
        syncedAt: dateTimeStringSchema.nullable(),
    });
