import { DemandHqId } from "./DemandIds";

export interface ResonanceOauthRequestBody {
    code: string;
    demandHqId: DemandHqId;
}

export interface ResonanceOauthAuthorizeNetPostResponse {
    message: string;
}
