import React, { ReactElement } from "react";

import { CommentCard } from "@components/comments/CommentCard";
import { DemandHqCommentGqlFragment } from "@hooks/graphql/fragments/demandHqComment";
import { usePublicIdentity } from "@hooks/graphql/queries/usePublicIdentity";
import { Box, useTheme } from "@mui/material";

export interface CommentCardParentProps {
    demandHqComment: DemandHqCommentGqlFragment;
}

export const CommentCardParent = ({
    demandHqComment,
}: CommentCardParentProps): ReactElement => {
    const theme = useTheme();

    const { data } = usePublicIdentity({
        identityId: demandHqComment.createdBy,
    });

    return (
        <Box margin={theme.spacing(1)}>
            <CommentCard
                demandHqComment={demandHqComment}
                identity={data?.publicIdentity}
            />
        </Box>
    );
};
