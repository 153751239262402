/** How this variant gets delivered. Defaults to "SHIPPING". */
export enum VariantDeliveryMethod {
    Email = "EMAIL",
    Shipping = "SHIPPING",
}

export type VariantOptionValues = [
    string,
    (string | undefined | null)?,
    (string | undefined | null)?,
];

export const buildVariantTitle = (
    optionValues: VariantOptionValues,
): string => {
    return optionValues.filter(Boolean).join(" / ");
};

export enum InventoryStatus {
    InStock = "INSTOCK",
    OutOfStock = "OUTOFSTOCK",
}

export enum VariantWeightUnit {
    Pounds = "LBS",
    Grams = "GRAMS",
}

export enum VariantShippingDimensionUnit {
    Inches = "IN",
    Centimeters = "CM",
}

export enum VariantStandardizedColor {
    Beige = "BEIGE",
    Black = "BLACK",
    Blue = "BLUE",
    Bronze = "BRONZE",
    Brown = "BROWN",
    Camo = "CAMO",
    Clear = "CLEAR",
    Gold = "GOLD",
    Gray = "GRAY",
    Green = "GREEN",
    Multicolor = "MULTICOLOR",
    OffWhite = "OFFWHITE",
    Orange = "ORANGE",
    Pink = "PINK",
    Purple = "PURPLE",
    Red = "RED",
    Silver = "SILVER",
    White = "WHITE",
    Yellow = "YELLOW",
}

/** These fields are shared between a SupplierVariantSupplement and a Variant */
export interface VariantCommon {
    /**
     * UPC, ISBN, GTIN, etc. Frequently required in external demand channels.
     */
    barcode?: string | null;
    /**
     * Quantity in one variant (i.e. 12-pack).
     *
     * Must be a positive integer.
     */
    casePack?: number | null;
    /**
     * How this variant gets delivered. Defaults to "SHIPPING".
     * This also determines what requirements are needed for checking out with the variant (shipping address vs email address).
     */
    deliveryMethod?: VariantDeliveryMethod | null;
    /** Often same as vendor, but not always. */
    manufacturer?: string | null;
    /**
     * Minimum Advertised Price
     *
     * Must be a positive number.
     */
    mapPrice?: number | null;
    /** Manufacturer Part Number */
    mpn?: string | null;
    /**
     * Manufacturer's Suggested Retail Price
     *
     * Must be a positive number.
     */
    msrp?: number | null;
    shippingDimensions?: {
        /** Must be a positive number. */
        length: number;
        /** Must be a positive number. */
        width: number;
        /** Must be a positive number. */
        height: number;
        unit: VariantShippingDimensionUnit;
    } | null;
    /** Stock Keeping Unit */
    sku?: string | null;
    standardizedColor?: VariantStandardizedColor | null;
    /** Sales Tax related code for this variant */
    taxCode?: string | null;
    weight?: {
        /** Must be a positive number. */
        amount: number;
        unit: VariantWeightUnit;
    } | null;
}
