import {
    AppIdOrUserName,
    ResonanceDomainEntityId,
    ResonanceIdentityId,
    ResonanceUpdatedBy,
} from "../../entityComposites";
import { ResonanceDates } from "../../global";

import { EventEntityType, EventMutationType, EventsEntityIdPrefix } from ".";

/** This is the name that the role must be that the event sender assumes, and it must have invoke privileges. */
export const RESONANCE_EVENT_SUBSCRIPTION_LAMBDA_ROLE_NAME =
    "ResonanceEventSubscriptionLambdaRole";

/**
 * This should be part of the name of any lambda that the Events Sender needs to invoke.
 * This prefix is used to give the ResonanceEventSubscriptionLambdaRole permission to invoke the lambda (resources in policy using this as part of a wildcard).
 * This is short so that the name of the lambda remains significant to its actual purpose, it stands for "Resonance Event Subscription Lambda".
 */
export const RESONANCE_EVENT_SUBSCRIPTION_LAMBDA_PREFIX = "RESL-";

/** What type of target is the endpoint? */
export enum ResonanceEventSubscriptionProtocol {
    Lambda = "LAMBDA",
}

export type ResonanceEventSubscriptionId =
    `${EventsEntityIdPrefix.ResonanceEventSubscription}-${string}`;

export interface ResonanceEventSubscriptionIdFields {
    /**
     * subscriptionArn would be unique enough, but this gives us the ability to change subscriptionArn
     * without a dynamo transaction if we wanna shake something up.
     * (Think disaster or just a major migration)
     */
    eventSubscriptionId: ResonanceEventSubscriptionId;
}

/**
 * This is the "main" entity that is being subscribed to.
 *
 * This can either be a ResonanceDomainEntityId (SupplierId, DemandHqId) to subscribe to an entity to which they have access.
 * This is useful to monitor things like changes to Products and such.
 *
 * Or an Identity (AppClientId, UserName) to subscribe to THEMSELF.
 * This is useful to monitor things like app installations, uninstallations, and such.
 */
export type ResonanceEventSubscriptionEntityId =
    | ResonanceDomainEntityId
    | ResonanceIdentityId;

/**
 * This distinguishes between the types of subscriptions.
 * @see ResonanceEventSubscriptionEntityId
 */
export enum ResonanceEventSubscriptionEntityType {
    DomainEntity = "DOMAINENTITY",
    Identity = "IDENTITY",
}

/**
 * This entity is our database representation of a subscription to an event.
 * This will be created on demand, and then each event will be sent according to the indexes and data of this entity.
 */
export interface ResonanceEventSubscription
    extends ResonanceEventSubscriptionIdFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /** Changes based on protocol, but indicates the exact target. */
    endpoint: string;
    /** Any entity */
    itemType: EventEntityType;
    /** Create, Update, or Delete */
    mutationType: EventMutationType;
    /** What type of target is the endpoint? */
    protocol: ResonanceEventSubscriptionProtocol;
    /**
     * This is the "main" entity that is being subscribed to.
     *
     * This can either be a ResonanceDomainEntityId (SupplierId, DemandHqId) to subscribe to an entity to which they have access.
     * This is useful to monitor things like changes to Products and such.
     *
     * Or an Identity (AppClientId, UserName) to subscribe to THEMSELF.
     * This is useful to monitor things like app installations, uninstallations, and such.
     *
     * subscriptionEntityType indicates what type of entity this is.
     */
    subscriptionEntityId: ResonanceEventSubscriptionEntityId;
    /**
     * This distinguishes between the types of subscriptions.
     * @see ResonanceEventSubscriptionEntityId
     */
    subscriptionEntityType: ResonanceEventSubscriptionEntityType;
    /**
     * This is who created the subscription.
     * We use AppId instead of AppClientId so that both clients can see these.
     */
    subscriptionOwnerId: AppIdOrUserName;
}

interface ResonanceEventGqlFields {
    mutationType: Uppercase<EventMutationType>; // GQL Likes CAPS
}

export type ResonanceEventSubscriptionGql = Omit<
    ResonanceEventSubscription,
    keyof ResonanceEventGqlFields
> &
    ResonanceEventGqlFields;

export type ResonanceEventSubscriptionModelCreateInput = Omit<
    ResonanceEventSubscription,
    | keyof ResonanceEventSubscriptionIdFields
    | keyof ResonanceDates
    | keyof ResonanceUpdatedBy
>;

/** Input for the actual Events REST API */
export type ResonanceEventSubscriptionApiCreateInput =
    ResonanceEventSubscriptionModelCreateInput;

/** Input for GraphQL APIs that use events Service Client, like Supply API */
export type ResonanceEventSubscriptionGqlApiCreateInput = Omit<
    ResonanceEventSubscriptionGql, // API create uses the GQL fields
    | keyof ResonanceEventSubscriptionIdFields
    | keyof ResonanceDates
    | keyof ResonanceUpdatedBy
>;

// Immutable, no updates, must delete and re-create
