import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceCurrency, ResonanceDates } from "../../global";
import { ResonanceShippingMethod, VariantDeliveryMethod } from "../common";
import { PurchaseOrderItem } from "../supply/PurchaseOrderItem";

import {
    DemandHqId,
    OrderId,
    OrderItemId,
    ProductId,
    VariantId,
} from "./DemandIds";
import { Discount } from "./Discount";

/** Indicates why an Adjustment occurred. */
export enum OrderItemAdjustmentReason {
    CustomerFoundLowerPrice = "CUSTOMER_FOUND_LOWER_PRICE",
    CustomerNoLongerNeeds = "CUSTOMER_NO_LONGER_NEEDS",
    CustomerOrderedByAccident = "CUSTOMER_ORDERED_BY_ACCIDENT",
    CustomerOther = "CUSTOMER_OTHER",
    CustomerShippingTookTooLong = "CUSTOMER_SHIPPING_TOOK_TOO_LONG",
    ItemNotAsAdvertised = "ITEM_NOT_AS_ADVERTISED",
    Other = "OTHER",
    OutOfStock = "OUT_OF_STOCK",
    SpotDiscount = "SPOT_DISCOUNT",
    WrongItemShipped = "WRONG_ITEM_SHIPPED",
}

export enum OrderItemAdjustmentType {
    Price = "PRICE",
    Quantity = "QUANTITY",
}

/** Adjustments to orders occur for a variety of reasons, and are only allowed at certain stages of the order process. */
export interface OrderItemAdjustment {
    adjustedAt: string;
    adjustmentAmount: number;
    adjustmentReason: OrderItemAdjustmentReason;
    adjustmentType: OrderItemAdjustmentType;
}

/** Discounts alter pricing of an OrderItem, and may include a coupon code to do so. */
export interface OrderItemDiscount
    extends Pick<Discount, "code" | "discountAmount" | "discountType"> {
    discountedAt: string;
}

export type OrderItemPurchaseOrderItemIdFields = Pick<
    PurchaseOrderItem,
    "orderItemId" | "purchaseOrderItemId"
>;

export type OrderItemPurchaseOrderItemImmutableFields = Pick<
    PurchaseOrderItem,
    | "supplierId"
    | "externalProductId"
    | "externalVariantId"
    | "supplierProductId"
    | "supplierVariantId"
>;

export type OrderItemPurchaseOrderItem = OrderItemPurchaseOrderItemIdFields &
    OrderItemPurchaseOrderItemImmutableFields &
    Pick<
        PurchaseOrderItem,
        | "externalPurchaseOrderId"
        | "externalPurchaseOrderItemId"
        | "fulfillments"
        | "quantity"
        | "status"
    >;

export enum OrderItemStatus {
    Assigned = "ASSIGNED",
    /** Indicates that the OrderItem was assigned, but is no longer needed to be fulfilled. */
    Cancelled = "CANCELLED",
    /** Indicates that the OrderItem is fully fulfilled and is now complete. */
    Fulfilled = "FULFILLED",
    /** Indicates that the OrderItem is ready for processing, and data is complete. */
    Initial = "INITIAL",
    PartiallyAssigned = "PARTIALLY_ASSIGNED",
    /** Indicates that the OrderItem is in the process of being fulfilled. */
    PartiallyFulfilled = "PARTIALLY_FULFILLED",
}

/**
 * Allows for a shipping charge to be added to an Order or OrderItem.
 */
export interface OrderShippingCharge {
    amount: number;
    title?: string | null;
}

/**
 * Most commonly a sales tax of some variety.
 */
export interface OrderItemTax {
    amount: number;
    rate: number;
    title: string;
}

export interface OrderItemIdFields {
    orderItemId: OrderItemId;
}

export interface OrderItemRelationshipFields {
    demandHqId: DemandHqId;
    orderId: OrderId;
    productId: ProductId;
    variantId: VariantId;
}

/**
 * These fields can only be set on Creation.
 */
export interface OrderItemImmutableFields {
    barcode?: string | null;
    currency: ResonanceCurrency;
    deliveryMethod: VariantDeliveryMethod;
    productTitle: string;
    quantity: number;
    sku?: string | null;
    /** Price per item, can be multiplied by quantity for total price */
    unitPrice: number;
    variantTitle: string;
    vendor: string;
}

/**
 * These fields can be changed at the model level, but cannot be changed at the API level.
 * This means that the API must calculate these values.
 */
export interface OrderItemReadOnlyFields {
    status: OrderItemStatus;
}

/**
 * An OrderItem is a single variant attached to an order, holding the quantity and unitPrice, as well as key information about the variant.
 * Adjustments, discounts, and taxes are also included, and can be added after creation, up to a certain point in the process.
 */
export interface OrderItem
    extends OrderItemIdFields,
        OrderItemRelationshipFields,
        OrderItemImmutableFields,
        OrderItemReadOnlyFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    adjustments?: OrderItemAdjustment[] | null;
    /**
     * Anything can go into these fields, but the following limits apply:
     * - Each key is limited to 64 characters.
     * - Each value is limited to 512 characters.
     * - Maximum of 5 key/value pairs.
     */
    customProperties?: Record<string, string> | null;
    discounts?: OrderItemDiscount[] | null;
    note?: string | null;
    purchaseOrderItems?: OrderItemPurchaseOrderItem[] | null;
    /** Shipping charges can be on the item or on the order level */
    shippingCharges?: OrderShippingCharge[] | null;
    shippingMethod?: ResonanceShippingMethod | null;
    taxes?: OrderItemTax[] | null;
}

export type OrderItemModelCreateInput = Omit<
    OrderItem,
    keyof OrderItemIdFields | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type OrderItemApiCreateInput = Omit<
    OrderItemModelCreateInput,
    keyof OrderItemReadOnlyFields | "purchaseOrderItems"
>;

export type OrderItemModelUpdateInput = OrderItemIdFields &
    Omit<
        OrderItem,
        | keyof OrderItemIdFields
        | keyof OrderItemRelationshipFields
        | keyof OrderItemImmutableFields
        | keyof ResonanceDates
        | keyof ResonanceUpdatedBy
    >;

export type OrderItemApiUpdateInput = Omit<
    OrderItemModelUpdateInput,
    keyof OrderItemReadOnlyFields | "purchaseOrderItems"
>;
