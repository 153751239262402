import {
    ALL_SUPPLY_ENTITIES,
    SupplyEntity,
    SupplyEventSubscriptionApiCreateInput,
} from "@buildresonance/resonance-lib-entities";
import { object, ObjectSchema, string } from "yup";

import { resonanceEventSubscriptionApiCreateInputSchema } from "../events/eventSubscription";
import { transformBlankStringToNull } from "../stringHelpers";

export const supplyEventSubscriptionApiCreateInputSchema: ObjectSchema<SupplyEventSubscriptionApiCreateInput> =
    resonanceEventSubscriptionApiCreateInputSchema
        .concat(
            object({
                itemType: string()
                    .transform(transformBlankStringToNull)
                    .required()
                    .oneOf(
                        ALL_SUPPLY_ENTITIES.map((entity) =>
                            entity.toLowerCase(),
                        ) as Lowercase<SupplyEntity>[],
                    ),
            }),
        )
        .noUnknown();
