import {
    IdentityParentEntityId,
    ResonanceIdentityId,
} from "../../entityComposites";

/**
 * These fields require no permissions to return for a ResonanceIdentity (No PII, ever).
 * @see ResonanceIdentity
 */
export interface PublicIdentityGql {
    displayName: string;
    identityId: ResonanceIdentityId;
    /**
     * For appClients, this is AppId.
     * For users, this is undefined.
     */
    identityParentEntityId?: IdentityParentEntityId;
}
