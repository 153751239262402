import { ParsedUrlQuery } from "querystring";

import React, { ReactElement, useCallback, useMemo, useState } from "react";

import {
    DemandHqId,
    EventEntityType,
} from "@buildresonance/resonance-lib-entities";
import { SELECTED_TAB_QUERY_PARAM } from "@hooks/custom/useSelectedTab";
import { useListDemandHqEvents } from "@hooks/graphql/queries/useListDemandHqEvents";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import EventsIcon from "@mui/icons-material/ChangeHistory";
import { Badge, Drawer, IconButton, Tooltip, useTheme } from "@mui/material";
import { EditCollectionTab } from "@views/demandHqs/collections/EditCollectionParent/types";
import { EditPageTab } from "@views/demandHqs/pagesComponents/EditPageParent/types";
import { EditProductTab } from "@views/demandHqs/products/EditProductParent/EditProductTabs";
import { useRouter } from "next/router";

import { useDemandHqCommentEntityId } from "../useDemandHqCommentEntityId";

import { EventsDrawer } from "./EventsDrawer";

const findItemTypeFromRoute = (
    pathname: string,
    query: ParsedUrlQuery,
): EventEntityType | null => {
    switch (pathname) {
        case DemandAdminRoute.CollectionEdit.pathname:
            return findCollectionEditItemTypeFromSelectedTab(
                query[SELECTED_TAB_QUERY_PARAM] as
                    | EditCollectionTab
                    | undefined,
            );
        case DemandAdminRoute.PageEdit.pathname:
            return findPageEditItemTypeFromSelectedTab(
                query[SELECTED_TAB_QUERY_PARAM] as EditPageTab | undefined,
            );
        case DemandAdminRoute.ProductEdit.pathname:
            return findProductEditItemTypeFromSelectedTab(
                query[SELECTED_TAB_QUERY_PARAM] as EditProductTab | undefined,
            );
        case DemandAdminRoute.VariantEdit.pathname:
            return "variant";

        default:
            return null;
    }
};

const findCollectionEditItemTypeFromSelectedTab = (
    selectedTab: EditCollectionTab | null | undefined,
): EventEntityType | null => {
    switch (selectedTab) {
        case EditCollectionTab.Basics:
        case EditCollectionTab.Preview:
        default:
            return "collection";
        case EditCollectionTab.Content:
            return "collectioncontent";
    }
};

const findPageEditItemTypeFromSelectedTab = (
    selectedTab: EditPageTab | null | undefined,
): EventEntityType | null => {
    switch (selectedTab) {
        case EditPageTab.Basics:
        default:
            return "page";
        case EditPageTab.Content:
            return "pagecontent";
    }
};

const findProductEditItemTypeFromSelectedTab = (
    selectedTab: EditProductTab | null | undefined,
): EventEntityType | null => {
    switch (selectedTab) {
        case EditProductTab.Basics:
        default:
            return "product";
        case EditProductTab.Description:
            return "productlocale";
        case EditProductTab.Images:
            return "productcontentitem";
        case EditProductTab.Variants:
            return null;
    }
};

export const EventsButtonParent = (): ReactElement | null => {
    const theme = useTheme();
    const router = useRouter();
    const demandHqId = router.query.demandHqId as DemandHqId | undefined;
    const entityId = useDemandHqCommentEntityId();

    const itemType = useMemo(
        () => findItemTypeFromRoute(router.pathname, router.query),
        [router.pathname, router.query],
    );

    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const demandHqEventsResult = useListDemandHqEvents(
        {
            demandHqId,
            entityId,
            itemType,
        },
        { enabled: !!itemType && drawerIsOpen },
    );

    const demandHqEvents = useMemo(
        () =>
            demandHqEventsResult?.data?.pages?.flatMap(
                (page) => page?.events?.nodes,
            ) ?? [],
        [demandHqEventsResult],
    );

    const determineBadgeContent = useCallback((): string | undefined => {
        if (demandHqEvents.length > 0) {
            if (demandHqEvents.length >= 10) {
                return `9+`;
            }

            return demandHqEvents.length.toString();
        }

        return undefined;
    }, [demandHqEvents.length]);

    const container =
        typeof window !== "undefined" ? () => window.document.body : undefined;

    const handleDrawerClose = (): void => {
        setDrawerIsOpen(false);
    };

    if (!demandHqId || !entityId || !itemType) {
        return null;
    }

    return (
        <>
            <Tooltip title="Events (Change / Audit Logs)">
                <Badge
                    overlap="circular"
                    color="primary"
                    badgeContent={determineBadgeContent()}
                >
                    <IconButton
                        color="inherit"
                        onClick={() => setDrawerIsOpen((prev) => !prev)}
                    >
                        <EventsIcon />
                    </IconButton>
                </Badge>
            </Tooltip>
            <Drawer
                id="Events-drawer"
                container={container}
                open={drawerIsOpen}
                onClose={handleDrawerClose}
                anchor={theme.direction === "rtl" ? "left" : "right"}
                variant="temporary"
            >
                <EventsDrawer demandHqEventsResult={demandHqEventsResult} />
            </Drawer>
        </>
    );
};
