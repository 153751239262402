import { ResonanceDomain } from "../../domainNames";
import { ResonanceIdentityId } from "../../entityComposites";

/** The type of action to be undertaken */
export enum ResonanceActionType {
    GQL = "GQL",
    Link = "LINK",
}

/**
 * For asynchronous Resonance actions, e.g. GQL actions, the is the format of the message.
 */
export interface ResonanceActionMessage<
    TResonanceAction extends ResonanceAction = ResonanceAction,
> {
    /**
     * The requested action and metadata.
     */
    message: ResonanceActionMessageMessage<TResonanceAction>;
    /** Message subject */
    subject: string;
    /** The timestamp for the action, ISO string */
    timestamp: string;
}

export interface ResonanceActionMessageMessage<
    TResonanceAction extends ResonanceAction = ResonanceAction,
> {
    metadata: ResonanceActionMetadata;
    action: TResonanceAction;
}

export interface ResonanceActionMetadata {
    /** Required for most, if not all actions */
    identityId: ResonanceIdentityId;
    domain: ResonanceDomain;
}

/**
 * A ResonanceAction is a request to perform an action on behalf of a user.
 * This action could be anything, but is typically some form of API Request.
 * This entity exists to allow us to perform actions on behalf of a user asynchronously,
 * or in bulk, without needing to rewrite existing logic.
 *
 * Union type for Resonance Actions - add additional actions as required
 * Use a unique `ResonanceActionType` for each action
 */
export type ResonanceAction = GqlActionRequest | LinkAction;

/**
 * A Resonance GQL action request.
 * The combination of query (the GQL string) and variables can accomplish any GQL API Call, but it is always a mutation.
 */
export interface GqlActionRequest {
    actionType: ResonanceActionType.GQL;
    actionRequest: {
        query: string;
        variables:
            | {
                  readonly [variable: string]: unknown;
              }
            | null
            | undefined;
    };
}

export interface LinkAction {
    actionType: ResonanceActionType.Link;
    actionRequest: {
        href: string;
        rel?: string;
        target?: string;
    };
}
