import { DemandHqNotification } from "@buildresonance/resonance-lib-entities";
import { gql } from "graphql-request";

export const DEMAND_HQ_NOTIFICATION_FRAGMENT = gql`
    fragment demandHqNotificationFragment on DemandHqNotification {
        acknowledgedBy
        action {
            ... on DemandGqlAction {
                actionType
                actionRequest {
                    query
                    variables
                }
            }
            ... on DemandLinkAction {
                actionType
                actionRequest {
                    href
                    rel
                    target
                }
            }
        }
        actionLabel
        category
        createdAt
        createdBy
        content
        demandHqId
        demandHqNotificationId
        ignoredBy
        resolvedBy
        senderReferenceKey
        sentiment
        status
        subject
        updatedAt
        updatedBy
    }
`;

export type DemandHqNotificationFragment = DemandHqNotification;
