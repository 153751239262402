import {
    DemandHqId,
    ProductId,
    VariantId,
    DemandEntityIdPrefix,
    PageId,
    StorefrontId,
    CollectionId,
    NavId,
    CustomerId,
    CustomerAddressId,
    OrderId,
    OrderItemId,
    OrderTransactionId,
    CollectionTypeContentId,
    PageTypeContentId,
    ReviewId,
    TaxonomyBranchId,
    DemandHqNotificationId,
    DiscountId,
} from "@buildresonance/resonance-lib-entities";
import { string } from "yup";

// This file exists to fight circular dependencies.

export const collectionIdSchema = string<CollectionId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.Collection}.*`),
);
export const collectionTypeContentIdSchema =
    string<CollectionTypeContentId>().matches(
        new RegExp(`^${DemandEntityIdPrefix.CollectionTypeContent}.*`),
    );
export const customerIdSchema = string<CustomerId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.Customer}.*`),
);
export const customerAddressIdSchema = string<CustomerAddressId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.CustomerAddress}.*`),
);
export const demandHqIdSchema = string<DemandHqId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.DemandHq}.*`),
);
export const demandHqNotificationIdSchema =
    string<DemandHqNotificationId>().matches(
        new RegExp(`^${DemandEntityIdPrefix.DemandHqNotification}.*`),
    );
export const discountIdSchema = string<DiscountId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.Discount}.*`),
);
export const navIdSchema = string<NavId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.Nav}.*`),
);
export const orderIdSchema = string<OrderId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.Order}.*`),
);
export const orderItemIdSchema = string<OrderItemId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.OrderItem}.*`),
);
export const orderTransactionIdSchema = string<OrderTransactionId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.OrderTransaction}.*`),
);
export const pageIdSchema = string<PageId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.Page}.*`),
);
export const pageTypeContentIdSchema = string<PageTypeContentId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.PageTypeContent}.*`),
);
export const productIdSchema = string<ProductId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.Product}.*`),
);
export const reviewIdSchema = string<ReviewId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.Review}.*`),
);
export const storefrontIdSchema = string<StorefrontId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.Storefront}.*`),
);
export const taxonomyBranchIdSchema = string<TaxonomyBranchId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.TaxonomyBranch}.*`),
);
export const variantIdSchema = string<VariantId>().matches(
    new RegExp(`^${DemandEntityIdPrefix.Variant}.*`),
);
