import React, { useState } from "react";

import {
    ResponsiveFooter,
    ResponsiveLayout,
    ResponsiveMainProps,
    ResponsiveSideDrawer,
} from "@buildresonance/resonance-lib-layout-components";
import { Grid, Typography } from "@mui/material";

import packageJson from "../../../package.json";

import { DemandHeader } from "./DemandHeader";
import { NavDrawer } from "./NavDrawer";

const AdminLayout: React.FC<ResponsiveMainProps> = ({
    children,
    ...otherProps
}) => {
    const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);

    const handleMobileNavClose = (): void => {
        setMobileNavIsOpen(false);
    };

    return (
        <ResponsiveLayout
            header={<DemandHeader setMobileNavIsOpen={setMobileNavIsOpen} />}
            sidebar={
                <ResponsiveSideDrawer
                    mobileIsOpen={mobileNavIsOpen}
                    onClose={handleMobileNavClose}
                >
                    <NavDrawer handleDrawerClose={handleMobileNavClose} />
                </ResponsiveSideDrawer>
            }
            footer={
                <ResponsiveFooter>
                    <Grid container justifyContent="space-between">
                        <Grid item></Grid>
                        <Grid item xs="auto">
                            <Typography color="textSecondary">
                                v{packageJson.version}
                            </Typography>
                        </Grid>
                    </Grid>
                </ResponsiveFooter>
            }
            responsiveMainProps={otherProps}
        >
            {children}
        </ResponsiveLayout>
    );
};

export { AdminLayout };
