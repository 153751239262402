import { date, mixed, MixedSchema } from "yup";

export const transformDateToString = (
    value: Date | string | null | undefined,
): string | null | undefined => {
    // Transform to string
    if (value instanceof Date && !isNaN(value.getTime())) {
        return value.toISOString();
    }
    return value as string | null | undefined;
};

export const dateTimeStringSchema = mixed<string>()
    .test((value, ctx) => {
        const isNullable = (ctx.schema as MixedSchema<string>).describe()
            .nullable;

        return isNullable
            ? date().nullable().isValidSync(value)
            : date().isValidSync(value);
    }) // Validate as date, works with string or date
    .transform(transformDateToString);
