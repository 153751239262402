export type DemandAppsTableName = `${string}-apps-table`;
export type DemandCatalogTableName = `${string}-catalog-table`;
export type DemandCommerceTableName = `${string}-commerce-table`;
export type DemandCustomersTableName = `${string}-customers-table`;
export type DemandMetadataTableName = `${string}demand-metadata-table`;
export type DemandPagesTableName = `${string}-pages-table`;
export type DemandPersonalizationTableName = `${string}-personalization-table`;
export type DemandReviewsTableName = `${string}-reviews-table`;
export type DemandStorefrontTableName = `${string}-storefront-table`;
export type DemandWorkflowTableName = `${string}-workflow-table`;

export type DemandPaymentSecureTableName = `${string}-payment-secure-table`;
export type DemandSettingsSecureTableName = `${string}-settings-secure-table`;

export type DemandBulkActionsBucketName =
    `${string}-demand-bulk-actions-bucket`;
