import React, { ReactElement } from "react";

import { DemandHqPathParam } from "@components/types";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import { useRouter } from "next/router";

import { AppBarSearchAutocomplete } from "../common/AppBarSearchAutocomplete";
import { DemandEntityRecentSearchesLocalStorageKey } from "../common/constants";

const OrderSearchInputParent = ({
    setSearchInputIsFocused,
}: {
    setSearchInputIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement | null => {
    const router = useRouter();

    const { demandHqId } = router.query as DemandHqPathParam;

    if (
        !demandHqId ||
        ![
            DemandAdminRoute.OrderCreate.pathname,
            DemandAdminRoute.OrderEdit.pathname,
            DemandAdminRoute.Orders.pathname,
        ].includes(router.pathname)
    ) {
        return null;
    }

    return (
        <AppBarSearchAutocomplete
            autoCompleteQueryResponse={undefined}
            id="order-search-input"
            label="Order Search"
            placeholder="Search Orders..."
            searchPathName={DemandAdminRoute.Orders.pathname}
            sendDebouncedAutoCompleteQuery={() => {}}
            setSearchInputIsFocused={setSearchInputIsFocused}
            localStorageKey={DemandEntityRecentSearchesLocalStorageKey.Order}
        />
    );
};

export { OrderSearchInputParent };
