import {
    ResonanceIdentityId,
    PublicIdentityGql,
} from "@buildresonance/resonance-lib-entities";
import { gql as identityGql, GraphQLClient } from "graphql-request";
import { QueryFunction } from "react-query";

/**  THIS IS AN ADMINIDENTITY QUERY, NOT DEMAND */
const GET_PUBLIC_IDENTITY_QUERY = identityGql`
    query getPublicIdentity($identityId: ID!) {
        publicIdentity(identityId: $identityId) {
            displayName
            identityId
        }
    }
`;

export type GetPublicIdentityResponse = {
    publicIdentity: PublicIdentityGql;
};

export type GetPublicIdentityVariables = {
    identityId: ResonanceIdentityId;
};

export type GetPublicIdentityQueryKey = [
    "getPublicIdentity",
    ResonanceIdentityId,
];

export const buildGetPublicIdentityQueryKey = (
    identityId: ResonanceIdentityId,
): GetPublicIdentityQueryKey => ["getPublicIdentity", identityId];

/** Ensure the client is to the AdminIdentity API */
const getPublicIdentity =
    (
        client: GraphQLClient,
    ): QueryFunction<GetPublicIdentityResponse, GetPublicIdentityQueryKey> =>
    async ({ queryKey }) => {
        return await client.request<
            GetPublicIdentityResponse,
            GetPublicIdentityVariables
        >(GET_PUBLIC_IDENTITY_QUERY, {
            identityId: queryKey[1],
        });
    };

export { getPublicIdentity };
