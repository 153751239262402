import { useResonanceApi } from "@buildresonance/resonance-lib-frontend-admin-auth-passwordless";
import { GqlApi, buildGqlEndpoint } from "@lib/common/domainNames";
import { ClientError } from "graphql-request";
import {
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useInfiniteQuery,
} from "react-query";

import {
    ListDemandHqEventsInfiniteQueryKey,
    ListDemandHqEventsResponse,
    ListDemandHqEventsVariables,
    buildListDemandHqEventsInfiniteQueryKey,
    listDemandHqEvents,
} from "./listDemandHqEvents";

export const useListDemandHqEvents = (
    variables: Partial<Omit<ListDemandHqEventsVariables, "before">>,
    options?: UseInfiniteQueryOptions<
        ListDemandHqEventsResponse,
        ClientError,
        ListDemandHqEventsResponse,
        ListDemandHqEventsResponse,
        ListDemandHqEventsInfiniteQueryKey
    >,
): UseInfiniteQueryResult<ListDemandHqEventsResponse, ClientError> => {
    const { gqlClient, withAuthInfiniteQueryOptions } = useResonanceApi({
        endpoint: buildGqlEndpoint(GqlApi.Demand),
    });

    return useInfiniteQuery<
        ListDemandHqEventsResponse,
        ClientError,
        ListDemandHqEventsResponse,
        ListDemandHqEventsInfiniteQueryKey
    >(
        buildListDemandHqEventsInfiniteQueryKey(
            variables as Omit<ListDemandHqEventsVariables, "before">,
        ),
        listDemandHqEvents(gqlClient),
        withAuthInfiniteQueryOptions({
            ...options,
            enabled:
                !!variables.demandHqId &&
                !!variables.entityId &&
                (options?.enabled ?? true),
            getNextPageParam: (lastPage) =>
                lastPage?.events?.pageInfo?.hasPreviousPage
                    ? lastPage?.events?.pageInfo?.startCursor
                    : undefined,
        }),
    );
};
