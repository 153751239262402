import { ServiceClientMethod } from "../../serviceClient/serviceClientGlobal";

export enum SupplyServiceClientRoute {
    GraphQL = "graphql",
}

export const SUPPLY_SERVICE_CLIENT_ROUTES_TO_METHOD_MAP: Record<
    SupplyServiceClientRoute,
    ServiceClientMethod[]
> = {
    [SupplyServiceClientRoute.GraphQL]: ["POST"],
};
