import {
    ResonanceAction,
    ResonanceCreatedBy,
    ResonanceDates,
    ResonanceIdentityId,
    ResonanceUpdatedBy,
} from "../..";

/**
 * Denotes positivity or negativity of a Notification, can affect the display of the message.
 */
export enum NotificationSentiment {
    Positive = "POSITIVE",
    Neutral = "NEUTRAL",
    SemiNegative = "SEMINEGATIVE",
    Negative = "NEGATIVE",
}

/**
 * The status of a Notification, calculated by actions that have or have not been taken.
 */
export enum NotificationStatus {
    /** The Notification has not been interacted with by a User */
    Fresh = "FRESH",
    /** The Notification has been acknowledged by a User, and is in progress. */
    Acknowledged = "ACKNOWLEDGED",
    /** The Notification has been resolved by a User. */
    Resolved = "RESOLVED",
    /** The Notification has been ignored by a User. */
    Ignored = "IGNORED",
}

export interface NotificationIdentityFields {
    acknowledgedBy?: ResonanceIdentityId | null;
    ignoredBy?: ResonanceIdentityId | null;
    resolvedBy?: ResonanceIdentityId | null;
}

/**
 * An actionable Notification.
 * A User can acknowledge, ignore, or resolve a Notification.
 * In some cases, a suggested action can be taken by the User to resolve the Notification.
 */
export interface NotificationCommonFields<
    TNotificationCategory extends string,
> {
    /** Notifications can have suggested actions that can be taken by the User. */
    action?: ResonanceAction | null;
    /** A verb to describe what the action will accomplish. */
    actionLabel?: string | null;
    /** This denotes what the Notification pertains to. */
    category: TNotificationCategory;
    /** The actual message. */
    content: string;
    /** Set this when you want the Notification to disappear. */
    expiresAt?: number | null;
    /**
     * The sender of the Notification can use this to reference it later, this can be, but does not need to be, unique.
     * (You can list several Notifications under the same reference key, for instance, if you want to show a list of Notifications for a single entity)
     */
    senderReferenceKey?: string | null;
    /** Denotes positivity or negativity of a Notification, can affect the display of the message. */
    sentiment?: NotificationSentiment | null;
    /** The status of a Notification, calculated by actions that have or have not been taken. */
    status: NotificationStatus;
    /** The subject or title of a Notification. */
    subject: string;
}

export interface ResonanceNotification<TNotificationCategory extends string>
    extends NotificationIdentityFields,
        NotificationCommonFields<TNotificationCategory>,
        ResonanceDates,
        ResonanceCreatedBy,
        ResonanceUpdatedBy {}
