import { ResonanceUpdatedBy } from "../../../entityComposites";
import { ResonanceDates, ScopePermission } from "../../../global";
import { Permissions } from "../../../util/permissions";
import { ALL_DEMAND_ENTITIES, DemandEntity } from "../DemandEntity";
import { DemandHqId, StorefrontId } from "../DemandIds";

/**
 * These entities are only ever accessible in the Storefront API,
 * and are therefore separate, since permissions for DemandHq aren't a thing.
 */
export enum StorefrontEntity {
    Cart = "CART",
    Checkout = "CHECKOUT",
}

export const ALL_STOREFRONT_ENTITIES = Object.values(StorefrontEntity);

export const WRITABLE_STOREFRONT_ENTITIES = [
    StorefrontEntity.Cart,
    StorefrontEntity.Checkout,
];

export type ExcludedStorefrontDemandEntity =
    | DemandEntity.DemandHqIdentity
    | DemandEntity.DemandHqQuickLink;

export type StorefrontAndDemandEntity =
    | Exclude<DemandEntity, ExcludedStorefrontDemandEntity>
    | StorefrontEntity;

export enum StorefrontRole {
    Client = "CLIENT",
    Server = "SERVER",
}

export type StorefrontScope =
    `public${Lowercase<StorefrontAndDemandEntity>}/${ScopePermission}`;

export type StorefrontPermissions = Permissions<
    StorefrontRole,
    StorefrontScope
>;

export const STOREFRONT_EXCLUDED_DEMAND_ENTITIES: ExcludedStorefrontDemandEntity[] =
    [DemandEntity.DemandHqIdentity, DemandEntity.DemandHqQuickLink];

export const ALL_STOREFRONT_AND_DEMAND_ENTITIES: StorefrontAndDemandEntity[] = (
    ALL_DEMAND_ENTITIES.filter(
        (demandEntity) =>
            !STOREFRONT_EXCLUDED_DEMAND_ENTITIES.includes(
                demandEntity as ExcludedStorefrontDemandEntity,
            ),
    ) as StorefrontAndDemandEntity[]
).concat(ALL_STOREFRONT_ENTITIES);

export const ALL_STOREFRONT_SCOPES: StorefrontScope[] = [
    ...(ALL_STOREFRONT_ENTITIES.flatMap((entity) => [
        `public${entity.toLowerCase()}/${ScopePermission.Write}`,
        `public${entity.toLowerCase()}/${ScopePermission.Read}`,
    ]) as StorefrontScope[]),
];

export const CLIENT_EXCLUDED_SCOPES: Set<StorefrontScope> = new Set([
    "publiccustomer/write", // For updating customer data, should run through a proxy
    "publiccheckout/write", // For updating checkout data, should run through a proxy
]);

export const STOREFRONT_ENTITY_ROLES: Record<
    StorefrontRole,
    StorefrontScope[]
> = {
    [StorefrontRole.Server]: ALL_STOREFRONT_AND_DEMAND_ENTITIES.map(
        (entity) => `public${entity.toLowerCase()}/${ScopePermission.Write}`,
    ) as StorefrontScope[],
    [StorefrontRole.Client]: (
        ALL_STOREFRONT_AND_DEMAND_ENTITIES.map(
            (entity) =>
                `public${entity.toLowerCase()}/${ScopePermission.Write}`,
        ) as StorefrontScope[]
    ).filter((scope) => !CLIENT_EXCLUDED_SCOPES.has(scope)),
};

/** What form does the customer authentication form take when it is handed to the Storefront API? */
export enum StorefrontAuthenticationType {
    Cognito = "COGNITO",
    JWKS = "JWKS",
    None = "NONE",
}

export interface StorefrontCommonAuthenticationSettings {
    issuer: string;
}

/** Settings required to properly verify a Cognito JWT */
export type StorefrontCognitoAuthenticationSettings =
    StorefrontCommonAuthenticationSettings;

/**
 * Settings required to properly verify a JWT that exposes a public key using a JWKS file.
 * Inside each token must be a 'kid' field that matches the JWKS file's 'kid' field.
 * Also, there must be a field that exposes the externalCustomerId (usually sub).
 */
export interface StorefrontJwksAuthenticationSettings
    extends StorefrontCommonAuthenticationSettings {
    externalCustomerIdFieldName: string;
    jwksUrl: string;
}

/**
 * Any fields that always show up are required, any fields that might show up are optional.
 * Cast to one of the more specific types where appropriate.
 */
export type StorefrontAuthenticationSettings =
    StorefrontCommonAuthenticationSettings &
        Omit<
            Partial<
                StorefrontCognitoAuthenticationSettings &
                    StorefrontJwksAuthenticationSettings
            >,
            keyof StorefrontCommonAuthenticationSettings
        >;

export interface StorefrontIdFields {
    storefrontId: StorefrontId;
}

export interface StorefrontRelationshipFields {
    demandHqId: DemandHqId;
}

/**
 * This represents an identity which accesses the Storefront API.
 *
 * The key comes in as a base64 encoded string of a StorefrontIdentityKey and is used to identity the storefront through the API.
 * It is not meant to be secure as it only provides unauthenticated access to data.
 */
export interface StorefrontIdentity {
    /** Human-readable way to categorize the use of this identity, must be unique to this storefront, all lowercase, and contain no spaces, limit 32 characters. */
    slug: string;
    permissions: StorefrontPermissions;
}

/**
 * A Storefront is an application of some variety that presents an e-commerce experience directly to customers and facilitates the browsing, shopping, and ordering of Product Variants.
 */
export interface Storefront
    extends StorefrontIdFields,
        StorefrontRelationshipFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    authenticationSettings?: StorefrontAuthenticationSettings | null;
    authenticationType?: StorefrontAuthenticationType | null;
    description?: string | null;
    name: string;
    storefrontIdentities?: StorefrontIdentity[] | null;
    urlDomain: string;
}

export type StorefrontApiCreateInput = Omit<
    Storefront,
    keyof StorefrontIdFields | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type StorefrontApiUpdateInput = StorefrontIdFields &
    Partial<
        Omit<
            Storefront,
            | keyof StorefrontIdFields
            | keyof StorefrontRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type StorefrontModelCreateInput = StorefrontApiCreateInput;

export type StorefrontModelUpdateInput = StorefrontApiUpdateInput;

export type PublicStorefrontGql = Pick<
    Storefront,
    | keyof StorefrontIdFields
    | keyof StorefrontRelationshipFields
    | "description"
    | "name"
    | "urlDomain"
>;
