import { ResourceType } from "./global";
import { Stage } from "./serviceClient";

/** This also exists in infrastructure-constants, use most appropriate version */
export const RESONANCE_BASE_DOMAIN_NAME = "buildresonance.com";
/** This also exists in infrastructure-constants, use most appropriate version */
export const RESONANCEAPPS_BASE_DOMAIN_NAME = "buildresonance.solutions";

export const ALL_RESONANCE_BASE_DOMAIN_NAMES = [
    RESONANCE_BASE_DOMAIN_NAME,
    RESONANCEAPPS_BASE_DOMAIN_NAME,
];

export const RESONANCE_INTERNAL_USERS_GROUP = "InternalUsers";

export enum ResonanceDomain {
    AdminIdentity = "adminidentity",
    Cdn = "cdn",
    Demand = "demand",
    Docs = "docs",
    Events = "events",
    Internal = "internal",
    Marketing = "marketing",
    Supply = "supply",
}

export const ALL_RESONANCE_DOMAINS = Object.values(ResonanceDomain);

export const buildResonanceDomainNameSsmParam = (
    domain: ResonanceDomain,
    stage: Stage,
    resourceType: ResourceType,
): string => `/${domain}/domainName/${resourceType}/${stage}`;

export const buildResonanceDomainName = (
    domain: ResonanceDomain,
    stage: Stage,
    resourceType: ResourceType,
): string => {
    if (resourceType === ResourceType.Api) {
        if (stage === Stage.Prod) {
            return `api.${domain}.${RESONANCE_BASE_DOMAIN_NAME}`;
        }
        return `api.${stage}.${domain}.${RESONANCE_BASE_DOMAIN_NAME}`;
    }

    if (stage === Stage.Prod) {
        return domain === ResonanceDomain.Marketing
            ? `www.${RESONANCE_BASE_DOMAIN_NAME}`
            : `${domain}.${RESONANCE_BASE_DOMAIN_NAME}`;
    }
    return domain === ResonanceDomain.Marketing
        ? `${stage}.${RESONANCE_BASE_DOMAIN_NAME}`
        : `${stage}.${domain}.${RESONANCE_BASE_DOMAIN_NAME}`;
};

/**
 * Detects if the second-level domain of a url is a resonance domain.
 *
 * @param url The url to detect the domain from
 * @returns {ResonanceDomain | undefined}
 */
export const detectResonanceDomainName = (
    url: string,
): ResonanceDomain | undefined => {
    if (!url.includes(RESONANCE_BASE_DOMAIN_NAME)) {
        return undefined;
    }

    for (const resonanceDomain of ALL_RESONANCE_DOMAINS) {
        if (url.includes(`${resonanceDomain}.${RESONANCE_BASE_DOMAIN_NAME}`))
            return resonanceDomain;
    }

    return ResonanceDomain.Marketing;
};
