import {
    CollectionTypeContentApiCreateInput,
    CollectionTypeContentApiUpdateInput,
} from "@buildresonance/resonance-lib-entities";
import { ObjectSchema, object, string } from "yup";

import { seoFieldsSchema } from "../common/seo";
import { tagSmallArraySchema } from "../common/tags";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";

import {
    collectionContentAsStringSchema,
    COLLECTION_TITLE_MAX_LENGTH,
    collectionTypeSchema,
    COLLECTION_DESCRIPTION_MAX_LENGTH,
} from "./collection";
import {
    collectionTypeContentIdSchema,
    demandHqIdSchema,
    storefrontIdSchema,
} from "./demandIds";

export const collectionTypeContentApiCreateInputSchema: ObjectSchema<CollectionTypeContentApiCreateInput> =
    object({
        collectionType: collectionTypeSchema.required(),
        demandHqId: demandHqIdSchema.required(),
        contentAsString: collectionContentAsStringSchema.nullable(),
        description: string().max(COLLECTION_DESCRIPTION_MAX_LENGTH).nullable(),
        secondaryContentAsString: collectionContentAsStringSchema.nullable(),
        storefrontId: storefrontIdSchema.required(),
        subtitle: string().max(COLLECTION_TITLE_MAX_LENGTH).nullable(),
        tags: object({
            raw: tagSmallArraySchema.nullable(),
        })
            .nullable()
            .noUnknown()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        title: string().max(COLLECTION_TITLE_MAX_LENGTH).nullable(),
    })
        .concat(seoFieldsSchema)
        .noUnknown();

export const collectionTypeContentApiUpdateInputSchema: ObjectSchema<CollectionTypeContentApiUpdateInput> =
    object({
        collectionTypeContentId: collectionTypeContentIdSchema.required(),
        contentAsString: collectionContentAsStringSchema.nullable(),
        description: string().max(COLLECTION_DESCRIPTION_MAX_LENGTH).nullable(),
        secondaryContentAsString: collectionContentAsStringSchema.nullable(),
        subtitle: string().max(COLLECTION_TITLE_MAX_LENGTH).nullable(),
        tags: object({
            raw: tagSmallArraySchema.nullable(),
        })
            .nullable()
            .noUnknown()
            .transform(requiredOnlyWhenExistsObjectTransformer)
            .default(undefined),
        title: string().max(COLLECTION_TITLE_MAX_LENGTH).nullable(),
    })
        .concat(seoFieldsSchema)
        .noUnknown();
