import {
    VariantCommon,
    VariantDeliveryMethod,
    VariantShippingDimensionUnit,
    VariantStandardizedColor,
    VariantWeightUnit,
} from "@buildresonance/resonance-lib-entities";
import { object, number, string, ObjectSchema } from "yup";

import { transformNaNToNull } from "../numberHelpers";
import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";
import { transformBlankStringToNull } from "../stringHelpers";

export const VARIANT_BARCODE_MAX_LENGTH = 32;
export const VARIANT_MANUFACTURER_MAX_LENGTH = 256;
export const VARIANT_MPN_MAX_LENGTH = 64;
export const VARIANT_SKU_MAX_LENGTH = 64;
export const VARIANT_TAX_CODE_MAX_LENGTH = 32;

export const variantShippingDimensionsSchema = object({
    length: number().required().min(0).transform(transformNaNToNull),
    width: number().required().min(0).transform(transformNaNToNull),
    height: number().required().min(0).transform(transformNaNToNull),
    unit: string()
        .transform(transformBlankStringToNull)
        .required()
        .oneOf(Object.values(VariantShippingDimensionUnit)),
})
    .noUnknown()
    .transform(requiredOnlyWhenExistsObjectTransformer)
    .default(undefined);

export const variantWeightSchema = object({
    amount: number().required().positive().transform(transformNaNToNull),
    unit: string()
        .transform(transformBlankStringToNull)
        .required()
        .oneOf(Object.values(VariantWeightUnit)),
})
    .noUnknown()
    .transform(requiredOnlyWhenExistsObjectTransformer)
    .default(undefined);

export const variantCommonSchema: ObjectSchema<VariantCommon> = object({
    barcode: string().max(VARIANT_BARCODE_MAX_LENGTH).nullable(),
    casePack: number()
        .nullable()
        .integer()
        .min(0)
        .transform(transformNaNToNull),
    deliveryMethod: string()
        .transform(transformBlankStringToNull)
        .oneOf(Object.values(VariantDeliveryMethod))
        .nullable(),
    manufacturer: string().nullable().max(VARIANT_MANUFACTURER_MAX_LENGTH),
    mapPrice: number().nullable().positive().transform(transformNaNToNull),
    mpn: string().nullable().max(VARIANT_MPN_MAX_LENGTH),
    msrp: number().nullable().positive().transform(transformNaNToNull),
    shippingDimensions: variantShippingDimensionsSchema.nullable(),
    sku: string().nullable().max(VARIANT_SKU_MAX_LENGTH),
    standardizedColor: string()
        .transform(transformBlankStringToNull)
        .nullable()
        .oneOf([...Object.values(VariantStandardizedColor), null, undefined]),
    taxCode: string().nullable().max(VARIANT_TAX_CODE_MAX_LENGTH),
    weight: variantWeightSchema.nullable(),
}).noUnknown();
