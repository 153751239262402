import React, { ReactElement, useState } from "react";

import { Collapse, useMediaQuery, useTheme } from "@mui/material";

import { DemandHeaderCenter } from "./DemandHeaderCenter";
import { DemandHeaderLeftParent } from "./DemandHeaderLeftParent";
import { DemandHeaderRight } from "./DemandHeaderRight";
import { DemandResponsiveHeader } from "./DemandResponsiveHeader";

const DemandHeader = ({
    setMobileNavIsOpen,
}: {
    setMobileNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));

    const [searchInputIsFocused, setSearchInputIsFocused] = useState(false);

    return (
        <DemandResponsiveHeader>
            <Collapse
                orientation="horizontal"
                in={!isSmall || !searchInputIsFocused}
            >
                <DemandHeaderLeftParent
                    setMobileNavIsOpen={setMobileNavIsOpen}
                />
            </Collapse>
            <DemandHeaderCenter
                setSearchInputIsFocused={setSearchInputIsFocused}
            />
            <Collapse
                orientation="horizontal"
                in={!isSmall || !searchInputIsFocused}
            >
                <DemandHeaderRight />
            </Collapse>
        </DemandResponsiveHeader>
    );
};

export { DemandHeader };
