import { ResonanceServiceId } from "./AdminIdentityIds";

export interface ResonanceServiceIdFields {
    resonanceServiceId: ResonanceServiceId;
}

export interface ResonanceService extends ResonanceServiceIdFields {
    secret: string;
}

export type ResonanceServiceModelCreateInput = ResonanceServiceIdFields;
