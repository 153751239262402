import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { ContentItem } from "../cdn";
import { ContentItemId } from "../cdn/CdnIds";

import { SeoFields } from "./common/SeoFields";
import { DemandEntityStatus } from "./DemandEntity";
import { DemandHqId, CollectionId, StorefrontId, ProductId } from "./DemandIds";

export const COLLECTION_POSITION_MAX_VALUE = 9999999999;

/** Indicates a significant Product tag category/key combination for this collection, typically for faceting purposes. */
export interface CollectionProductTagPrefix {
    category?: string | null;
    key: string;
    position?: number | null;
}

export enum CollectionRuleField {
    Tag = "TAG",
    Vendor = "VENDOR",
}

/**
 * Operators should always be affirmative (never contain the word "not" or "doesn't").
 * There is a "not" category of rules instead.
 */
export enum CollectionRuleOperator {
    Equals = "EQUALS",
}

export interface CollectionRule<TValue extends string = string> {
    field: CollectionRuleField;
    operator: CollectionRuleOperator;
    value: TValue;
}

export interface CollectionRules {
    all?: CollectionRule[] | null;
    any?: CollectionRule[] | null;
    not?: CollectionRule[] | null;
}

export interface CollectionIdFields {
    collectionId: CollectionId;
}

export interface CollectionRelationshipFields {
    demandHqId: DemandHqId;
}

/**
 * A Collection is a group of products, what may often be referred to as a "Category Page" of some variety.
 * In reality, a collection is far more flexible and can be used to represent any product grouping, and the collectionType can help differentiate between them.
 */
export interface Collection
    extends CollectionIdFields,
        CollectionRelationshipFields,
        SeoFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /** ContentItems associated with this collection. No other info needed. */
    contentItemIds?: ContentItemId[] | null;
    /**
     * Any string to declare a category of this collection, can be used to filter collections, limit 128 characters.
     * This string must be in slug format (lowercase and dashes).
     */
    collectionType: string;
    /** A short description of the collection, limit 1000 characters. */
    description?: string | null;
    /**
     * This can be used to pin certain products to the top of a collection when using certain sorts.
     * Max number of products is 20.
     */
    featuredProductIds?: ProductId[] | null;
    /** Indicates significant Product tag category/key combinations for this collection, typically for faceting purposes. */
    productTagPrefixes?: CollectionProductTagPrefix[] | null;
    /** A primary image url for this collection, can be generated from a Collection ContentItem. */
    primaryImageUrl?: string | null;
    /** ISO format date when the collection was published. Note that ensuing updates with status set to PUBLISHED will republish and re-set this date. */
    publishedAt?: string | null;
    /**
     * Set of Rules used to determine which products are part of this collection.
     * Maximum of 10 rules per rule type.
     */
    rules?: CollectionRules | null;
    /** A secondary image url for this collection, often a logo or other related image. */
    secondaryImageUrl?: string | null;
    /** Slug for the URL. Limit 128 characters. */
    slug: string;
    /** The status of the collection. */
    status: DemandEntityStatus;
    /** Which storefronts should have access to this collection? */
    storefrontIds?: StorefrontId[] | null;
    /** The subtitle of the collection, limit 256 characters. */
    subtitle?: string | null;
    /** A tertiary image url for this collection, not often used, but available. */
    tertiaryImageUrl?: string | null;
    /** The title of the collection, limit 256 characters. */
    title: string;
}

/**
 * The actual content itself, a child object of the Collection with a 1-1 relationship.
 */
export interface CollectionContent
    extends CollectionIdFields,
        CollectionRelationshipFields,
        ResonanceUpdatedBy {
    contentAsString: string;
    secondaryContentAsString?: string | null;
    updatedAt: string;
}

/**
 * The tags associated with this collection, a child object of the Collection with a 1-1 relationship.
 * This is separate due to possible size, at 1000 * 96 = 96000 bytes, way more than we want to store with many GSI indexes, but we still want Dynamo speed (and cost is comparable to S3 for these params).
 * This enables us not to pull them for list queries and such as well, this should be obscured from consumers by the API, but still obvious at the model level.
 */
export interface CollectionTags
    extends CollectionIdFields,
        CollectionRelationshipFields,
        ResonanceUpdatedBy {
    /** Tags for this collection, limit to 1000 and 96 characters each. */
    raw?: string[] | null;
    updatedAt: string;
}

export type CollectionModelCreateInput = Omit<
    Collection,
    keyof CollectionIdFields | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type CollectionApiCreateInput = Omit<
    CollectionModelCreateInput,
    "publishedAt" | "status"
> & {
    /** Tags for this collection, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

export type CollectionModelUpdateInput = CollectionIdFields &
    Partial<
        Omit<
            Collection,
            | keyof CollectionIdFields
            | keyof CollectionRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type CollectionApiUpdateInput = CollectionModelUpdateInput & {
    /** Tags for this collection, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

export interface CollectionGql extends Collection {
    content?:
        | (CollectionContent & {
              contentAsHtml: string;
              secondaryContentAsHtml?: string | null;
          })
        | null;
    /** The primaryImageUrl, if exists, or the url of the first contentItem in the contentItemsId array for the page, if it exists.  If the primaryImageUrl is a CDN url, it can be given resize properties. */
    primaryImageUrlWithFallback?: string | null;
    tags?: CollectionTags | null;
}

export type CollectionContentApiUpsertInput = Pick<
    CollectionContent,
    "contentAsString" | "secondaryContentAsString"
> &
    CollectionIdFields;

export type CollectionContentModelUpsertInput =
    CollectionContentApiUpsertInput & CollectionRelationshipFields;

export interface CollectionImageFromFileApiCreateInput
    extends CollectionIdFields,
        CollectionRelationshipFields {
    /** Limit 1000 characters */
    fileName: string;
    /** Limit 100 characters */
    fileExtension: string;
}

export interface CollectionImageFromUrlApiCreateInput
    extends CollectionIdFields,
        CollectionRelationshipFields {
    /**
     * The URL of the image to upload.
     *
     * Should match url structure, including https://, and should be a valid image.
     */
    url: string;
}

/**
 * Note that this is totally different from CollectionContent, and instead refers to "ContentItems" for Collections, being images, videos, and other.
 * This naming was held because ContentItems are technically children of Content.
 */
export type CollectionContentItem = Pick<
    ContentItem,
    "contentExtension" | "contentItemId" | "contentType"
> & {
    contentKey: string;
    demandHqId: DemandHqId;
};

export type PublicCollectionGql = Pick<
    CollectionGql,
    | "collectionId"
    | "collectionType"
    | "content"
    | "contentItemIds"
    | "demandHqId"
    | "description"
    | "featuredProductIds"
    | "productTagPrefixes"
    | "primaryImageUrl"
    | "primaryImageUrlWithFallback"
    | "publishedAt"
    | "rules"
    | "secondaryImageUrl"
    | "seoDescription"
    | "seoTitle"
    | "slug"
    | "storefrontIds"
    | "subtitle"
    | "tags"
    | "tertiaryImageUrl"
    | "title"
>;

/**
 * This is what Search stores for collections.
 */
export interface CollectionComposite {
    collection: Collection;
    collectionContent?: CollectionContent | null;
    collectionTags?: CollectionTags | null;
}

export type CollectionSearchComposite = CollectionComposite;
