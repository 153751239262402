export enum S3FileExtension {
    CSV = "csv",
    JSON = "json",
}

export enum BulkActionStatus {
    Created = "CREATED",
    InProgress = "IN_PROGRESS",
    Completed = "COMPLETED",
    Error = "ERROR",
}
