import { ResonanceUpdatedBy } from "../../entityComposites";
import {
    ResonanceCurrency,
    ResonanceDates,
    ResonanceLocaleType,
} from "../../global";
import { UserName } from "../adminidentity/AdminIdentityIds";

import { DemandHqId } from "./DemandIds";
import { DemandSubscriptionCode } from "./DemandSubscription";

/**
 * Indicates what stage a DemandHq is in.
 * This field is unlikely to even be exposed through the API or UI, and instead just used to shut down DemandHqs someday.
 * In the short term, just assign everything to PUBLISHED, as this field is important for getting all DemandHqs when needed.
 */
export enum DemandHqStatus {
    /** Indicates a DemandHq's goods are no longer available for retailing. */
    Archived = "ARCHIVED",
    /** Indicates that a DemandHq's goods are available for retailing. */
    Published = "PUBLISHED",
}

/** IdFields are the ones that are required to find the correct entity. Must come in all update methods. */
export interface DemandHqIdFields {
    demandHqId: DemandHqId;
}

/**
 * The most basic item in the DemandHq domain, who's primary purpose is to create product and product-related experiences
 * that create demand for Orders.
 * This item should never be deleted once it exists, archive instead.
 */
export interface DemandHq
    extends DemandHqIdFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    defaultLocaleType: ResonanceLocaleType;
    defaultCurrency: ResonanceCurrency;
    demandSubscriptionCodes?: DemandSubscriptionCode[] | null;
    /** 8000 character limit */
    description?: string | null;
    /** 128 character limit */
    name: string;
    /**
     * These users will get notified if something requires attention for this DemandHq.
     * If none are provided, ownerUserName will be notified.
     */
    notificationUserNames?: {
        catalog?: UserName[] | null;
        commerce?: UserName[] | null;
        /** This is part of commerce, but has specific enough notification requirements to be split out */
        orders?: UserName[] | null;
    } | null;
    ownerUserName: UserName;
    status: DemandHqStatus;
}

export type DemandHqGql = DemandHq;

export type DemandHqModelUpdateInput = DemandHqIdFields &
    Partial<
        Omit<
            DemandHq,
            | keyof DemandHqIdFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type DemandHqModelCreateInput = Omit<
    DemandHq,
    "demandHqId" | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type DemandHqApiUpdateInput = Omit<
    DemandHqModelUpdateInput,
    "status" | keyof ResonanceDates
>;

/**
 * Just ownerUserName missing.
 */
export type DemandHqApiCreateInput = Omit<
    DemandHqModelCreateInput,
    "ownerUserName" | "status"
>;

export type PublicDemandHqGql = Pick<
    DemandHqGql,
    "demandHqId" | "description" | "name"
>;

export const PUBLIC_DEMAND_HQ_FIELDS: (keyof PublicDemandHqGql)[] = [
    "demandHqId",
    "description",
    "name",
];
