import { ResonanceDates } from "../../global";
import { AnyObject } from "../../utility";

import { EventsEntityIdPrefix } from "./EventsEntity";
import { ResonanceEvent, ResonanceEventCompressed } from "./ResonanceEvent";
import { ResonanceEventSubscription } from "./ResonanceEventSubscription";

export interface ResonanceEventSubscriptionSend<
    TData extends AnyObject = AnyObject,
> {
    event: ResonanceEvent<TData>;
    eventSubscription: ResonanceEventSubscription;
}

export interface ResonanceEventCompressedSubscriptionSend {
    event: ResonanceEventCompressed;
    eventSubscription: ResonanceEventSubscription;
}

export type ResonanceEventSubscriptionFailureId =
    `${EventsEntityIdPrefix.ResonanceEventSubscriptionFailure}-${string}`;

export interface ResonanceEventSubscriptionFailureIdFields {
    eventSubscriptionFailureId: ResonanceEventSubscriptionFailureId;
}

/**
 * Represents a consistent failure to send an event to a subscriber.
 * Only created after all retries fail.
 */
export interface ResonanceEventSubscriptionFailure
    extends ResonanceEventSubscriptionSend,
        ResonanceEventSubscriptionFailureIdFields,
        ResonanceDates {
    /** Dynamo TTL */
    expiresAt?: number;
}

export type ResonanceEventSubscriptionFailureModelCreateInput = Omit<
    ResonanceEventSubscriptionFailure,
    | "expiresAt"
    | keyof ResonanceEventSubscriptionFailureIdFields
    | keyof ResonanceDates
>;
