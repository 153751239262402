import {
    DemandHqGql,
    DemandHqId,
} from "@buildresonance/resonance-lib-entities";
import { gql, GraphQLClient } from "graphql-request";
import { QueryFunction } from "react-query";

const GET_DEMAND_HQ_QUERY = gql`
    query getDemandHq($demandHqId: ID!) {
        demandHq(demandHqId: $demandHqId) {
            createdAt
            defaultCurrency
            defaultLocaleType
            demandHqId
            demandSubscriptionCodes
            description
            name
            ownerUserName
            updatedAt
        }
    }
`;

export type GetDemandHqQueryKey = [string, DemandHqId];

export const buildGetDemandHqQueryKey = (
    demandHqId: DemandHqId,
): GetDemandHqQueryKey => [`getDemandHq`, demandHqId];

export interface GetDemandHqResponse {
    demandHq: Pick<
        DemandHqGql,
        | "createdAt"
        | "demandHqId"
        | "defaultCurrency"
        | "defaultLocaleType"
        | "demandSubscriptionCodes"
        | "description"
        | "ownerUserName"
        | "name"
        | "updatedAt"
    >;
}

const getDemandHq =
    (
        client: GraphQLClient,
    ): QueryFunction<GetDemandHqResponse, GetDemandHqQueryKey> =>
    async ({ queryKey }) => {
        return await client.request<
            GetDemandHqResponse,
            {
                demandHqId: DemandHqId;
            }
        >(GET_DEMAND_HQ_QUERY, { demandHqId: queryKey?.[1] });
    };

export { getDemandHq };
