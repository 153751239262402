import { ResonanceDomain } from "./domainNames";
import {
    AdminIdentityEntity,
    AdminIdentityIdPrefix,
    ADMIN_IDENTITY_ENTITY_TO_ID_PREFIX_MAP,
} from "./domains/adminidentity/AdminIdentityEntity";
import {
    PartnerId,
    AppId,
    UserName,
    AppClientId,
    ResonanceAdminIdentityIds,
    ResonanceServiceId,
} from "./domains/adminidentity/AdminIdentityIds";
import {
    CdnEntity,
    CdnEntityIdPrefix,
    CDN_ENTITY_TO_ID_PREFIX_MAP,
} from "./domains/cdn/CdnEntity";
import { ResonanceCdnIds } from "./domains/cdn/CdnIds";
import {
    DemandEntity,
    DemandEntityIdPrefix,
    DemandHqId,
    DemandSecureEntityIdPrefix,
    DEMAND_ENTITY_TO_ID_PREFIX_MAP,
    ResonanceDemandIds,
} from "./domains/demand";
import {
    EventsEntity,
    EventsEntityIdPrefix,
    EVENTS_ENTITY_TO_ID_PREFIX_MAP,
} from "./domains/events/EventsEntity";
import {
    SupplyEntity,
    SupplyEntityIdPrefix,
    SUPPLY_ENTITY_TO_ID_PREFIX_MAP,
} from "./domains/supply/SupplyEntity";
import { ResonanceSupplyIds, SupplierId } from "./domains/supply/SupplyIds";

/**
 * Represents the Id for the top level of an entity in a certain domain or domain group.
 * "global" represents something that exists outside one of these trees.
 */
export type ResonanceDomainEntityId =
    | "global"
    | DemandHqId
    | PartnerId
    | SupplierId;

export type ResonanceId =
    | ResonanceAdminIdentityIds
    | ResonanceCdnIds
    | ResonanceDemandIds
    | ResonanceSupplyIds;

export type ResonanceEntity =
    | AdminIdentityEntity
    | CdnEntity
    | DemandEntity
    | EventsEntity
    | SupplyEntity;

export const ALL_RESONANCE_ENTITIES: ResonanceEntity[] = [
    ...Object.values(AdminIdentityEntity),
    ...Object.values(CdnEntity),
    ...Object.values(DemandEntity),
    ...Object.values(EventsEntity),
    ...Object.values(SupplyEntity),
];

/**
 * You may need to UPPERCASE the entity name to use this.
 */
export const RESONANCE_ENTITY_TO_DOMAIN_MAP: Record<
    ResonanceEntity,
    ResonanceDomain
> = ALL_RESONANCE_ENTITIES.reduce(
    (result, entity) => {
        if (
            Object.values(AdminIdentityEntity).includes(
                entity as AdminIdentityEntity,
            )
        ) {
            result[entity] = ResonanceDomain.AdminIdentity;
            return result;
        }
        if (Object.values(CdnEntity).includes(entity as CdnEntity)) {
            result[entity] = ResonanceDomain.Cdn;
            return result;
        }
        if (Object.values(DemandEntity).includes(entity as DemandEntity)) {
            result[entity] = ResonanceDomain.Demand;
            return result;
        }
        if (Object.values(EventsEntity).includes(entity as EventsEntity)) {
            result[entity] = ResonanceDomain.Events;
            return result;
        }
        if (Object.values(SupplyEntity).includes(entity as SupplyEntity)) {
            result[entity] = ResonanceDomain.Supply;
            return result;
        }
        throw new Error(`Unknown resonance entity: ${entity}`);
    },
    {} as Record<ResonanceEntity, ResonanceDomain>,
);

// Always 5 characters
export type ResonanceEntityIdPrefix =
    | AdminIdentityIdPrefix
    | CdnEntityIdPrefix
    | DemandEntityIdPrefix
    | DemandSecureEntityIdPrefix
    | EventsEntityIdPrefix
    | SupplyEntityIdPrefix;

export const ALL_RESONANCE_ID_PREFIXES: ResonanceEntityIdPrefix[] = [
    ...Object.values(AdminIdentityIdPrefix),
    ...Object.values(CdnEntityIdPrefix),
    ...Object.values(DemandEntityIdPrefix),
    ...Object.values(DemandSecureEntityIdPrefix),
    ...Object.values(EventsEntityIdPrefix),
    ...Object.values(SupplyEntityIdPrefix),
];

export const ALL_RESONANCE_ENTITIES_TO_ID_PREFIX_MAP: Record<
    ResonanceEntity,
    ResonanceEntityIdPrefix
> = {
    ...ADMIN_IDENTITY_ENTITY_TO_ID_PREFIX_MAP,
    ...CDN_ENTITY_TO_ID_PREFIX_MAP,
    ...DEMAND_ENTITY_TO_ID_PREFIX_MAP,
    ...EVENTS_ENTITY_TO_ID_PREFIX_MAP,
    ...SUPPLY_ENTITY_TO_ID_PREFIX_MAP,
};

export const ALL_ID_PREFIXES_TO_RESONANCE_ENTITY_MAP: Record<
    ResonanceEntityIdPrefix,
    ResonanceEntity
> = Object.fromEntries(
    Object.entries(ALL_RESONANCE_ENTITIES_TO_ID_PREFIX_MAP)
        .reverse()
        .map(([key, value]) => [value, key]),
) as Record<ResonanceEntityIdPrefix, ResonanceEntity>;

/**
 * Used to classify an identity.
 */
export type ResonanceIdentityType =
    | AdminIdentityEntity.AppClient
    | AdminIdentityEntity.ResonanceService
    | AdminIdentityEntity.User;

/**
 * IdentityId is easy to confuse with UserName.
 * Because Cognito does not allow renaming, all identityIds are called userNames there.
 * Where we DO have control, UserName will refer to a User's UserName, and if it is the Cognito userName for ANY identity, use this.
 *
 * Note that this entity uses AppClientId, not AppId. Use this entity for permission-based identity items, but not for things that apply to a whole App.
 * You can use AppClientId to lookup AppId if needed.
 *
 * For a composite with AppId use
 * @see AppIdOrUserName
 *
 * @see ResonanceServiceId for more explanation on how it is used.
 */
export type ResonanceIdentityId = UserName | AppClientId | ResonanceServiceId;

/**
 * If all you have is an ID and you need to know what type of identity it is.
 *
 * @param identityId Any ResonanceId
 * @returns The IdentityType, or null if it isn't an IdentityId
 */
export const determineIdentityTypeByIdentityId = (
    identityId: ResonanceIdentityId,
): ResonanceIdentityType | null => {
    if (identityId.startsWith(AdminIdentityIdPrefix.AppClient)) {
        return AdminIdentityEntity.AppClient;
    }
    if (identityId.startsWith(AdminIdentityIdPrefix.ResonanceService)) {
        return AdminIdentityEntity.ResonanceService;
    }
    if (identityId.startsWith(AdminIdentityIdPrefix.User)) {
        return AdminIdentityEntity.User;
    }
    return null;
};

/**
 * May be easy to confuse with
 * @see ResonanceIdentityId
 *
 * This one uses AppId for apps, rather than AppClientId (the Cognito entity). Use this when the App itself is the important entity (not permissions which would apply to both app clients).
 */
export type AppIdOrUserName = AppId | UserName;

/**
 * For places where an identity may have an applicable parent. Just Apps for now.
 */
export type IdentityParentEntityId = AppId;

/**
 * Just to ensure naming is identical
 */
export interface ResonanceCreatedBy {
    /**
     * In rare cases, where the creator is unlikely to ever interact with an entity, but another identity likely will interact, and we need to keep track of both...use this.
     */
    createdBy: ResonanceIdentityId;
}

/**
 * Just to ensure naming is identical
 */
export interface ResonanceUpdatedBy {
    /**
     * This field holds the userName (or appClientId, but that's nearly the same thing) of the entity that last changed this item.
     * This is most valuable on events, as each event will contain the entity that made the event occur. Use liberally.
     * This applies on create as well, we just didn't need the separate field.
     */
    updatedBy: ResonanceIdentityId;
}
