import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { SupplierVariantSupplement } from "../supply";
import { SupplierVariant } from "../supply/SupplierVariant";
import {
    SupplierId,
    SupplierProductId,
    SupplierVariantId,
} from "../supply/SupplyIds";

import { DemandHqId, ProductId, VariantId } from "./DemandIds";

export interface VariantSupplierVariantIdFields {
    supplierVariantId: SupplierVariantId;
    variantId: VariantId;
}

export interface VariantSupplierVariantRelationshipFields {
    demandHqId: DemandHqId;
    productId: ProductId;
    supplierId: SupplierId;
    supplierProductId: SupplierProductId;
}

/**
 * This provides a window into a SupplierVariant associated with a Variant,
 * and can be used to determine inventory and cost as well as for assigning OrderItems.
 *
 * This lives in Demand, as opposed to SupplierDemandHq, which lives in Supply,
 * because while permissions are a Supply-side concern, inventory and cost are Demand-side.
 */
export type VariantSupplierVariant = VariantSupplierVariantIdFields &
    VariantSupplierVariantRelationshipFields &
    ResonanceDates &
    ResonanceUpdatedBy &
    Pick<SupplierVariant, "inventoryPolicy" | "inventoryQuantity"> &
    Pick<
        SupplierVariantSupplement,
        "averageShippingCost" | "cost" | "handlingCost"
    >;

export type VariantSupplierVariantModelCreateInput = Omit<
    VariantSupplierVariant,
    keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type VariantSupplierVariantApiCreateInput =
    VariantSupplierVariantModelCreateInput;

export type VariantSupplierVariantModelUpdateInput =
    VariantSupplierVariantIdFields &
        Partial<
            Omit<
                VariantSupplierVariant,
                | keyof VariantSupplierVariantIdFields
                | keyof VariantSupplierVariantRelationshipFields
                | keyof ResonanceDates
                | keyof ResonanceUpdatedBy
            >
        >;

export type VariantSupplierVariantApiUpdateInput =
    VariantSupplierVariantModelUpdateInput;
