import {
    UserNotificationStatus,
    UserNotificationApiUpdateStatusInput,
} from "@buildresonance/resonance-lib-entities";
import { object, ObjectSchema, string } from "yup";

import { transformBlankStringToNull } from "../stringHelpers";

import { userNotificationIdSchema } from "./adminIdentityIds";

export const userNotificationApiUpdateStatusInputSchema: ObjectSchema<UserNotificationApiUpdateStatusInput> =
    object({
        status: string<UserNotificationStatus>()
            .transform(transformBlankStringToNull)
            .required()
            .oneOf(Object.values(UserNotificationStatus)),
        userNotificationId: userNotificationIdSchema.required(),
    }).noUnknown();
