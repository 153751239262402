import {
    CollectionId,
    CustomerId,
    DemandHqCommentEntityId,
    OrderId,
    PageId,
    ProductId,
    VariantId,
} from "@buildresonance/resonance-lib-entities";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import { useRouter } from "next/router";

export const useDemandHqCommentEntityId = ():
    | DemandHqCommentEntityId
    | undefined => {
    const router = useRouter();

    switch (router.pathname) {
        case DemandAdminRoute.CollectionEdit.pathname:
            return router.query.collectionId as CollectionId;
        case DemandAdminRoute.CustomerEdit.pathname:
            return router.query.customerId as CustomerId;
        case DemandAdminRoute.OrderEdit.pathname:
            return router.query.orderId as OrderId;
        case DemandAdminRoute.PageEdit.pathname:
            return router.query.pageId as PageId;
        case DemandAdminRoute.ProductEdit.pathname:
            return router.query.productId as ProductId;
        case DemandAdminRoute.VariantEdit.pathname:
            return router.query.variantId as VariantId;
        default:
            return undefined;
    }
};
