import React, { ReactElement } from "react";

import { useTheme } from "@mui/material";
import { Box } from "@mui/system";

import { CollectionSearchInputParent } from "./CollectionSearchInputParent";
import { CustomerSearchInputParent } from "./CustomerSearchInputParent";
import { OrderSearchInputParent } from "./OrderSearchInputParent";
import { PageSearchInputParent } from "./PageSearchInputParent";
import { ProductSearchInputParent } from "./ProductSearchInputParent";

const DemandHeaderCenter = ({
    setSearchInputIsFocused,
}: {
    setSearchInputIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flex="1"
            alignItems="center"
            marginX={theme.spacing(3)}
        >
            {/* These render conditionally based on the current route */}
            <CollectionSearchInputParent
                setSearchInputIsFocused={setSearchInputIsFocused}
            />
            <CustomerSearchInputParent
                setSearchInputIsFocused={setSearchInputIsFocused}
            />
            <OrderSearchInputParent
                setSearchInputIsFocused={setSearchInputIsFocused}
            />
            <PageSearchInputParent
                setSearchInputIsFocused={setSearchInputIsFocused}
            />
            <ProductSearchInputParent
                setSearchInputIsFocused={setSearchInputIsFocused}
            />
        </Box>
    );
};

export { DemandHeaderCenter };
