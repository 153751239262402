import React, { ReactElement } from "react";

import { Box } from "@mui/system";

import { CommentsButtonParent } from "./entityButtons/CommentsButtonParent";
import { EventsButtonParent } from "./entityButtons/EventsButtonParent";
import { UserButtonParent } from "./UserButtonParent";

const DemandHeaderRight = (): ReactElement => {
    return (
        <Box display="flex" alignItems="center">
            <EventsButtonParent />
            <CommentsButtonParent />
            <UserButtonParent />
        </Box>
    );
};

export { DemandHeaderRight };
