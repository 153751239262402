import React, { ReactElement } from "react";

import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import { useRouter } from "next/router";

import { AppBarSearchAutocomplete } from "../common/AppBarSearchAutocomplete";
import { DemandEntityRecentSearchesLocalStorageKey } from "../common/constants";

import { useProductAutoComplete } from "./useProductAutoComplete";

const ProductSearchInputParent = ({
    setSearchInputIsFocused,
}: {
    setSearchInputIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement | null => {
    const router = useRouter();

    const demandHqId = router.query.demandHqId as DemandHqId | undefined;

    const { debouncedQuery, productAutoCompletes } =
        useProductAutoComplete(demandHqId);

    if (
        !demandHqId ||
        ![
            DemandAdminRoute.ProductCreate.pathname,
            DemandAdminRoute.ProductEdit.pathname,
            DemandAdminRoute.Products.pathname,
        ].includes(router.pathname)
    ) {
        return null;
    }

    return (
        <AppBarSearchAutocomplete
            autoCompleteQueryResponse={productAutoCompletes}
            id="product-search-input"
            label="Product Search"
            placeholder="Search Products..."
            searchPathName={DemandAdminRoute.Products.pathname}
            sendDebouncedAutoCompleteQuery={debouncedQuery}
            setSearchInputIsFocused={setSearchInputIsFocused}
            localStorageKey={DemandEntityRecentSearchesLocalStorageKey.Product}
        />
    );
};

export { ProductSearchInputParent };
