import { ResonanceCreatedBy } from "../../../entityComposites";
import { ResonanceDates } from "../../../global";
import { DemandEntity, DemandEntityIdPrefix } from "../DemandEntity";
import {
    CollectionId,
    CollectionTypeContentId,
    CustomerId,
    DemandHqCommentId,
    DemandHqId,
    OrderId,
    PageId,
    PageTypeContentId,
    ProductId,
    VariantId,
} from "../DemandIds";

export const DEMAND_HQ_COMMENT_VALID_ID_PREFIXES = [
    DemandEntityIdPrefix.Collection,
    DemandEntityIdPrefix.CollectionTypeContent,
    DemandEntityIdPrefix.Customer,
    DemandEntityIdPrefix.Order,
    DemandEntityIdPrefix.Page,
    DemandEntityIdPrefix.PageTypeContent,
    DemandEntityIdPrefix.Product,
    DemandEntityIdPrefix.Variant,
];

export const DEMAND_HQ_COMMENT_VALID_ENTITIES: DemandEntity[] = [
    DemandEntity.Collection,
    DemandEntity.CollectionTypeContent,
    DemandEntity.Customer,
    DemandEntity.Order,
    DemandEntity.Page,
    DemandEntity.PageTypeContent,
    DemandEntity.Product,
    DemandEntity.Variant,
];

export type DemandHqCommentEntityId =
    | CollectionId
    | CollectionTypeContentId
    | CustomerId
    | OrderId
    | PageId
    | PageTypeContentId
    | ProductId
    | VariantId;

export interface DemandHqCommentIdFields {
    demandHqCommentId: DemandHqCommentId;
}

export interface DemandHqCommentRelationshipFields {
    demandHqId: DemandHqId;
    /** The entity this comment is about. */
    entityId: DemandHqCommentEntityId;
}

export interface DemandHqComment
    extends DemandHqCommentIdFields,
        DemandHqCommentRelationshipFields,
        ResonanceDates,
        ResonanceCreatedBy {
    /** Markdown is allowed. */
    contentAsString: string;
    expiresAt?: number | null;
}

export type DemandHqCommentModelCreateInput =
    DemandHqCommentRelationshipFields &
        Pick<DemandHqComment, "contentAsString" | "expiresAt">;

export type DemandHqCommentApiCreateInput = DemandHqCommentRelationshipFields &
    Pick<DemandHqComment, "contentAsString">;
