import React, { ReactElement } from "react";

import { StorefrontPathParam } from "@components/types";
import { DemandAdminRoute } from "@lib/common/DemandAdminRoute";
import { useRouter } from "next/router";

import { AppBarSearchAutocomplete } from "../common/AppBarSearchAutocomplete";
import { DemandEntityRecentSearchesLocalStorageKey } from "../common/constants";

const CustomerSearchInputParent = ({
    setSearchInputIsFocused,
}: {
    setSearchInputIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement | null => {
    const router = useRouter();

    const { demandHqId, storefrontId } = router.query as StorefrontPathParam;

    if (
        !demandHqId ||
        !storefrontId ||
        ![
            DemandAdminRoute.CustomerCreate.pathname,
            DemandAdminRoute.CustomerEdit.pathname,
            DemandAdminRoute.Customers.pathname,
        ].includes(router.pathname)
    ) {
        return null;
    }

    return (
        <AppBarSearchAutocomplete
            autoCompleteQueryResponse={undefined}
            id="customer-search-input"
            label="Customer Search"
            placeholder="Search Customers..."
            searchPathName={DemandAdminRoute.Customers.pathname}
            sendDebouncedAutoCompleteQuery={() => {}}
            setSearchInputIsFocused={setSearchInputIsFocused}
            localStorageKey={DemandEntityRecentSearchesLocalStorageKey.Customer}
        />
    );
};

export { CustomerSearchInputParent };
