/* istanbul ignore file */

import { useEffect } from "react";

export const DEFAULT_HASH_PREFIX = "user-content-";

/**
 * This custom hook allows to redirect the user to the target element, even if it has a prefix to prevent DOM clobbering.
 * More specifically, a link like:
 * <a href="#my-target-element">...</a>
 * Will properly redirect to:
 * <h1 id="user-content-my-target-element">...</h1>
 * So long as this custom hook is present.
 *
 * The reasoning behind this has to do with DOM clobbering.
 * @see https://github.com/rehypejs/rehype-sanitize#example-headings-dom-clobbering
 *
 * @param idOrNamePrefix The prefix prepended to id or names, defaults to "user-content-" (like GitHub).
 * @param options
 * - clickDefaultNotPreventedOnly: If true, it will scroll to the anchor only if the default click event is not prevented. Otherwise it will always scroll to the anchor. Defaults to false.
 * - enabled: If false, the hook will not do anything. Defaults to true.
 */
const useHashLinkRedirector = (
    options: {
        clickDefaultNotPreventedOnly?: boolean;
        enabled?: boolean;
        idOrNamePrefix?: string;
    } = { idOrNamePrefix: DEFAULT_HASH_PREFIX },
): void => {
    // Page load
    useEffect(() => {
        // SSR-ready
        if (typeof window !== "undefined" && options?.enabled !== false) {
            return setAndRemoveHashLinkListeners(
                buildHashChangeFunction(options.idOrNamePrefix),
                {
                    clickDefaultNotPreventedOnly:
                        options?.clickDefaultNotPreventedOnly ?? false,
                },
            );
        }

        return;
    }, [options]);
};

/**
 * The reason this is abstracted this way is in case we ever need to use this outside React, just want it to be ready.
 *
 * @param idOrNamePrefix The prefix prepended to id or names, defaults to "user-content-" (like GitHub).
 * @returns A function that can be used to redirect the user to the target element, even if it has a prefix to prevent DOM clobbering.
 */
export const buildHashChangeFunction =
    (idOrNamePrefix = DEFAULT_HASH_PREFIX) =>
    (): void => {
        let hash: string | undefined;

        try {
            hash = decodeURIComponent(location.hash.slice(1)).toLowerCase();
        } catch {
            return;
        }

        if (hash) {
            const name = `${idOrNamePrefix}${hash}`;
            const target =
                document.getElementById(name) ||
                document.getElementsByName(name)[0];

            if (target) {
                setTimeout(() => {
                    target.scrollIntoView();
                }, 0);
            }
        }
    };

/**
 * The reason this is abstracted this way is in case we ever need to use this outside React, just want it to be ready.
 *
 * @param hashChange The function to call in order to redirect a hash link to one with a prefix.
 * @returns A function that can be used to remove all listeners.
 */
export const setAndRemoveHashLinkListeners = (
    hashChange: () => void,
    options: { clickDefaultNotPreventedOnly: boolean },
): (() => void) => {
    hashChange();

    // When URL changes.
    window.addEventListener("hashchange", hashChange);

    const clickEventListener = (event: MouseEvent): void => {
        if (
            event.target &&
            event.target instanceof HTMLAnchorElement &&
            event.target.href === location.href &&
            location.hash.length > 1
        ) {
            setTimeout(() => {
                if (
                    !options?.clickDefaultNotPreventedOnly ||
                    !event.defaultPrevented
                ) {
                    hashChange();
                }
            });
        }
    };

    // When on the URL already, perhaps after scrolling, and clicking again, which
    // doesn’t emit `hashChange`.
    document.addEventListener("click", clickEventListener, false);

    return () => {
        window.removeEventListener("hashchange", hashChange);
        document.removeEventListener("click", clickEventListener);
    };
};

export default useHashLinkRedirector;
