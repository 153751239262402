/**
 * Pagination args that control size, direction, and starting point.
 */
export interface RelayArgs {
    first?: number | null;
    last?: number | null;
    before?: string | null;
    after?: string | null;
}

/**
 * Anytime you are accepting RelayArgs, use this response shape to ensure the consumer can paginate.
 */
export interface RelayConnection<TEntity> {
    edges: {
        node: TEntity | null;
    }[];
    nodes: (TEntity | null)[];
    pageInfo: RelayPageInfo;
}

/**
 * Pagination response from the APIs.
 * Both end and startCursor should always be populated with the first and last values in the set. The presence of these does not necessarily indicate that there are more pages.
 * You may read that start and end cursors should be non-null, but there is nothing to populate them with when the set is empty. We undefine them here rather than obliterating the object.
 *
 * We do not populate the opposite direction "has" accurately, as our APIs cannot efficiently return it.
 * As in if you are paginating with first/after, hasPreviousPage will always return false, even if there might be a previous page.
 * Same with last/before/hasNextPage.
 *
 * @see https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo.Fields for details as to why.
 */
export interface RelayPageInfo {
    /** Populated so long as the set has items, with the cursor associated with the last item in the set. */
    endCursor?: string | null;
    /** Populated no matter what, but will always be false if you are paginating with last/before. */
    hasNextPage: boolean;
    /** Populated so long as the set has items, with the cursor associated with the first item in the set. */
    startCursor?: string | null;
    /** Populated no matter what, but will always be false if you are paginating with first/after. */
    hasPreviousPage: boolean;
}
