import {
    EXISTS_SPECIAL_FILTER_VALUE,
    FilterOperator,
    SearchMetricsInput,
} from "@buildresonance/resonance-lib-entities";
import {
    AnyObject,
    ObjectSchema,
    StringSchema,
    array,
    mixed,
    number,
    object,
    string,
} from "yup";

import { requiredOnlyWhenExistsObjectTransformer } from "../objectHelpers";

export const MAX_NUMBER_OF_AGGREGATIONS = 65536;

export const operatorSchema = string().oneOf(["AND", "OR", "NOT"]);

export const rangeFilterSchema = object({
    max: mixed<string | number>().test(
        "max-string-or-number",
        "Must be a string, number, or undefined",
        (value) =>
            value === undefined ||
            typeof value === "string" ||
            typeof value === "number",
    ),
    min: mixed<string | number>()
        .test(
            "max-string-or-number",
            "Must be a string, number, or undefined",
            (value) =>
                value === undefined ||
                typeof value === "string" ||
                typeof value === "number",
        )
        .test(
            "min-greater-than-or-equal-to-zero-when-a-number",
            "min must be greater than or equal to 0",
            (value) => {
                if (typeof value === "number") return value >= 0;
                return true;
            },
        )
        .test(
            "max-greater-than-or-equal-to-min-when-both-are-numbers",
            "min must be less than or equal to max",
            (value, context) => {
                if (
                    typeof value === "number" &&
                    typeof context.parent.max === "number"
                ) {
                    return value <= context.parent.max;
                }
                return true;
            },
        ),
}).noUnknown();

export const buildTermFilterSchema = <TType extends string>(
    stringSchema: StringSchema<TType | undefined>,
): ObjectSchema<
    { operator: FilterOperator | undefined; values: TType[] },
    AnyObject,
    undefined,
    "d"
> => {
    const valueSchema = string<TType>().test((value) => {
        if (value === EXISTS_SPECIAL_FILTER_VALUE) {
            return true;
        }
        return stringSchema.isValidSync(value);
    });

    return object({
        operator: operatorSchema,
        values: array().of(valueSchema.required()).required(),
    })
        .noUnknown()
        .transform(requiredOnlyWhenExistsObjectTransformer)
        .default(undefined);
};

/**
 * TODO: rangeFilterSchema now handles this case, and more.  Remove when front ends are updated.
 * For enhanced UX, we allow users to enter a range filter with only one of the min or max values.
 * The UI is responsible for providing defaults for the missing values when making queries.
 *
 * @deprecated
 */
export const optionalRangeFilterSchema = array(
    object({
        max: number().nullable(),
        min: number().nullable(),
    })
        .test(
            "min-greater-than-zero",
            "min must be greater than or equal to 0",
            ({ min }) => {
                // min is optional
                if (min === null || min === undefined) return true;

                return min >= 0;
            },
        )
        .test(
            "max-greater-than-zero",
            "max must be greater than or equal to 0",
            ({ max }) => {
                // max is optional
                if (max === null || max === undefined) return true;

                return max >= 0;
            },
        )
        .test(
            "min-less-than-or-equal-to-max",
            "min must be less than or equal to max",
            ({ min, max }) => {
                // only test if both min and max are defined
                if (
                    min === null ||
                    min === undefined ||
                    max === null ||
                    max === undefined
                )
                    return true;

                return min <= max;
            },
        )
        .noUnknown(),
);

export const searchMetricsInputSchema: ObjectSchema<SearchMetricsInput> =
    object({
        totalCountAccuracy: number().nullable().integer().min(0).max(10000),
    }).noUnknown();
