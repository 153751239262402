import { ResonanceDomain } from "../../domainNames";

import { AdminIdentityIdPrefix } from "./AdminIdentityEntity";

/** The string part is a KSUID */
export type AppId = `${AdminIdentityIdPrefix.App}-${string}`;

/**
 * The string part is a UUID.
 * This is going into Cognito as the username and must be all lowercase (case insensitive).
 * UUIDs are all lowercase, KSUIDs have uppercase and lowercase letters.
 */
export type AppClientId = `${AdminIdentityIdPrefix.AppClient}-${string}`;

export type PartnerId = `${AdminIdentityIdPrefix.Partner}-${string}`;

/**
 * Could also be called a UserId.
 * The string part is a UUID.
 * This is going into Cognito as the username and must be all lowercase (case insensitive).
 * UUIDs are all lowercase, KSUIDs have uppercase and lowercase letters.
 */
export type UserName = `${AdminIdentityIdPrefix.User}-${string}`;

export type UserNotificationId =
    `${AdminIdentityIdPrefix.UserNotification}-${string}`;

export type ResonanceAdminIdentityIds =
    | AppId
    | AppClientId
    | PartnerId
    | UserName
    | UserNotificationId;

/**
 * Type of services to individually identify an area of a domain.
 */
export enum ResonanceServiceType {
    Api = "api",
    Jobs = "jobs",
}

/** A specific service to grant access to in another service (Api only on the target) */
export type ResonanceServiceComposite =
    | `${ResonanceDomain}-${ResonanceServiceType}`
    | `storefront-${ResonanceServiceType}`;

export const RESONANCE_SERVICE_ID_PREFIX =
    AdminIdentityIdPrefix.ResonanceService;

/**
 * An identifier for a Domain and ServiceType combination.
 * Is an identityId for a service used to identify the service on a request (as username).
 * The target service uses this to do a permissions check that is across all entities (as opposed to specific to an entity, like usual).
 */
export type ResonanceServiceId =
    `${AdminIdentityIdPrefix.ResonanceService}-${ResonanceServiceComposite}`;
