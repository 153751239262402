import {
    NavApiCreateInput,
    NavApiUpdateInput,
    NAV_POSITION_MAX_VALUE,
} from "@buildresonance/resonance-lib-entities";
import { number, object, ObjectSchema, string } from "yup";

import { transformNaNToNull, transformZeroToNull } from "../../numberHelpers";
import { SLUG_REGEX, SLUG_REGEX_VALIDATION_MESSAGE } from "../../stringHelpers";
import {
    demandHqIdSchema,
    navIdSchema,
    storefrontIdSchema,
} from "../demandIds";

export const NAV_HREF_MAX_LENGTH = 768;
export const NAV_NAV_TYPE_MAX_LENGTH = 128;
export const NAV_LABEL_MAX_LENGTH = 128;

export const navLevelSchema = number().min(0).max(6);
export const navTypeSchema = string()
    .matches(SLUG_REGEX, SLUG_REGEX_VALIDATION_MESSAGE)
    .max(NAV_NAV_TYPE_MAX_LENGTH);

export const rootNavApiCreateInputSchema: ObjectSchema<
    Omit<NavApiCreateInput, "parentNavId">
> = object({
    demandHqId: demandHqIdSchema.required(),
    href: string().max(NAV_HREF_MAX_LENGTH).required(),
    label: string().max(NAV_LABEL_MAX_LENGTH).required(),
    navLevel: number().min(0).max(0).required().transform(transformNaNToNull),
    navType: navTypeSchema.required(),
    position: number()
        .integer()
        .positive()
        .nullable()
        .max(NAV_POSITION_MAX_VALUE)
        .transform(transformNaNToNull)
        .transform(transformZeroToNull),
    storefrontId: storefrontIdSchema.required(),
}).noUnknown();

export const navApiCreateInputSchema: ObjectSchema<NavApiCreateInput> =
    rootNavApiCreateInputSchema
        .omit(["navLevel"])
        .concat(
            object({
                navLevel: number()
                    .min(1)
                    .max(6)
                    .required()
                    .transform(transformNaNToNull),
                parentNavId: navIdSchema.required(),
            }),
        )
        .noUnknown();

export const navApiUpdateInputSchema: ObjectSchema<NavApiUpdateInput> = object({
    href: string().max(NAV_HREF_MAX_LENGTH),
    label: string().max(NAV_LABEL_MAX_LENGTH),
    navId: navIdSchema.required(),
    position: number()
        .integer()
        .positive()
        .nullable()
        .max(NAV_POSITION_MAX_VALUE)
        .transform(transformNaNToNull)
        .transform(transformZeroToNull),
}).noUnknown();
