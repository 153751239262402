import { ResonanceUpdatedBy } from "../../../entityComposites";
import { ResonanceDates } from "../../../global";
import { DemandHqId, StorefrontId } from "../DemandIds";

export interface StorefrontEmailSettingsIdFields {
    storefrontId: StorefrontId;
}

export interface StorefrontEmailSettingsRelationshipFields {
    demandHqId: DemandHqId;
}

export interface StorefrontEmailSettingsSmtpCredentials {
    /** Domain only (smtp.example.com) */
    host: string;
    port: number;
    username: string;
    password: string;
}

export interface StorefrontEmailSettings
    extends StorefrontEmailSettingsIdFields,
        StorefrontEmailSettingsRelationshipFields,
        Pick<ResonanceDates, "updatedAt">,
        ResonanceUpdatedBy {
    /** The email address that will be used as the "from" address for all trigger emails. Required in order for any emails to be sent. */
    fromEmailAddress?: string | null;
    /** The name that will be used as the "from" name for all trigger emails. Defaults to the fromEmailAddress. */
    fromName?: string | null;
    /** The url of the logo to use in the upper right corner of most email triggers. Defaults to the Text of the storefront name. */
    logoUrl?: string | null;
    /** The URL to send customers to in order to view their order information. Use {order.orderId} to have the orderId substituted into the URL. */
    orderUrl?: string | null;
    /** DeliveryConfirmation Emails are sent when the tracking information for the PurchaseOrderItem reports DELIVERED for a given Storefront Order. */
    sendDeliveryConfirmations?: boolean | null;
    /** OrderConfirmation Emails are sent when an order transitions from status PLACED to AUTHORIZED. This is immediate with most storefronts. */
    sendOrderConfirmations?: boolean | null;
    /** ShipConfirmation Emails are sent when an OrderItem PurchaseOrderItem has a fulfillment created on it. AKA when at least one item from an order is shipped. */
    sendShipConfirmations?: boolean | null;
    /** How trigger emails are sent. Required in order for any emails to be sent. */
    smtpCredentials?: StorefrontEmailSettingsSmtpCredentials | null;
}

// No create inputs for this entity needed, partial updating is possible due to 100% optional fields and a single index.

export type StorefrontEmailSettingsModelUpdateInput =
    StorefrontEmailSettingsIdFields &
        StorefrontEmailSettingsRelationshipFields &
        Partial<
            Omit<
                StorefrontEmailSettings,
                | keyof StorefrontEmailSettingsIdFields
                | keyof StorefrontEmailSettingsRelationshipFields
                | "updatedAt"
                | keyof ResonanceUpdatedBy
            >
        >;

export type StorefrontEmailSettingsApiUpdateInput = Omit<
    StorefrontEmailSettingsModelUpdateInput,
    keyof StorefrontEmailSettingsRelationshipFields
>;
