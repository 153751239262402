import { ScopePermission } from "../../global";
import { AdminIdentityIdPrefix } from "../adminidentity/AdminIdentityEntity";

import { DemandSecureEntityIdPrefix } from "./secure";

/**
 * What stage of creation is this DemandEntity in?
 * @see Collection, Page, Product
 */
export enum DemandEntityStatus {
    Draft = "DRAFT",
    NeedsReview = "NEEDSREVIEW",
    Published = "PUBLISHED",
    TemporaryHold = "TEMPORARYHOLD",
    Archived = "ARCHIVED",
}

export enum DemandEntity {
    DemandHq = "DEMANDHQ",
    DemandHqAutomationSettings = "DEMANDHQAUTOMATIONSETTINGS",
    DemandHqUserSettings = "DEMANDHQUSERSETTINGS",

    DemandHqIdentity = "DEMANDHQIDENTITY",
    /** This entity is a copy of the SupplyEntity SupplierDemandHq */
    DemandHqSupplier = "DEMANDHQSUPPLIER",

    Customer = "CUSTOMER",
    CustomerTags = "CUSTOMERTAGS",
    CustomerAddress = "CUSTOMERADDRESS",

    Product = "PRODUCT",
    ProductContentItem = "PRODUCTCONTENTITEM",
    ProductCustomData = "PRODUCTCUSTOMDATA",
    ProductLocale = "PRODUCTLOCALE",
    ProductMetrics = "PRODUCTMETRICS",
    ProductTags = "PRODUCTTAGS",
    Variant = "VARIANT",
    VariantCustomData = "VARIANTCUSTOMDATA",
    VariantSupplierVariant = "VARIANTSUPPLIERVARIANT",
    VariantMetrics = "VARIANTMETRICS",

    Collection = "COLLECTION",
    CollectionContent = "COLLECTIONCONTENT",
    CollectionTags = "COLLECTIONTAGS",
    CollectionTypeContent = "COLLECTIONTYPECONTENT",

    Discount = "DISCOUNT",

    Nav = "NAV",

    Order = "ORDER",
    OrderItem = "ORDERITEM",
    OrderTags = "ORDERTAGS",
    OrderTransaction = "ORDERTRANSACTION",

    Page = "PAGE",
    PageContent = "PAGECONTENT",
    PageTags = "PAGETAGS",
    PageTypeContent = "PAGETYPECONTENT",

    Redirect = "REDIRECT",

    Review = "REVIEW",

    Storefront = "STOREFRONT",
    StorefrontAnalyticsSettings = "STOREFRONTANALYTICSSETTINGS",
    StorefrontEmailSettings = "STOREFRONTEMAILSETTINGS",

    TaxonomyBranch = "TAXONOMYBRANCH",

    DemandHqQuickLink = "DEMANDHQQUICKLINK",
    DemandHqComment = "DEMANDHQCOMMENT",
    DemandHqNotification = "DEMANDHQNOTIFICATION",
    ProductDataRule = "PRODUCTDATARULE",

    GatewayCredentials = "GATEWAYCREDENTIALS",

    // Personalization
    Association = "ASSOCIATION",
}

/** Contains all Writable and Readable Entities for the Demand Domain */
export type DemandScope = `${Lowercase<
    Exclude<
        DemandEntity,
        | DemandEntity.CollectionTags // Handled by Collection
        | DemandEntity.CustomerTags // Handled by Customer
        | DemandEntity.OrderTags // Handled by Order
        | DemandEntity.PageTags // Handled by Page
        | DemandEntity.ProductTags // Handled by Product
        | DemandEntity.ProductMetrics // Handled by Product
        | DemandEntity.VariantMetrics // Handled by Variant
        | DemandEntity.DemandHqQuickLink // Not editable cross identity
        | DemandEntity.DemandHqUserSettings // Not editable cross identity
        | DemandEntity.ProductDataRule // Handled by DemandHqAutomationSettings
    >
>}/${ScopePermission}`;

export const WRITABLE_DEMAND_ENTITIES: DemandEntity[] = [
    DemandEntity.Association,
    DemandEntity.Collection,
    DemandEntity.CollectionContent,
    DemandEntity.CollectionTypeContent,
    DemandEntity.Customer,
    DemandEntity.CustomerAddress,
    DemandEntity.DemandHq,
    DemandEntity.DemandHqAutomationSettings,
    DemandEntity.DemandHqComment,
    DemandEntity.DemandHqIdentity,
    DemandEntity.DemandHqNotification,
    DemandEntity.DemandHqSupplier,
    DemandEntity.Discount,
    DemandEntity.GatewayCredentials,
    DemandEntity.Nav,
    DemandEntity.Order,
    DemandEntity.OrderItem,
    DemandEntity.OrderTransaction,
    DemandEntity.Page,
    DemandEntity.PageContent,
    DemandEntity.PageTypeContent,
    DemandEntity.Product,
    DemandEntity.ProductContentItem,
    DemandEntity.ProductCustomData,
    DemandEntity.ProductDataRule,
    DemandEntity.ProductLocale,
    DemandEntity.Redirect,
    DemandEntity.Review,
    DemandEntity.Storefront,
    DemandEntity.StorefrontAnalyticsSettings,
    DemandEntity.StorefrontEmailSettings,
    DemandEntity.TaxonomyBranch,
    DemandEntity.Variant,
    DemandEntity.VariantCustomData,
    DemandEntity.VariantSupplierVariant,
];

export const ALL_DEMAND_SCOPES: DemandScope[] = [
    ...(WRITABLE_DEMAND_ENTITIES.flatMap((entity) => [
        `${entity.toLowerCase()}/${ScopePermission.Write}`,
        `${entity.toLowerCase()}/${ScopePermission.Read}`,
    ]) as DemandScope[]),
];

export enum DemandEntityIdPrefix {
    /**
     * Covers all of the below, as they use the same ID (but separate DemandEntity):
     * @see Collection
     * @see CollectionContent
     * @see CollectionTags
     */
    Collection = "clctn",
    CollectionTypeContent = "clctc",
    /**
     * Covers all of the below, as they use the same ID (but separate DemandEntity):
     * @see Customer
     * @see CustomerTags
     */
    Customer = "cstmr",
    CustomerAddress = "caddr",
    /** Covers pretty much everything in the metadata domain group */
    DemandHq = "dmdhq",

    DemandHqComment = "dcmnt",
    DemandHqNotification = "dhqnt",
    Discount = "dscnt",

    /** Nav is a hierarchical relationship entity for collections */
    Nav = "navid",
    /**
     * Order is the goal of any DemandHq, a customer wants at least one Product Variant.
     * @see Order
     * @see OrderTags
     */
    Order = "order",
    /** OrderItem is an individual item from an Order matching a single Product Variant */
    OrderItem = "oritm",
    /** OrderTransaction is any payment-related occurrence for an order (auth, payment, refund, etc) */
    OrderTransaction = "ortrx",
    /** Pages with markdown content for Storefronts */
    Page = "dpage",
    PageTypeContent = "pagtc",
    /**
     * Covers all of the below, as they use the same ID (but separate DemandEntity).
     * @see Product
     * @see ProductContentItem
     * @see ProductLocale
     * @see ProductTags
     * @see ProductMetrics
     */
    Product = "prdct",
    Redirect = "rdrct",
    Review = "rview",

    Storefront = "stfnt",
    StorefrontIdentity = "sftid",

    TaxonomyBranch = "txbrn",

    /**
     * Covers all of the below, as they use the same ID (but separate DemandEntity).
     * @see Variant
     * @see VariantCustomData
     * @see VariantSupplierVariant
     * @see VariantMetrics
     */
    Variant = "varnt",
}

/**
 * This is used in event publishing to determine the main entityId.
 * Generally interfaces have more than one id in them, so this helps immensely.
 * MAKE SURE TO UPDATE THIS, OR EVENTS WILL HAVE NO ENTITYID. (TS will help you)
 *
 * ! Order is important - for shared values, only the first id prefix will be preserved
 * i.e. place `Variant` first (not VariantMetrics)
 */
export const DEMAND_ENTITY_TO_ID_PREFIX_MAP: Record<
    DemandEntity,
    DemandEntityIdPrefix | AdminIdentityIdPrefix | DemandSecureEntityIdPrefix
> = {
    [DemandEntity.DemandHq]: DemandEntityIdPrefix.DemandHq,
    [DemandEntity.DemandHqAutomationSettings]: DemandEntityIdPrefix.DemandHq,
    [DemandEntity.DemandHqUserSettings]: AdminIdentityIdPrefix.User,

    [DemandEntity.DemandHqIdentity]: DemandEntityIdPrefix.DemandHq,
    [DemandEntity.DemandHqSupplier]: DemandEntityIdPrefix.DemandHq,

    [DemandEntity.Customer]: DemandEntityIdPrefix.Customer,
    [DemandEntity.CustomerTags]: DemandEntityIdPrefix.Customer,
    [DemandEntity.CustomerAddress]: DemandEntityIdPrefix.CustomerAddress,

    [DemandEntity.Discount]: DemandEntityIdPrefix.Discount,

    [DemandEntity.Product]: DemandEntityIdPrefix.Product,
    [DemandEntity.ProductContentItem]: DemandEntityIdPrefix.Product,
    [DemandEntity.ProductCustomData]: DemandEntityIdPrefix.Product,
    [DemandEntity.ProductLocale]: DemandEntityIdPrefix.Product,
    [DemandEntity.ProductMetrics]: DemandEntityIdPrefix.Product,
    [DemandEntity.ProductTags]: DemandEntityIdPrefix.Product,

    [DemandEntity.Variant]: DemandEntityIdPrefix.Variant,
    [DemandEntity.VariantCustomData]: DemandEntityIdPrefix.Variant,
    [DemandEntity.VariantSupplierVariant]: DemandEntityIdPrefix.Variant,
    [DemandEntity.VariantMetrics]: DemandEntityIdPrefix.Variant,

    [DemandEntity.Collection]: DemandEntityIdPrefix.Collection,
    [DemandEntity.CollectionContent]: DemandEntityIdPrefix.Collection,
    [DemandEntity.CollectionTags]: DemandEntityIdPrefix.Collection,
    [DemandEntity.CollectionTypeContent]:
        DemandEntityIdPrefix.CollectionTypeContent,

    [DemandEntity.Nav]: DemandEntityIdPrefix.Nav,

    [DemandEntity.Order]: DemandEntityIdPrefix.Order,
    [DemandEntity.OrderTags]: DemandEntityIdPrefix.Order,
    [DemandEntity.OrderItem]: DemandEntityIdPrefix.OrderItem,
    [DemandEntity.OrderTransaction]: DemandEntityIdPrefix.OrderTransaction,

    [DemandEntity.Page]: DemandEntityIdPrefix.Page,
    [DemandEntity.PageContent]: DemandEntityIdPrefix.Page,
    [DemandEntity.PageTags]: DemandEntityIdPrefix.Page,
    [DemandEntity.PageTypeContent]: DemandEntityIdPrefix.PageTypeContent,

    [DemandEntity.Redirect]: DemandEntityIdPrefix.Redirect,
    [DemandEntity.Review]: DemandEntityIdPrefix.Review,

    [DemandEntity.Storefront]: DemandEntityIdPrefix.Storefront,
    [DemandEntity.StorefrontAnalyticsSettings]: DemandEntityIdPrefix.Storefront,
    [DemandEntity.StorefrontEmailSettings]: DemandEntityIdPrefix.Storefront,

    [DemandEntity.TaxonomyBranch]: DemandEntityIdPrefix.TaxonomyBranch,

    [DemandEntity.DemandHqQuickLink]: AdminIdentityIdPrefix.App,
    [DemandEntity.DemandHqComment]: DemandEntityIdPrefix.DemandHqComment,
    [DemandEntity.DemandHqNotification]:
        DemandEntityIdPrefix.DemandHqNotification,
    [DemandEntity.ProductDataRule]: DemandEntityIdPrefix.DemandHq,

    [DemandEntity.GatewayCredentials]:
        DemandSecureEntityIdPrefix.GatewayCredentials,

    // Associations do not have their own ID
    [DemandEntity.Association]: DemandEntityIdPrefix.DemandHq,
};

export const ALL_DEMAND_ENTITIES = [...WRITABLE_DEMAND_ENTITIES];

export const GLOBAL_DATA_MANAGER_NON_WRITEABLE_ENTITIES = [
    DemandEntity.DemandHqIdentity,
];

export const PAGE_MANAGER_WRITEABLE_ENTITIES = [
    DemandEntity.Page,
    DemandEntity.PageContent,
    DemandEntity.PageTags,
    DemandEntity.PageTypeContent,
];

export const PAGE_MANAGER_READABLE_ENTITIES = [
    DemandEntity.DemandHq,
    DemandEntity.DemandHqIdentity,
    DemandEntity.Storefront,
    DemandEntity.Product,
    DemandEntity.Collection,
];

export enum DemandRole {
    Admin = "ADMIN",
    GlobalDataManager = "GLOBAL_DATA_MANAGER",
    PageManager = "PAGE_MANAGER",
}

export const DEMAND_ENTITY_ROLES: Record<DemandRole, DemandScope[]> = {
    [DemandRole.Admin]: ALL_DEMAND_ENTITIES.map(
        (entity) =>
            `${entity.toLowerCase()}/${ScopePermission.Write}` as DemandScope,
    ),
    [DemandRole.GlobalDataManager]: ALL_DEMAND_ENTITIES.map(
        (entity) =>
            `${entity.toLowerCase()}/${ScopePermission.Read}` as DemandScope,
    ).concat(
        ALL_DEMAND_ENTITIES.filter(
            (entity) =>
                !GLOBAL_DATA_MANAGER_NON_WRITEABLE_ENTITIES.includes(entity),
        ).map(
            (entity) =>
                `${entity.toLowerCase()}/${
                    ScopePermission.Write
                }` as DemandScope,
        ),
    ),
    [DemandRole.PageManager]: PAGE_MANAGER_WRITEABLE_ENTITIES.map(
        (entity) =>
            `${entity.toLowerCase()}/${ScopePermission.Write}` as DemandScope,
    ).concat(
        PAGE_MANAGER_READABLE_ENTITIES.map(
            (entity) =>
                `${entity.toLowerCase()}/${
                    ScopePermission.Read
                }` as DemandScope,
        ),
    ),
};

export const DEMAND_SCOPE_DESCRIPTIONS: Record<DemandScope, string> = {
    "association/read": "Read access to Associations.",
    "association/write": "Write access to Associations.",
    "collection/read": "Read access to Collections.",
    "collection/write": "Write access to Collections.",
    "collectioncontent/read":
        "Read access to the CollectionContent, which are markdown based blurbs of text for Collections.",
    "collectioncontent/write":
        "Write access to the CollectionContent, which are markdown based blurbs of text for Collections.",
    "collectiontypecontent/read":
        "Read access to the CollectionTypeContent, which are markdown based blurbs of text for CollectionTypes.",
    "collectiontypecontent/write":
        "Write access to the CollectionTypeContent, which are markdown based blurbs of text for CollectionTypes.",
    "customer/read":
        "Can see information about customers, including personal information. This is not recommended to provide to apps unless critical to the app's functionality.",
    "customer/write":
        "Can alter customer information, including personal information. This is not recommended to provide to apps unless critical to the app's functionality.",
    "customeraddress/read":
        "Read access to CustomerAddresses, which is personal information. This is not recommended to provide to apps unless critical to the app's functionality.",
    "customeraddress/write":
        "Write access to the CustomerAddresses, which is personal information. This is not recommended to provide to apps unless critical to the app's functionality.",
    "demandhq/read":
        "Read access to basic DemandHq information (name, description, etc.)",
    "demandhq/write":
        "Write access to the DemandHq. Can alter DemandHq information (name, description, etc.), including ownership. This is not recommended to provide to apps.",
    "demandhqautomationsettings/read":
        "Read access to DemandHqAutomationSettings.",
    "demandhqautomationsettings/write":
        "Write access to DemandHqAutomationSettings. These settings are very powerful, and this permission should only be provided to trusted users, and probably no apps.",
    "demandhqcomment/read":
        "Read access to DemandHqComments, which are comments on any Demand entity for viewing by other DemandHq users.",
    "demandhqcomment/write":
        "Write access to DemandHqComments, which are comments on any Demand entity for viewing by other DemandHq users.",
    "demandhqidentity/read":
        "Can invite, remove, and alter DemandHq users and other apps permissions. This is not recommended to provide to apps.",
    "demandhqidentity/write":
        "Can read DemandHq users and other apps permissions. This is not recommended to provide to apps as an app can always read their own permissions.",
    "demandhqnotification/read":
        "Read access to DemandHqNotifications, which are notifications central to a DemandHq.",
    "demandhqnotification/write":
        "Write access to DemandHqNotifications, which are notifications central to a DemandHq. Write access allows to resolve, ignore, or otherwise change a notification.",
    "demandhqsupplier/read":
        "Read access to DemandHqSuppliers. These represent access status for Suppliers for a given DemandHq.",
    "demandhqsupplier/write":
        "Write access to DemandHqSuppliers. These represent access status for Suppliers for a given DemandHq, and this permission gives the identity the right to request access.",
    "discount/read":
        "Read access to Discounts. Read access to discounts in a DemandHq.",
    "discount/write":
        "Write access to Discounts. Write access to discounts in a DemandHq.",
    "gatewaycredentials/read":
        "Read access to GatewayCredentials, which are used to connect to payment gateways.  These are extremely sensitive credentials and should not be shared with any app.",
    "gatewaycredentials/write":
        "Write access to GatewayCredentials, which are used to connect to payment gateways.  This should only ever be handled as part of an OAuth flow, should only ever be handled by a secure API, and should never be shared with any app.",
    "nav/read": "Read access to the navigational hierarchy or Nav menus.",
    "nav/write": "Write access to the navigational hierarchy Nav menus.",
    "order/read":
        "Read access to an order's basic information, which includes up to two customer addresses. This is not recommended to provide to apps unless critical to the app's functionality.",
    "order/write":
        "Write access to an order's basic information. This is not recommended to provide to apps unless critical to the app's functionality.",
    "orderitem/read": "Read access to the Items within an order.",
    "orderitem/write": "Write access to the Items within an order.",
    "ordertransaction/read": "Read access to OrderTransactions.",
    "ordertransaction/write":
        "Write access to OrderTransactions, including authorizations, captures, refunds, etc.",
    "page/read":
        "Read access to the Pages which represent a page with textual and image content on it",
    "page/write":
        "Write access to Pages which represent a page with textual and image content on it",
    "pagecontent/read":
        "Read access to PageContent, which is the actual content of the page.",
    "pagecontent/write":
        "Write access to PageContent, which is the actual content of the page.",
    "pagetypecontent/read":
        "Read access to PageTypeContent, which are blurbs of content relating to Page Types.",
    "pagetypecontent/write":
        "Write access to PageTypeContent, which are blurbs of content relating to Page Types.",
    "product/read":
        "Read access to Products. This is the main entity of the Demand domain, and is used to represent a product, which is a group of SKUs for sale.",
    "product/write":
        "Write access to Products. This is the main entity of the Demand domain, and is used to represent a product, which is a group of SKUs for sale.",
    "productcontentitem/read":
        "Read access to ProductContentItems, which include information about such as images, video, files, etc.",
    "productcontentitem/write":
        "Write access to ProductContentItems, which include information about such as images, video, files, etc.",
    "productcustomdata/read": "Read access to ProductCustomData.",
    "productcustomdata/write": "Write access to ProductCustomData.",
    "productlocale/read": "Read access to ProductLocales.",
    "productlocale/write": "Write access to ProductLocales.",
    "redirect/read": "Read access to Redirects for a Storefront.",
    "redirect/write": "Write access to Redirects for a Storefront.",
    "review/read":
        "Read access to Reviews. These are reviews of products, typically from customers.",
    "review/write":
        "Write access to Reviews. These are reviews of products, typically from customers.",
    "storefront/read":
        "Read access to the Storefront which is an application of some variety that presents an e-commerce experience directly to customers and facilitates the browsing, shopping, and ordering of Product Variants.",
    "storefront/write":
        "Write access to the Storefront which is an application of some variety that presents an e-commerce experience directly to customers and facilitates the browsing, shopping, and ordering of Product Variants.",
    "storefrontanalyticssettings/read":
        "Read access to StorefrontAnalyticsSettings. These control the analytics and data collection settings for a Storefront.",
    "storefrontanalyticssettings/write":
        "Write access to StorefrontAnalyticsSettings. These control the analytics and data collection settings for a Storefront.",
    "storefrontemailsettings/read":
        "Read access to StorefrontEmailSettings. These control the credentials and settings for sending various trigger-based emails.",
    "storefrontemailsettings/write":
        "Write access to StorefrontEmailSettings. These control the credentials and settings for sending various trigger-based emails.",
    "taxonomybranch/read":
        "Read access to TaxonomyBranches, which are the individual pieces of the hierarchical structure of a Product Taxonomy Tree.",
    "taxonomybranch/write":
        "Write access to TaxonomyBranches, which are the individual pieces of the hierarchical structure of a Product Taxonomy Tree.",
    "variant/read":
        "Read access to the Variant. This is the child entity to a Product, and is a variation, such as a color, size, etc.",
    "variant/write":
        "Write access to the Variant. This is the child entity to a Product, and is a variation, such as a color, size, etc.",
    "variantcustomdata/read": "Read access to VariantCustomData.",
    "variantcustomdata/write": "Write access to VariantCustomData.",
    "variantsuppliervariant/read":
        "Read access to VariantSupplierVariants (seeing which SupplierVariants are inventorying variants).",
    "variantsuppliervariant/write":
        "Write access to VariantSupplierVariants (changing which SupplierVariants are inventorying variants).",
};
