import {
    ResonanceDomainEntityId,
    ResonanceIdentityId,
} from "../../../entityComposites";
import { DemandBulkActionImportType } from "../../demand/bulk/DemandBulkActionImport";
import { SupplyBulkActionImportType } from "../../supply/bulk/SupplyBulkActionImport";
import { ResonanceAction } from "../ResonanceAction";

import { S3FileExtension, BulkActionStatus } from "./common";

type ObjMap<TData = unknown> = {
    [key: string]: TData;
};

export type BulkActionImportPrefixAndKeyData<
    TDomainEntityId extends ResonanceDomainEntityId,
    TBulkActionImportType extends
        | DemandBulkActionImportType
        | SupplyBulkActionImportType,
> = {
    domainEntityId: TDomainEntityId;
    fileExtension: S3FileExtension;
    /** The requesting identityId */
    identityId: ResonanceIdentityId;
    importType: TBulkActionImportType;
    importStatus: BulkActionStatus;
    /** Arbitrary short name given by the user */
    name: string;
    /** S3 can only sort one way, and this makes that way be createdAt desc. You calculate the largest date you can and subtract right now. */
    ticksTillTheEndOfTime: number;
};

/** The data that is stored in the S3 .json file */
export type BulkActionImportData<
    TAction extends ResonanceAction = ResonanceAction,
    TResultData extends ObjMap = ObjMap,
    TErrors = unknown[],
    TExtensions extends ObjMap = ObjMap,
> = {
    /** The array of actions to complete on start. */
    actions: BulkActionImportDataAction<
        TAction,
        TResultData,
        TErrors,
        TExtensions
    >[];
    catastrophicError?: string | null;
};

export type BulkActionImportDataAction<
    TAction extends ResonanceAction = ResonanceAction,
    TResultData extends ObjMap = ObjMap,
    TErrors = unknown[],
    TExtensions extends ObjMap = ObjMap,
> = {
    action: TAction;
    result?: BulkActionImportResult<TResultData, TErrors, TExtensions> | null;
};

export type BulkActionImportResult<
    TResultData extends ObjMap = ObjMap,
    TErrors = unknown[],
    TExtensions extends ObjMap = ObjMap,
> = {
    data?: TResultData | null;
    errors?: TErrors | null;
    extensions?: TExtensions | null;
};
