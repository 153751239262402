import { ScopePermission } from "../global";

export interface Permissions<TRole extends string, TScope extends string> {
    roles: TRole[];
    scopes: TScope[];
}

export interface BuildSetOfScopesFromPermissionsOptions {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logger?: { error: (message: string, ...otherArgs: any[]) => void };
}

export const buildSetOfScopesFromPermissions = <
    TRole extends string,
    TScope extends string,
>(
    permissions: Permissions<TRole, TScope> | undefined | null,
    roleMap: Record<TRole, TScope[]>,
    options: BuildSetOfScopesFromPermissionsOptions = {},
): Set<TScope> => {
    if (!permissions) {
        return new Set<TScope>();
    }

    const permissionsFromRole = permissions.roles.flatMap((role) => {
        const innerPermissionsFromRole = roleMap?.[role];
        if (!innerPermissionsFromRole) {
            const loggerToUse = options?.logger ?? console;
            loggerToUse.error(
                `Error while building permission set: Non-existent role present: ${role}`,
            );
            return [];
        }
        return innerPermissionsFromRole;
    });

    const scopesArray = [...permissions.scopes, ...permissionsFromRole];
    return new Set<TScope>([
        ...scopesArray,
        ...(scopesArray
            .filter((scope) => scope && scope.endsWith(ScopePermission.Write))
            .map((scope) =>
                scope.replace(ScopePermission.Write, ScopePermission.Read),
            ) as TScope[]),
    ]);
};
