import { ParsedUrlQuery } from "querystring";

import React, { ReactElement } from "react";

import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import {
    AppBarSearchTextField,
    SearchIconInputAdornment,
} from "@buildresonance/resonance-lib-form-components";
import { useUniqueArrayLocalStorageState } from "@buildresonance/resonance-lib-nav-components";
import {
    CLEAR_SEARCH_OPTION_TEXT,
    useSearchAutocompleteParams,
} from "@hooks/forms/useSearchAutoCompleteParams";
import { Autocomplete } from "@mui/material";
import { useRouter } from "next/router";

import { DemandEntityRecentSearchesLocalStorageKey } from "./constants";

export interface AppBarSearchAutocompleteProps {
    autoCompleteQueryResponse: string[] | undefined;
    id: string;
    label: string;
    placeholder: string;
    searchPathName: string;
    sendDebouncedAutoCompleteQuery: (query: string) => void;
    setSearchInputIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
    localStorageKey: DemandEntityRecentSearchesLocalStorageKey;
}

export const AppBarSearchAutocomplete = ({
    autoCompleteQueryResponse,
    id,
    label,
    placeholder,
    searchPathName,
    sendDebouncedAutoCompleteQuery,
    setSearchInputIsFocused,
    localStorageKey,
}: AppBarSearchAutocompleteProps): ReactElement | null => {
    const router = useRouter();

    const demandHqId = router.query.demandHqId as DemandHqId | undefined;

    const { autoCompleteOptions, searchValue, setSearchValue } =
        useSearchAutocompleteParams({
            autoCompletes: autoCompleteQueryResponse,
        });

    const { add: addRecentSearch } =
        useUniqueArrayLocalStorageState<ParsedUrlQuery>({
            key: localStorageKey,
            itemComparator: (item1, item2) => {
                return JSON.stringify(item1) === JSON.stringify(item2);
            },
        });

    const handleInputChange = (
        _: React.SyntheticEvent<Element, Event>,
        value: string,
    ): void => {
        if (value === CLEAR_SEARCH_OPTION_TEXT) {
            setSearchValue("");
            return;
        }

        setSearchValue(value);
        if (value && value.length > 0) {
            sendDebouncedAutoCompleteQuery(value);
        }
    };

    const handleSearch = (_: unknown, value: string | null): void => {
        if (demandHqId) {
            setSearchValue(value ?? "");
            addRecentSearch({
                ...router.query,
                query: value ?? "",
            });

            void router.push({
                pathname: searchPathName,
                query: {
                    ...router.query,
                    page: "1",
                    query:
                        value === CLEAR_SEARCH_OPTION_TEXT
                            ? ""
                            : (value ?? undefined),
                    demandHqId,
                },
            });
        }
    };

    return (
        <Autocomplete
            id={id}
            disableClearable
            freeSolo
            fullWidth
            selectOnFocus
            inputValue={searchValue}
            onInputChange={handleInputChange}
            onChange={handleSearch}
            options={autoCompleteOptions}
            onBlur={() => setSearchInputIsFocused(false)}
            onFocus={() => setSearchInputIsFocused(true)}
            renderInput={(params) => (
                <AppBarSearchTextField
                    label={label}
                    params={params}
                    textFieldProps={{
                        placeholder,
                    }}
                    endAdornmentComponent={
                        <SearchIconInputAdornment
                            onClick={() => handleSearch(undefined, searchValue)}
                        />
                    }
                />
            )}
        />
    );
};
