import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates, ScopePermission } from "../../global";

import { AdminIdentityEntity } from "./AdminIdentityEntity";
import { PartnerId, UserName } from "./AdminIdentityIds";

/**
 * @see AdminIdentityEntity.Partner Write: the ability to change details or add users to the Partner.
 * @see AdminIdentityEntity.App Write: the ability to create apps and change details.
 */
export type PartnerScope = `${Lowercase<
    | AdminIdentityEntity.Partner
    | AdminIdentityEntity.App
    | AdminIdentityEntity.PartnerIdentity
>}/${ScopePermission}`;

/** Other users within the partner can write these */
export const WRITABLE_PARTNER_ENTITIES: AdminIdentityEntity[] = [
    AdminIdentityEntity.Partner,
    AdminIdentityEntity.App,
    AdminIdentityEntity.PartnerIdentity,
];

/** Other users within the partner can only read these */
export const READONLY_PARTNER_ENTITIES: AdminIdentityEntity[] = [];

export const ALL_PARTNER_SCOPES: PartnerScope[] = [
    ...(WRITABLE_PARTNER_ENTITIES.flatMap((entity) => [
        `${entity.toLowerCase()}/${ScopePermission.Write}`,
        `${entity.toLowerCase()}/${ScopePermission.Read}`,
    ]) as PartnerScope[]),
];

export enum PartnerRole {
    Admin = "ADMIN",
}

export const PARTNER_ROLES: Record<PartnerRole, PartnerScope[]> = {
    [PartnerRole.Admin]: WRITABLE_PARTNER_ENTITIES.map(
        (entity) => `${entity.toLowerCase()}/${ScopePermission.Write}`,
    ) as PartnerScope[],
};

export interface PartnerIdFields {
    partnerId: PartnerId;
}

/**
 * This is the parent entity to "App".
 * @see AdminIdentityEntity.Identity
 * @see AppSupplement
 *
 * You must create a partner before creating an App. Partners can have many apps.
 */
export interface Partner
    extends PartnerIdFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /** Short name for the partner, limit 128 characters */
    name: string;
    /** Description of the partner, limit 8000 characters. */
    description?: string | null;
    /**
     * These users will get notified if something goes wrong with an app, i.e. a webhook receiver returning 500.
     * If none are provided, ownerUserName will be notified.
     */
    notificationUserNames?: {
        apps?: UserName[] | null;
    } | null;
    ownerUserName: UserName;
}

export type PartnerGql = Partner;

/** Shape accepted for creating partners in the model, it creates partnerId and the dates */
export type PartnerModelCreateInput = Omit<
    Partner,
    "partnerId" | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

/** Shape accepted for creating partners in the API, it uses the requesting user as the owner */
export type PartnerApiCreateInput = Omit<
    PartnerModelCreateInput,
    "ownerUserName"
>;

/** Shape accepted for updating partners in the model */
export type PartnerModelUpdateInput = PartnerIdFields &
    Partial<
        Omit<
            Partner,
            | keyof PartnerIdFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

/** Shape accepted for updating partners in the api */
export type PartnerApiUpdateInput = PartnerIdFields &
    Partial<
        Omit<
            Partner,
            | keyof PartnerIdFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;
