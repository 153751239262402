import { ResonanceEventSubscriptionApiCreateInput } from "../events";

import { SupplyEntity } from "./SupplyEntity";

export type SupplyEventSubscriptionApiCreateInput = Omit<
    ResonanceEventSubscriptionApiCreateInput,
    "itemType"
> & {
    itemType: Lowercase<SupplyEntity>; // More specific, since we know we are the supply API
};
