export enum CompleteCheckoutErrorCode {
    /** The Checkout failed validation */
    CheckoutInvalid = "CHECKOUT_INVALID",
    /** The checkout was not found */
    CheckoutNotFound = "CHECKOUT_NOT_FOUND",
    /** The Checkout Order was not found */
    CheckoutOrderNotFound = "CHECKOUT_ORDER_NOT_FOUND",
    /** The Checkout failed validation */
    CheckoutOrderInvalid = "CHECKOUT_ORDER_INVALID",
    /** The customer was not found */
    CustomerNotFound = "CUSTOMER_NOT_FOUND",
    /** The Order Input failed validation */
    OrderInputInvalid = "ORDER_INPUT_INVALID",
    /** Supplied Order Tags are invalid */
    OrderTagsInvalid = "ORDER_TAGS_INVALID",
    /** Order Items in the checkout were not found */
    CheckoutOrderItemsNotFound = "CHECKOUT_ORDER_ITEMS_NOT_FOUND",
    /** Order Items in the Checkout failed validation */
    CheckoutOrderItemsInvalid = "CHECKOUT_ORDER_ITEMS_INVALID",
    /** The requested Variant was not found */
    VariantNotFound = "VARIANT_NOT_FOUND",
    /** The requested product was not found */
    ProductNotFound = "PRODUCT_NOT_FOUND",
    /** Duplicate variant found while parsing  */
    VariantDuplicate = "VARIANT_DUPLICATE",
    /** No variant metrics - cannot verify quantity */
    VariantMetricsNotFound = "VARIANT_METRICS_NOT_FOUND",
    /** There is no item quantity for the checkout order item */
    CheckoutOrderItemQuantityNotFound = "CHECKOUT_ORDER_ITEM_QUANTITY_NOT_FOUND",
    /** There is less quantity available than requested */
    InsufficientQuantity = "INSUFFICIENT_QUANTITY",
    /** There was an error while attempting to create the order items */
    OrderItemsInvalid = "ORDER_ITEMS_INVALID",
    /** `OrderItemModel.createBulk` returned unprocessed items */
    UnprocessedOrderItems = "UNPROCESSED_ORDER_ITEMS",
    /** There was an error while attempting to create the order transaction */
    InvalidOrderTransaction = "INVALID_ORDER_TRANSACTION",
    /** The Authorize.Net transaction was not found or failed amount validation */
    InvalidAuthorizeNetTransaction = "INVALID_AUTHORIZE_NET_TRANSACTION",
    /** There was an error while attempting to complete the checkout */
    FailedToCompleteCheckout = "FAILED_TO_COMPLETE_CHECKOUT",
}
