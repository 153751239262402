import { AdminIdentityServiceClientRoute } from "../domains/adminidentity/adminIdentityServiceClient";
import { CdnServiceClientRoute } from "../domains/cdn/cdnServiceClient";
import { DemandServiceClientRoute } from "../domains/demand/demandServiceClient";
import { EventsServiceClientRoute } from "../domains/events/eventsServiceClient";
import { SupplyServiceClientRoute } from "../domains/supply/supplyServiceClient";

import { ServiceClientMethod } from "./serviceClientGlobal";

export enum Stage {
    Prod = "prod",
    Staging = "staging",
}

export enum ServiceClientProjectName {
    AdminIdentity = "ResonanceApiAdminIdentity",
    Cdn = "ResonanceApiCdn",
    Demand = "ResonanceApiDemand",
    Events = "ResonanceApiEvents",
    Supply = "ResonanceApiSupply",
}

export type ServiceClientRoute =
    | AdminIdentityServiceClientRoute
    | CdnServiceClientRoute
    | DemandServiceClientRoute
    | EventsServiceClientRoute
    | SupplyServiceClientRoute;

export const buildServiceClientFunctionName = (
    stage: Stage,
    projectName: ServiceClientProjectName,
    method: ServiceClientMethod,
    route: ServiceClientRoute,
): string => `${stage}-${projectName}-${method}-${route}`;
