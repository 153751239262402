import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { ContentItemId } from "../cdn/CdnIds";

import {
    ProductIdFields,
    ProductRelationshipFields,
} from "./common/ProductCommon";
import { SeoFields } from "./common/SeoFields";
import { DemandEntityStatus } from "./DemandEntity";
import { StorefrontId } from "./DemandIds";
import {
    ProductContentItem,
    ProductContentItemAssociationInCompositeApiCreateInput,
    ProductImageFromUrlInCompositeApiInput,
} from "./ProductContentItem";
import {
    ProductLocale,
    ProductLocaleInCompositeApiCreateInput,
} from "./ProductLocale";
import { ProductMetrics } from "./ProductMetrics";
import { Variant, VariantInCompositeApiCreateInput } from "./Variant";
import { VariantMetrics } from "./VariantMetrics";

/**
 * This is the main entity of the Demand domain, and is used to represent a product, which is a group of SKUs.
 * SKUs are represented by Variants, a child of this entity.
 * This entity is less frequently updated, it is more likely that Variant will be updated frequently.
 */
export interface Product
    extends ProductIdFields,
        ProductRelationshipFields,
        SeoFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /**
     * The labels for the possible option groups for this product, i.e. "Color", "Size", "Material", etc.
     * These will match with labelOptions in Variants.
     *
     * Set the first optionLabel to "No Option" if you don't want to use options for this product.
     * @see https://docs.buildresonance.com/concepts/products/#no-option-products for more info.
     *
     * Each is limited to 64 characters.
     */
    optionLabels: [
        string,
        (string | undefined | null)?,
        (string | undefined | null)?,
    ];
    /** ISO format date when the product was published. Note that ensuing updates with status set to PUBLISHED will republish and re-set this date. */
    publishedAt?: string | null;
    /** Initially, a product's popularity is unknown, so this seed value helps with properly assessing. A percentile value from 0-100. */
    seedPopularity?: number | null;
    /** What stage of creation is this Product in? */
    status: DemandEntityStatus;
    /** Which storefronts should have access to this product? */
    storefrontIds?: StorefrontId[] | null;
    /** Slug for the URL. Limit 128 characters. */
    slug: string;
    /**
     * Product Title is not localized, this is unusual to do unless your Product has a very general name.
     *
     * Limit to 256 characters.
     */
    title: string;
    /**
     * AKA Brand.
     *
     * Limit to 256 characters.
     */
    vendor: string;
}

/**
 * The tags associated with this page, a child object of the Product with a 1-1 relationship.
 * This is separate due to possible size, at 1000 * 96 = 96000 bytes, way more than we want to store with many GSI indexes, but we still want Dynamo speed (and cost is comparable to S3 for these params).
 * This enables us not to pull them for list queries and such as well, this should be obscured from consumers by the API, but still obvious at the model level.
 */
export interface ProductTags
    extends ProductIdFields,
        ProductRelationshipFields,
        ResonanceUpdatedBy {
    /** Tags for this page, limit to 1000 and 96 characters each. */
    raw?: string[] | null;
    updatedAt: string;
}

export type ProductModelCreateInput = Omit<
    Product,
    keyof ProductIdFields | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type ProductModelUpdateInput = ProductIdFields &
    Partial<Omit<ProductModelCreateInput, keyof ProductRelationshipFields>>;

/**
 * status cant be set on create due to lack of possible variants that are required to publish.
 * If a one-shot Product is needed, a mutation should be created to handle all of the Product family created at once.
 */
export type ProductApiCreateInput = Omit<
    ProductModelCreateInput,
    "publishedAt" | "status"
> & {
    /** Tags for this page, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

export type ProductApiUpdateInput = ProductModelUpdateInput & {
    /** Tags for this page, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

export interface ProductGql extends Product {
    tags?: ProductTags | null;
}

export type VariantInProductCompositeApiCreateInput = Omit<
    VariantInCompositeApiCreateInput,
    "contentItemIds"
> & {
    /**
     * For contentItems where you are associating an existing contentItem, input the actual contentItemId.
     * For other contentItem Inputs, match this to the contentItemInputId you have assigned.
     */
    contentItemInputIds?: (ContentItemId | string)[] | null;
};

export interface ProductCompositeApiCreateInput {
    productInput: ProductApiCreateInput;
    productContentItemAssociationInputs?:
        | ProductContentItemAssociationInCompositeApiCreateInput[]
        | null;
    productImageFromUrlInputs?: ProductImageFromUrlInCompositeApiInput[] | null;
    productLocaleInputs?: ProductLocaleInCompositeApiCreateInput[] | null;
    variantInputs?: VariantInProductCompositeApiCreateInput[] | null;
}

export interface ProductComposite {
    product: Product;
    productContentItems?: ProductContentItem[];
    productTags?: ProductTags;
    productLocales?: ProductLocale[];
    variants?: Variant[];
}

export interface ProductSearchComposite extends ProductComposite {
    productMetrics?: ProductMetrics | null;
    variantMetricses?: VariantMetrics[] | null;
}
