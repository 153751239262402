export interface ParsedTag {
    category?: string | null;
    key?: string | null;
    value: string;
}

export class TagParser {
    static parse(rawTag: string): ParsedTag {
        const tagSplit = rawTag.split(",");

        const [first, second, ...restOfTag] = tagSplit;

        if (tagSplit.length === 1) {
            return {
                category: null,
                key: null,
                value: first,
            };
        }

        if (tagSplit.length === 2) {
            return {
                category: null,
                key: first.length === 0 ? null : first ?? null,
                value: second,
            };
        }

        return {
            category: first.length === 0 ? null : first ?? null,
            key: second.length === 0 ? null : second ?? null,
            value: restOfTag.join(","),
        };
    }

    static stringify(parsedTag: ParsedTag): string {
        const { category, key, value } = parsedTag;
        if (value.includes(",")) {
            return [
                category?.trim() ?? "",
                key?.trim() ?? "",
                value?.trim(),
            ].join(",");
        }

        return [category?.trim(), key?.trim(), value?.trim()]
            .filter(Boolean)
            .join(",");
    }

    static trim(rawTag: string): string {
        return this.stringify(this.parse(rawTag));
    }
}
