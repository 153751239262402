import { ResonanceUpdatedBy } from "../../entityComposites";
import { ResonanceDates } from "../../global";
import { ContentItem } from "../cdn";
import { ContentItemId } from "../cdn/CdnIds";

import { SeoFields } from "./common/SeoFields";
import { DemandEntityStatus } from "./DemandEntity";
import { DemandHqId, PageId, StorefrontId } from "./DemandIds";

export interface PageIdFields {
    pageId: PageId;
}

export interface PageRelationshipFields {
    demandHqId: DemandHqId;
}

/**
 * A Page is a part of the Demand Domain and represents a page with textual and image content on it, that has nothing to do with products.
 * This can range from "About Us" pages to Blog articles. Use the "pageType" field to differentiate.
 * Pages do not have locales specified, but since everything would be different anyway, "pageType" can be a composite key with locale in it.
 */
export interface Page
    extends PageIdFields,
        PageRelationshipFields,
        SeoFields,
        ResonanceDates,
        ResonanceUpdatedBy {
    /** The author of the page, limit 256 characters. */
    author?: string | null;
    /** ContentItems associated with this page. No other info needed. */
    contentItemIds?: ContentItemId[] | null;
    /** A short description of the page, limit 1000 characters. */
    description?: string | null;
    /**
     * Any string to declare a category of this page, can be used to filter pages, limit 128 characters.
     * This string must be in slug format (lowercase and dashes).
     */
    pageType: string;
    /** A primary image url for this page, can be generated from a Page ContentItem. */
    primaryImageUrl?: string | null;
    /** ISO format date when the page was published. Note that ensuing updates with status set to PUBLISHED will republish and re-set this date. */
    publishedAt?: string | null;
    /** A secondary image url for this page, often a logo or other related item. */
    secondaryImageUrl?: string | null;
    /** Slug for the URL. Limit 128 characters. */
    slug: string;
    /** The status of the page. */
    status: DemandEntityStatus;
    /** Which storefronts should have access to this page? */
    storefrontIds?: StorefrontId[] | null;
    /** The subtitle of the page, limit 256 characters. */
    subtitle?: string | null;
    /** A tertiary image url for this page, not often used, but available. */
    tertiaryImageUrl?: string | null;
    /** The title of the page, limit 256 characters. */
    title: string;
}

/**
 * The actual content itself, a child object of the Page with a 1-1 relationship.
 */
export interface PageContent
    extends PageIdFields,
        PageRelationshipFields,
        ResonanceUpdatedBy {
    contentAsString: string;
    updatedAt: string;
}

/**
 * The tags associated with this page, a child object of the Page with a 1-1 relationship.
 * This is separate due to possible size, at 1000 * 96 = 96000 bytes, way more than we want to store with many GSI indexes, but we still want Dynamo speed (and cost is comparable to S3 for these params).
 * This enables us not to pull them for list queries and such as well, this should be obscured from consumers by the API, but still obvious at the model level.
 */
export interface PageTags
    extends PageIdFields,
        PageRelationshipFields,
        ResonanceUpdatedBy {
    /** Tags for this page, limit to 1000 and 96 characters each. */
    raw?: string[] | null;
    updatedAt: string;
}

export type PageModelCreateInput = Omit<
    Page,
    keyof PageIdFields | keyof ResonanceDates | keyof ResonanceUpdatedBy
>;

export type PageApiCreateInput = Omit<
    PageModelCreateInput,
    "publishedAt" | "status"
> & {
    /** Tags for this page, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

export type PageModelUpdateInput = PageIdFields &
    Partial<
        Omit<
            Page,
            | keyof PageIdFields
            | keyof PageRelationshipFields
            | keyof ResonanceDates
            | keyof ResonanceUpdatedBy
        >
    >;

export type PageApiUpdateInput = PageModelUpdateInput & {
    /** Tags for this page, limit to 1000 and 96 characters each. */
    tags?: string[] | null;
};

export interface PageGql extends Page {
    content?:
        | (PageContent & {
              contentAsHtml: string;
          })
        | null;
    /** The primaryImageUrl, if exists, or the url of the first contentItem in the contentItemsId array for the page, if it exists.  If the primaryImageUrl is a CDN url, it can be given resize properties. */
    primaryImageUrlWithFallback?: string | null;
    tags?: PageTags | null;
}

export type PageContentApiUpsertInput = Pick<PageContent, "contentAsString"> &
    PageIdFields;

export type PageContentModelUpsertInput = PageContentApiUpsertInput &
    PageRelationshipFields;

export interface PageImageFromFileApiCreateInput
    extends PageIdFields,
        PageRelationshipFields {
    /** Limit 1000 characters */
    fileName: string;
    /** Limit 100 characters */
    fileExtension: string;
}

export interface PageImageFromUrlApiCreateInput
    extends PageIdFields,
        PageRelationshipFields {
    /**
     * The URL of the image to upload.
     *
     * Should match url structure, including https://, and should be a valid image.
     */
    url: string;
}

/**
 * Note that this is totally different from PageContent, and instead refers to "ContentItems" for Pages, being images, videos, and other.
 * This naming was held because ContentItems are technically children of Content.
 */
export type PageContentItem = Pick<
    ContentItem,
    "contentExtension" | "contentItemId" | "contentType"
> & {
    contentKey: string;
    demandHqId: DemandHqId;
};

export type PublicPageGql = Pick<
    PageGql,
    | "author"
    | "demandHqId"
    | "description"
    | "pageId"
    | "pageType"
    | "primaryImageUrl"
    | "primaryImageUrlWithFallback"
    | "publishedAt"
    | "secondaryImageUrl"
    | "seoDescription"
    | "seoTitle"
    | "slug"
    | "subtitle"
    | "tertiaryImageUrl"
    | "title"
>;

/**
 * This is what Search stores for pages.
 */
export interface PageComposite {
    page: Page;
    pageContent?: PageContent | null;
    pageTags?: PageTags | null;
}

export type PageSearchComposite = PageComposite;
