import {
    EventsEntityIdPrefix,
    ResonanceEventSubscriptionId,
} from "@buildresonance/resonance-lib-entities";
import { string } from "yup";

// eslint-disable-next-line import/prefer-default-export
export const resonanceEventSubscriptionIdSchema =
    string<ResonanceEventSubscriptionId>().matches(
        new RegExp(`^${EventsEntityIdPrefix.ResonanceEventSubscription}.*`),
    );
