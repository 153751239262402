import { ServiceClientMethod } from "../../serviceClient/serviceClientGlobal";

export enum DemandServiceClientRoute {
    GraphQL = "graphql",
}

export const DEMAND_SERVICE_CLIENT_ROUTES_TO_METHOD_MAP: Record<
    DemandServiceClientRoute,
    ServiceClientMethod[]
> = {
    [DemandServiceClientRoute.GraphQL]: ["POST"],
};
