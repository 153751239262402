import {
    CartItem,
    SavedForLaterItem,
    SAVED_FOR_LATER_ITEM_POSITION_MAX_VALUE,
} from "@buildresonance/resonance-lib-entities";
import { array, number, object, ObjectSchema, string } from "yup";

import { customPropertiesSchema } from "../../global";
import { transformNaNToNull, transformZeroToNull } from "../../numberHelpers";
import { variantIdSchema } from "../demandIds";
import {
    ORDER_ITEM_MAX_NUMBER_OF_DISCOUNTS,
    orderItemDiscountSchema,
} from "../orderItem";

export const CART_NAME_MAX_LENGTH = 64;

export const cartNameSchema = string().max(CART_NAME_MAX_LENGTH).nullable();

export const cartItemSchema: ObjectSchema<CartItem> = object({
    customProperties: customPropertiesSchema.nullable(),
    quantity: number()
        .integer()
        .min(0)
        .required()
        .transform(transformNaNToNull),
    variantId: variantIdSchema.required(),
    discounts: array()
        .of(orderItemDiscountSchema)
        .nullable()
        .max(ORDER_ITEM_MAX_NUMBER_OF_DISCOUNTS),
}).noUnknown();

export const savedForLaterItemSchema: ObjectSchema<SavedForLaterItem> = object({
    position: number()
        .integer()
        .positive()
        .max(SAVED_FOR_LATER_ITEM_POSITION_MAX_VALUE)
        .nullable()
        .transform(transformNaNToNull)
        .transform(transformZeroToNull),
    variantId: variantIdSchema.required(),
}).noUnknown();
