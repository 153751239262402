import React, { ReactElement, useCallback, useMemo, useState } from "react";

import { DemandHqId } from "@buildresonance/resonance-lib-entities";
import { useListDemandHqComments } from "@hooks/graphql/queries/useListDemandHqComments";
import CommentsIcon from "@mui/icons-material/CommentOutlined";
import { Badge, Drawer, IconButton, Tooltip, useTheme } from "@mui/material";
import { useRouter } from "next/router";

import { CommentsDrawer } from "./CommentsDrawer";
import { useDemandHqCommentEntityId } from "./useDemandHqCommentEntityId";

export const CommentsButtonParent = (): ReactElement | null => {
    const theme = useTheme();
    const router = useRouter();
    const demandHqId = router.query.demandHqId as DemandHqId | undefined;
    const entityId = useDemandHqCommentEntityId();

    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const demandHqCommentsResult = useListDemandHqComments({
        demandHqId,
        entityId,
    });

    const demandHqComments = useMemo(
        () =>
            demandHqCommentsResult?.data?.pages?.flatMap(
                (page) => page?.demandHq?.comments?.nodes,
            ) ?? [],
        [demandHqCommentsResult],
    );

    const determineBadgeContent = useCallback((): string | undefined => {
        if (demandHqComments.length > 0) {
            if (demandHqComments.length >= 10) {
                return `9+`;
            }

            return demandHqComments.length.toString();
        }

        return undefined;
    }, [demandHqComments.length]);

    const container =
        typeof window !== "undefined" ? () => window.document.body : undefined;

    const handleDrawerClose = (): void => {
        setDrawerIsOpen(false);
    };

    if (!demandHqId || !entityId) {
        return null;
    }

    return (
        <>
            <Tooltip title="Comments">
                <Badge
                    overlap="circular"
                    color="primary"
                    badgeContent={determineBadgeContent()}
                >
                    <IconButton
                        color="inherit"
                        onClick={() => setDrawerIsOpen((prev) => !prev)}
                    >
                        <CommentsIcon />
                    </IconButton>
                </Badge>
            </Tooltip>
            <Drawer
                id="comments-drawer"
                container={container}
                open={drawerIsOpen}
                onClose={handleDrawerClose}
                anchor={theme.direction === "rtl" ? "left" : "right"}
                variant="temporary"
            >
                <CommentsDrawer
                    demandHqCommentsResult={demandHqCommentsResult}
                />
            </Drawer>
        </>
    );
};
